import * as React from 'react';
 
import Box from '@mui/material/Box'; 
import Typography from '@mui/material/Typography';
import { Redirect } from "react-router-dom";

import SvgIcon from '@mui/material/SvgIcon'; 
import { ReactComponent as logo } from "./logo.svg";

// import useSound from 'use-sound'
// import backgroundfx from '../../staticData/sounds/background music.mp3';
export default function CustomizedDialogs({loadingProgress} ) {
    // const [playbackgroundfx] = useSound(backgroundfx,{volume:1});
    
    const LineStyle={
        background:"#fff",
        height:{md:"9px",xs:"6.5px"}, 
        transition: "all 0.1s ease"
    }
   if(loadingProgress>=0.99){
        // playbackgroundfx()
        return <Redirect to={"/intro"} />
   }

  return ( 
     <Box 
     sx={{
         position:"fixed",
         justifyContent:"center",
         width:"100vw",
         height:"100vh",
         backgroundSize: "cover",
     }}>
        <Box 
        sx={{
            background:"#E98300",
            width:"100vw",
            height:"100vh", 
            display:"flex",
            alignItems:"center"
        }}>
            <Box 
            sx={{
                width:"100%",  
                padding:{md:"0 228px",xs:"0 20px"}
            }}>
                <Box sx={{
                    position:"absolute",
                    width:{md:`calc(50% + 165px )`,xs:`calc(50% + 110px )`},
                    marginBottom:"20px"
                    
                }}>
                    <Box sx={{...LineStyle, 
                        position: "relative" , 
                        left: {md:"-228px",xs:"-20px"},
                        width:`calc(${loadingProgress*100}% )`,
                        bottom: {md:'8px',xs:"17px"}

                    }}></Box>
                </Box>
                <Box sx={{display:"flex",justifyContent:"center",alignItems:"center"}}>

               <SvgIcon sx={{ display:{md:"flex",xs:"none"},width:"473px" , height:269 ,margin:"auto"}}
                  component={logo} inheritViewBox /> 
                <SvgIcon sx={{ display:{xs:"block",md:"none"},width:232*1.6 , height:86*1.6 }}
                    component={logo} inheritViewBox /> 
                </Box>
             <Box sx={{
                    width:"100vw",
                    position:"absolute",
                    right:0,
                    width:{md:`calc(50% + 175px )`,xs:`calc(50% + 100px )`}
                }}>
                <Box sx={{...LineStyle, 
                    position:"absolute",
                    right:0,
                    top: {md:'0px',xs:"10px"},
                    width:`calc(${loadingProgress*100}% )`
                    }}></Box>
                 </Box>
            </Box>
        </Box>
       
    </Box>
  );
}