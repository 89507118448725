import React, {useState } from "react";
 
import Box from '@mui/material/Box'; 
import Typography from '@mui/material/Typography'; 
import Stack from '@mui/material/Stack'; 
import Button from '@mui/material/Button'; 
import IconButton from '@mui/material/IconButton'; 

// import { ReactComponent as montagem } from './icons/montagem.svg';

import SvgIcon from '@mui/material/SvgIcon'; 

import { ReactComponent as quizIcon } from "./quiz.svg"; 
import { ReactComponent as warning } from "./warning.svg"; 
import { ReactComponent as search } from "./search.svg"; 
import { ReactComponent as close } from "./close.svg"; 

export default function CustomizedDialogs({texts,txtlang,detailedScore,setselected,selected,toggleDrawer}) {

const titleStyle={
    fontWeight: 800,
    fontSize: "24px",
    lineHeight: "70px",
    textTransform: "uppercase",
    color: "#E98300",

}

const rowStyle={
    display:"flex",
}

const bodytitleStyle={
    fontWeight: 900,
    fontSize: "90px",
    lineHeight: "90px",
    textTransform: "uppercase",
    color: "#E98300",
}

const subtitleStyle={ 
    fontWeight: 900,
    fontSize: "16px",
    lineHeight: "22px", 
    letterSpacing: "0.1em",
    textTransform: "uppercase",
    color: "#2E4957",
    marginBottom:"-5px"
}

const scoreContainerStyle={
    fontWeight: 900,
    letterSpacing: "0.03em",
    textTransform: "uppercase",
    color: "#2E4957",
}

const scoreValueStyle={
    fontFamily:"AvenirBold",
    fontSize: "80px",
    lineHeight: "109px", 
}

    const scoreTotalStyle={
    fontFamily:"AvenirBold",
    fontSize: "48px",
    lineHeight: "66px",
}

const stackStyle={
    marginTop:"60px"
}

const rowRight={
    marginLeft:"20px"
}
const buttonStyle={
    marginTop:"65px",
    padding:"19px 90px" ,letterSpacing: "0.1em", fontWeight:500, width:"340px",
    fontSize: "18px",
    lineHeight: "25px"
    }
const closeStyle={
    position:"absolute",
    top:"30px",
    right:"30px"
}
const handleClose=()=>{
    toggleDrawer(false)
}
return ( <Box >
      <IconButton sx={closeStyle} onClick={handleClose} >
        <SvgIcon sx={{width:25 , height:25}} component={close} inheritViewBox /> 
      </IconButton>  
        <Typography sx={titleStyle}  >
           {texts.GameRes[txtlang]}  
        </Typography> 
        <Typography sx={bodytitleStyle}  >
        {texts.GameRes[txtlang]} 
        
        </Typography> 
        <Stack direction="column" spacing={4} sx={stackStyle}  >
            <Box sx={rowStyle}>
                <SvgIcon sx={{width:104 , height:104}}
                component={quizIcon} inheritViewBox /> 
                <Box sx={rowRight}>
                    <Typography sx={subtitleStyle}  >
                      quiz
                    </Typography>
                    <Box sx={scoreContainerStyle}>
                        <Typography component="span"  sx={scoreValueStyle}  >
                            {detailedScore.quiz[0]}
                        </Typography>
                        <Typography component="span" sx={scoreTotalStyle}  >
                            /{detailedScore.quiz[1]}

                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box sx={rowStyle}>
                <SvgIcon sx={{width:104 , height:104}}
                component={warning} inheritViewBox /> 
                <Box sx={rowRight}>
                    <Typography sx={subtitleStyle}  >
                        {texts.rhg[txtlang]} 
                    </Typography>
                    <Box sx={scoreContainerStyle}>
                        <Typography component="span"  sx={scoreValueStyle}  >
                        {detailedScore.risk[0]}
                        </Typography>
                        <Typography component="span" sx={scoreTotalStyle}  >
                            /{detailedScore.risk[1]}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box sx={rowStyle}>
                <SvgIcon sx={{width:104 , height:104}}
                component={search} inheritViewBox /> 
                <Box sx={rowRight}>
                    <Typography sx={subtitleStyle}  >
                    {texts.fre[txtlang]} 
                    </Typography>
                    <Box sx={scoreContainerStyle}>
                        <Typography component="span"  sx={scoreValueStyle}  >
                        {detailedScore.find[0]}
                        </Typography>
                        <Typography component="span" sx={scoreTotalStyle}  >
                            /{detailedScore.find[1]}
                        </Typography>
                    </Box>
                </Box>
            </Box>

        </Stack>
            <Button sx={buttonStyle}  variant="contained" onClick={handleClose}>
                {texts.got[txtlang]} 
            </Button>
    </Box>
    );
}