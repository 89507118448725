import React, {useState ,useEffect} from "react";
import { Link,useHistory } from "react-router-dom";

import cover from "./BoardCover.png"
 
import Box from '@mui/material/Box'; 
import Typography from '@mui/material/Typography';  
import Container from '@mui/material/Container'; 
import Button from '@mui/material/Button'; 
import Scenarios from "./scenarios"
import Modules from "./Modules"
import {examData} from "../../staticData/QuizData" 
import Autostop from '../Autostop/Autostop'
 

export default function CustomizedDialogs({txtlang,texts,isPT,playclicksfx,selected,setselected,finsihedModule,setfinsihedModule,setData,data,tokenExams,completedScenario,setcompletedScenario,ActiveScenario,setActiveScenario,title,body,isScenarios}) {
    const history = useHistory();
    
const translatedexamData = examData[isPT]
    
    const setDoneModule =()=>{

        const newselected = selected.filter(s=>!finsihedModule.includes(s))
        const oldFinished = [...finsihedModule, ...newselected ]
        setfinsihedModule(oldFinished)
    }

    const iconFontStyle={
        fontFamily: 'Avenir',
        fontWeight: 800,
        fontSize: "24px",
        lineHeight: "70px",
        textAlign: "center",
        textTransform: "uppercase",
        color: "#FFFFFF",
        '@media (max-width:800px)': {
            lineHeight: "60px",
            fontSize: "12px"
        },
    }

    const infoTextStyle={
        marginTop:"-10px",
        lineHeight: "68px",
        color: "#FFFFFF",
        textAlign:"center",
        fontFamily: 'Avenir',
        fontWeight: 900,
        fontSize: "50px",
        lineHeight:" 68px",
        '@media (max-width:800px)': {
            fontSize: "20px",
            lineHeight:"32px",
            padding:"0 60px"

        },
    }

    const shuffleArray = array => {
        const arr = [...array]
        for (let i = arr.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          const temp = arr[i];
          arr[i] = arr[j];
          arr[j] = temp;
        }
        return arr
      }

    const merge=()=>{
        let allData ={}
        selected.forEach(s => { 
           const examScenarioIndexs = Object.keys(translatedexamData[s])
            examScenarioIndexs.forEach(e=>{
                if(allData[e]!==undefined){
                    const uniq = translatedexamData[s][e].filter(x=>{
                        let flag = true
                        allData[e].forEach(a=>{
                            if(a.ID ==x.ID){
                                flag = false
                            }
                        })
                        return flag
                    
                    }).filter(x=>!tokenExams.includes(x.ID))
                    allData[e] = [...allData[e],...uniq]
                }else{
                     const uniq = translatedexamData[s][e].filter(x=>!tokenExams.includes(x.ID))
                    allData[e] = [...uniq]
                }
                allData[e]=shuffleArray(allData[e])
            })
        });
       return  allData
    }
    const SelectScenario =()=>{
        playclicksfx()
        if(isScenarios){
            history.push("/riskhunt");
        }else{
            const mergeddata = merge()
            setData(mergeddata)
            history.push("/scenarios");
            // history.push("/areas");
        }
        
    }


  return ( 
     <Box 
     sx={{
        boxSizing: "border-box",

         background:`url(${cover})`,
         width:"100vw",
        //  minHeight:"100vh",
         height:"100%",
         backgroundSize: "cover",
         backgroundPositionY: "center",
         backgroundPositionX: "center",
         
     }}>
    {/* <Autostop handlestopPopup={()=>{}} openstopPopup={true}/> */}

        <Box 
        sx={{
            background:"rgba(46, 73, 87, 0.85)",
            width:"100vw",
            boxSizing:"border-box",
            minHeight:"100vh",
            height:"100%",
            display:"flex",
            paddingLeft:"40px",
            display:"flex",
            flexDirection:"column",
            alignItems:"center",
            padding:"146px 0px 80px",
            '@media (max-width:800px)': {
                padding:"100px 0 20px",
              },
        }}>
        
            <Typography sx={iconFontStyle}  >
                <Box sx={{display:{xs:"none",sm:"block"}}}>
                 {!isScenarios?texts.area1[txtlang]:texts.scen[txtlang]}   
                </Box>

            </Typography> 
            
            <Typography sx={infoTextStyle}  >
                <Box>
                 {!isScenarios?texts.area2[txtlang]:texts.choosesen[txtlang]}   
                </Box>
                
            </Typography>
         
            <Container maxWidth="xl" sx={{
                display:"flex",
                flexDirection:"column",
                justifyContent:"center",
                alignItems:"center",
                // width:"100vw",
                // border:"1px solid green",
                paddingRight:"25px",
                paddingLeft:"25px"
            }}>       
            {isScenarios?<Scenarios 
            txtlang={txtlang}
            texts={texts}
            playclicksfx={playclicksfx}
            setselected={setselected}
            setDoneModule={setDoneModule}
            setfinsihedModule={setfinsihedModule}
            finsihedModule={finsihedModule}
            data={data}
            tokenExams={tokenExams}
            setActiveScenario={setActiveScenario} 
            ActiveScenario={ActiveScenario}
            completedScenario={completedScenario}
            setcompletedScenario={setcompletedScenario}
            />:
             
            <Modules
             txtlang={txtlang} texts={texts} playclicksfx={playclicksfx} finsihedModule={finsihedModule} selected={selected} setselected={setselected}/>
            }
          {((!isScenarios && selected.length) || (ActiveScenario && Object.keys(ActiveScenario).length))?
            <Button sx={{
                display:"block",
                marginTop:"66px",
                padding:"20px 90px" , 
                fontWeight:500,
                fontSize: "18px",
                lineHeight: "25px", 
                letterSpacing:"0.1em",
                '@media (max-width:800px)': {
                    fontSize: "12px",
                    padding:"12px 90px" , 
                    marginTop:"13px",
                    width:"calc(100%)"
                },
                }} 
                variant="contained"
               onClick={SelectScenario}
                >
                {texts.cont[txtlang]}
                </Button>
          :null}
           </Container>
          
        </Box>
    </Box>
  );
}