import * as React from 'react';  
import IconButton from '@mui/material/IconButton'; 
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box'; 
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button'; 

import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as Play } from "./Play.svg"

const buttonStyle={
    marginTop:"5px",
    padding:"1px 20px",
    fontWeight: 500,
    fontSize: {md:"18px",xs:"14px"},
    lineHeight:{md:"35px",xs:"29px"}, 
}
 
export default function CustomizedDialogs({texts,txtlang,btnTut,playclicksfx,hasCorrection , sethasCorrection,correctionVideo,setcloseRotateAnimation,Interval,setPlayed} ) {

  function handleClick(){
    playclicksfx()
    console.log("hasCorrection",hasCorrection)
    if(hasCorrection==true){
      console.log("Next will work")
      sethasCorrection(10)
    }else if(hasCorrection==10){
      console.log("Next will be disabled")
      sethasCorrection(false)
    }
    var videotag = document.getElementById("video")
    videotag.play()
    setPlayed(true) 
    Interval()
    setcloseRotateAnimation(true)
  }   
   
  return ( 
     <Box 
     sx={{  
      background: "#faa0", 
      position: "fixed",
      top: 0,
      left: 0,
         width:"100vw",
         height:"100vh",
         backgroundSize: "cover",
         zIndex:1000000,

     }}>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true} 
        >
        <Box 
        sx={{ 
            width:"100vw",
            height:"100vh", 
            display:"flex",
            alignItems:"center",
            justifyContent:"center",
            flexDirection:"column"
        }}>
          {hasCorrection===10?<Typography
          gutterBottom
           sx={{
            color:"white",
            marginTop:"20px",
            marginBottom:"20px",
            fontWeight: 700,
            fontSize: "24px",
            textTransform: "uppercase"
       }}
          >
            {texts.behav[txtlang]}
          </Typography>:null}
          
            <IconButton onClick={handleClick} >

             <SvgIcon sx={{width:{md:"70px",xs:'50px'},height:{md:"70px",xs:'50px'} }}  component={Play} inheritViewBox /> 
            </IconButton>
           <Typography sx={{
                color:"white",
                marginTop:"20px",
                fontWeight: 500,
                fontSize: {md:"18px",xs:"12px"},
                lineHeight:{md:"35px",xs:"29px"},
                textTransform: "uppercase"
           }}>
            {texts.r[txtlang]}
           </Typography> 
           <Button onClick={handleClick} sx={buttonStyle}  variant="contained" >
           {btnTut?texts.plytut[txtlang]:   texts.clck[txtlang]}
            </Button>
        </Box>
        </Backdrop>
    </Box>
  );
}