import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';



export default function FormPropsTextFields({txtlang ,texts,playswipefx,sethovered, setTeamIsregister}) {
  // txtlang ,texts
  const {singlereg , sing,  register} = texts
  return (
    <Box 

    onMouseEnter={()=>sethovered(true)}
    onMouseLeave={()=>sethovered(false)}
    sx={{

        width:"100%",
        display:"flex",
        jusifyContent:"center",
        alignItems:"center",
        textAlign:"center",
        flexDirection:"column"
      }}>
        <Typography sx={{     
          fontFamily:"AvenirBold",           
          fontWeight: 900,
          fontSize: "40px",
          lineHeight: "50px",
          textTransform:" uppercase",
          color: "#FFFFFF",
          textAlign:"center"
        }}  >
           {singlereg[txtlang]}
        </Typography> 
        <Typography
        onClick={()=>alert("helllo")}
        sx={{
          fontWeight: 800,
          fontSize: "18px",
          lineHeight: "25px",
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          letterSpacing: "0.01em",
          textTransform: "uppercase",
          color: "rgba(255, 255, 255, 0.7)",
        }}  >
            {sing[txtlang]}
        </Typography> 
        
        <Button sx={{
            width:"280px",
            marginTop:"40px",
            padding:"20px 10px" , 
            fontWeight:500,
            fontSize: "18px",
            lineHeight: "25px", 
            letterSpacing:"0.1em",
          }}
            variant="contained"
            color="secondary"
  
          onClick={()=>{
            playswipefx()
            setTeamIsregister(false)
            sethovered(false)
          }}
          >
            {register[txtlang]}
            </Button>
        </Box>
  );
}