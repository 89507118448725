import React from "react";
 
 
import Box from '@mui/material/Box'; 
import Button from '@mui/material/Button'; 
import Typography from '@mui/material/Typography';
import Input from "./CustomInput"
import {Link} from "react-router-dom" 
import isPhone from "../../helpers/isPhone"

export default function FormPropsTextFields({setTeamIsregister,txtlang ,texts,playclicksfx,lsethovered}) {
  const { welcom,savecont,singlereg,name,idemp,upto5emp} = texts
  // txtlang
    const iconFontStyle={
        fontFamily: 'Avenir',
        fontWeight: 800,
        fontSize: "24px",
        lineHeight: "70px",
        textTransform: "uppercase",  
        color: "#FFFFFF",
        display: { xs: 'none', sm: 'block' } 
        
      }
      
      const infoTextStyle={
        fontFamily:"AvenirBold",
        fontWeight: 900,
        fontSize: "90px",
        lineHeight: "90px",
        textTransform: "uppercase",  
        color: "#FFFFFF",
        // marginBottom:"60px",
        '@media (max-width:800px)': {
          lineHeight: "40px",
          color: "#FFFFFF",
          marginTop:"140px",
          fontSize: "28px"
        },
      }
      const subTextInfo = {
        fontWeight: 800,
        fontSize: "14px",
        lineHeight: "40px",
        letterSpacing: "0.1em",
        textTransform: "uppercase",
        color: "#FFFFFF",
      }
      const subSub={
        fontWeight: 900,
        fontSize: "12px",
        lineHeight: "16px",
        letterSpacing: "0.01em",
        textTransform: "uppercase",
        color: "rgba(255, 255, 255, 0.7)",
        marginBottom:{md:"40px",xs:"20px"}
      }
  return (

    <Box 
      sx={{
        height:"100%",
        '@media (min-width:800px)': {
          padding:"146px 230px",
        },
          
          width:"100%"
      }}>

      <Typography sx={iconFontStyle}  >
      {welcom[txtlang]}
      </Typography>
      

      <Typography sx={infoTextStyle}  >
      {singlereg[txtlang][0] }
      <Box component="br" sx={{ display: { xs: 'none', sm: 'block' } }}></Box> 
      {singlereg[txtlang][1] }
      </Typography>

        
      <Box sx={{display: { xs: 'block', sm: 'none' }}}>
        <Typography onClick={()=>setTeamIsregister(true)}  sx={subTextInfo}>
        OR <Box component="span" sx={{textDecoration:"underline", cursor:"pointer"}}> register as a team &#8594; </Box>
        </Typography>

        <Typography sx={subSub}>
        {upto5emp[txtlang]}
        </Typography>
      </Box>
            
      <Box sx={{
        // width:"537px",
        '@media (min-width:800px)': {
        width:"537px",
        },
      }}>
        
      <Input noDelete={true} label={name[txtlang]} placeholder="Amanda Soares"/>
      <Input noDelete={true} label={idemp[txtlang]} placeholder=""/>

      </Box>
      <Button sx={{
         '@media (min-width:800px)': {
          width:"510px",
          padding:"20px 90px" , 
          fontSize: "18px",
          lineHeight: "25px", 
          marginTop:"40px",

          },
        lineHeight: "16px", 
        fontSize: "12px",
        padding:"14px 20px" , 
        marginTop:"30px",
        fontWeight:500,
        letterSpacing:"0.1em",
      }} 
      fullWidth
      variant="contained"
      color="secondary"
      component={Link}
      to="/tutorial360"
      onClick={()=>playclicksfx()}
      >
   {savecont[txtlang]}
        </Button>
    </Box>
 );
}