import React, {useState ,useEffect} from "react";
import Button from '@mui/material/Button'; 
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CustomDialogTitle from "../Dialog/DialogTitle"
import { Link,useHistory ,useLocation} from "react-router-dom";
 
 
export default function ConfirmationPopup({txtlang,remainingHotspots,playclicksfx , restartVideo,openrestartPopup}) {
    const [data,setData] = useState({
        title:{
          "English version":"Looks like the video ended",
          "Portuguese version":`Parece que o vídeo terminou` ,      
        },
        body:{
          "English version":`You still have ${remainingHotspots} hotspots to find and some time left.`,
          "Portuguese version":`Ainda tem ${remainingHotspots} hotspots por descobrir e algum tempo` ,      
        },
        btn1:{
          "English version": "Click to restart the video",
          "Portuguese version":"Clique para recomeçar o vídeo" ,      
        }, 
    })
    
 const {title,body,btn1,btn2} = data

   useEffect(() => {
 

  }, []); 
     const history = useHistory();

    
  return ( 
      <Dialog
        onClose={restartVideo}
        aria-labelledby="customized-dialog-title"
        open={openrestartPopup}
        sx={{
          '& .MuiPaper-root ': {
            maxWidth:"1000px",
            padding: `${"60px 80px 75px"}`,
        }
        }}>
        <CustomDialogTitle id="customized-dialog-title" onClose={restartVideo}/>

        <Grid container spacing={2} sx={{alignItems:"center"}} >
          
          <Grid item xs={12}>
            <DialogContent sx={{paddingTop: "10px", justifyContent:"center", display:"flex", flexDirection:"column", alignItems:"center"}} >
            <Typography color="secondary" variant='h4' sx={{ fontFamily:"AvenirBold", fontWeight:"bolder",fontSize:"44px"}} gutterBottom>
              {title[txtlang]}
            </Typography>
            <Typography sx={{lineHeight:2.2, fontSize:16}} >
              {body[txtlang]} 
            </Typography> 

              <Button sx={{ marginTop:"80px",marginBottom:"20px",padding:"20px 66px" ,letterSpacing: "0.1em", fontWeight:500,fontWeight: 500,fontSize: '18px',lineHeight: '25px'}}
                variant="contained" onClick={()=>{
                restartVideo()
                playclicksfx()
              }}>
                {btn1[txtlang]}
              </Button>

              {/* <Button onClick={()=>{
                GoHome()
                playclicksfx()
                }} sx={{padding:"10px" ,letterSpacing: "0.1em", fontWeight:500,textDecoration: "underline"}} color="secondary">
                {btn2}
              </Button> */}
          
            </DialogContent>

          </Grid>
        </Grid>
      </Dialog> 
  );
}