import React, {useState ,useEffect} from "react";
import Button from '@mui/material/Button'; 
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CustomDialogTitle from "../Dialog/DialogTitle"
import { Link,useHistory ,useLocation} from "react-router-dom";
 


export default function ConfirmationPopup({ texts, txtlang,handleHurryUpClose,openHurryUp}) {

    const {title,body,btn1} = {
        title:texts.hury[txtlang],
        btn1:texts.contply[txtlang],
    }

   useEffect(() => {
   
  }, []);  

  return ( 
      <Dialog
        onClose={()=>{}}
        aria-labelledby="customized-dialog-title"
        open={openHurryUp}
        sx={{
          '& .MuiPaper-root ': {
            maxWidth:"1000px",
            padding: {md:"50px 130px 60px",xs:"20px 0px 20px"},
            // padding: {md:"60px 80px 75px",xs:"40px 00px 55px"},

        }
        }}>
        <CustomDialogTitle id="customized-dialog-title" onClose={handleHurryUpClose}/>

        <Grid container spacing={2} sx={{alignItems:"center"}} >
          
          <Grid item xs={12}>
            <DialogContent sx={{paddingTop: "10px", justifyContent:"center", display:"flex", flexDirection:"column", alignItems:"center"}} >
            <Typography color="secondary" variant='h4' sx={{
               fontFamily:"AvenirBold", fontWeight:"bolder",
                fontSize:{md:"44px",xs:"20px"},
                marginBottom:{xs:"18px",md:"0"}
              
               }} gutterBottom>
              {title}
            </Typography>
            <Typography sx={{textAlign:"center", lineHeight:{md:2.2,xs:"26px"}, fontSize:16}} >
            {texts.active[txtlang]}

            </Typography> 

              <Button sx={{ 
                  width:{xs:"100%",md:"340px"},
                marginTop:{md:"60px",xs:"40px"},marginBottom:"20px",
                padding:{md:"20px 66px",xs:"10px 0px"},
                fontSize:{xs:"12px",md:"18px"},
                letterSpacing: "0.1em", fontWeight:500,lineHeight: '25px'}}  variant="contained"
           
                onClick={()=>{
                handleHurryUpClose()
              }}>
                {btn1}
              </Button>
            </DialogContent>

          </Grid>
        </Grid>
      </Dialog> 
  );
}