import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
 
import SvgIcon from '@mui/material/SvgIcon'; 

export default function SimplePaper({colored,ishp,icon,Nonumber,title,denominator,numerator}) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        height:"40px",
        '& > :not(style)': {
          color:"#fff",
          alignItems: "base-line",
          fontWeight:900
        },
      }}
    >
     {!Nonumber?
      <Box
        sx={{
          background: "rgba(255, 255, 255, 0.3)",
          height:"3px",
          width:"100%"
        }}>
          <Box
        sx={{
          background: (theme) => theme.palette.primary.main,
          height:"3px",
          width:`${(denominator/numerator)*100}%`,
          // transition: "all 2s"
        }}></Box>
        </Box>:null}

      <Paper square elevation={4}  
      sx={{ 
        display:"flex", 
        justifyContent:!Nonumber?"space-between":"flex-end",
          width:!Nonumber?180:null, 
          height:20, 
          p:"7px 25px 12px ",
          background: (theme) => !colored && ishp?theme.palette.primary.main:theme.palette.secondary.main,
       }} >

{Nonumber? <SvgIcon sx={{width:25 , height:25}} component={icon} inheritViewBox />:null}

      
        <Typography sx={{
          marginLeft:"7px",
          justifyContent: "flex-end",
            textAlign:"center",
            color: "rgba(255, 255, 255, 0.5)", 
            fontSize: "16px",
            letterSpacing: "0.1em",
            textTransform: "uppercase",
            fontWeight:"bold",
            }} variant='paper-title'>
                {title}
        </Typography> 
        {!Nonumber? <Typography sx={{ 
            fontSize: "16px",
            letterSpacing: "0.03em", 
            fontWeight:"500",
            marginBottom:"-10px",
            marginTop: "1px"
            }} variant='body2' >{denominator}/{numerator}</Typography>:null}
        
      </Paper>
     
    </Box>
  );
}
