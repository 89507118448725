import React from "react";
// const texts = {
//     true:{
//         // EN
//         introTitle:"RISK awareness",
//         introBody:"Are you an expert of safety at work?",
//         introBtn:"READY TO START",

//     },
//     false:{
//         // PT 
//         introTitle:"CONSCIÊNCIA DE RISCO",
//         introBody:"Você é um especialista em segurança no trabalho?",
//         introBtn:"PRONTO PARA COMEÇAR",
//     }
// }


const texts = {
    introTitle:{
     "English version": "RISK AWARENESS",
     "Portuguese version": "PREVENÇÃO DE RISCOS",
    },
    introBody: {
     "English version": "Are you an expert of safety at work?",
     "Portuguese version": "É um especialista em segurança no trabalho?",
    },
    introBtn:{
     "English version": "READY TO START",
     "Portuguese version": "PRONTO PARA COMEÇAR",
    },
    welcom: { //d
     "English version": "WELCOME",
     "Portuguese version": "BEM-VINDO",
    },
    singlereg:{ 
     "English version": ["SINGLE ", "REGISTER"],
     "Portuguese version":["REGISTO" , "ÚNICO"],
    },
    register:{
        "English version": "register",
        "Portuguese version": "REGISTAR",
    },
    sing:{
        "English version": "register as a single player",
        "Portuguese version": " registar como jogador único",  
    },
    name:{
     "English version": "Name",
     "Portuguese version": "Nome",
    },
   idemp:{
     "English version": "ID Employee",
     "Portuguese version": "ID de Funcionário",
    },
    and:{
      "English version": ", and",
      "Portuguese version": ", e",
    },
   savecont: { //d
     "English version": "SAVE AND CONTINUE",
     "Portuguese version": "SALVE E CONTINUE",
    },
    teamReg:{
     "English version": ["TEAM " ,"REGISTER"],
     "Portuguese version": ["REGISTO ", "DE EQUIPA"],
    },
    reg5:{
     "English version": "REGISTER UP TO 5 USERS",
     "Portuguese version": "REGISTE ATÉ 5 UTILIZADORES",
    },
   regnow: {
     "English version": "REGISTER NOW",
     "Portuguese version": "REGISTAR AGORA",
    },
    addMemb:{
     "English version": "ADD NEW MEMBER",
     "Portuguese version": "ADICIONAR NOVO MEMBRO",
    },
    upto5emp:{
     "English version": "UP TO 5 EMPLOYEES",
     "Portuguese version": "ATÉ 5 FUNCIONÁRIOS",
    },
    whatyourTeamName:{
     "English version": "What’s your Team Name?",
     "Portuguese version": "Qual é o nome da sua equipa?",
    },
//    Soundon: {
//      "English version": "SOUND ON",
//      "Portuguese version": "SOM LIGADO",
//     },
    // {
    //  "English version": "Improve your experience by turning ON your audio",
    //  "Portuguese version": "Melhore a sua experiência ativando o som",
    // },
    // {
    //  "English version": "360º INTERACTION",
    //  "Portuguese version": "INTERAÇÃO 360º",
    // },
    // {
    //  "English version": "Hold and drag the screen to explore scenarios",
    //  "Portuguese version": "Segure e arraste o ecrã para explorar cenários",
    // },
    // {
    //  "English version": "TIME IS COUNTING",
    //  "Portuguese version": "O TEMPO ESTÁ A CONTAR",
    // },
    // {
    //  "English version": "Total time will be tracked during the entire game",
    //  "Portuguese version": "O tempo total será monitorizado durante todo o jogo",
    // },
    nwcompleted:{
      "English version": "now completed",
      "Portuguese version": "agora completo",
    },
    are:{
      "English version": "are",
     "Portuguese version": "estão",
    },
    is:{
      "English version": "is",
     "Portuguese version": "está",
    },
    area1:{
     "English version": "WHERE DO YOU WORK?",
     "Portuguese version": "ONDE TRABALHA?",
    },
    area2:{
     "English version": "Choose the work areas where you usually work",
     "Portuguese version": "Escolha as áreas de trabalho onde costuma trabalhar",
    },
    assemp:{
     "English version": "Assembly",
     "Portuguese version": "Montagem ",
    },
    ficha:{
     "English version": "Prefabrication",
     "Portuguese version": "Pré-Fabricação",
    },
    admin:{
     "English version": "Administrative area",
     "Portuguese version": "Área administrativa",
    },
    warehouse:{
     "English version": "Warehouse",
     "Portuguese version": "Armazém",
    },
    cont:{
     "English version": "CONTINUE",
     "Portuguese version": "CONTINUAR",
    },
    scen:{
     "English version": "SCENARIOS",
     "Portuguese version": "CENÁRIOS",
    },
   choosesen: {
     "English version": "Now it’s time to choose a Scenario to play!",
     "Portuguese version": "Agora é hora de escolher um cenário para jogar!",
    },
   s2 :{
     "English version": "Scenario",
     "Portuguese version": "Cenário",
    },
    r:{
     "English version": "READY?",
     "Portuguese version": "PRONTO?",
    },
   clck: {
     "English version": "CLICK PLAY TO START",
     "Portuguese version": "CLIQUE NO PLAY PARA COMEÇAR",
     },
     plytut:{
      "English version": "Click play to start tutorial",
      "Portuguese version": "CLIQUE NO PLAY PARA COMEÇAR tutorial",
     },
    prog:{
     "English version": "PROGRESS",
     "Portuguese version": "PROGRESSO",
    },
    tl:{
     "English version": "TIME LEFT",
     "Portuguese version": "TEMPO RESTANTE",
    },
    gs:{
     "English version": "GAME SCORE",
     "Portuguese version": "PONTUAÇÃO DO JOGO",
    },
   ts: {
     "English version": "TOTAL SCORE",
     "Portuguese version": "PONTUAÇÃO TOTAL",
    },
   ques: {
     "English version": "DO YOU KNOW THE ANSWER?",
     "Portuguese version": "SABE A RESPOSTA?",
    },
    checkans:{
     "English version": "CHECK MY ANSWER",
     "Portuguese version": "CONFIRMAR RESPOSTA",
    },
    showh:{
     "English version": "SHOW HINTS",
     "Portuguese version": "MOSTRAR DICAS",
    },
    hideh:{
      "English version": "hide hints",
      "Portuguese version": " Esconder dicas",
     },
   hs: {
     "English version": "HOTSPOTS",
     "Portuguese version": "HOTSPOTS",
    },
   found: {
     "English version": "YOU’VE FOUND THE SPOT!",
     "Portuguese version": "ENCONTROU O PONTO!",
    },
   got: {
     "English version": "GOT IT!",
     "Portuguese version": "ENTENDI!",
    },
    ply:{
     "English version": "LET'S PLAY!",
     "Portuguese version": "VAMOS JOGAR!",
    },
   qz: {
     "English version": "QUIZ",
     "Portuguese version": "QUIZ",
    },
   pa: {
     "English version": "PAY ATTENTION",
     "Portuguese version": "PRESTE ATENÇÃO",
    },
   watch: {
     "English version": "Watch the video and pay atention to the action",
     "Portuguese version": "Assista ao vídeo e preste atenção à ação",
    },
//    time: {
//      "English version": "TIME",
//      "Portuguese version": "TEMPO",
//     },
    // {
    //  "English version": "Can you answer correctly in under a minute and a half?",
    //  "Portuguese version": "Consegue responder corretamente em menos de 60 segundos?",
    // },
    // {
    //  "English version": "There are several Hazards and Risks in the room",
    //  "Portuguese version": "Existem vários perigos e riscos na sala",
    // },
    // {
    //  "English version": "Can you find the all under 6 minutes?",
    //  "Portuguese version": "Consegue encontrar todos em menos de 5 minutos?",
    // },
    // {
    //  "English version": "If you get stuck, call for some hints",
    //  "Portuguese version": "Se ficar preso, peça algumas dicas",
    // },
    chk:{
     "English version": ["Let's check" , "how it works"],
     "Portuguese version": ["Vamos verificar", "como funciona"],
    },
    rhg:{
     "English version": "RISK HUNT",
     "Portuguese version": "CAÇA AO PERIGO",
    },
  fre:  {
     "English version": "FIND RIGHT ELEMENTS",
     "Portuguese version": "ENCONTRE OS ELEMENTOS CERTOS",
    },
    ops:{
     "English version": ["OOPS..." , "TRY AGAIN!"],
     "Portuguese version": ["Ops..." , "TENTE NOVAMENTE!"],
    },
    ca:{
     "English version": "CORRECT ANSWERS",
     "Portuguese version": "RESPOSTAS CORRETAS",
    },
   scoreh :{
     "English version": "Your score should be higher than 80 to complete a module with success",
     "Portuguese version": "A sua pontuação deve ser superior a 80 para concluir um módulo com sucesso",
    },
  scorelow:  {
     "English version": "INCORRECT ANSWERS",
     "Portuguese version": "RESPOSTAS INCORRETAS",
    },
   totTime: {
     "English version": "TOTAL TIME",
     "Portuguese version": "TEMPO TOTAL",
    },
    plyagain:  {
   "English version": "TRY AGAIN",
     "Portuguese version": "TENTE NOVAMENTE",
    },
    weldone:{
     "English version": ["WELL " , "DONE!"],
     "Portuguese version": "PARABÉNS!",
    },
   comp: {
     "English version": "is now complete",
     "Portuguese version": "agora está completo",
    },
   revres:{
     "English version": "REVIEW MY RESULT",
     "Portuguese version": "REVER O MEU RESULTADO",
    },
    sendRes:{
     "English version": "SEND RESULT BY EMAIL",
     "Portuguese version": "ENVIAR RESULTADO POR E-MAIL",
    },
    GameRes:{
     "English version": "GAME RESUME",
     "Portuguese version": "RESUMO DO JOGO",
    },
   Here: {
     "English version": <>here <br/> you are</>,
     "Portuguese version": "AQUI ESTÁ",
    },
   Thank: {
     "English version": "THANK YOU!",
     "Portuguese version": "OBRIGADO!",
    },
    Sent:{
     "English version": "YOUR EMAIL WAS SENT",
     "Portuguese version": "O SEU E-MAIL FOI ENVIADO",
    },
   retrnh: {
     "English version": "RETURN TO HOME",
     "Portuguese version": "RECOMEÇAR",
    },
    plyother:{
     "English version": "PLAY ANOTHER MODULE",
     "Portuguese version": "JOGUE OUTRO MÓDULO",
    },
    wantply:{
     "English version": "Do you want to Try Again?",
     "Portuguese version": "Quer tentar novamente?",
    },
   moretime: {
     "English version": "You can test your safety knowledge one more time!",
     "Portuguese version": "Pode testar os seus conhecimentos de segurança mais uma vez!",
    },
    iwanttry:{
     "English version": "YES, I WANT TO TRY AGAIN",
     "Portuguese version": "SIM, QUERO TENTAR NOVAMENTE",
    },
    backscore:{
     "English version": "BACK TO SCORE",
     "Portuguese version": "VOLTAR À PONTUAÇÃO",
    },
   retHome: {
     "English version": "DO YOU WANT TO RETURN TO HOME?",
     "Portuguese version": "QUER RECOMEÇAR?",
    },
   lostall: {
     "English version": "Are you sure you want to quit? All progress will be lost!",
     "Portuguese version": "Tem a certeza que quer sair? Todo o progresso será perdido!",
    },
   contply: {
     "English version": "CONTINUE PLAYING",
     "Portuguese version": "CONTINUE A JOGAR",
    },
    goHome:{
     "English version": "I WANT TO GO HOME",
     "Portuguese version": "QUERO RECOMEÇAR",
    },
   hury:{
     "English version": "You should Hurry Up!",
     "Portuguese version": "Deve apressar-se",
    },
    active: {
    "English version": <> Is everything ok? You haven't been active in 90 seconds.<br/> If you are having difficulties, use "SHOW HINTS" </> ,
     "Portuguese version":  <>Está tudo bem? Não está ativo há 90 segundos. <br/> Se estiver com dificuldades, use "MOSTRAR DICAS"</>,
    },
    ended:{
      "English version": "Game ended",
      "Portuguese version": "O jogo terminou",
    },
    behav:{
      "English version": "LET’S SEE THE CORRECT BEHAVIOUR!",
      "Portuguese version": "Vamos ver o comportamento correcto!",
    },
    look:{
      "English version": "LOOK AROUND AND GET READY TO ANSWER",
      "Portuguese version": "Olhe à sua volta e prepare-se para responder",
    },  
    inter:{
      "English version": "Click and drag to view in 360º",
      "Portuguese version": "Clique e arraste para ver em 360º",
    },
    splshtut:{
      "English version": "Risk Hunt-How to find risks",
      "Portuguese version": "Caça ao Perigo-Como encontrar riscos",      
    },
    rhtip:{
      "English version": "Risk Hunt-Tip ",
      "Portuguese version": "CAÇA AO PERIGO - DICA ",      
    },
    t1:{
      "English version": [`Click and drag with your mouse to rotate the video.`, `Once you stopped dragging look for incorrect behaviours or equipments on the 360º video.`],
      "Portuguese version": [`Clique e arraste com o rato para rodar o vídeo.`, `Assim que termine de arrastar procure os comportamentos ou equipamentos incorrectos no vídeo 360º.`],      
    },
    t2:{
      "English version": [`Once you identify an error, click on it.`, `In this example, we notice the employee is not using all the required protection equipment.`],
      "Portuguese version":[`Assim que identifique um erro, clique nele.`, `Neste exemplo, reparamos que a colaboradora não está a usar todos os equipamentos de proteção necessários.`] ,      
    },
    t3:{
      "English version":[`Once the error is identified an informative pop-up will appear.`, `A +10 point increase will happen to your score!`],
      "Portuguese version":[`Assim que o erro seja identificado irá aparecer um pop-up informativo.`, `O seu score será aumentado com +10 pontos!`], 
    },
    t4:{
      "English version":[`After you finish reading the information click the “GOT IT!” button to close the pop-up and resume the game.`] ,
      "Portuguese version":[` Assim que termine de ler a informação clique no botão "ENTENDI!" para fechar o pop-up e continuar o jogo.`] ,
    },
    t5:{
      "English version":[`If you’re having trouble finding a Risk you can use the “SHOW HINTS” button.`, `A white marker for the risk’s position will appear.`] ,
      "Portuguese version": [`Se está a ter dificuldades em encontrar um risco, pode usar o botão "MOSTRAR DICAS".`, `Um ícone branco irá aparecer para marcar a posição do risco.`],      
    },
    t6:{
      "English version":[`The game ends once you found all the risks in the Scenario or once the time runs out.`, `Track how many hotspots are missing with the hotspots counter.`],
      "Portuguese version":[`O jogo termina assim que encontrar todos os riscos no cenário ou quando o tempo terminar.`, `Verifique o número de hotspots que ainda lhe faltam com o contador de hotspots.`] ,      
    },
    lk:{
      "English version":"LOOKS LIKE SOMETHING ISN'T BEING DONE PROPERLY!",
      "Portuguese version":`PARECE QUE ALGUMA COISA NÃO ESTÁ A SER FEITA CORRETAMENTE!` ,      
    },
    tt:{
      "English version":"Looks like the video ended",
      "Portuguese version":`Parece que o vídeo terminou` ,      
    },
    bdy:{
      "English version":"Looks like the video ended",
      "Portuguese version":`Parece que o vídeo terminou` ,      
    },
    timef:{
      "English version":<>You have 30 seconds to find the risk <br/> before the video continues.</>,
      "Portuguese version":<>Tem 30 segundos para descobrir o risco antes que o vídeo continue</> ,         
    },
    timeff:{
      "English version":<>LOOKS LIKE SOMETHING ISN'T BEING DONE PROPERLY!</>,
      "Portuguese version":<>PARECE QUE ALGUMA COISA NÃO ESTÁ A SER FEITA CORRETAMENTE!</> ,         
    },
}





export default texts