import React, {useState ,useEffect} from "react";
import Button from '@mui/material/Button'; 
import Dialog from '@mui/material/Dialog';
import Slider from '@mui/material/Slider';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import CustomDialogTitle from "../Dialog/DialogTitle"

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
let intervalID

const fixFormat = num =>{
    if (num<10){
        num = "0" + String(num)
    }
    return num
  }
  
  const formatTime = time=>{
    time = Math.floor(time)
    let seconds =  (Math.floor(time/60))
    let minutes =fixFormat(Math.floor(time%60))
    return [seconds , minutes]
  }
  
export default function ConfirmationPopup({texts,txtlang,stopsfx,playredTimersfxsfx,handlestopPopup,openstopPopup}) {
    const [data,setData] = useState({})
    const [isHurry, setisHurry] = useState(false)
    const [time, Settime] = useState(0)

    const headerfont={
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "26px",
        textAlign:"center"
    }  
    const bodyfont={
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "20px",

        color: "#000000",
        textAlign:"center",
        padding:"0 20px",
        marginTop:"30px"
    }
    const sliderStyle={
        marginTop:"-12px",
    
        height:"10px",
        "& .MuiSlider-rail":{
          borderRadius:"0",
          color: isHurry?"rgba(188, 31, 0, 0.3)":"rgba(233, 131, 0, 0.3)",
          transition: "all 1s linear",
    
        },
        "& .MuiSlider-track":{
          borderRadius:"0",
          color: isHurry?"#CD202C":"#E98300",
          transition: "all 1s linear",
        },
        "& .MuiSlider-thumbColorPrimary":{
          width:"0px",
          height:"15px",
          transition: "all 1s linear",
        },
        "& .MuiSlider-valueLabelOpen":{
          borderRadius:"0",
          background: isHurry?"#CD202C":"#E98300",
          fontFamily: 'Avenir',
          fontWeight: 800,
          fontSize: "16px",
          lineHeight: "22px",
          letterSpacing: "0.1em",
          transition: "all 1s linear",
        }
      }
    useEffect(() => {
        startTimer()
        return function cleanup(){
          clearInterval(intervalID)
          stopsfx()
        //   SetIsActive(false)
        }
    }, []); 

    const startTimer=()=>{
        intervalID = setInterval(()=>{
          Settime(t=>{
            if(t>=30){
              handlestopPopup(false)
              clearInterval(intervalID)
            }else if(t>=20 && !isHurry){
              playredTimersfxsfx()
             setisHurry(true)
            }
           return t+1
           })
       },1000)
     }
  return ( 
      <Paper
        onClose={()=>stopsfx()}
        aria-labelledby="customized-dialog-title"
        open={openstopPopup}
        sx={{
          zIndex:1000000,
            maxWidth:"540px",
            // borderTop:true?"10px solid #E98300":"",
            borderTopRightRadius: 0,
            overflowY:"visible",
            position:"fixed",
            left:"70px",
            bottom:"190px"

        }}>
             <Grid item xs={12}>
          {!false? <Slider
              max={30}
              disabled
              sx={sliderStyle} 
              valueLabelFormat={(t)=>{
                const [m,s] = formatTime(30-t)
                return `${m}:${s}`
                }
              }
              value={time}
              aria-label="Small"
              valueLabelDisplay="on"
          /> :null} 

          <IconButton
            aria-label="close"
            onClick={()=>handlestopPopup(false)}
            sx={{
              zIndex:10000,
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>

       
        <Grid container spacing={2} sx={{alignItems:"center", padding:"50px 20px 50px"}} >
            <DialogContent sx={{paddingTop: "10px", justifyContent:"center", display:"flex", flexDirection:"column", alignItems:"center"}} >
            <Typography justifyContent="center" color="secondary" variant='h5' sx={headerfont} gutterBottom>
            {texts.timeff[txtlang]}
            </Typography>
            <Typography justifyContent="center" sx={bodyfont} >
            {texts.timef[txtlang]}
            </Typography> 
            </DialogContent>

          </Grid>
        </Grid>
      </Paper> 
  );
}