import React, {useState ,useEffect } from "react";

import Box from '@mui/material/Box'; 
import Typography from '@mui/material/Typography';
import SvgIcon from '@mui/material/SvgIcon';  
import { ReactComponent as icon } from '../Tutorial/icons/rotateVideos.svg';

export default function CustomizedDialogs({txt,setcloseRotateAnimation }) {
    const BodyStyle={ 
        marginTop:"25px",
        fontFamily: 'Avenir',
        fontWeight: 900,
        fontSize: "28px",
        lineheight: "68px",
        display: "flex",
        textTransform: "uppercase",
        color: "#FFFFFF",
    }
    useEffect(() => {  
        setTimeout(()=>{
            setcloseRotateAnimation(false)
        },3000)
     }, []);

  return ( 
     <Box 
     sx={{
         position:"fixed",
         width:"100vw",
         height:"100vh",
         backgroundSize: "cover",
         backgroundPositionY: "center",
         backgroundPositionX: "center",
     }}>
        <Box 
        sx={{
            width:"100vw",
            height:"100vh", 
            display:"flex",
        }}>
            <Box 
            sx={{
                display:"flex",
                alignItems:"center",
                justifyContent:"center",
                flexDirection:"column",
                width:"100%"
            }}>
            <SvgIcon sx={{width:200,height:200}}  component={icon} inheritViewBox />
            <Typography sx={BodyStyle}>
                {txt}
            </Typography>
            </Box>
        </Box>
       
    </Box>
  );
}