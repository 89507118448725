import React, {useEffect,useState } from "react";
 
import Box from '@mui/material/Box'; 
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as logo } from './icons/logo.svg';
import { ReactComponent as close } from './icons/close.svg';
import { ReactComponent as info } from './icons/info.svg';
import { ReactComponent as home } from './icons/home.svg';
import { ReactComponent as EN } from './icons/EN.svg';
import { ReactComponent as PT } from './icons/PT.svg';
import { ReactComponent as StarIcon } from '../../assets/icons/NotesIcon.svg';
import { ReactComponent as miniLogo } from '../../assets/icons/logo.svg';
import { ReactComponent as MuteIcon } from '../../assets/icons/mute.svg';
import { ReactComponent as unMuteIcon } from '../../assets/icons/volume.svg';
import { ReactComponent as menuPhone } from '../../assets/icons/menuPhone.svg';

import SvgIcon from '@mui/material/SvgIcon'; 
import { Link ,useLocation,useHistory} from "react-router-dom";

export default function Sidebar(props) {
    const {openPhoneSidebar,setopenPhoneSidebar,isPT,switchPT,handleInfobuttonclicked,openSidebar,setopenSidebar,handleConfirmationOpen,AudioMute,muteAudio} = props
     const history = useHistory();

    const onlyEN = ['/tutorial360','/areas','/scenarios','/intro','/register']
    const onlygames = ['/riskhunt']
    const location = useLocation();
    const {pathname} = location
   const showOnlyEN = onlyEN.includes(pathname)
   const showOnlyInfo = onlygames.includes(pathname)
const showEN = pathname !=="/riskhunt"
   const toggleMute = (m)=>{
   const videotag = document.getElementById("video")
    console.log("videotag",videotag)
    videotag.muted=m
    muteAudio(m)
   }
   const handleHomeClicked =()=>{
     if(pathname === "/riskhunt"){
       handleConfirmationOpen()
     }else{
      history.push("/")
     }
   }
 const iconstyle={
     width:24,
     height:24,
     cursor:"pointer",
 }
  return ( 
    <>
      <Box>
           {pathname!=="/"?  <IconButton
           onClick={handleHomeClicked}
           sx={{
            position:"absolute",
            '@media (min-width:800px)': {
              top:20,
            },
            left:openSidebar?20:0,
            padding:0,
            transition:"0.3s all ease",
            zIndex:1002,

        }}>
      {!openPhoneSidebar?<>
       {openSidebar? <SvgIcon sx={{
         width:"130px",
         height:"74px",
         
        }} component={logo} inheritViewBox />:
        <SvgIcon sx={{
          width:74,
          height:74,
        }} component={miniLogo} inheritViewBox />
      }
      </>:null} 
        </IconButton> :null
          }

        
     <Box 
     sx={{
         position:"absolute",
         background:openSidebar?`#fff`:"#fff0",
         overflow:"hidden",
         width:openSidebar?"60px":"0",
         bottom:0,
         height:"100vh",
         display:"flex",
         justifyContent:"center",
         transition:"0.4s ease-out all",
         zIndex:1001
     }}>
         
            
        <Stack sx={{ justifyContent:"flex-end",alignItems:"center", marginBottom:2,zIndex:1000,}} direction="column" spacing={2.5} >
           
           {openSidebar?<>
           {showEN?<SvgIcon  onClick={()=>switchPT()} sx={iconstyle} component={!isPT?EN:PT} inheritViewBox />:null} 
            {AudioMute?<SvgIcon onClick={()=>toggleMute(false)} sx={iconstyle} component={unMuteIcon} inheritViewBox />:
            <SvgIcon onClick={()=>toggleMute(true)} sx={iconstyle} component={MuteIcon} inheritViewBox />
          }
            
            {!showOnlyEN?<> 
                <SvgIcon onClick={handleHomeClicked} sx={iconstyle}  component={home} inheritViewBox />
            </>:null}
            {showOnlyInfo?<SvgIcon onClick={()=>handleInfobuttonclicked(true)} sx={iconstyle}  component={info} inheritViewBox />:null}
            <SvgIcon sx={{width:20,height:20, cursor:"pointer"}}  component={close} 
            inheritViewBox onClick={()=>setopenSidebar(false)} />
           </>:
           null            } 
           
         </Stack>
        
      </Box>
      <>
      {pathname!=="/"? <SvgIcon sx={{
        width:30, 
        height:20,
        margin:"0px 0",
        cursor:"pointer",
        position:"fixed",
        bottom:"20px",
        left:"10px",
        zIndex:1000,
        display:{xs:"none",md:"block"}
        
      }} component={StarIcon} inheritViewBox onClick={()=>setopenSidebar(true)} />:null}
      </>
      {pathname!=="/"? <SvgIcon sx={{
          width:30, 
          height:20,
          margin:"0px 0",
          cursor:"pointer",
          position:"fixed",
          top:"20px",
          right:"18px",
          zIndex:1000,
          display:{xs:"block",md:"none"}

      }} component={menuPhone} inheritViewBox onClick={()=>setopenPhoneSidebar(true)} />:null}

      
      
    </Box></>
  );
}

