import React, {useState } from "react";

// import { Link } from "react-router-dom";
import {Redirect} from "react-router-dom";
 
import Box from '@mui/material/Box'; 
import Typography from '@mui/material/Typography';
import SvgIcon from '@mui/material/SvgIcon'; 
import NavigateBtn from "./components/NavigateBtns";
import DottedBtns from "./components/DottedBtns";

export default function CustomizedDialogs({texts,playclicksfx }) {
    const [ActiveIndex,setActiveIndex]=useState(0)
    const [willroute,setwillroute]=useState(false)
    
    // const willroute = ActiveIndex ==texts.length
    if(willroute){
      return routeToPage()
    }
    const[body,title,icon,coverImg]=texts[ActiveIndex]

    function routeToPage(){
      return <Redirect to={"/areas"} />
    }
  function renderInfo(){

    return <>
        <SvgIcon sx={{         
          width:277,height:277,
          '@media (max-width:800px)': {
            width:120,height:120,
          },
          }}  component={icon} inheritViewBox />
        <Typography sx={TitleStyle}  >
        {title}
        </Typography> 
        
        <Typography sx={BodyStyle}  >
        {body}
        </Typography>
    </>
  }

const TitleStyle={
    marginTop:"25px",
    fontFamily: 'Avenir',
    fontWeight: 900,
    fontSize: "50px",
    lineheight: "68px",
    display: "flex",
    textTransform: "uppercase",
    color: "#FFFFFF",
    '@media (max-width:800px)': {
      fontSize: "28px"
    },

}

const BodyStyle={ 
    marginTop:"8px",
    fontFamily: 'Avenir', 
    fontWeight: 500,
    fontSize: "28px",
    lineHeight: "38px",
    color: "rgba(255, 255, 255, 0.7)",
    '@media (max-width:800px)': {
      fontSize: "14px",
      marginTop:"0px",
    },
}
 
  return ( 
     <Box 
     sx={{
         background:`url(${coverImg})`,
         width:"100vw",
         minHeight:"100vh",
         height:"100%",
         backgroundSize: "cover",
         backgroundPositionY: "center",
         backgroundPositionX: "center",
     }}>
        <Box 
        sx={{
            background:"rgba(46, 73, 87, 0.85)",
            width:"100vw",
            minHeight:"100vh",
            height:"100%", 
            display:"flex",

        }}>
            <Box 
            sx={{
                display:"flex",
                alignItems:"center",
                justifyContent:"center",
                flexDirection:"column",
                width:"100%"
            }}>
              {renderInfo()}
                <Box sx={{
                  marginTop:"50px",
                  '@media (max-width:800px)': {
                    position:"absolute",
                    bottom:"58px"
                  },
                  }}>
                  <DottedBtns texts={texts} ActiveIndex={ActiveIndex}/>
                </Box>
                <Box sx={{
                  position:"absolute",
                  bottom:{xs:10,md:60},
                  right:{xs:10,md:100},
                  display:{xs:"block",sm:"block"}}}>
                <NavigateBtn playclicksfx={playclicksfx} setwillroute={setwillroute} length={texts.length} ActiveIndex={ActiveIndex} setActiveIndex={setActiveIndex} />
                </Box>  
            </Box>
        </Box>
       
    </Box>
  );
}