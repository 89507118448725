import React from "react";
 
 
import Box from '@mui/material/Box'; 
import Button from '@mui/material/Button'; 
import Typography from '@mui/material/Typography';


export default function FormPropsTextFields({txtlang ,texts,playswipefx,sethovered,setTeamIsregister}) {
  const {register,teamReg,reg5,addMemb,idemp,savecont,upto5emp,whatyourTeamName} = texts
    
    return (
<Box 

onMouseEnter={()=>sethovered(true)}
onMouseLeave={()=>sethovered(false)}
sx={{
    width:"100%",
    height:"100%",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    textAlign:"center",
    flexDirection:"column"
  }}>
    <Typography sx={{     
      fontFamily:"AvenirBold",           
      fontWeight: 900,
      fontSize: "40px",
      lineHeight: "50px",
      textTransform:" uppercase",
      color: "#FFFFFF",
      textAlign:"center"
    }}  >
       {teamReg[txtlang][0]}
       <Typography component="br" sx={{display:{xs:"block",md:"none"}}}></Typography>
       {teamReg[txtlang][1]}
   
    </Typography> 
    <Typography sx={{
      fontWeight: 800,
      fontSize: "18px",
      lineHeight: "25px",
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      letterSpacing: "0.01em",
      textTransform: "uppercase",
      color: "rgba(255, 255, 255, 0.7)",
    }}  >
     {reg5[txtlang]}
    </Typography> 
    
    <Button sx={{
        width:"300px",
        marginTop:"40px",
        padding:"20px 10px" , 
        fontWeight:500,
        fontSize: "18px",
        lineHeight: "25px", 
        letterSpacing:"0.1em",
      }} 
        variant="contained"
        color="primary"

        onClick={()=>{
          playswipefx()
          setTeamIsregister(true)
          sethovered(false)
        }}
      >
        {register[txtlang]}
        </Button>
    </Box>
     );
    }