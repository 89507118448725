import * as React from 'react';
import cover from "./cover.png"
 
import Box from '@mui/material/Box'; 
import Typography from '@mui/material/Typography';
import DeskSplash from "../Splash/desksplash"
import isPhone from "../../helpers/isPhone"
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as box } from "../Areas/icons/box.svg"
import { ReactComponent as fabrica } from "../Areas/icons/fabrica.svg"
import { ReactComponent as montagem } from "../Areas/icons/montagem.svg"
import { ReactComponent as pc } from "../Areas/icons/pc.svg"

export default function CustomizedDialogs({loadedDone,title,Sectiontitle,loadingProgress,moduleName} ) {

    let part1,part2
    if(Sectiontitle!==undefined){
        [part1 , part2] = Sectiontitle.split("-")
    }
    const LineStyle={
        background:"#E98300",
        height:"9px", 
        transition: "all 0.3s ease"
    }
    const fontStyle= {
        fontFamily:"AvenirBold",
        fontWeight: "900",
        fontSize: {xs:"38px",md:"80px"},
        lineHeight: {xs:"52px",md:"115px"},
        display: "flex",
        alignItems: "center",
        color:"white",
        letterSpacing: "0.02em",
        textTransform: "uppercase",
        marginTop:"30px",
        marginBottom:"20px"
    }
    const buttonStyle={ 

        minWidth: "200px",
        borderRadius: "1px",
        boxShadow: "none",
        padding: {md:"15px 40px" ,xs:"11px 30px"}, 
        fontWeight: "900",
        fontSize:{md:"37px",xs:"14px"} ,
        lineHeight: {md:"51px",xs:"19px"},
        letterSpacing: "0.04em",
        color: "#fff",
        outline: "0",
        justifyContent: "center",
        border: "0",
        margin: "0",
        display:"inline-flex",
        textAlign:"center",
        verticalAlign: "middle",
        textTransform: "uppercase",
        backgroundColor: "#E98300",
        marginTop:{md:"80px",xs:"45px"},
    }
    const smallTitle={
        display:{md:"block",xs:"none"},
        fontFamily:"AvenirBold",
        fontWeight: 400,
        fontSize: '32px',
        lineHeight: '70px',
        textTransform: 'uppercase',
        color: '#FFFFFF',
        marginBottom:"70px"
    }
const selectIcon = (moduleName)=>{
    if(moduleName === "Pre-Prefabrication"){
        return fabrica
    }else if (moduleName === "Assembly"){
        return montagem 
    }else if(moduleName === "Administrative"){
        return pc
    }
}
  return ( 
    <>
   {!isPhone()? <DeskSplash
    loadedDone={loadedDone} moduleName={moduleName} title={title} Sectiontitle={Sectiontitle}  loadingProgress={loadingProgress}/>:     <Box 
     sx={{
         background:`url(${cover})`,
         width:"100vw",
         height:"100vh",
         backgroundSize: "cover",
         backgroundPositionY: "center",
         backgroundPositionX: "center",
     }}>
        <Box 
        sx={{
            background:"rgba(46, 73, 87, 0.85)",
            width:"100vw",
            height:"100vh", 
            display:"flex",
            alignItems:"center"
        }}>
            <Box 
            sx={{
                width:"100%",  
                padding:{md:"0 228px",xs:"0 20px"}
            }}>
                <Typography sx={smallTitle}  >
                    {moduleName=="Warehouse"?
                    <SvgIcon sx={{width:"100px",height:"90px", marginRight:" -3px", marginBottom:"-36px" }}  component={box} inheritViewBox /> :
                    <SvgIcon sx={{width:"41px",height:"40px", marginRight:"20px", marginBottom:"-7px" }}  component={selectIcon(moduleName)} inheritViewBox />
                    }

                {title}
                </Typography> 

                <Box sx={{
                     position: "absolute" ,
                     width:{md:`calc(50% + 165px )`,xs:`calc(50% + 110px )`},
                     marginBottom:"20px"
                }}>
                    <Box sx={{...LineStyle, 
                     position: "relative" , 
                     left: {md:"-228px",xs:"-20px"},
                     width:`calc(${loadingProgress*100}% )`,
                     bottom: {md:'8px',xs:"17px"},
                    }}>
                    </Box>
                </Box>
                <Box sx={{display:"flex",justifyContent:"center",flexDirection:"column"}}>
                <Typography sx={fontStyle}  >
                {part1} 
                </Typography> 
                <Box sx={{
                    height:"10px",
                    width:"100vw",
                    position:"relative",
                    right:"0px",
                    top:"8px",
                    width:{md:`calc(50% + 175px )`,xs:`calc(100% + 20px )`}
                }}>
                    <Box sx={{
                        ...LineStyle, 
                        position:"absolute",
                        right:0,
                        // top: {md:'0px',xs:"70px"},
                          width:`calc(${loadingProgress*100}% )`
                         }}>
                    </Box>
                </Box>
               
                    
                {part2?<Typography sx={buttonStyle}>
                        {part2}
                    </Typography>:null }  
                </Box>
            </Box>
            
        </Box>
        <Box sx={{
            fontFamily: "Avenir",
            position: "absolute",
            bottom: "65px",
            width:'100%',
            textAlign:"center",
            fontSize: {md:"32px",xs:"20px"},
            color: "#fff",
            fontWeight: "bold",
        }} >
            {!loadedDone?<>{Math.round(loadingProgress*100)}%</>:"Get ready"}
                
            </Box>
    </Box>
   }
    </>

  );
}