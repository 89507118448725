import React, {useEffect,useState } from "react";

import cover from "./covers/tutorial.png"
import {Redirect} from "react-router-dom";
 
import Box from '@mui/material/Box'; 
import Typography from '@mui/material/Typography';
import SvgIcon from '@mui/material/SvgIcon'; 
import NavigateBtn from "./components/NavigateBtns";
import DottedBtns from "./components/DottedBtns";
import tempCover from "./tempCover2.png"
import isPhone from "../../helpers/isPhone"
export default function CustomizedDialogs({txtlang,handleInfobuttonclicked,stexts,playclicksfx,seenTutorial,texts, gameName, breakline}) {
    const [ActiveIndex,setActiveIndex]=useState(0)
    const [willroute,setwillroute]=useState(false)
    const {ply,chk} = stexts
    useEffect(() => {
      setActiveIndex(0)
    }, []);

    function getTitle(game){
      if(game==='find'){
        return [stexts.fre[txtlang]]
      }else if(game==="risk"){
        return [stexts.rhg[txtlang]]
      }else{
        return [stexts.qz[txtlang]]
      } 
    }
    if(willroute){
      if(gameName=="quiz"){
        seenTutorial(0)
        return <Redirect to={"/riskhunt"} />
      }else if(gameName=="find"){
        seenTutorial(2)
        return <Redirect to={"/riskhunt"} />
      }else{
        seenTutorial(1)
        if(isPhone()){
          return <Redirect to={"/riskhunt"} />
        }else{
          return <Redirect to={"/rht"} />
        }
      }
    }

  
  function renderInfo(){
    const[body,title,icon,iconsize]=texts[ActiveIndex]
    return <>
  
      <Box sx={{ visibility: icon===false?"hidden":""}}> 
      
        <SvgIcon className={title} sx={{ 
          marginTop:"170px",
          width:[iconsize[0]],
          height:iconsize[1],
          '@media (max-width:800px)': {
          // marginTop:"140px",

            // marginTop:title=="time"?"0px":"130px",
            marginRight:title=="time"?"-40px":(title=="Scene Score"?"-25px":""),
            marginBottom:title=="time"?"-34px":"",


            
            width:[iconsize[0]*0.55],
            height:iconsize[1]*0.55,
          }
          }}  component={icon} inheritViewBox />
        <Typography sx={iconFontStyle}  >
        {title}
        </Typography> 
      </Box> 
        
   { icon===false?  <Box sx={{
      position:"absolute",
      width:"100vw",
      height:"100vh",
      top:"0px",
      left:"0px",
      display:"flex",
      alignItems:"center", 
      justifyContent:"flex-end",
      marginLeft:"-100px",
      // marginBottom:"-550px",
      // border:"1px solid blue",
      '@media (max-width:800px)': {
        // backgroundPositionY: "center",
        // backgroundPositionX: "right",
      justifyContent:"center",
        height:"190px",
        marginLeft:"0px",
        position:"static"
      }
    }}>

    <Box sx={{
      background:`url(${tempCover})`,
      backgroundSize: "cover",
      backgroundPositionY: "center",
      backgroundPositionX: "center",
      // width:"910px",
      // height:"515px",
      width: "calc(1916 * 0.025vw )",
      height: "calc(933 * 0.025vw)",
      marginBottom:"-50px",


      '@media (max-width:800px)': {
      // backgroundPositionY: "center",
      // backgroundPositionX: "right",
      // backgroundSize: "auto",

        marginLeft:"0px",
        top:"-40px",
        position:"relative",
        width: "calc(1916 * 0.048vw )",
        height: "calc(933 * 0.048vw)",
        marginBottom:"0px",

      }
    }}>
      
    </Box>
    </Box>:null
   }


        <Typography sx={infoTextStyle}  >
        {body} 
        </Typography>
        { icon===false? <Typography sx={{
           ...infoTextStyle,
           color:"#E98300",
           position:"relative",
          '@media  (max-width:800px)': {
            color:"#ffffff",
            top:"-325px"
          }
           }}  >
        {chk[txtlang][0]} 
        <Box sx={{
           color:"#E98300",
           marginLeft:"12px",
          '@media (min-width:800px)': {
          display:"inline-block",
          },
          '@media (max-width:800px)': {
            fontSize: "20px",
             marginLeft:"0",

          }

        }}>
           {chk[txtlang][1]} <Box sx={{display:{xs:"none",md:"inline-block"}}}>&#8594;</Box> 
          </Box> 
        </Typography>:null} 
        
    </>
  }
 
  const letsplayStyle={
    marginTop:"-8px",
    fontFamily: 'Avenir',
    fontStyle: "normal",
    fontWeight: 800,
    fontSize: "24px",
    lineHeight: "70px", 
    textTransform: "uppercase",
    color: "#E98300",
    letterSpacing:"0.02em",
    '@media (max-width:800px)': {
      marginTop:"0px",
      fontSize: "12px",
      lineHeight: "50px", 

    }
  }
const fontStyle= {
    width:breakline?"100px":"520px",
    marginLeft:"-5px",
    fontFamily: 'Avenir',
    fontWeight: 900,
    fontSize: "90px",
    lineHeight: "90px",
    textTransform: "uppercase",
    color:"white",
    '@media (max-width:800px)': {
      marginLeft:"0px",
      width:"100%",
      fontSize: "38px",
      lineHeight: "40px",
    }
}
const iconFontStyle={
    marginTop:"-20px",
    fontFamily: 'Avenir',
    fontWeight: 800,
    fontSize:"22px",
    lineHeight: "70px",
    alignItems: "center",
    letterSpacing: "0.05em",
    textTransform: "uppercase",
    color: "#FFFFFF",
    '@media (max-width:800px)': {
    marginTop:"-25px",
    fontSize: "12px",
    }
}
const infoTextStyle={
    marginTop:"45px",
    fontFamily: 'Avenir',
    fontWeight: 900,
    fontSize: "50px",
    lineHeight: "68px",
    color: "#FFFFFF",
    '@media (max-width:800px)': {
      marginTop:"0px",
      fontSize: "20px",
      lineHeight: "27px",
      padding:"0 22px"
      
    }
}

  return ( 
     <Box 
     sx={{
         background:`url(${cover})`,
         width:"100vw",
         height:"100vh",
         backgroundSize: "cover",
         backgroundPositionY: "center",
         backgroundPositionX: "center",
        //  border:"1px solid orange",
         

     }}>
        <Box 
        sx={{
            background:"rgba(46, 73, 87, 0.85)",
            width:"100vw",
            height:"100vh", 
            display:"flex",
        }}>
            <Box 
            className={`playScreen`}
            sx={{
                padding:"80px 200px",
                width:"100%",
                display:"flex",
                flexDirection:"column",
                justifyContent:"space-around",
                '@media (max-width:800px)': {
                  padding:"110px 0px",
                  justifyContent:"flex-start",
                  alignItems:"center",
                  // border:"1px solid red"
                },
            }}>
              <Box sx={{
                // border:"1px solid green",
                '@media (max-width:800px)': {
                  textAlign:"center"
                }
              }}>
                <Typography sx={letsplayStyle}  >
                    {ply[txtlang]}
                </Typography> 
                <Typography sx={fontStyle}  >
                  {/* {gameTitle}  */}
                  {/* Risk hunt game */}
                  {/* TODO uncomment */}
                  {getTitle(gameName)[0]}
                </Typography> 
              </Box>

              <Box sx={{
                paddingBottom:"80px",
                '@media (max-width:800px)': {
                  textAlign:"center"
                }
                }}>
                {renderInfo()}
                <Box sx={{
                  marginTop:"30px",
                  '@media (max-width:800px)': {
                    position:"absolute",
                    left:'50%',
                    transform:"translateX(-50%)",
                    bottom:"58px",
                    textAlign:"center",
                    // width:"95%"

                  }}}>
                  <DottedBtns texts={texts} ActiveIndex={ActiveIndex}/>
                </Box>

                <Box sx={{
                  position:"absolute",
                  bottom:{md:60,xs:10},
                  right:{md:100,xs:10}
                  }}>
                <NavigateBtn handleInfobuttonclicked={handleInfobuttonclicked} playclicksfx={playclicksfx} setwillroute={setwillroute} length={texts.length} ActiveIndex={ActiveIndex} setActiveIndex={setActiveIndex} />
                </Box>
              </Box>
            </Box>
        </Box>
       
    </Box>
  );
}