import React, {useState } from "react";
import { Link } from "react-router-dom";

import cover from "./awarnessCover.png"
 
import Box from '@mui/material/Box'; 
import Typography from '@mui/material/Typography';  
import Container from '@mui/material/Container'; 
import Button from '@mui/material/Button'; 
import texts from "../../staticData/SiteText";

export default function Intro({txtlang,isPT,playbackgroundfx,title,body,isScenarios,playclicksfx}) {
const {introTitle,introBody,introBtn} = texts

// "English version"§

  const titleStyle={ 
    textAlign: "center",
    textTransform: "uppercase",
    color: "#FFFFFF",
    fontFamily: 'Avenir',
    fontWeight: 900,
    fontSize: "100px",
    lineHeight: "140px",
    letterSpacing: "0.04em",
    '@media (max-width:800px)': {
    fontSize: '33px',
    lineHeight: "60px",

  },
  // [theme.breakpoints.up('md')]: {
  //   fontSize: '2.4rem',
  // },
}

const infoTextStyle={
    fontFamily: 'Avenir',
    fontWeight: 400,
    fontSize: "30px",
    lineHeight: "41px",
    textAlign: "center",
    color: "#FFFFFF",
    opacity: 0.7,
    '@media (max-width:800px)': {
      fontSize: '16px',
    lineHeight: "20px",
    },

}

const readybtn={
  marginTop:"60px",
  padding:"20px 90px" , 
  fontWeight:500,
  fontSize: "18px",
  lineHeight: "25px", 
  letterSpacing:"0.1em",
  '@media (max-width:800px)': {
    fontSize: '12px',
    // width:"200px"
      padding:"14px 40px" , 
      marginTop:"40px",
  },
}

const container = theme=>({
  background:`url(${cover})`,
  width:"100vw",
  height:"100vh",
  backgroundSize: "cover",
  backgroundPositionY: "center",
  backgroundPositionX: "center",
  '@media (max-width:800px)': {
    // backgroundPositionX: "center",
  },
})
 
  return ( 
     <Box 
     sx={container}>
        <Box 
        sx={{
            background:"rgba(233, 131, 0, 0.8)",
            width:"100vw",
            height:"100vh", 
            display:"flex",
            // paddingLeft:"40px",
            display:"flex",
            flexDirection:"column",
            alignItems:"center",
            justifyContent:"center",

        }}>
  
            <Typography sx={titleStyle}  >
            {introTitle[txtlang]}
            </Typography> 
            
            <Typography sx={infoTextStyle}  >
            {introBody[txtlang]}
            </Typography>
         
           
              <Button 
              onClick={()=>{
                // playbackgroundfx()
                // playclicksfx()
              }}
              sx={readybtn} component={Link} to="/register"  variant="contained" color="secondary">
              {introBtn[txtlang]}
              </Button>

        </Box>
    </Box>
  );
}