import * as React from 'react';
import Button from '@mui/material/Button';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as userIcon } from "../../assets/icons/user.svg"
 
export default function IconLabelButtons() {
  return (
      <Button variant="text"  size="small"
       startIcon={ <SvgIcon sx={{
        width:14,
        height:16, 
        margin:"0px 0"
    }} component={userIcon} inheritViewBox  /> }
        sx={{
          minWidth:"inherit",
          p:"0 20px",
          height:"35px",
          color:"white",
          fontWeight:"bold",
          fontSize:"16px",
          margin:"5px 5px 5px 40px !important",
          letterSpacing:"0.1em"
      }}>
            micros
      </Button>
  );
}

