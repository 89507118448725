import * as React from "react";
import Box from '@mui/material/Box';
import { keyframes } from "styled-components";

const SA = ({count,init}) => { 
    var spin = keyframes`
    0% { 
        transform: translateX(${init.x}px)  translateY(${init.y}px); 
    }
    100% {  
        display:block;
        transform: translateX(${count.x}px) translateY(${count.y}px);  }
   `;
   
  return  <Box
      sx={{
        position:"absolute",
        top:20, 
        left:20,
        fontSize:"24px",
        animation: `${spin} 2s ease-in  `,
        color: (theme) => theme.palette.primary.main,
      }}
       >
           +10
        </Box>
};

export default SA

 