import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import SvgIcon from '@mui/material/SvgIcon'; 
import { ReactComponent as trash } from './trash.svg';


export default function FormPropsTextFields({noDelete,index,label,placeholder,deleteRow}) {

  return (
    <Box
      component="form"
      noValidate
      autoComplete="on"
      sx={{marginTop:"20px",
      display:"inline-block",
      width:"100%",

    }}
    >

    <Box sx={{
      // marginBottom:"-5px",
      // border:"1px solid red",
      display:"flex",
      alignItems:"center",
      justifyContent:"space-between"
    }}>
  <label className='textInputlabel' htmlFor="fname">{label}</label> 
  {(noDelete!==true && label=="Name")?<IconButton
  onClick={()=>deleteRow(index)}
  sx={{
      display:{xs:"block",sm:"none"},
    
  }}  aria-label="delete">
   <SvgIcon sx={{ width:14}}  component={trash} inheritViewBox />
  </IconButton>:null}
    </Box>
    <Box sx={{display:"flex",justifyContent:"space-between"}}>
      <input className='textInput' type="text" id="fname" name="fname"/>
      {(noDelete!==true && label=="ID Employee")?<IconButton
      onClick={()=>deleteRow(index)}
      sx={{
          display:{xs:"none",sm:"block"},
          marginBottom:"-10px"

      }}  aria-label="delete">
      <SvgIcon sx={{ width:17}}  component={trash} inheritViewBox />
      </IconButton>:null} 
    </Box>
    </Box>
  );
}
