import React, {useEffect,useState } from "react";

import Box from '@mui/material/Box'; 
import Button from '@mui/material/Button'; 
import Grid from '@mui/material/Grid'; 
import Input from "./CustomInput"
import Typography from '@mui/material/Typography';
import {Link} from "react-router-dom" 

 const  TeamForm= React.forwardRef((props, ref) =>{
    const {playclicksfx,txtlang ,texts,setTeamIsregister} = props
    const {welcom,teamReg,name,addMemb,idemp,savecont,upto5emp,whatyourTeamName} = texts

    const [users,setusers]=useState([{},{},{}])
    const [hovered,sethovered]=useState(false)
    const hideAddUsersBtn = users.length ==5 ?false:true
    const addUser = ()=>{
      playclicksfx()
        const newUsers= [...users, {}]
        setusers(newUsers)
    }
    const deleteRow= (index)=>{
      console.log("INDD",index)
        const newUsers= users.filter((u,uindex)=>{
            return uindex!=index
        })
        setusers(newUsers)
    }

    const iconFontStyle={
      fontFamily: 'Avenir',
      fontWeight: 800,
      fontSize: "24px",
      lineHeight: "70px",
      textTransform: "uppercase",  
      color: "#FFFFFF",
      display:{xs:"none",sm:"block"}
    }

    const infoTextStyle={
    fontFamily:"AvenirBold",
    fontWeight: 900,
    fontSize: "90px",
    lineHeight: "90px",
    textTransform: "uppercase",  
    color: "#FFFFFF",
    marginBottom:"50px",
    display:{xs:"none",sm:"block"}
    }
    const subTextInfo = {
      minWidth:"89vw",
      // marginTop:"400px",
      // border:"1px solid red",
      fontWeight: 800,
      fontSize: "14px",
      lineHeight: "40px",
      letterSpacing: "0.1em",
      textTransform: "uppercase",
      color: "#FFFFFF",
    }
    const switchToSingle=()=>{
      console.log("HY")
      setTeamIsregister(false)
    }
  return (
<Box 
sx={{
    display:"flex",
    justifyContent:"center",
    flexDirection:"column",
    '@media (min-width:800px)': {
      padding:"10px 150px 10px 170px",
    },
    width:"100%",


}}>

<Typography sx={iconFontStyle}  >
  {welcom[txtlang]}
</Typography> 


<Grid container spacing={0} > 
<Grid item  xs={6} >

<Typography sx={infoTextStyle}  >
 {teamReg[txtlang]}
</Typography>

<Box sx={{display: { xs: 'block', sm: 'none' }}}>
  <Typography sx={subTextInfo} onClick={()=>switchToSingle()}>
  OR <Box component="span" sx={{textDecoration:"underline", cursor:"pointer"}}> register as a single &#8594; </Box>
  </Typography>
</Box>

</Grid>
<Grid item sx={{
      marginTop:"45px",
    '@media (max-width:800px)': {
      marginTop:"15px"
    },
  }}  xs={12} sm={6} >
<Input  label={whatyourTeamName[txtlang]} placeholder="Amanda Soares"/>
</Grid> 
{users.map((u,index)=>< React.Fragment key={index*2}>
    <Grid sx={{
       '@media (max-width:800px)': {
        marginTop:"20px"
      },
     }} item  xs={12} sm={6} >
        <Input deleteRow={deleteRow} label={name[txtlang]}   index={index}  placeholder="Amanda Soares"/>
    </Grid> 
    <Grid item  xs={12} sm={6}>
    <Input  label={idemp[txtlang]} deleteRow={deleteRow} index={index} placeholder=""/>
    </Grid>
</React.Fragment>)}


<Grid item  xs={12} sm={6}>
   {hideAddUsersBtn? <Button sx={{
   marginLeft:"-10px",
   fontFamily:"AvenirBold",
   justifyContent:"flex-start",
  width:"300px",
  marginTop:"30px",
  padding:"20px 10px" , 
  fontWeight:700,
  fontSize: "16px",
  lineHeight: "16px", 
  letterSpacing:"0.1em",
  "&:hover":{
      background:"none"
  },
  '@media (max-width:800px)': {
    justifyContent:"flex-end",
    width:"100%",
    marginTop:"15px",
    marginLeft:"0px",

    // border:"1px solid red"
  },
}} 
  onClick={addUser}
  color="primary"
>
  + {addMemb[txtlang]}
  </Button>:null}

  <Typography sx={{

      marginLeft:"20px",
      marginTop:"-15px",
      fontFamily: 'Avenir',
      fontWeight: 800,
      fontSize: "14px",
      lineHeight: "19px",
      letterSpacing: "0.01em",
      textTransform: "uppercase",
      color: "rgba(255, 255, 255, 0.7)",
      display:{xs:"none",sm:"block"}
  }}  >
  {upto5emp[txtlang]}
</Typography> 
</Grid>
<Grid item  xs={12} sm={6} > 
<Button sx={{
    width:"95%",
    marginTop:"40px",
    padding:"20px 10px" , 
    fontWeight:500,
    fontSize: "18px",
    lineHeight: "25px", 
    letterSpacing:"0.1em",
    '@media (max-width:800px)': {
      marginTop:"15px",
      width:"100%",
      fontSize: "12px",
      padding:"12px 10px" , 

    },
    }} 
    fullWidth 
    variant="contained"
    onClick={()=>playclicksfx()}
  component={Link}
  to="/tutorial360"
    >
   {savecont[txtlang]}
  </Button>
  </Grid>
</Grid>
</Box>

);
})

export default TeamForm