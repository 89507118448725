import React, {useEffect,useState } from "react";

import cover from "./cover.png"

import { Link } from "react-router-dom";
 
import Box from '@mui/material/Box'; 
import Button from '@mui/material/Button'; 
import Grid from '@mui/material/Grid'; 
import Typography from '@mui/material/Typography';
import Input from "./CustomInput"
import TRleft from "./TRleft"
import TRright from "./TeamForm"

import SRleft from "./SRleft"
import SRright from "./SRright"

import useSound from 'use-sound'
import clicksfx from '../../staticData/sounds/swipe.mp3';
import texts from "../../staticData/SiteText";

import idPhone from "../../helpers/isPhone"


export default function TeamRegiser({txtlang,AudioMute,playclicksfx , openSidebar , gameName,breakline}) {
  const [playswipefx] = useSound(clicksfx,{volume: AudioMute?0:0.5,soundEnabled:!AudioMute});

    const [users,setusers]=useState([{},{},{}])
    const [isTeamRegister, setTeamIsregister]= useState(false)
    const [hovered,sethovered]=useState(false)


    const rednerPhoneContent=()=> <Box 
    sx={{
       transition:"0.1s ease-out all",
       paddingLeft:"0px",
        background:`url(${cover})`,
        // width: openSidebar?"calc(100vw - 60px)":"100vw",
        height:"100%",
        backgroundSize: "cover",
        backgroundPositionY: "center",
        backgroundPositionX: "center",
        backgroundAttachment:"fixed"
    }}>
      <Grid container sx={{height:"100%"}}>
       <Grid  item  xs={12}
            sx={{           
              padding:{md:"160px 20px 90px",xs:isTeamRegister?"160px 20px 90px":"0px 20px 0"},  
              background: {md:`rgba(46, 73, 87,0.85)`,xs:isTeamRegister ?"rgba(46, 73, 87,0.85)":'rgba(233, 131, 0, 0.85)'},
            //  background:`rgba(233, 131, 0, ${isTeamRegister?(hovered?0.85:1):0.85})`,
             jusifyConent:"flex-start",
             alignItems:"flex-start",
             minHeight:"100vh",
             height:"100%", 
             display:"flex",
             transition:"all 0.6s ease-out",
         }}>
          {/* <TRright setTeamIsregister={setTeamIsregister} playclicksfx={playclicksfx} txtlang={txtlang} texts={texts} /> */}
          {isTeamRegister?<TRright setTeamIsregister={setTeamIsregister} txtlang={txtlang} texts={texts}  playclicksfx={playclicksfx} />:<SRleft setTeamIsregister={setTeamIsregister} txtlang={txtlang} texts={texts} playclicksfx={playclicksfx} /> }
        
           {/* <SRleft playclicksfx={playclicksfx} />  */}
         </Grid> 
     
       </Grid>
   </Box>
 
  const renderDesktopContent = ()=> <Box 
    sx={{
       transition:"0.1s ease-out all",
       paddingLeft: openSidebar?"60px":"0px",
        background:`url(${cover})`,
        width: openSidebar?"calc(100vw - 60px)":"100vw",
        height:"100%",
        backgroundSize: "cover",
        backgroundPositionY: "center",
        backgroundPositionX: "center",
        backgroundAttachment:"fixed"
    }}>
      <Grid container sx={{height:"100%"}}>
       <Grid  item  xs={isTeamRegister?(hovered?4:3):(hovered?8:9)}
            sx={{             
             background:`rgba(233, 131, 0, ${isTeamRegister?(hovered?0.85:1):0.85})`,
             jusifyConent:"center",
             alignItems:"center",
             minHeight:"100vh",
             height:"100%", 
             display:"flex",
             transition:"all 0.6s ease-out",
         }}>
            {isTeamRegister?<TRleft txtlang={txtlang} texts={texts} playclicksfx={playclicksfx} playswipefx={playswipefx} sethovered={sethovered} setTeamIsregister={setTeamIsregister} />
            :<SRleft txtlang={txtlang} texts={texts} playclicksfx={playclicksfx} /> }
         </Grid> 
          <Grid item  xs={!isTeamRegister?(hovered?4:3):(hovered?8:9)}
          sx={{transition:"all 0.6s ease-out",}}>
             <Box 
               sx={{
                   background:`rgba(46, 73, 87,${!isTeamRegister?(hovered?0.85:1):0.85})`,
                   minHeight:"100vh",
                   height:"100%", 
                   display:"flex",
               }}>
             {isTeamRegister?<TRright txtlang={txtlang} texts={texts}  playclicksfx={playclicksfx} />:<SRright txtlang={txtlang} texts={texts} playswipefx={playswipefx} sethovered={sethovered} setTeamIsregister={setTeamIsregister}/> }
        
           </Box>            
           </Grid>
       </Grid>
   </Box>
  
  return ( 
    <>
    {idPhone()? rednerPhoneContent():renderDesktopContent()
  
  }
  </>
  );
}