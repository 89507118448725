import * as React from 'react';
import Button from '@mui/material/Button'; 
 import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as eye } from "../../assets/icons/eye.svg"
import { ReactComponent as HideEye } from "../../assets/icons/HideEye.svg"
export default function IconLabelButtons({lablehide,lableshow,lable,shotHintsRef,isTutorial,willShowHints,showHint,willshowHints}) {
 
  return (
      <Button ref={shotHintsRef} variant="contained" size="small"
      onClick={()=>{
        if(!isTutorial){
          showHint()
        }
      }
    }
       startIcon={<SvgIcon  component={willShowHints?eye:HideEye} inheritViewBox />}
        sx={{ 
          fontWeight:"bold",
          minWidth:"172px",
          p:"0 20px",
          letterSpacing:"0.1em",
          height:"40px"
      }}>
        {willShowHints?lableshow:lablehide} 
      
       
      </Button>
  );
}



