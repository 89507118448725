import React, {useState ,useEffect} from "react";
import Button from '@mui/material/Button'; 
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CustomDialogTitle from "../Dialog/DialogTitle"
import { Link,useHistory ,useLocation} from "react-router-dom";
 

export default function ConfirmationPopup({ texts, txtlang,playclicksfx , handleConfirmationClose,openConfirmation}) {
  
  const risk = {
    title:texts.retHome[txtlang],
    body:texts.lostall[txtlang],
    btn1:texts.contply[txtlang],
    btn2:texts.goHome[txtlang]
}
const score={
    title:texts.wantply[txtlang],
    body:texts.moretime[txtlang],
    btn1:texts.iwanttry[txtlang],
    btn2:texts.backscore[txtlang]
}
  
  const [type,settype] = useState(0) //0 socre, 1 risk go home

    const location = useLocation();
    const {pathname} = location
    let data =type?risk: score 

    const {title,body,btn1,btn2} = data

   useEffect(() => {
    if(pathname ==="/scoreSucceed"){
        settype(0)
    }else{
        settype(1)
    }

  }, []); 
     const history = useHistory();

     const GoHome = ()=>{
        history.push("/");
        handleConfirmationClose()
     }
  return ( 
      <Dialog
        onClose={handleConfirmationClose}
        aria-labelledby="customized-dialog-title"
        open={openConfirmation}
        sx={{
          '& .MuiPaper-root ': {
            maxWidth:"1000px",
            padding: {md:"35px 80px 75px",xs:"40px 00px 55px"},
            textAlign:"center"
          }
        }}>
        <CustomDialogTitle id="customized-dialog-title" onClose={handleConfirmationClose}/>

        <Grid container spacing={2} sx={{alignItems:"center"}} >
          
          <Grid item xs={12}>
            <DialogContent sx={{paddingTop: {xs:"10px",md:"40px"}, justifyContent:"center", display:"flex", flexDirection:"column", alignItems:"center"}} >
            <Typography color="secondary" variant='h4' sx={{
               fontFamily:"AvenirBold", fontWeight:"bolder",
               lineHeight:{xs:"27px",md:"60px"},
               fontSize:{md:"44px",xs:"20px"}}
            } gutterBottom>
              {title}
            </Typography>
            <Typography sx={{lineHeight:{md:2.2,xs:"26px"}, fontSize:{md:"18px",xs:"14px"}}} >
              {body} 
            </Typography> 

              <Button sx={{
                  marginTop:{md:"80px",xs:"40px"},marginBottom:"20px",
                  padding:{md:"20px 26px",xs:"10px 0px"},
                  letterSpacing: "0.1em",
                  fontWeight:500,
                  fontWeight: 500,
                  fontSize:{xs:"12px",md:"18px"},
                  lineHeight: '25px',
                  width:{xs:"100%",md:"340px"},
                }}  variant="contained" onClick={()=>{
              type?handleConfirmationClose():GoHome()
                playclicksfx()
              }}>
                {btn1}
              </Button>

              <Button onClick={()=>{
              type?GoHome():handleConfirmationClose()
                playclicksfx()
                }} sx={{
                      padding:{md:"10px",xs:"0"} ,
                      letterSpacing: "0.1em",
                      fontWeight:500,
                      textDecoration: "underline",
                      fontSize:{xs:"12px",md:"18px"}
                   }} color="secondary">
                {btn2}
              </Button>
          
            </DialogContent>

          </Grid>
        </Grid>
      </Dialog> 
  );
}