import React, {useEffect,useState } from "react";

import IconButton from '@mui/material/IconButton'; 
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box'; 
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button'; 
import Stack from '@mui/material/Stack'; 

import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as Play } from "../UI/Ready/Play.svg"
import { ReactComponent as Pause } from "../UI/Ready/pause.svg"


import Slider from '@mui/material/Slider';

const fixFormat = num =>{
    if (num<10){
        num = "0" + String(num)
    }
    return num
}

const formatTime = time=>{
    time = Math.floor(time)
    let seconds = fixFormat (Math.floor(time/60))
    let minutes =fixFormat(Math.floor(time%60))
    return [seconds , minutes]
}

 
const buttonStyle={
    marginTop:"5px",
    padding:"1px 20px ",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight:"35px", 
}
const timeStyle = {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "22px",
    color: "#FFFFFF",
    margin:"0 20px"
}
const sliderStyle  = {
    marginBottom:"-5px",
    color: '#fff', 
        zIndex:100,

    '& .MuiSlider-thumb': {
        zIndex:100,
        transition: '1s linear'
    } , "& .MuiSlider-rail":{
        transition: "all 1s linear",
        zIndex:100
      },
      "& .MuiSlider-track":{
        zIndex:100,
        transition: "all 1s linear",
      },
            
}
 let video
export default function CustomizedDialogs({ playclicksfx , openstopPopup,disableSlider, setcurrentTime,currentTime ,totalTime} ) {

    const [Played,setPlayed] = useState(1) 

    const [seconds , minutes] = formatTime(currentTime)
    const [totalseconds , totalminutes] = formatTime(totalTime)

    

  function handleClick(){ 
    playclicksfx()
    video = document.getElementById("video")

    if(Played){
        video.pause()
        setPlayed(false) 
    }else{
        video.play()
        setPlayed(true) 
    
    }
  }   

  const handleTimeChange = val=>{
      if(video ==undefined){
          video = document.getElementById("video")
      }
    const newTime = totalTime * val/100
    setcurrentTime(newTime)
    video.currentTime= newTime
  }
  useEffect(() => { 
  }, []);
  return ( 
     <Box 
     sx={{  
        background: "#faa0", 
        position: "fixed",
        width:"100%",
        height:"30px",
        bottom: 85,
        left: 0, 
        zIndex:1,
     }}>
         
        <Stack direction="row" sx={{
            alignItems:"center",
            justifyContent:"center"
        }}>
            {(!openstopPopup)?
                <IconButton onClick={handleClick}  >
                <SvgIcon sx={{width:"16px",height:"16px" }}  component={Played?Pause:Play} inheritViewBox /> 
                </IconButton>:null}
        <Typography
        sx={timeStyle}>
            {seconds}:{minutes}
        </Typography>
        <Box sx={{ width:"80%" }}>
      <Slider
        // disabled={disableSlider}
        disabled={true}
        sx={sliderStyle}
        size="small"
        color="secondary"
        value={100 * currentTime / totalTime}
        aria-label="Small" 
        onChange={(event)=>handleTimeChange(event.target.value)}
      />
       
           {/* <Box sx={{height:"3px",width:`calc(${100 * currentTime / totalTime}%  )`,
           background:"white", transition: "1s all linear"}}>

           </Box> */}
        </Box>
        <Typography
        sx={timeStyle}>
            {totalseconds}:{totalminutes}
        </Typography>
        </Stack>
         
    </Box>
  );
}