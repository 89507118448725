import React from "react";

export default function NavigateBtn({texts ,ActiveIndex}) {
 
  return (
    <svg className='dotpagination' width="84" height="16" viewBox="0 0 76 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        {texts.map((t,index)=>(
            <circle key={index} opacity={ActiveIndex==index?"1":"0.5"} 
            cx={11 + index*20} cy="8" r={ActiveIndex==index?"8":"5"}  fill="white"/>
        ))}                 
    </svg>
        
  );
}