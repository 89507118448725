import React, {useEffect,useState } from "react";
import { createTheme ,ThemeProvider,responsiveFontSizes} from '@mui/material/styles';
import { green,orange } from '@mui/material/colors';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Box from '@mui/material/Box'; 

import Quiz from "./UI/Quiz/Quiz"
import FRE from "./UI/FRE/FRE"
import Tutorial from "./UI/Tutorial/TutorialRiskHunting"
import Tutorial360 from "./UI/Tutorial/Tutorial360"
import Areas from "./UI/Areas/Areas"
import IntroSplash from "./UI/IntroSplash/IntroSplash"
import Thankyou from "./UI/Thankyou/Thankyou"
import SingleRegister from "./UI/Register/SingleRegister"
import TeamRegister from "./UI/Register/TeamRegister"
import Score from "./UI/Score/Score"
import ReviewResults from "./UI/Score/ReviewResults"
import Sidebar from "./UI/Sidebar/Sidebar"
import SidebarPhone from "./UI/Sidebar/SidebarPhone"
import RiskHunt from "./UI/RiskHunt/RiskHunt"
import LoadingSplash from "./UI/loadingSplash/loadingSplash"
import ConfirmationPopup from "./UI/ConfirmationPop/ConfirmationPop";
import RHRut from "./UI/RiskHunt/RHTUT"
import PopupTutorial from "./UI/PopupTutorial/PopupTutorial";
// import cover from "./awarnessCo
import useSound from 'use-sound'
import clicksfx from './staticData/sounds/click.wav';
import backgroundfx from './staticData/sounds/background music.mp3';
import texts from "./staticData/SiteText"
import staticData from "./staticData/staticData"
import Dialog from "./UI/Dialog/Dialog";
// "../Dialog/Dialog"
let theme = createTheme({
  typography: {
    fontFamily: 'Avenir, Arial',
  },
  components: {
    // Name of the component
    // MuiPaper-root-MuiDialog-paper
    MuiPaper:{
      borderRadius:"0"
    },
    MuiDialog:{
      borderRadius:"0"
    },
    MuiIconButton:{
      defaultProps: {
        // The props to change the default for.
        disableRipple: true, // No more ripple, on the whole application 💣!
      },
    },
    MuiButton: {
      variants: [ {
        props: { variant: 'dashed' },
        style: {
          textTransform: 'none',
          border: `2px dashed ${green[500]}`,
        },
      }],
      defaultProps: {
        // The props to change the default for.
        disableRipple: true, // No more ripple, on the whole application 💣!
      },
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          minWidth:"200px",
          borderRadius:"1px",
          boxShadow: "none"
          // fontSize: '1rem',  
        },
      },
    },
  },
  palette: { 
    spacing: 4,
    primary: {
      main: "#E98300",
      contrastText: '#fff',
    },
    secondary: {
      main: "#2E4957" ,
      contrastText: '#fff',

    },
    neutral: {
      main: '#04748B',
      dark: orange[100],
      light: orange["A700"],
      contrastText: '#fff',
    },
  }, 
});

theme = responsiveFontSizes(theme);
export default function App() { 


  const [openSidebar,setopenSidebar] = useState(false)
  const [openPhoneSidebar,setopenPhoneSidebar] = useState(false)
  const [splashLoading,setsplashLoading] = useState(0)
  const [openQuiz, setopenQuiz] = useState(false);
  const [openConfirmation, setopenConfirmation] = useState(false);
  const [loading,setLoading]= useState(false)
  const [ActiveScenario,setActiveScenario]= useState({ })
  const [completedScenario,setcompletedScenario]=useState([])
  const [tokenExams , settokenExams] = useState([])
  const [TotalScore , setTotalScore] = useState(0)
  const [UserTime , setUserTime] = useState(0)
  const [data, setData] = useState({})
  const [finsihedModule,setfinsihedModule]=useState([ ])
  // const [finsihedModule,setfinsihedModule]=useState([])

  const [selected,setselected]=useState([])
  const [TutorialShown,setTutorialShown]=useState([0,0,0]) // quiz risk find 
  const [detailedScore,setdetailedScore]=useState({quiz:[0,0],risk:[0,0],find:[0,0]}) // [quiz , riskHunt, FindRight Element]
  const [gameIndex,setgameIndex]=useState(0)
  const [AudioMute,setAudioMute]=useState(false)
  const [muteBackground,setmuteBackground]=useState(false)
  const [openInfo,setopenInfo]=useState(false)
  const [CurrentExam, setCurrentExam] = useState({})
  const [isPT, setisPT] = useState(true)

  const resetStates=()=>{
    setCurrentExam({})
    setopenInfo(false)
    setgameIndex(0)
    setdetailedScore({quiz:[0,0],risk:[0,0],find:[0,0]})
    setTutorialShown([0,0,0])
    setselected([])
    setfinsihedModule([])
    setData({})
    setUserTime(0)
    setTotalScore(0)
    settokenExams([])
    setcompletedScenario([])
    setActiveScenario({})
  }
const txtlang = !isPT?"Portuguese version":"English version"

  const {risk ,riskHuntingTexts, quizTexts,findTexts,tutorial360} = staticData[isPT]

  const [playclicksfx] = useSound(clicksfx,{volume: AudioMute?0:0.5,
    soundEnabled:!AudioMute});
  // const [playbackgroundfx,{stop,resume}] = useSound(backgroundfx,{volume:0.02,
  //   soundEnabled:(!AudioMute || muteBackground),interrupt:true, onPlayError: () => {
  //     console.error('Error occured!');
  //   },});

  const playbackgroundfx = () => {
    const audio = document.getElementById('background_sound');
    audio.volume = 0.02;
    audio.play()
  }
  
  const stop = () => {
    console.log("stop")
    const audio = document.getElementById('background_sound');
    audio.pause()
  }
  
  const muteAudio = (val)=>{
    setAudioMute(val)
    if(val){
      stop()
    }else{
      playbackgroundfx()
    }
  }
  const handleConfirmationClose=()=>{
    setopenConfirmation(false)
  } 
  const handleConfirmationOpen=()=>{
    playclicksfx()
    setopenConfirmation(true)
  } 
  const loadSite = ()=>{
    let counter =0
  let intervalid =  setInterval(()=>{
      counter+=0.03
      if(counter >=1){
        clearInterval(intervalid)         
      }else{
        setsplashLoading(counter)
      }
    },20)
   
  }

  const seenTutorial = (index)=>{
    console.log("INNNN",index)
    const old = [...TutorialShown]
    old[index]=true
    setTutorialShown(old)
  }
const handleInfobuttonclicked = (willOpen)=>{
  const videotag = document.getElementById("video")
  if(willOpen){
    setopenInfo(true)
    videotag.pause()
  }else{
    setopenInfo(false)
    videotag.play()  
  }
}
const switchPT = ()=>{
  setisPT(!isPT) 
}


  useEffect(() => {
    loadSite()

    const playBackgroundMusic = () => {
      playbackgroundfx()
      document.removeEventListener("click", playBackgroundMusic)
    }
    document.addEventListener("click", playBackgroundMusic);
  }, []);

  return (
    <ThemeProvider theme={theme}>

      <BrowserRouter>
      <Box sx={{
        // overflow: "hidden",
        // height:"100vh"
      }} id="container">
      <PopupTutorial   stexts={texts} isPT={isPT} txtlang={txtlang} gameName={CurrentExam.game} handleInfobuttonclicked={handleInfobuttonclicked} openInfo={openInfo} playbackgroundfx={playbackgroundfx} playclicksfx={playclicksfx}/>
      {/* {console.log("loading",loading)} */}
      {/* {!loading? <Sidebar openSidebar={openSidebar} setopenSidebar={setopenSidebar} />:null} */}
      {/* <RiskHunt risk={risk} TotalScore={TotalScore} setTotalScore={setTotalScore} tokenExams={tokenExams} settokenExams={settokenExams} completedScenario={completedScenario} setcompletedScenario={setcompletedScenario}  ActiveScenario={ActiveScenario} setActiveScenario={setActiveScenario} loading={loading} setLoading={setLoading} setopenQuiz={setopenQuiz} openQuiz={openQuiz}/>  */}
      {/* <Dialog
              texts={texts}
              txtlang={txtlang}
              playclicksfx={()=>{}}
              data={{
                position: [1.46,-1.74],
                "body": " It is forbidden to drive machinery or work equipment and at the same time use a mobile phone (talking, texting, etc.). You must stop and\/or park the forklift and answer the call, for example, in safety.",
                cover : [require("./staticData/dialogImages/Te1Z6dzw.jpeg"),
                    require("./staticData/dialogImages/CYtF46ig.jpeg")
              ]
                 
              }}
              handleClickOpen={()=>{}}
              handleClose={()=>{}}
              openDialog={true}
            /> */}
      {!loading? <SidebarPhone setopenPhoneSidebar={setopenPhoneSidebar} openPhoneSidebar={openPhoneSidebar} isPT={isPT} txtlang={txtlang} switchPT={switchPT} handleInfobuttonclicked={handleInfobuttonclicked} AudioMute={AudioMute} muteAudio={muteAudio} handleConfirmationOpen={handleConfirmationOpen} openSidebar={openSidebar} setopenSidebar={setopenSidebar} />:null}
      {!loading? <Sidebar setopenPhoneSidebar={setopenPhoneSidebar} openPhoneSidebar={openPhoneSidebar} isPT={isPT} txtlang={txtlang} switchPT={switchPT} handleInfobuttonclicked={handleInfobuttonclicked} AudioMute={AudioMute} muteAudio={muteAudio} handleConfirmationOpen={handleConfirmationOpen} openSidebar={openSidebar} setopenSidebar={setopenSidebar} />:null}
       {openConfirmation? <ConfirmationPopup  texts={texts} txtlang={txtlang} isPT={isPT} AudioMute={AudioMute} playclicksfx={playclicksfx} openConfirmation={openConfirmation} handleConfirmationClose={handleConfirmationClose}/>:null}
      
       <Switch>
          <Route exact path="/" render={props => <LoadingSplash txtlang={txtlang} loadingProgress={splashLoading}/>} />
          <Route exact path="/rht" render={props => <RHRut texts={texts} txtlang={txtlang}  isPT={isPT} AudioMute={AudioMute} playclicksfx={playclicksfx} seenTutorial={()=>seenTutorial(1)} finsihedModule={finsihedModule} setUserTime={ setUserTime} setdetailedScore={setdetailedScore} detailedScore={detailedScore} risk={risk} TotalScore={TotalScore} setTotalScore={setTotalScore} tokenExams={tokenExams} settokenExams={settokenExams} completedScenario={completedScenario} setcompletedScenario={setcompletedScenario}  ActiveScenario={ActiveScenario} setActiveScenario={setActiveScenario} loading={loading} setLoading={setLoading} setopenQuiz={setopenQuiz} openQuiz={openQuiz}/> } />=
          <Route exact path="/riskhunt" render={props => <RiskHunt  muteBackgeound={stop} muteAudio={muteAudio} texts={texts} txtlang={txtlang} isPT={isPT} setCurrentExam={setCurrentExam} CurrentExam={CurrentExam} playbackgroundfx={playbackgroundfx} setmuteBackground={stop} AudioMute={AudioMute} playclicksfx={playclicksfx} gameIndex={gameIndex} setgameIndex={setgameIndex} TutorialShown={TutorialShown} finsihedModule={finsihedModule} setUserTime={ setUserTime} setdetailedScore={setdetailedScore} detailedScore={detailedScore} risk={risk} TotalScore={TotalScore} setTotalScore={setTotalScore} tokenExams={tokenExams} settokenExams={settokenExams} completedScenario={completedScenario} setcompletedScenario={setcompletedScenario}  ActiveScenario={ActiveScenario} setActiveScenario={setActiveScenario} loading={loading} setLoading={setLoading} setopenQuiz={setopenQuiz} openQuiz={openQuiz}/> } />=
          {/* <Route exact path="/riskhunt" render={props => <RHRut seenTutorial={()=>seenTutorial(1)} finsihedModule={finsihedModule} setUserTime={ setUserTime} setdetailedScore={setdetailedScore} detailedScore={detailedScore} risk={risk} TotalScore={TotalScore} setTotalScore={setTotalScore} tokenExams={tokenExams} settokenExams={settokenExams} completedScenario={completedScenario} setcompletedScenario={setcompletedScenario}  ActiveScenario={ActiveScenario} setActiveScenario={setActiveScenario} loading={loading} setLoading={setLoading} setopenQuiz={setopenQuiz} openQuiz={openQuiz}/> } />= */}
          {/* <Route exact path="/singleResister" render={props => <SingleRegister/>} /> */}
          <Route exact path="/intro" render={props => <IntroSplash txtlang={txtlang} isPT={isPT} playbackgroundfx={playbackgroundfx} playclicksfx={playclicksfx}/>} />
          <Route exact path="/register" render={props => <TeamRegister txtlang={txtlang} isPT={isPT} AudioMute={AudioMute}  playclicksfx={playclicksfx} openSidebar={openSidebar}/>} />
          <Route exact path="/tutorial360" render={props => <Tutorial360 txtlang={txtlang}  isPT={isPT} playclicksfx={playclicksfx} AudioMute={AudioMute} setTutorialShown={setTutorialShown} texts={tutorial360} />} />
          <Route exact path="/findElement" render={props =>  <Tutorial stexts={texts} txtlang={txtlang} isPT={isPT}  playclicksfx={playclicksfx} AudioMute={AudioMute} TutorialShown={TutorialShown}  seenTutorial={()=>seenTutorial(2)} setTutorialShown={setTutorialShown} gameName="find" gameTitle={texts.fre[txtlang]} texts={findTexts}/>} />
          <Route exact path="/riskHuntTutorial" render={props =>  <Tutorial stexts={texts} txtlang={txtlang}  isPT={isPT} playclicksfx={playclicksfx} AudioMute={AudioMute} seenTutorial={()=>seenTutorial(1)}  setTutorialShown={setTutorialShown} gameName={"risk"} gameTitle={texts.rhg[txtlang]} breakline={true} texts={riskHuntingTexts}/>} />
          <Route exact path="/quiz" render={props => <Tutorial stexts={texts} txtlang={txtlang}  isPT={isPT} playclicksfx={playclicksfx} AudioMute={AudioMute} TutorialShown={TutorialShown} seenTutorial={()=>seenTutorial(0)}  setTutorialShown={setTutorialShown} gameName="quiz"gameTitle={texts.qz[txtlang]}  texts={quizTexts}/>} />
          <Route exact path="/areas" render={props => <Areas texts={texts} txtlang={txtlang} isPT={isPT} AudioMute={AudioMute} playclicksfx={playclicksfx} selected={selected} setselected={setselected} finsihedModule={finsihedModule} setfinsihedModule={setfinsihedModule} setData={setData} data={data} tokenExams={tokenExams} completedScenario={completedScenario} setcompletedScenario={setcompletedScenario} isScenarios={false} title={texts.area1[txtlang]} body={texts.area2[txtlang]}/>} />
          <Route exact path="/scenarios" render={props => <Areas texts={texts}txtlang={txtlang} isPT={isPT} AudioMute={AudioMute} playclicksfx={playclicksfx} selected={selected} setselected={setselected} finsihedModule={finsihedModule} setfinsihedModule={setfinsihedModule}  setData={setData} data={data} tokenExams={tokenExams} settokenExams={settokenExams} completedScenario={completedScenario}  setcompletedScenario={setcompletedScenario} ActiveScenario={ActiveScenario} setActiveScenario={setActiveScenario} isScenarios={true} title={texts.scen[txtlang]} body={texts.choosesen[txtlang]}/>} />
          <Route exact path="/scoreSucceed" render={props => <Score texts={texts} txtlang={txtlang}  isPT={isPT} finsihedModule={finsihedModule} AudioMute={AudioMute} playclicksfx={playclicksfx} handleConfirmationOpen={handleConfirmationOpen} UserTime={UserTime} detailedScore={detailedScore} /> } />
          <Route exact path="/scoreFailed" render={props => <Score texts={texts} txtlang={txtlang} isPT={isPT} finsihedModule={finsihedModule} AudioMute={AudioMute} playclicksfx={playclicksfx} UserTime={UserTime} detailedScore={detailedScore}/> } />
          <Route exact path="/finish" render={props => <Thankyou resetStates={resetStates} texts={texts} txtlang={txtlang} isPT={isPT} AudioMute={AudioMute} playclicksfx={playclicksfx} />} />
      </Switch>
      <audio id="background_sound" src={backgroundfx} volume={0.02} loop/>
      <video  loop preload='auto' id="video" crossOrigin="anonymous" playsInline   > </video>
      <Box id="canvContainer">

      </Box>

      </Box>
     </BrowserRouter>      
  
          {/* <Quiz  handleClickOpen={()=>setopenQuiz(true)} handleClose={()=>setopenQuiz(false)} setopenQuiz={setopenQuiz} openQuiz={openQuiz} /> */}
        {/* <FRE data={data[ActiveID]} handleClickOpen={handleClickOpen} handleClose={handleClose} setopenQuiz={setopenQuiz} openQuiz={openQuiz} /> */}
        
        
         
      </ThemeProvider>
  );
}

// theme.typography.h3 = {
//   fontSize: '1.2rem',
//   '@media (min-width:600px)': {
//     fontSize: '1.5rem',
//   },
//   [theme.breakpoints.up('md')]: {
//     fontSize: '2.4rem',
//   },
// };