export const playHotspotMusic = () => {
  const soundElement = document.getElementById("hotspot_sound");
  soundElement.play();
}

export const playredTimersfxsfx = () => {
  const soundElement = document.getElementById("quiz_second");
  soundElement.volume = 0.25;
  soundElement.play();
}

export const stop = () => {
  const soundElement = document.getElementById("quiz_second");
  soundElement.pause();
}

export const playQuizTimerFirst = () => {
  console.log("playQuizTimerFirst")
  const soundElement = document.getElementById("quiz_first");
  soundElement.volume = 0.25;

  soundElement.play();
}

export const stopQuizTimerFirst = () => {
  console.log("stopQuizTimerFirst")
  const soundElement = document.getElementById("quiz_first");
  soundElement.pause();
}
