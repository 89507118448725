import img8 from "./dialogImages/img8.jpeg"
import img7 from "./dialogImages/img7.jpeg"
import img6 from "./dialogImages/img6.jpeg"
import img5 from "./dialogImages/img5.jpeg"
import img4 from "./dialogImages/img4.jpeg"
import img2 from "./dialogImages/img2.jpeg"
import img3 from "./dialogImages/img3.jpeg"
// import video from './videos/newDemo.mp4'
//-----------------------
import video from './videos/comp.mp4'
import { ReactComponent as worker } from "../UI/Tutorial/icons/worker.svg";
import { ReactComponent as time } from '../UI/Tutorial/icons/time.svg';
import { ReactComponent as hints } from '../UI/Tutorial/icons/hints.svg';
import { ReactComponent as eye } from '../UI/Tutorial/icons/eye.svg';
import { ReactComponent as time2 } from '../UI/Tutorial/icons/time2.svg';
import { ReactComponent as Sound } from '../UI/Tutorial/icons/Sound.svg';
import { ReactComponent as rotate } from '../UI/Tutorial/icons/rotate.svg';

import timeCover from "../UI/Tutorial/covers/time.png"
import soundCover from "../UI/Tutorial/covers/sound.png"
import rotateCover from "../UI/Tutorial/covers/rotate.png"

const scale = 0.75

const allstaticData = {
  true:{
     tutorial360:[
      ["Improve your visit by turning ON your audio","SOUND ON",Sound,soundCover],
      ["Hold and drag the screen to explore scenarios","360º INTERACTION",rotate,rotateCover],
      ["Total time will be tracked during the entire game","TIME IS COUNTING",time2,timeCover],
     ],
      riskHuntingTexts:[
         ["There are several Hazards and Risks in the room","Scene Score",worker,[70+70*scale,100+100*scale]],
         ["Can you find them all under 5 minutes?","time",time,[78+78*scale,105+105*scale]],
         ["If you get stuck, call for some hints","show hints",hints,[35+35*scale,35+35*scale]],
         ["",false,false,[35,35]],
     ],
      quizTexts :[
       ["Watch the video and pay attention to the action","pay attention",eye,[26 + 26*scale,17+17*scale]],
       ["Can you answer correctly under 90 seconds?","time",time,[78 + 78*scale ,105+105*scale]],
       
     ],
      findTexts :[
       ["Watch the video and pay attention to the action","pay attention",eye,[26+26*scale,17+17*scale]],
       ["Can you identify the right elements under 90 seconds?","time",time,[78+78*scale,105+105*scale]],
       
     ],
      risk :{
        module:"montagem",
        scenario:"scenario 1",
        ID:"prints-3",
        Area:"SPR",
        video:video,
        hotspots:[{
        visible:false,  
        position:[2.3,3],
        title:"Toolshop Zona 2 - erosão",
        body:"Derrames devem ser contidos com os panos/ almofadas/ chouriços/ mantas absorventes existentes nos contentores MEWA.",
        cover:false
      },
      {
        visible:false,
        position:[1.5,5.42],
        title:"Toolshop Zona 2 - erosão",
        body:"Verifique todos os dispositivos de segurança da máquina quando começa a trabalhar, se detetar alguma anomalia deve reportar ao seu chefe de equipa/ supervisor.",
        cover:img2
      },
      {
        visible:false,
        position:[2.1,4.52],
        title:"Toolshop Zona 2 - erosão",
        body:"Para todas as tarefas manuais onde exista risco de corte com arestas vivas, utilize sempre as luvas de proteção anti-corte para prevenir pequenos ferimentos.",
        cover:img3
      },
      {
        visible:false,
        position:[1.68,3.88],
        title:"Toolshop Zona 2 - erosão",
        body:"Sempre que utiliza produtos químicos deve utilizar as luvas de proteção química (nitrilo), de acordo com a política das luvas.",
        cover:img4
      },
      {
        visible:false,
        position:[2,1.9],
        title:"Toolshop Zona 2 - erosão",
        body:"Componentes e materiais de trabalho (como por exemplo, moldes, placas metálicas, ferramentas, etc...), devem ser sempre colocados o mais ao centro na bancada de trabalho, de forma a não cair.",
        cover:img5
      },
      {
        visible:false,
        position:[1.64,5.18],
        title:"Toolshop Zona 2 - erosão",
        body:'Equipamentos de trabalho, por exemplo, microscópios, lupas, outras luminárias, devem ser desligados quando não estão em utilização. "Seja um colaborador verde".',
        cover:img6
      },
      {
        visible:false,
        position:[2.44,1.44],
        title:"Toolshop Zona 2 - erosão ",
        body:"Manter o local de trabalho organizado e limpo é fundamental para as atividades que fazemos diariamente. Utilize os contentores apropriados para colocar os resíduos do setor.",
        cover:img7
      },
      {
        visible:false,
        position:[1.46,3.78],
        title:"Toolshop Zona 2 - erosão ",
        body:"Todos os EPI´s obrigatórios devem ser usados corretamente e a sua não utilização é da responsabilidade do colaborador. Caso a situação seja recorrente, pode ser alvo de um processo disciplinar.",
        cover:img8
      },
    ]
  }
  },
  false:{
    tutorial360:[
      ["Melhore a sua experiência ativando o som","SOM LIGADO",Sound,soundCover],
      ["Segure e arraste o ecrã para explorar cenários","INTERAÇÃO 360º",rotate,rotateCover],
      ["O tempo total será monitorizado durante todo o jogo","O TEMPO ESTÁ A CONTAR",time2,timeCover],
     ]
    ,riskHuntingTexts:[
         ["Existem vários perigos e riscos na sala","Pontuação do Cenário",worker,[70+70*scale,100+100*scale]],
         ["Consegue encontrar todos em menos de 5 minutos?","Tempo",time,[78+78*scale,105+105*scale]],
         ["Se ficar preso, peça algumas dicas","Mostrar Dicas",hints,[35+35*scale,35+35*scale]],
         ["",false,false,[35,35]],
     ]
     
    ,quizTexts :[
       ["Assista ao vídeo e preste atenção à ação","Preste atenção",eye,[26 + 26*scale,17+17*scale]],
       ["Consegue responder corretamente em menos de 90 segundos?","Tempo",time,[78 + 78*scale ,105+105*scale]],
       
     ]
    ,findTexts :[
       ["Assista ao vídeo e preste atenção à ação","Preste atenção",eye,[26+26*scale,17+17*scale]],
       ["Consegue identificar os elementos correctos em menos de 90 segundos?","Tempo",time,[78+78*scale,105+105*scale]],
     ],
    risk :
        {
          module:"montagem",
          scenario:"scenario 1",
          ID:"prints-3",
          Area:"SPR",
          video:video,
          hotspots:[{
            visible:false,  
            position:[2.3,3],
            title:"Toolshop Zona 2 - erosão",
            body:"Derrames devem ser contidos com os panos/ almofadas/ chouriços/ mantas absorventes existentes nos contentores MEWA.",
            cover:false
          },
          {
            visible:false,
            position:[1.5,5.42],
            title:"Toolshop Zona 2 - erosão",
            body:"Verifique todos os dispositivos de segurança da máquina quando começa a trabalhar, se detetar alguma anomalia deve reportar ao seu chefe de equipa/ supervisor.",
            cover:img2
          },
          {
            visible:false,
            position:[2.1,4.52],
            title:"Toolshop Zona 2 - erosão",
            body:"Para todas as tarefas manuais onde exista risco de corte com arestas vivas, utilize sempre as luvas de proteção anti-corte para prevenir pequenos ferimentos.",
            cover:img3
          },
          {
            visible:false,
            position:[1.68,3.88],
            title:"Toolshop Zona 2 - erosão",
            body:"Sempre que utiliza produtos químicos deve utilizar as luvas de proteção química (nitrilo), de acordo com a política das luvas.",
            cover:img4
          },
          {
            visible:false,
            position:[2,1.9],
            title:"Toolshop Zona 2 - erosão",
            body:"Componentes e materiais de trabalho (como por exemplo, moldes, placas metálicas, ferramentas, etc...), devem ser sempre colocados o mais ao centro na bancada de trabalho, de forma a não cair.",
            cover:img5
          },
          {
            visible:false,
            position:[1.64,5.18],
            title:"Toolshop Zona 2 - erosão",
            body:'Equipamentos de trabalho, por exemplo, microscópios, lupas, outras luminárias, devem ser desligados quando não estão em utilização. "Seja um colaborador verde".',
            cover:img6
          },
          {
            visible:false,
            position:[2.44,1.44],
            title:"Toolshop Zona 2 - erosão ",
            body:"Manter o local de trabalho organizado e limpo é fundamental para as atividades que fazemos diariamente. Utilize os contentores apropriados para colocar os resíduos do setor.",
            cover:img7
          },
          {
            visible:false,
            position:[1.46,3.78],
            title:"Toolshop Zona 2 - erosão ",
            body:"Todos os EPI´s obrigatórios devem ser usados corretamente e a sua não utilização é da responsabilidade do colaborador. Caso a situação seja recorrente, pode ser alvo de um processo disciplinar.",
            cover:img8
          },
        ]
        }
  }
}

//-----------------------


// import video from './three/162001.mp4'


export default allstaticData