import React from "react";
// const videos = {
//     "apq-38":[require("./videos/Apq-38.m4v"), 286128],
//     "circ-75 ac":[require("./videos/Circ-75 Ac.m4v"), 33280],
//     "Empac-46":[require("./videos/Empac-46.m4v"),138248 ],
//     "gfs-69":[require("./videos/Gfs-69.m4v"), 258608],
//     "hv-9":[require("./videos/Hv-9.m4v"),266880 ],
//     "micros-29":[require("./videos/Micros-29.m4v"), 72904],
//     "minis-24":[require("./videos/Minis-24.m4v"),88488 ],
//     "prints-1":[require("./videos/Prints-1.m4v"),169424 ],
//     "qualidade-49":[require("./videos/Qualidade-49.m4v"), 121936],
//     "recozi-40":[require("./videos/Recozi-40.m4v"),392888 ],
//     "stamp-31-pt1 ac":[require("./videos/Stamp-31-Pt1 Ac.m4v"),211656 ],
//     "stamp-31-pt1":[require("./videos/Stamp-31-Pt1.m4v"),164360 ],
//     "stamp-33":[require("./videos/Stamp-33.m4v"),25504 ],
//     "stamp-33 ac":[require("./videos/Stamp-33 Ac.m4v"),175640 ],
//     "tool_z3-61":[require("./videos/Tool Z3-61.m4v"),231872 ],
//     "arm-54":[require("./videos/Arm-54.m4v"), 185856],
//     "circ-75":[require("./videos/Circ-75.m4v"),47528 ],
//     "eng.pro-47":[require("./videos/Eng.Pro-47.m4v"),257640 ],
//     "eng.pro-48":[require("./videos/Eng.Pro-48.m4v"),119616 ],
//     "gfs-70":[require("./videos/Gfs-70.m4v"),156256 ],
//     "lab-39":[require("./videos/Lab-39.m4v"), 124016],
//     "micros-30":[require("./videos/Micros-30.m4v"), 263832],
//     "minis-25":[require("./videos/Minis-25.m4v"),229896 ],
//     "prints-2":[require("./videos/Prints-2.m4v"),130552 ],
//     "qualidade-50":[require("./videos/Qualidade-50.m4v"),106384 ],
//     "semi-13":[require("./videos/Semi-13.m4v"),162104 ],
//     "stamp-34":[require("./videos/Stamp-34.m4v"), 129776],
//     "arm-56":[require("./videos/Arm-56.m4v"),143984 ],
//     "corredor-79 ac":[require("./videos/Corredor-79 Ac.m4v"),88144 ],
//     "corredor-79":[require("./videos/Corredor-79.m4v"),142152 ],
//     "gfs-73":[require("./videos/Gfs-73.m4v"), 99896],
//     "micros-26":[require("./videos/Micros-26.m4v"),342048 ],
//     "minis-18 ac":[require("./videos/Minis-18 Ac.m4v"),25888 ],
//     "minis-18":[require("./videos/Minis-18.m4v"),39024 ],
//     "mold-41":[require("./videos/Mold-41.m4v"),108464 ],
//     "prints-3":[require("./videos/Prints-3.m4v"),398952 ],
//     "qualidade-51":[require("./videos/Qualidade-51.m4v"),106384 ],
//     "semi-14":[require("./videos/Semi-14.m4v"),139768 ],
//     "stamp-31-pt2":[require("./videos/Stamp-31-Pt2.m4v"), 174128],
//     "stamp-35":[require("./videos/Stamp-35.m4v"),91624 ],
//     "arm-57":[require("./videos/arm-57-Review.mp4"), 290218],
//     "entrada-58":[require("./videos/Entrada-58.m4v"),249664 ],
//     "hv-10":[require("./videos/Hv-10.m4v"),  142928],
//     "micros-27":[require("./videos/Micros-27.m4v"),61200],
//     "mold-42":[require("./videos/Mold-42.m4v"),186152 ],
//     "prints-4":[require("./videos/Prints-4.m4v"), 56016],
//     "qualidade-52":[require("./videos/Qualidade-52.m4v"),117104 ],
//     "semi-16":[require("./videos/Semi-16.m4v"),196736 ],
//     "stamp-32 ac":[require("./videos/Stamp-32 Ac.m4v"),86688 ],
//     "stamp-32":[require("./videos/Stamp-32.m4v"), 65072],
//     "stamp-36":[require("./videos/Stamp-36.m4v"), 148544],
//     "bc-74":[require("./videos/Bc-74.m4v"),161528 ],
//     "covid-78":[require("./videos/Covid-78.m4v"),136840 ],
//     "galv-37":[require("./videos/Galv-37.m4v"), 304960],
//     "hv-7":[require("./videos/Hv-7-Review.m4v"), 221928],
//     "micros-28 ac":[require("./videos/Micros-28 Ac.m4v"), 101448],
//     "minis-22":[require("./videos/Minis-22.m4v"),274768 ],
//     "mold-43":[require("./videos/Mold-43.m4v"),190392 ],
//     "prints-5":[require("./videos/Prints-5.m4v"), 223368],
//     "qualidade-53 ac":[require("./videos/Qualidade-53 Ac.m4v"),171968 ],
//     "qualidade-53":[require("./videos/Qualidade-53-Review.mp4"),148945 ],
//     "sensores-21-pt1":[require("./videos/Sensores-21-Pt1.m4v"),232544 ],
//     "sensores-21-pt2":[require("./videos/Sensores-21-Pt2.m4v"), 146904],
//     "tool_z1-59":[require("./videos/Tool Z1-59.m4v"),115040 ],
//     "BDR-11":[require("./videos/Bdr-11.m4v"),188392 ],
//     "empac-45":[require("./videos/Empac-45.m4v"),168888 ],
//     "gfs-68":[require("./videos/Gfs-68.m4v"),613256 ],
//     "hv-8":[require("./videos/Hv-8.m4v"),144432 ],
//     "micros-28":[require("./videos/Micros-28.m4v"),80344 ],
//     "minis-23":[require("./videos/Minis-23.m4v"),115072 ],
//     "mold-44":[require("./videos/Mold-44.m4v"),218328 ],
//     "prints-6":[require("./videos/Prints-6.m4v"),94184 ],
//     "tool_z2-60":[require("./videos/Tool Z2-60.m4v"), 195160],
//     "comp.mp4":[require("./videos/comp.mp4"),76464 ],
//     "semi-15":[require("./videos/XnPokPaL.png"),188392 ],
//     "sensores-17":[require("./videos/sensores-17.png"),188392 ]
// }  

 const videos = {
    "apq-38":["https://te.vr360.pt/static/media/Apq-38.04a9e7f0.m4v", 286128],
    "circ-75 ac":["https://te.vr360.pt/static/media/Circ-75 Ac.23b29d13.m4v", 33280],
    "Empac-46":["https://te.vr360.pt/static/media/Empac-46.4b55bf64.m4v",138248 ],
    "gfs-69":["https://te.vr360.pt/static/media/Gfs-69.2a7cf35c.m4v", 258608],
    "hv-9":["https://te.vr360.pt/static/media/Hv-9.593b538b.m4v",266880 ],
    "micros-29":["https://te.vr360.pt/static/media/Micros-29.3c807c97.m4v", 72904],
    "minis-24":["https://te.vr360.pt/static/media/Minis-24.558c3cca.m4v",88488 ],
    "prints-1":["https://te.vr360.pt/static/media/Prints-1.e103fdcc.m4v",169424 ],
    "qualidade-49":["https://te.vr360.pt/static/media/Qualidade-49.43b6260c.m4v", 121936],
    "recozi-40":["https://te.vr360.pt/static/media/Recozi-40.2b9fd903.m4v",392888 ],
    "stamp-31-pt1 ac":["https://te.vr360.pt/static/media/Stamp-31-Pt1 Ac.3948d6c1.m4v",211656 ],
    "stamp-31-pt1":["https://te.vr360.pt/static/media/Stamp-31-Pt1.38618b0b.m4v",164360 ],
    "stamp-33":["https://te.vr360.pt/static/media/Stamp-33.f753b4b4.m4v",25504 ],
    "stamp-33 ac":["https://te.vr360.pt/static/media/Stamp-33 Ac.d57f28c0.m4v",175640 ],
    "tool_z3-61":["https://te.vr360.pt/static/media/Tool Z3-61.51f5fe9a.m4v",231872 ],
    "arm-54":["https://te.vr360.pt/static/media/Arm-54.c08b89c2.m4v", 185856],
    "circ-75":["https://te.vr360.pt/static/media/Circ-75.657c5ede.m4v",47528 ],
    "eng.pro-47":["https://te.vr360.pt/static/media/Eng.Pro-47.477673f8.m4v",257640 ],
    "eng.pro-48":["https://te.vr360.pt/static/media/Eng.Pro-48.0ad733ea.m4v",119616 ],
    "gfs-70":["https://te.vr360.pt/static/media/Gfs-70.832c5be1.m4v",156256 ],
    "lab-39":["https://te.vr360.pt/static/media/Lab-39.75e77ca9.m4v", 124016],
    "micros-30":["https://te.vr360.pt/static/media/Micros-30.f055bbf1.m4v", 263832],
    "minis-25":["https://te.vr360.pt/static/media/Minis-25.ed687682.m4v",229896 ],
    "prints-2":["https://te.vr360.pt/static/media/Prints-2.4440105a.m4v",130552 ],
    "qualidade-50":["https://te.vr360.pt/static/media/Qualidade-50.c6d29516.m4v",106384 ],
    "semi-13":["https://te.vr360.pt/static/media/Semi-13.4b05bda6.m4v",162104 ],
    "stamp-34":["https://te.vr360.pt/static/media/Stamp-34.4a0bd34d.m4v", 129776],
    "arm-56":["https://te.vr360.pt/static/media/Arm-56.072c0b55.m4v",143984 ],
    "corredor-79 ac":["https://te.vr360.pt/static/media/Corredor-79 Ac.a4f6c009.m4v",88144 ],
    "corredor-79":["https://te.vr360.pt/static/media/Corredor-79.6734c41a.m4v",142152 ],
    "gfs-73":["https://te.vr360.pt/static/media/Gfs-73.2da750c7.m4v", 99896],
    "micros-26":["https://te.vr360.pt/static/media/Micros-26.e4b2a348.m4v",342048 ],
    "minis-18 ac":["https://te.vr360.pt/static/media/Minis-18 Ac.cb0bb502.m4v",25888 ],
    "minis-18":["https://te.vr360.pt/static/media/Minis-18.a53965eb.m4v",39024 ],
    "mold-41":["https://te.vr360.pt/static/media/Mold-41.fb899458.m4v",108464 ],
    "prints-3":["https://te.vr360.pt/static/media/Prints-3.05852886.m4v",398952 ],
    "qualidade-51":["https://te.vr360.pt/static/media/Qualidade-51.409e5dd7.m4v",106384 ],
    "semi-14":["https://te.vr360.pt/static/media/Semi-14.e10bf843.m4v",139768 ],
    "stamp-31-pt2":["https://te.vr360.pt/static/media/Stamp-31-Pt2.03ccd5c7.m4v", 174128],
    "stamp-35":["https://te.vr360.pt/static/media/Stamp-35.e8d75829.m4v",91624 ],
    "arm-57":["https://te.vr360.pt/static/media/arm-57-Review.f70a46bf.mp4", 290218],
    "entrada-58":["https://te.vr360.pt/static/media/Entrada-58.d263e059.m4v",249664 ],
    "hv-10":["https://te.vr360.pt/static/media/Hv-10.882b62a2.m4v",  142928],
    "micros-27":["https://te.vr360.pt/static/media/Micros-27.ec596082.m4v",61200],
    "mold-42":["https://te.vr360.pt/static/media/Mold-42.94cd99d7.m4v",186152 ],
    "prints-4":["https://te.vr360.pt/static/media/Prints-4.b6e52f6f.m4v", 56016],
    "qualidade-52":["https://te.vr360.pt/static/media/Qualidade-52.edda58c9.m4v",117104 ],
    "semi-16":["https://te.vr360.pt/static/media/Semi-16.045d59d8.m4v",196736 ],
    "stamp-32 ac":["https://te.vr360.pt/static/media/Stamp-32 Ac.25ccdc53.m4v",86688 ],
    "stamp-32":["https://te.vr360.pt/static/media/Stamp-32.c639acbf.m4v", 65072],
    "stamp-36":["https://te.vr360.pt/static/media/Stamp-36.b6bffbe1.m4v", 148544],
    "bc-74":["https://te.vr360.pt/static/media/Bc-74.c8652768.m4v",161528 ],
    "covid-78":["https://te.vr360.pt/static/media/Covid-78.e2a3eebf.m4v",136840 ],
    "galv-37":["https://te.vr360.pt/static/media/Galv-37.0c737f9b.m4v", 304960],
    "hv-7":["https://te.vr360.pt/static/media/Hv-7-Review.04deffa9.m4v", 221928],
    "micros-28 ac":["https://te.vr360.pt/static/media/Micros-28 Ac.72a5c609.m4v", 101448],
    "minis-22":["https://te.vr360.pt/static/media/Minis-22.bb68a458.m4v",274768 ],
    "mold-43":["https://te.vr360.pt/static/media/Mold-43.a25bb384.m4v",190392 ],
    "prints-5":["https://te.vr360.pt/static/media/Prints-5.e457d7c1.m4v", 223368],
    "qualidade-53 ac":["https://te.vr360.pt/static/media/Qualidade-53 Ac.ca834008.m4v",171968 ],
    "qualidade-53":["https://te.vr360.pt/static/media/Qualidade-53-Review.mp4",148945 ],

    "sensores-21-pt1":["https://te.vr360.pt/static/media/Sensores-21-Pt1.18ef43d0.m4v",232544 ],
    "sensores-21-pt2":["https://te.vr360.pt/static/media/Sensores-21-Pt2.74541ff3.m4v", 146904],

    "tool_z1-59":["https://te.vr360.pt/static/media/Tool Z1-59.dc16c582.m4v",115040 ],
    "BDR-11":["https://te.vr360.pt/static/media/Bdr-11.4fd7a478.m4v",188392 ],
    "empac-45":["https://te.vr360.pt/static/media/Empac-45.1153b1f7.m4v",168888 ],
    "gfs-68":["https://te.vr360.pt/static/media/Gfs-68.babe2f26.m4v",613256 ],
    "hv-8":["https://te.vr360.pt/static/media/Hv-8.229895be.m4v",144432 ],
    "micros-28":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
    "minis-23":["https://te.vr360.pt/static/media/Minis-23.fd13f6a2.m4v",115072 ],
    "mold-44":["https://te.vr360.pt/static/media/Mold-44.dcace21d.m4v",218328 ],
    "prints-6":["https://te.vr360.pt/static/media/Prints-6.4e9edac8.m4v",94184 ],
    "tool_z2-60":["https://te.vr360.pt/static/media/Tool Z2-60.3fd9956c.m4v", 195160],
    "comp.mp4":["https://te.vr360.pt/static/media/comp.379c9dd5.mp4",76464 ],
    "semi-15":["https://te.vr360.pt/static/media/XnPokPaL.0f0a89c6.png",188392 ],
    "sensores-17":["https://te.vr360.pt/static/media/sensores-17.4c8138f4.png",188392 ]
} 

// const videos = {
//     "apq-38":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "circ-75 ac":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "Empac-46":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "gfs-69":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "hv-9":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "micros-29":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "minis-24":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "prints-1":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "qualidade-49":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "recozi-40":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "stamp-31-pt1 ac":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "stamp-31-pt1":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "stamp-33":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "stamp-33 ac":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "tool_z3-61":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "arm-54":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "circ-75":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "eng.pro-47":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "eng.pro-48":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "gfs-70":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "lab-39":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "micros-30":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "minis-25":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "prints-2":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "qualidade-50":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "semi-13":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "stamp-34":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "arm-56":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "corredor-79 ac":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "corredor-79":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "gfs-73":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "micros-26":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "minis-18 ac":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "minis-18":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "mold-41":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "prints-3":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "qualidade-51":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "semi-14":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "stamp-31-pt2":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "stamp-35":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "arm-57":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "entrada-58":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "hv-10":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "micros-27":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "mold-42":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "prints-4":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "qualidade-52":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "semi-16":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "stamp-32 ac":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "stamp-32":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "stamp-36":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "bc-74":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "covid-78":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "galv-37":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "hv-7":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "micros-28 ac":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "minis-22":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "mold-43":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "prints-5":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "qualidade-53 ac":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "qualidade-53":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "sensores-21-pt1":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "sensores-21-pt2":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "tool_z1-59":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "BDR-11":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "empac-45":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "gfs-68":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "hv-8":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "micros-28":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "minis-23":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "mold-44":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "prints-6":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "tool_z2-60":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "comp.mp4":["https://te.vr360.pt/static/media/Micros-28.d6eb88db.m4v",80344 ],
//     "semi-15":["https://te.vr360.pt/static/media/XnPokPaL.0f0a89c6.png",188392 ],
//     "sensores-17":["https://te.vr360.pt/static/media/sensores-17.4c8138f4.png",188392 ]
// } 



 
const examData = {
    true:{
        ficha : {
            1:[  { 
                ac:true,
                "game":"quiz",
                "MODULE": "Pre-Prefabrication",
                "Scenario": "Scene 1",
                "ID": "stamp-32",
                "Section": "stamping (Minting and warehousing, Tooling, Billing)",
                "Number of the area to be filmed (same as on the floor plan)": 32,
                "Area": "Stamping Tooling",
                "Sectiontitle": "Ergonomics - Manual handling of loads",
                "Risk to represent": "tool movement - falling objects by detachment, and ergonomics",
                "Scenery (what should be filmed\/photographed)": "1-Movement of the tool - transport on the cart (put the protection to block the tool from falling from the cart while walking",
                "number of collaborators": 1,
                "Action of each employee present in the scenario": "collaborator 1- action 1",
                "Equipment and objects to have prepared": "tool cart",
                "VIDEO 360 OR PHOTO 360?": "Video",
                "- Video runs in its entirety, with incorrect behaviors, the quiz appears and then starts 2D video with corrective actions?= 1 360 video + 1 2D video": "Video runs in its entirety, with incorrect behaviors, the quiz appears and then starts 2D video with corrective actions?= 1 360 video + 1 2D video",
                "startTime":4,
                "General observations": null,
                "Question": "How should you proceed when transporting the tool to the rack\n\nIdentify the wrong answer.",
                ans:[ "Transport the tool with the side covers of the transport trolley fitted.",
                "Transport the tool on the stacker, as the tool is larger than the trolley and does not allow the side protections to be fitted correctly.",
                "Asking my colleague for help to share efforts and put the tool on the rack, as it weighs 30Kg.",
                "Transport the tool on the transport trolley without the side covers fitted, as the tool is larger than the trolley.",]
                ,ca:[3],invert:true},
        
                {
                    ac:true, 
                    "game":"quiz",
                    "MODULE": "Pre-Prefabrication",
                    "Scenario": "Scene 1",
                    "ID": "circ-75",
                    "Section": "movement of people",
                    "Number of the area to be filmed (same as on the floor plan)": 75,
                    "Area": "Movement of people outside",
                    "Sectiontitle": "Outdoor circulation - Precautions to be taken",
                    "Risk to represent": "hit-and-run",
                    "Scenery (what should be filmed\/photographed)": "1-Film the employee entering the entrance and instead of going through the pedestrian path and the respective crosswalk, he goes in the middle of the road 2-A forklift is coming towards him in reverse with a load",
                    "number of collaborators": 2,
                    "Action of each employee present in the scenario": "contributor 1-action1 contributor 2-action 2",
                    "Equipment and objects to have prepared": "forklift",
                    "VIDEO 360 OR PHOTO 360?": "video",
                    "- Video runs in its entirety, with incorrect behaviors, the quiz appears and then starts 2D video with corrective actions?= 1 360 video + 1 2D video": "360 video + 2D video",
                    "startTime": null,
                    "General observations": null,
                    "Question": "When walking outside on the plant, where should you walk?\n\nIdentify the correct answer.",
                    ans:[ "You should walk on the road, but close to the crosswalk.",
                    "In places designated\/marked for pedestrian circulation, such as crosswalks and footpaths.",
                     "You should walk on the road, there is no risk of being run over. ",
                    "You can walk wherever you want. ",]
                    ,ca:[1]},
                   {
                       "game":"quiz",
                    "MODULE": "Pre-Prefabrication",
                    "Scenario": "Scene 1",
                    "ID": "covid-78",
                    "Section": "COVID-19",
                    "Number of the area to be filmed (same as on the floor plan)": 78,
                    "Area": "COVID 19 - preventive measures 2",
                    "Sectiontitle": "COVID 19 preventive measures - Hand hygiene",
                    "Risk to represent": "WAIT FOR CONFIRMATION FROM ANA, OK from Ana fadista",
                    "Scenery (what should be filmed\/photographed)": "1- Hand disinfection scenario in the canteen",
                    "number of collaborators": 2,
                    "Action of each employee present in the scenario": "collaborator 1 and 2- action 1 and action 2",
                    "Equipment and objects to have prepared": null,
                    "VIDEO 360 OR PHOTO 360?": "video",
                    "- Video runs in its entirety, with incorrect behaviors, the quiz appears and then starts 2D video with corrective actions?= 1 360 video + 1 2D video": " Video (already with correct actions) is interrupted to present a quiz and then continues to show the correct way to proceed? = 1 only 360 video",
                    "startTime": 5,
                    "General observations": null,
                    "Question": "During the COVID-19 pandemic the company implemented several prevention measures.\n\nIdentify the correct answer.",
                    ans:[ "The canteen is a low contagion risk site for COVID-19, so I do not need to disinfect my hands on entry.",
                    "I already had COVID-19, I no longer need to comply with the prevention rules.",
                     "I should always disinfect my hands upon entering any enclosed space that is shared with more people in order to prevent contagion.",
                    "The use of mask and distancing is sufficient for the prevention of COVID-19 in closed places.",]
                    ,ca:[2]},
                   {  
                        as:[8],
                        Sectiontitle:"Find all hazards in the location shown",
                        "MODULE": "Pre-Prefabrication",
                        "Scenario": "Scenario 1",
                        "ID": "stamp-35",
                        "Secção": "Stamping (Cunhagem e armazem, Ferramentaria, Bilhers)",
                        "Area": "Bihlers",
                        "game":"risk", 
                        "Kuula  (link)": "https:\/\/kuula.co\/post\/NFxSz\/collection\/7qbyZ"
                        ,hotspots :[
                            {
                                position:[3.80,-3.70],
                                "body": "All trolleys for carrying hand tools, or others, should have locked wheels to prevent the trolley from moving inadvertently when not in use.",
                                cover : require("./dialogImages/u5OWuDtI.jpeg")
                                    
                            },{
                                position:[4.72,1.12],
                                "body": "Even if you are carrying out tasks with a low risk of cuts or where there is an exemption from wearing anti-cut gloves, you should carry the gloves with you in the glove clip so they are always handy should you need them.",
                                cover : require("./dialogImages/zGsNqK58.jpeg")
                            },
                            {
                                position:[4.54,2.02],
                                "body": "Place the waste in the appropriate containers for the type of waste you wish to dispose of.",
                                cover : require("./dialogImages/dfpFGB-w.jpeg")
                            },
                            {
                                position:[4.50,1.20],
                                "body": "Work at a height requires the use of auxiliary equipment in a good conservation status and in accordance with safety rules. Always place both feet on the base of the platform or on the step of the stepladder, in order to keep your body balanced and not fall.",
                                cover : require("./dialogImages/PKgXaJ9A.jpeg")
                            },
                            {
                                position:[4.52,-2.26],
                                "body": "You should not use your mobile phone while walking in corridors surrounding work areas. Inattention to your surroundings may cause you to fall at the same level, trip and slip. ",
                                cover : require("./dialogImages/OuN-w5Pw.jpeg")
                            },
                        ]
                    },
        
        {
            as:[27,50,55],
            // as:[3,7,10],
                Sectiontitle:"Find all hazards in the location shown",
         
            "MODULE": "Pre-Prefabrication",
         "Scenario": "Scenario 1",
         "ID": "stamp-36",
         "Secção": "Stamping (Cunhagem e armazem, Ferramentaria, Bilhers)",
         "Area": "Warehouse of the bands - Stamping",
         "game":"risk",
         "Kuula  (link)": "https:\/\/kuula.co\/post\/NFxSS\/collection\/7qbyZ"
          ,hotspots :[
          {
    position:[4.36,-5.02],
            "body": "Workplace organization is fundamental. It should keep the corridors clear, preventing tripping and slipping, falling objects and, above all, serious incidents. Apply the 5S tool.",
    
          }, {
            position:[4.62,-4.80],
            "body": "Work according to the rules, respect the safety signs implemented in the plant, such as danger, prohibition, obligation, emergency and fire-fighting signs, and others.",
         cover : require("./dialogImages/BaEl6_-M.jpeg")
                   },
           {
            position:[4.72,-4.48],
            "body": "It is forbidden to drive machinery or work equipment and at the same time use a mobile phone (talking, texting, etc.). You must stop and\/or park the forklift and answer the call, for example, in safety.",
         cover : require("./dialogImages/h25u-ojg.jpeg")
                   },
           {
    position:[4.28,-5.48],
            "body": "Climbing or standing on a pallet to reach a higher rack level is prohibited. You must use a ladder or platform to access the material you need at a higher level.",
           },
        ]
        },
                {
                    as:[12],
                    Sectiontitle:"Find all hazards in the location shown",
             
                "MODULE": "Pre-Prefabrication",
             "Scenario": "Scenario 1",
             "ID": "stamp-31-pt2",
             "Secção": "Stamping (Cunhagem e armazem, Ferramentaria, Bilhers)",
             "game":"risk",
             "Area":"Stamping",
             "Kuula  (link)": "https:\/\/kuula.co\/post\/NFgVz\/collection\/7qbyZ"
              ,hotspots :[
              {
                position:[1.46,-4.30],
                "body": "In order to minimize noise exposure, always leave the press soundproofing enclosure closed after running the machine.",
             cover : require("./dialogImages/Dm9eoFeU.jpeg")
                
              },{
                position:[1.62,-2.00],
                "body": "For all manual tasks where there is a risk of cutting with sharp edges, always use the anti-cut gloves to prevent minor injuries.",
                cover :[
                    require("./dialogImages/en04Mkzw.jpeg"),
                    require("./dialogImages/DjUPdqPA.jpeg"), 
                  ]
                       },
               {
            position:[1.62,-1.12],
                "body": "When driving in mixed traffic areas (fork-lift trucks and pedestrians), pay extra attention. If you drive forklifts, you should always honk your horn when entering and leaving the areas. As pedestrians, you should use the mirrors to view the roadway and pay attention to the forklift's position light (blue light).",
               },
               {
                position:[2.08,-1.46],
                "body": "Keeping the workplace organized and clean is fundamental to the activities we do every day. We avoid distractions, loss of time searching for what is essential, loss of concentration, and greater stress.",
             cover : require("./dialogImages/HIZr54xs.jpeg")
                       },
               {
                position:[1.56,-1.72],
                "body": "All mandatory PPE must be used correctly and failure to use it is the employee's responsibility. If the situation is recurrent, disciplinary action may be taken.",
             cover : require("./dialogImages/Crkrek44.jpeg")
                       },
               {
                position:[1.82,-1.96],
                "body": "It must always be ensured that the movable physical safety guards are placed in the appropriate place for their function. This eliminates the risk of contact with sharp edges of the band.",
             cover : require("./dialogImages/EhPx7SAQ.jpeg")
                      },
               {
                position:[2.52,-0.54],
                "body": "Keep the circulation paths clear and clean, thus allowing safe circulation in the workspace.",
             cover : require("./dialogImages/VnutjQTg.jpeg")
                       },
            ]
            },
                {
                    ac:true,
                    "game":"quiz",
                    "MODULE": "Pre-Prefabrication",
                    "Scenario": "Scene 1",
                    "ID": "stamp-31-pt1",
                    "Section": "stamping (Minting and warehousing, Tooling, Billing)",
                    "Number of the area to be filmed (same as on the floor plan)": 31,
                    "Area": "stamping",
                    "Sectiontitle": "Ergonomics - Manual handling of loads",
                    "Risk to represent": "Various sector risks",
                    "Scenery (what should be filmed\/photographed)": " 4- placing the large tool on the tool rack without assistance",
                    "number of collaborators": 4,
                    "Action of each employee present in the scenario": "collaborator 1- action 1 collaborator 2- action 2 action 3- no collaborator collaborator 3- action 4 action 5 - no collaborator collaborator 4- action 6",
                    "Equipment and objects to have prepared": " spill oil tool big cart",
                    "VIDEO 360 OR PHOTO 360?": "video",
                    "- Video runs in its entirety, with incorrect behaviors, the quiz appears and then starts 2D video with corrective actions?= 1 360 video + 1 2D video": "Video runs in its entirety, with incorrect behaviors, the quiz appears and then starts 2D video with corrective actions?= 1 360 video + 1 2D video",
                    "startTime": 4,
                    "General observations": null,
                    "Question": "When you pick up a tool from the rack using the transport trolley, what is the safety procedure you should take into account?\n\nIdentify the correct answer.",
                    ans:[ "Place the trolley against the rack, pulling the tool out by itself, as it is not heavy.",
                    "Place the trolley against the rack, and with the help of another employee in the area, pull the tool onto the trolley, and put the side locks on.",
                    "Place the trolley against the rack and lock it, with the help of another employee from the area, pull the tool onto the trolley and put the side locks on.",
                    "All of the above.",]
                    ,ca:[2]},
               
                {
                    ac:true,
                    "game":"quiz",
                    "MODULE": "Pre-Prefabrication",
                    "Scenario": "Scene 1",
                    "ID": "stamp-33",
                    "Section": "stamping (Minting and warehousing, Tooling, Billing)",
                    "Number of the area to be filmed (same as on the floor plan)": 33,
                    "Area": "Stamping Tooling",
                    "Sectiontitle": "Work tools - Danger struck by object",
                    "Risk to represent": "tool movement - falling objects by detachment",
                    "Scenery (what should be filmed\/photographed)": "1-Movement of the tool - leave the tool on the bench without the triangles",
                    "number of collaborators": 1,
                    "Action of each employee present in the scenario": "collaborator 1- action 1",
                    "Equipment and objects to have prepared": "tool",
                    "VIDEO 360 OR PHOTO 360?": "Video",
                    "- Video runs in its entirety, with incorrect behaviors, the quiz appears and then starts 2D video with corrective actions?= 1 360 video + 1 2D video": "Video runs in its entirety, with incorrect behaviors, the quiz appears and then starts 2D video with corrective actions?= 1 360 video + 1 2D video",
                    "startTime":4,
                    "General observations": "Filming the employee leaving the sign and leaving for lunch",
                    "Question": "When repairing a tool plate on the workbench, what safety procedures should I take into account?\n\nIdentify the correct answer.",
                    ans:[ "Leave the area, or move away from the bench, and leave the tool plate up.",
                    "Place the tool plate more centrally on the work bench so that it cannot fall over.",
                    "Leave the tool plate close to the edge of the bench to make it easier to transport to the trolley.",
                    "None of the previous.",]
                    ,ca:[1]},
            
                { 
                    "game":"quiz",
                    "MODULE": "Pre-Prefabrication",
                    "Scenario": "Scene 1",
                    "ID": "stamp-34",
                    "Section": "stamping (Minting and warehousing, Tooling, Billing)",
                    "Number of the area to be filmed (same as on the floor plan)": 34,
                    "Area": "Stamping Tooling",
                    "Sectiontitle": "Safety systems - Precautions to be taken",
                    "Risk to represent": "access to moving parts of the machine\/equipment",
                    "Scenery (what should be filmed\/photographed)": "1-Work on the emery always with the protection in place",
                    "number of collaborators": 1,
                    "Action of each employee present in the scenario": "collaborator 1- action 1",
                    "Equipment and objects to have prepared": "protection",
                    "VIDEO 360 OR PHOTO 360?": "Video",
                    "- Video runs in its entirety, with incorrect behaviors, the quiz appears and then starts 2D video with corrective actions?= 1 360 video + 1 2D video": " Video (already with correct actions) is interrupted to present a quiz and then continues to show the correct way to proceed? = 1 only 360 video",
                    "startTime":4,
                    "General observations": "Film the employee working with the emery without low protection",
                    "Question": "When I start work on the bench\/ pedestal grinding machine, what should I do? Identify the correct answer.",
                    ans:[ "I start the work without the guard of bench\/ pedestal grinding machine in place, as it is a quick task. ",
                    "I visually check the state of the grinding wheel and correctly place the guard on bench\/ pedestal grinding machine to do the task.",
                    "I use the anti-cutting\/anti-heat protection gloves to handle the workpiece on the bench\/ pedestal grinding machine.",
                    "I don't check the state of the grinding wheel before using the bench\/ pedestal grinding machine.",]
                    ,ca:[1]},
            ],
            2:[
                {
                    as:[45,61,80],
    
                Sectiontitle:"Find all hazards in the location shown",
         
            "MODULE": "Pre-Prefabrication",
         "Scenario": "Scenario 2",
         "ID": "galv-37",
         "Secção": "galvanic (linha produtiva, Armazém de PQ, ETARI)",
         "Area": "galvanic",
         "game":"risk",  
         "Kuula  (link)": "https:\/\/kuula.co\/post\/NFxSr\/collection\/7qbyZ"
          ,hotspots :[
          {
            position:[4.86,-5.00],
            "body": "It is forbidden to go under the crane while it is in operation. Before performing the mechanical movement of loads, check\n- if the weight of the load is in accordance with the capacity of the equipment; \n- the surroundings so as not to collide with objects while manipulating the load.",
         cover : require("./dialogImages/AQQc9k4c.jpeg")
                  
          },{
            position:[3.94,-2.40],
            "body": "Auxiliary lifting equipment must always be used at its workstation in order to avoid inappropriate ergonomic postures.",
         cover : require("./dialogImages/76zBJ_Vg.jpeg")
                   },
           {
            position:[4.54,-3.06],
            "body": "Places with restricted or conditioned access should only be used by employees from the area or authorized employees. This rule is due to the fact that employees from outside the area are not aware of all the specific risks inherent to it.",
         cover : require("./dialogImages/91S6K7Vw.jpeg")
                   },
           {
            position:[4.80,-3.68],
            "body": "When climbing or descending stairs you should do so safely, with your hands on the handrail and without using a mobile phone. ",
         cover : require("./dialogImages/ycZMMgQg.jpeg")
                   },
           {
            position:[4.44,-5.64],
            "body": "Workplace organization is fundamental. It should keep the corridors clear, preventing tripping and slipping, falling objects and, above all, serious incidents. Apply the 5S tool.",
         cover : require("./dialogImages/N_TBdl_Q.jpeg")
                   },
           {
            position:[4.36,-5.24],
            "body": "Water\/ oil spills should be contained (if you have the appropriate means, you should clean the floor) and signposted, avoiding falls at the same level and slips.",
         cover : require("./dialogImages/RRyz65Zw.jpeg")
            
           },
           {
            position:[4.60,-4.98],
            "body": "Never leave the crane hook suspended at a lower level near your body, you could hit it or be struck.",
         cover : require("./dialogImages/zLKNmtaQ.jpeg")
                   },
           {
            position:[4.72,-4.16],
            "body": "All mandatory PPE must be used correctly and failure to use it is the employee's responsibility. If the situation is recurrent, disciplinary action may be taken.",
         cover : require("./dialogImages/hn5JpoCA.jpeg")
                   },
           {
    position:[4.16,-2.92],
            "body": "When moving loads manually, bring the load close to your body (arms should not be outstretched), bend your legs, keep your back straight and place the box on the pallet.",
           },
        ]
        },
        
        {
            as:[13,47,51,74,82],
                Sectiontitle:"Find all hazards in the location shown",
         
            "MODULE": "Pre-Prefabrication",
         "Scenario": "Scenario 2",
         "ID": "lab-39",
         "Secção": "Lab. galvanic",
         "Area": "Lab. galvanic",
         "game":"risk",
         "Kuula  (link)": "https:\/\/kuula.co\/post\/NFxSB\/collection\/7qbyZ"
          ,hotspots :[
          {
            position:[4.52,4.30],
            "body": "When using chemical products you must wear chemical protective gloves (nitrile) in accordance with the glove policy. You must use your PPE correctly, protective gloves must be worn on both hands. ",
            cover :[
                require("./dialogImages/VzXs4Kmw.jpeg"), 
                require("./dialogImages/X_PBcRBQ.jpeg"),
              ]
            
          },{
            position:[4.76,4.36],
            "body": "You must use your PPE correctly. Safety goggles should be worn over the face, or in case of extra need, a safety visor should be worn.",
         cover : require("./dialogImages/jvS3pxJQ.jpeg")
               
        },
           {
            position:[4.54,2.60],
            "body": "In work equipment where there is ionizing radiation, such as x-rays, the use of an individual dosimeter to control radiation exposure is mandatory.",
         cover : require("./dialogImages/sDi-KrHA.jpeg")
               },
           {
            position:[4.46,6.62],
            "body": "Eating in the workplace is strictly forbidden.",
         cover : require("./dialogImages/gTIPim7g.jpeg")
             },
           {
            position:[4.18,3.88],
            "body": "In laboratories, or other workplaces, where there is the presence and handling of chemical products, it is forbidden to have water bottles.",
         cover : require("./dialogImages/u99AK4MA.jpeg")
            },
           {
            position:[4.68,1.30],
            "body": "Whenever you use chemicals in the hood, check that the extraction is functional, in order to reduce the risk of exposure to hazardous chemicals. ",
         cover : require("./dialogImages/wXxJiqRQ.jpeg")
             },
           {
            position:[4.32,1.64],
            "body": "Contaminated rags should be placed in the (red) hermetic container with pedal bin.",
         cover : require("./dialogImages/opa_D0lA.jpeg")
             },
           {
            position:[4.58,4.62],
            "body": "Chemical products or sample remains, should be placed in the S.C.A.T. funnel, which in turn has a direct connection to the WWT for treatment and disposal.",
         cover : require("./dialogImages/COWSXLkg.jpeg")
              },
          ]
        },
        
        {
                Sectiontitle:"Find all hazards in the location shown",
         
            "MODULE": "Pre-Prefabrication",
         "Scenario": "Scenario 2",
         "ID": "recozi-40",
         "Secção": "Recozimento",
         "Area": "annealing",
         "game":"risk", 
         "Kuula  (link)": "https:\/\/kuula.co\/post\/NDvzR\/collection\/7qbyZ"
          ,hotspots :[
          {
            position:[4.58,8.50],
            "body": "Emergency or fire-fighting equipment must be marked, unobstructed and easily accessible, facilitating its use.",
         cover : require("./dialogImages/vhKaNVnc.jpeg")
                   
          },{
            position:[4.52,8.04],
            "body": "Use the auxiliary equipment available, such as a pallet truck lift, for better ergonomic posture. You should always adjust the pallet truck to the height at which you will place the load on the pallet. ",
         cover : require("./dialogImages/6T5rd49Y.jpeg")
                   },
           {
            position:[4.66,7.46],
            "body": "Never leave a suspended load on the crane hook. The load could fall or become detached and strike you or your body.",
         cover : require("./dialogImages/bWMJ0vmQ.jpeg")
                   },
           {
            position:[4.90,7.32],
            "body": "When carrying out mechanical movement of loads with a crane, you must always place the load on the ground or in a safe place, as soon as you finish the operation.",
         cover : require("./dialogImages/2IW0BcIs.jpeg")
                   },
           {
    position:[4.54,7.06],
            "body": "In manual handling of loads (MHL), the weight and volume of the load are the factors which present the greatest risk to those moving them. In other situations, although there is no great weight to move, the danger lies in repetition and adopting incorrect postures. Use the correct MHL techniques to prevent musculoskeletal injuries.",
           },
           {
            position:[4.30,6.70],
            "body": "When moving loads manually, bring the load close to your body (arms should not be outstretched), bend your legs, keep your back straight and place the box on the pallet.",
         cover : require("./dialogImages/F29eyAXw.jpeg")
                   },
          ]
        },
                { 
                "game":"quiz",
                "MODULE": "Pre-Prefabrication",
                "Scenario": "Scenario 2",
                "ID": "apq-38",
                "Section": "Chemicals Warehouse",
                "Number of the area to be filmed (same as on the floor plan)": 38,
                "Area": "Chemicals Warehouse",
                "Sectiontitle": "Chemical - Storage, labelling, safety instructions, restricted access",
                "Risk to represent": null,
                "Scenery (what should be filmed\/photographed)": "transport the",
                "number of collaborators": null,
                "Action of each employee present in the scenario": null,
                "Equipment and objects to have prepared": null,
                "VIDEO 360 OR PHOTO 360?": "video",
                "- Video runs in its entirety, with incorrect behaviors, the quiz appears and then starts 2D video with corrective actions?= 1 360 video + 1 2D video": " Video (already with correct actions) is interrupted to present a quiz and then continues to show the correct way to proceed? = 1 only 360 video",
                "startTime": 9,
                "General observations": null,
                "Question": "Why is this container considered a restricted area?\n\nIdentify the correct answer.",
                ans:[ "It is a place where valuable raw materials are stored.",
                "It is a place where dangerous chemicals are stored.",
                "It is a place with racks for storing materials at height.",
                "None of the previous.",]
                ,ca:[1]
            },
            ],
            3:[
                {
                Sectiontitle:"Find all hazards in the location shown",
         
            "MODULE": "Pre-Prefabrication",
         "Scenario": "Scenario 3",
         "ID": "mold-41",
         "Secção": "Molding (Produção, Ferramentaria, sala de secagem, sala de escolhas)",
         "Area": "Molding",
         "game":"risk",
         "Kuula  (link)": "https:\/\/kuula.co\/post\/NFCf9\/collection\/7qbyZ"
          ,hotspots :[
            {
                position: [1.66,-1.82],
                "body": "Whenever there is contact with hot surfaces, and it is necessary to work or carry out repairs inside the machine, the anti-heat protection cuffs should be worn, preventing burns to the upper limbs.",
             cover : require("./dialogImages/oVLEB-cQ.jpeg")
                
               },
          {
            position:[1.90,-1.74],
            "body": "Oil spills in the prefabrication plant must be contained using the absorbent cloths\/ mats provided in the MEWA containers.",
         cover : require("./dialogImages/RW8E7IGg.jpeg")
                   
          }, {
            position: [4.96,7.62],
            "body": "Descending safely down the stepladder or ladder is fundamental in order not to fall. Make the descent safely, supporting both hands on the stepladder or ladder, and positioning the feet correctly on the rungs.",
         cover : require("./dialogImages/e3Oikf8c.jpeg")
                   },
           {
    position: [4.66,7.78],
            "body": "Never leave the load suspended on the crane hook. This can fall\/unhook and hit you or hit your body. It must always be placed on the ground or in a safe place as soon as you finish the operation.",
    },
    {
    position: [4.58,7.92],
        "body": "Never leave the load suspended on the crane hook. The load may fall or detach and hit you or your body. If you detect this situation and are not an operator of the equipment, do not remain indifferent, alert the supervisor\/ shift leader for immediate correction of the situation.",
           }
          
          ]
        },
       
        {
                Sectiontitle:"Find all hazards in the location shown",
         
            "MODULE": "Pre-Prefabrication",
         "Scenario": "Scenario 3",
         "ID": "mold-43",
         "Secção": "Molding (Produção, Ferramentaria, sala de secagem, sala de escolhas)",
         "Area": "PLCD\/ Inlay (ed.19)",
         "game":"risk", 
         "Kuula  (link)": "https:\/\/kuula.co\/post\/NFCfc\/collection\/7qbyZ"
          ,hotspots :[
          {
            position:[4.42,7.96],
            "body": "Check that the anti-fatigue mat is correctly positioned on the platform, so as not to lose the visibility of the steps, thus avoiding possible falls at uneven levels.",
         cover : require("./dialogImages/ZCoo38IU.jpeg")
                  
          }, {
    position:[4.54,9.18],
            "body": "When using blades, level 3, 4 or 5, as well as hand tools, you should wear the anti-cut protection gloves.",
        },
           {
            position:[4.44,7.14],
            "body": "Workplace organization is fundamental. It should keep the corridors clear, preventing tripping and slipping, falling objects and, above all, serious incidents. Apply the 5S tool.",
         cover : require("./dialogImages/pM_ed1Q8.jpeg")
                   },
           {
            position:[5.10,7.56],
            "body": "Whenever the crane is in operation, we must not carry out work at height in the vicinity or climb onto the machinery to carry out work (cleaning, repairs, etc.), as we could be hit by the load when moving the equipment. ",
         cover : require("./dialogImages/_-H9dqZM.jpeg")
                   },
           {
    position:[4.12,7.96],
            "body": "Keep circulation routes unobstructed and clean, thus allowing safe circulation in the workspace. Do not step over pallets to shorten the path.",
           },
           {
            position:[4.64,9.84],
            "body": "Use the platform frame to lean and pause, you should not sit on it raising your feet off the ground, as imbalance and falls from height can occur.",
         cover : require("./dialogImages/R7dE_OpA.jpeg")
                   },
           {
            position:[4.50,10.36],
            "body": "Keeping the workplace organised and clean is fundamental to the activities we do every day. We avoid distractions, loss of time searching for what is essential, loss of concentration, and greater stress.",
         cover : require("./dialogImages/8-sCLwlE.jpeg")
                   },
          ]
        },
       
        {
                Sectiontitle:"Find all hazards in the location shown",
         
            "MODULE": "Pre-Prefabrication",
         "Scenario": "Scenario 3",
         "ID": "mold-44",
         "Secção": "Molding (Produção, Ferramentaria, sala de secagem, sala de escolhas)",
         "Area": "Molding Tooling",
         "game":"risk",
         "Kuula  (link)": "https:\/\/kuula.co\/post\/NFCfX\/collection\/7qbyZ"
          ,hotspots :[
          {
    position:[4.58,7.42],
            "body": `Before and during the mechanical movement of loads with the crane, check: ^^ - if the weight of the load is in accordance with the capacity of the equipment; ^^ - the surroundings so as not to collide with objects while manipulating the load;^^ - the height at which you transport the load;^^ - whether sirens are working to alert other workers in the area;^^ - the balance of the load by reducing the speed of transport if necessary.\n`,
    
    }, {
    position:[5.16,7.14],
        "body": "All work equipment, such as pallet trucks, ladders, stepladders, platforms, among others, are subject to periodic safety checks by the GFS Department, and a visible inspection seal is placed to certify their validity.",
    },
    {
    position:[4.38,7.54],
        "body": "Work components and materials (such as moulds, metal plates, tools etc.) should always be placed as centrally as possible on the work bench so that they cannot fall over.",
    },
    {
    position:[4.40,7.26],
        "body": "Workplace organization is fundamental. It should keep the corridors clear, preventing tripping and slipping, falling objects and, above all, serious incidents. Apply the 5S tool.",
    },
    {
    position:[4.82,7.42],
        "body": "Never leave the crane hook suspended at a lower level close to your body, you could hit it or be struck.",
    },
    {
    position:[4.42,9.04],
        "body": "You should readjust the seat or chair to your height, adjusting your posture to promote better blood circulation, reducing the static muscle load associated with sitting posture.",
    },
           {
            position:[4.50,7.88],
            "body": "Whenever using chemical products you should wear the chemical protective gloves (nitrile) in accordance with the glove policy.",
         cover : require("./dialogImages/IIFyMVhA.jpeg")
                   },
          ]
        },
                {
                "game":"quiz",
                "MODULE": "Pre-Prefabrication",
                "Scenario": "Scenario 3",
                "ID": "mold-42",
                "Section": "molding (Production, Tooling, drying room, choices room)",
                "Number of the area to be filmed (same as on the floor plan)": 42,
                "Area": "Pre-Fab corridor - plastic material octobines",
                "Sectiontitle": "Clean and tidy - Danger of falling on same level, slipping",
                "Risk to represent": "fall to the same level, slip",
                "Scenery (what should be filmed\/photographed)": "1-spill plastic granules in the hallway and clean 2- ask for help to empty the sprues",
                "number of collaborators": 1,
                "Action of each employee present in the scenario": "collaborator 1- action 1",
                "Equipment and objects to have prepared": "plastic granules",
                "VIDEO 360 OR PHOTO 360?": "Video",
                "- Video runs in its entirety, with incorrect behaviors, the quiz appears and then starts 2D video with corrective actions?= 1 360 video + 1 2D video": " Video (already with correct actions) is interrupted to present a quiz and then continues to show the correct way to proceed? = 1 only 360 video",
                "startTime": 16,
                "General observations": "Take a picture of the handyman opening the bag of raw materials in the octobin and pouring material into the hallway",
                "Question": "During the transport of plastic waste by the handyman, waste falls on the floor. What should be done in this situation?\n\nIdentify the correct answer.",
                ans:[ "The handyman should leave the area clean. ",
                "Ask the GFS to clean up the material on the floor.",
                "Ask Isporeco to clean the material on the floor.",
                "It is not the handyman's task to clean the waste from the floor, so he leaves the area and does not clean up.",],
            ca:[0]}
    ],
            4:[
            {
                "game":"quiz",
                "MODULE": "Pre-Prefabrication",
                "Scenario": "Scenario 4",
                "ID": "entrada-58",
                "Section": "Input review",
                "Number of the area to be filmed (same as on the floor plan)": 58,
                "Area": "Incoming",
                "Sectiontitle": "Load lifting equipment",
                "Risk to represent": null,
                "Scenery (what should be filmed\/photographed)": " 1-Use of lifting platform",
                "number of collaborators": 1,
                "Action of each employee present in the scenario": "collaborator 1- action 1",
                "Equipment and objects to have prepared": null,
                "VIDEO 360 OR PHOTO 360?": "Video",
                "- Video runs in its entirety, with incorrect behaviors, the quiz appears and then starts 2D video with corrective actions?= 1 360 video + 1 2D video": " Video (already with correct actions) is interrupted to present a quiz and then continues to show the correct way to proceed? = 1 only 360 video",
                "startTime":7,
                "General observations": null,
                "Question": "When I use the platform for lifting loads, what safety procedures must I take into account when transporting a load to the first floor?\n\nIdentify the correct answer.",
                ans:[ "None. As the load is not very heavy, approximately 25kg, I do the manual transport of the load myself.",
                "I use the load-carrying platform to climb at the same time as the load.",
                    "I place the load inside the platform and push the up\/down button to transport it. ",
                "None of the previous answers.",]
                ,ca:[2]},
            ],
            5:[{
                Sectiontitle:"Find all hazards in the location shown",
         
            "MODULE": "Pre-Prefabrication",
         "Scenario": "Scenario 5",
         "ID": "tool_z1-59",
         "Secção": "Toolshop Zona 1",
         "Area": "Toolshop Zone 1 - milling machine",
         "game":"risk",
         "Kuula  (link)": "https:\/\/kuula.co\/post\/NFCqy\/collection\/7qbyZ"
          ,hotspots :[
          {
            position: [1.72,-1.72],
            "body": "For all manual tasks where there is a risk of cutting with sharp edges, always use the anti-cut gloves to prevent minor injuries.",
         cover : require("./dialogImages/7XCT2DSY.jpeg")
            
          }, {
            position: [1.86,1.58],
            "body": "Workplace organization is fundamental. It should keep the corridors clear, preventing tripping and slipping, falling objects and, above all, serious incidents. Apply the 5S tool.",
         cover : require("./dialogImages/zZMfDX6g.jpeg")
                   },
           {
            position: [2.02,-1.62],
            "body": "Contaminated rags should be placed in the (red) hermetic container with pedal bin.",
         cover : require("./dialogImages/YNewt-JI.jpeg")
               
        },
           {
            position: [1.68,-1.02],
            "body": "Whenever using chemical products you should wear the chemical protective gloves (nitrile) in accordance with the glove policy.",
            cover :[
                require("./dialogImages/4d_V03mQ.jpeg"), 
                require("./dialogImages/3AjuMMnQ.jpeg"),
              ]
            //  ,Z3KLxH5w
           },
          ]
        },
       
        {
                Sectiontitle:"Find all hazards in the location shown",
         
            "MODULE": "Pre-Prefabrication",
         "Scenario": "Scenario 5",
         "ID": "tool_z2-60",
         "Secção": "Toolshop Zona 2",
         "Area": "Toolshop Zone 2 - Erosion",
         "game":"risk",
         "Kuula  (link)": "https:\/\/kuula.co\/post\/NFCKk\/collection\/7qbyZ"
         ,hotspots :[
          {
    position:[2.3,3],
            "body": "Spillages should be contained with the absorbent cloths\/pads\/shrouds\/blankets provided in the MEWA containers.",
    
          },{
            position:[1.64,-0.92],
            "body": "Check all the safety devices on the machine when you start work, if you detect any anomaly you should report it to your shift leader \/ supervisor.",
         cover : require("./dialogImages/4TOASyMg.jpeg")
            
           },
           {
            position:[1.94,-1.72],
            "body": "For all manual tasks where there is a risk of cutting with sharp edges, always use the anti-cut gloves to prevent minor injuries.",
         cover : require("./dialogImages/4cfKB_ag.jpeg")
                   },
           {
            position:[1.62,-2.60],
            "body": "Whenever using chemical products you should wear the chemical protective gloves (nitrile) in accordance with the glove policy.",
         cover : require("./dialogImages/d0YkdiKo.jpeg")
                   },
           {
            position:[2,1.9],
            "body": "Work components and materials (e.g. moulds, metal plates, tools, etc.) should always be placed as centrally as possible on the work bench so that they do not fall over.",
         cover : require("./dialogImages/jG82-Wws.jpeg")
                   },
           {
            position:[1.44,-1.16],
            "body": "Work equipment, e.g. microscopes, magnifying glasses, other fixtures, should be switched off when not in use. \"Be a green employee\". ",
         cover : require("./dialogImages/TH0DHiNs.jpeg")
                   },
           {
            position:[2.42,1.42],
            "body": "Keeping the workplace organized and clean is fundamental to the activities we do every day. Use the appropriate containers to place the sector's waste.",
         cover : require("./dialogImages/lBITVfow.jpeg")
                   },
           {
            position:[1.46,-2.50],
            "body": "All mandatory PPE must be used correctly and failure to use it is the employee's responsibility. If the situation is recurrent, disciplinary action may be taken.",
         cover : require("./dialogImages/1vsA4EX8.jpeg")
                   },
          ]
        },
            {
                "game":"quiz",
                "MODULE": "Pre-Prefabrication",
                "Scenario": "Scenario 5",
                "ID": "tool_z3-61",
                "Section": "Toolshop Zone 3- SPW",
                "Number of the area to be filmed (same as on the floor plan)": 61,
                "Area": "Toolshop Zone 3 - SPW",
                "Sectiontitle": "Ergonomics - Manual handling of loads",
                "Risk to represent": "ergonomic risk",
                "Scenery (what should be filmed\/photographed)": "1-ergonomic risk, manual handling of loads - having a heavy piece at the back or away from the kardex.",
                "number of collaborators": 1,
                "Action of each employee present in the scenario": "collaborator 1- action 1",
                "Equipment and objects to have prepared": "heavy piece",
                "VIDEO 360 OR PHOTO 360?": "Photograph",
                "- Video runs in its entirety, with incorrect behaviors, the quiz appears and then starts 2D video with corrective actions?= 1 360 video + 1 2D video": "Photograph",
                "startTime": 8,
                "General observations": "take picture",
                "Question": "When using the dynamic warehouse (Kardex\/Megamat), how should the employee proceed? And what is the best way to position a heavy part inside?\n\nIdentify all correct answers.",
                ans:[ "Staying close to the dynamic warehouse during the operating cycle.",
                "Turn your back during the dynamic warehouse cycle movement.",
                    "Stand back, observe and wait until the operation cycle is finished before removing the part. ",
                "Place the piece inside the dynamic warehouse as close to the entrance\/exit as possible, thus minimizing physical effort.",]
                ,ca:[2,3]
            }],
            6:[
                {
                    "game":"quiz",
                    "MODULE": "Pre-Prefabrication",
                    "Scenario": "Scenario 7",
                    "ID": "gfs-68",
                    "Section": "GFS - Electronica",
                    "Number of the area to be filmed (same as on the floor plan)": 68,
                    "Area": "GFS",
                    "Sectiontitle": "Electronic maintenance - Safety devices",
                    "Risk to represent": null,
                    "Scenery (what should be filmed\/photographed)": "place safety devices.",
                    "number of collaborators": null,
                    "Action of each employee present in the scenario": null,
                    "Equipment and objects to have prepared": null,
                    "VIDEO 360 OR PHOTO 360?": "video",
                    "- Video runs in its entirety, with incorrect behaviors, the quiz appears and then starts 2D video with corrective actions?= 1 360 video + 1 2D video": " Video (already with correct actions) is interrupted to present a quiz and then continues to show the correct way to proceed? = 1 only 360 video",
                    "startTime": 8,
                    "General observations": null,
                    "Question": "What safety criteria must be taken into account during electronic maintenance task on the line, for example changing electrical connections, sensors, pneumatic connections, placing safety devices, etc.?\n\nIdentify all correct answers.",
                    ans:[ "Apply the LOTO procedure and  signalling with \"Machine under Repair\" tag.",
                    "Use the mandatory PPE's to perform the task, such as safety glasses, safety shoes, hearing protection, protective gloves, others...",
                        "Check the machine after maintenance, remove the tags and locks, and put the machine into operation.",
                    "Inform the employee that the machine is already operational, the safety devices are functional and that work can continue.",]
                    ,ca:[0,1,2,3]},
                    { 
                    "game":"quiz",
                    "MODULE": "Pre-Prefabrication",
                    "Scenario": "Scenario 7",
                    "ID": "gfs-69",
                    "Section": "GFS - Coverages",
                    "Number of the area to be filmed (same as on the floor plan)": 69,
                    "Area": "GFS",
                    "Sectiontitle": "Working at height - Roof access",
                    "Risk to represent": null,
                    "Scenery (what should be filmed\/photographed)": "1-Film from the outside the collaborator with epis and with another collaborator to accompany",
                    "number of collaborators": 2,
                    "Action of each employee present in the scenario": "collaborator 1 and 2- action 1",
                    "Equipment and objects to have prepared": null,
                    "VIDEO 360 OR PHOTO 360?": "Video",
                    "- Video runs in its entirety, with incorrect behaviors, the quiz appears and then starts 2D video with corrective actions?= 1 360 video + 1 2D video": " Video (already with correct actions) is interrupted to present a quiz and then continues to show the correct way to proceed? = 1 only 360 video",
                    "startTime": 8 ,
                    "General observations": "penthouse access photo tiara",
                    "Question": "What safety criteria should be taken into account when accessing the roof at height?\n\nIdentify all correct answers.",
                    ans:[ "At least two people must be working in the same place.",
                    "Complete the roof access record",
                        "Wear all compulsory PPE (safety shoes, goggles, safety harness, anti-cut gloves).",
                    "Although there is no guardrail and you have to lean over the roof, the task is so quick that you don't need to be wearing a safety harness.",]
                    ,ca:[0,1,2]},
                    {
                        "game":"quiz",
                    "MODULE": "Pre-Prefabrication",
                    "Scenario": "Scenario 7",
                    "ID": "gfs-70",
                    "Section": null,
                    "Number of the area to be filmed (same as on the floor plan)": 70,
                    "Area": "GFS",
                    "Sectiontitle": "Maintenance - Electrical connections",
                    "Risk to represent": "Note: hide box?",
                    "Scenery (what should be filmed\/photographed)": null,
                    "number of collaborators": null,
                    "Action of each employee present in the scenario": null,
                    "Equipment and objects to have prepared": null,
                    "VIDEO 360 OR PHOTO 360?": "Video",
                    "- Video runs in its entirety, with incorrect behaviors, the quiz appears and then starts 2D video with corrective actions?= 1 360 video + 1 2D video": " Video (already with correct actions) is interrupted to present a quiz and then continues to show the correct way to proceed? = 1 only 360 video",
                    "startTime":3  ,
                    "General observations": null,
                    "Question": "What safety criteria should be taken into account when working with electricity?\n\nIdentify all correct answers.",
                    ans:[ "Wear the necessary PPE for the task, such as a visor with protective helmet, safety shoes, dielectric protection gloves. ",
                    "At least two people must be working in the same place.",
                        "The second employee who is accompanying the work, only supervising, does not need to use PPE.",
                    "None of the previous answers.",]
                    ,ca:[0,1]},
                    {
                        Sectiontitle:"Find all hazards in the location shown",
                 
                    "MODULE": "Assembly",
                 "Scenario": "Scenario 7",
                 "ID": "gfs-73",
                 "Secção": "Prints (SRK, PK2, SPR, Workshop, M54, Micro K, Nano)",
                 "Area": "GFS Workshop",
                 "game":"risk",
                 "Kuula  (link)": "https:\/\/kuula.co\/post\/NFdQk\/collection\/7qbyZ"
                  ,hotspots :[
                  {
                position:[1.76,-3.30],
                    "body": "Workplace organization is fundamental. It should keep the corridors clear, preventing tripping and slipping, falling objects and, above all, serious incidents. Apply the 5S tool."
                },
                {
                position:[1.98,-2.56],
                "body": "Work tools (among other materials) should always be placed as centrally as possible on the workbench so that they do not fall over.",
                cover : require("./dialogImages/Znkp9NWg.jpeg")
    
            },
                {
                position:[1.62,0.14],
                "body": "Never leave a suspended load on the crane hook. It can fall off and hit you on the body.",
                },
                {
                position:[1.80,0.28],
                "body": "Keeping the workplace organized and clean is fundamental to the activities we do every day. We avoid distractions, loss of time searching for what is essential, loss of concentration, and greater stress.",
                cover : require("./dialogImages/WXA7Ysrg.jpeg") 
               
            },
                {
                position:[1.82,-0.82],
                "body": "You should keep all chemicals that are not in direct use stored in the dedicated cabinet. If they are secondary containers, check that the chemical is properly labelled and the container is in good condition.",
                cover : require("./dialogImages/5Jw3I87g.jpeg") 
              
            },
                {
                position:[1.78,-1.84],
                "body": "All mandatory PPE must be in good condition and used correctly. Protective gloves should be worn on both hands during the work task.",
                cover : require("./dialogImages/yJv5CeKA.png") 
            },
            { 
                position:[1.70,-0.11],
                "body": "In workplaces, it is forbidden to have water bottles next to chemical containers, thus preventing inadvertent ingestion of chemicals due to lack of labelling or misidentification of the secondary container.",
            },
                ]
                },
            ],
            7:[
                {
                ac:true,
                "game":"quiz",
                "MODULE": "Pre-Prefabrication",
                "Scenario": "Scenario 8",
                "ID": "corredor-79",
                "Section": "Molding (prefabrication aisle)",
                "Number of the area to be filmed (same as on the floor plan)": 79,
                "Area": "mixed circulation",
                "Sectiontitle": "Mixed circulation - precautions to be taken",
                "Risk to represent": "hit-and-run",
                "Scenery (what should be filmed\/photographed)": " Transporting octobin on the forklift and movement of people",
                "number of collaborators": 2,
                "Action of each employee present in the scenario": null,
                "Equipment and objects to have prepared": null,
                "VIDEO 360 OR PHOTO 360?": null,
                "- Video runs in its entirety, with incorrect behaviors, the quiz appears and then starts 2D video with corrective actions?= 1 360 video + 1 2D video": "360 video + 2D video",
                "startTime":null  ,
                "General observations": null,
                "Question": "What safety precautions need to be taken when placing octobines on pre-fabrication racks using a forklift?\n\nIdentify all correct answers.",
                ans:[ "Pedestrians should be aware of the forklift's manoeuvre by not passing behind it.",
                "Pedestrians must always circulate in the delimited pedestrian crossing, waiting for the forklift driver to perform the maneuver and leave the place.",
                "The forklift driver should always sound the horn when starting the drive.",
                "The forklift driver should drive in reverse, as the load obscures his view during transport, and be aware of pedestrian traffic.",]
                ,ca:[0,1,2,3]}
            ],
            
        },
    
        Assembly : {
            1:[
                 
        {
            Sectiontitle:"Find all hazards in the location shown",
     
        "MODULE": "Assembly",
     "Scenario": "Scenario 1",
     "ID": "prints-6",
     "Secção": "Prints (SRK, PK2, SPR, Workshop, M54, Micro K, Nano)",
     "Area": "prints warehouse",
     "game":"risk",
     "Kuula  (link)": "https:\/\/kuula.co\/post\/NFdQk\/collection\/7qbyZ"
      ,hotspots :[
      {
    position:[1.34,-1.92],
        "body": "Incorrectly positioned objects may fall and cause incidents."
    },
    {
    position:[1.98,-1.76],
    "body": "Climbing or standing on a pallet to reach a higher rack level is prohibited.",
    },
    {
    position:[2.10,-2.10],
    "body": "Damaged pallets must be replaced immediately by others in good condition.",
    },
    {
    position:[1.58,-1.42],
    "body": "Always use the handrail to hold on to and assist in descending a platform or ladder.",
    },
    {
    position:[1.82,-1.56],
    "body": "All work equipment, such as pallet trucks, ladders, stepladders, platforms, among others, are subject to periodic safety checks by the GFS Department, and a visible inspection seal is placed to certify their validity.",
    },
    {
    position:[2.24,-0.72],
    "body": "All chemicals must be stored in holding basins or in the chemical cabinet.",
    },
    ],
    },
                {
                    Sectiontitle:"Find all hazards in the location shown",
             
                "MODULE": "Assembly",
             "Scenario": "Scenario 1",
             "ID": "prints-4",
             "Secção": "Prints (SRK, PK2, SPR, Workshop, M54, Micro K, Nano)",
             "Area": "Micro K",
             "game":"risk",
             "Kuula  (link)": "https:\/\/kuula.co\/post\/NFdQY\/collection\/7qbyZ"
              ,hotspots :[
              {
             position:[1.86,-1.18],
                "body": "It is forbidden to work with machines with the door open in automatic mode, if you detect any anomaly you should report it to your shift leader.",
             cover : require("./dialogImages/xzLEWvQw.jpeg")
            
                   
            },
              {
             position:[2.22,-4.10],
                "body": "Electrical cabinets should always be closed, thus eliminating the risk of electrocution. Only authorized employees (BA4) may access them.",
             cover : require("./dialogImages/5jDq77dc.jpeg")
             
                },
            {
             position:[2.10,-2.50], 
                
             "body": "Do not look directly into the laser beam. All laser modules have physical guards to prevent free access to the laser beam.",
             cover : require("./dialogImages/3EWUI8BQ.jpeg")
             
                },
            {
             position:[1.50,-1.58],
                "body": "All mandatory PPE must be used correctly and failure to use it is the employee's responsibility.",
             cover : require("./dialogImages/mJZGx-Es.jpeg")
             
                },
            {
             position:[1.66,0.10],
                "body": "Emergency or fire-fighting equipment must be marked, unobstructed and easily accessible, facilitating its use.",
             cover : require("./dialogImages/6lB6WbTA.jpeg")
             
                },
            {
        position:[1.22,1.06],
                "body": "Check all the safety devices on the machine when you start work, if you detect any anomaly you should report it to your shift leader\/ supervisor.",
            },
            ]
            },
                {
                    Sectiontitle:"Find all hazards in the location shown",
                    "MODULE": "Assembly",
                    "Scenario": "Scenario 1",
                    "ID": "prints-3",
                    "Secção": "Prints (SRK, PK2, SPR, Workshop, M54, Micro K, Nano)",
                    "Area": "SPR", 
                    "game":"risk",
                    "Kuula  (link)": "https:\/\/kuula.co\/post\/NFdQP\/collection\/7qbyZ",
                    hotspots :[{
    
                        
                        position:[1.40,-1.50], //d
                        "body": "Whenever there is contact with hot surfaces, wear the appropriate PPE for the task, such as anti-heat protective gloves. The image shows some examples of types of heat-protection gloves.",
                         cover :[
                            require("./dialogImages/KFiGp43A.jpeg"),
                            require("./dialogImages/LhjB81OA.jpeg"),
                            require("./dialogImages/up8Dx5Ow.png"),
                          ]
                    },
                       {
    
        
                        position:[1.66,-1.50], //d
                        "body": "Work at a height requires the use of auxiliary equipment in a good conservation status and in accordance with safety rules. Always place both feet on the base of the platform or on the step of the stepladder, in order to keep your body balanced and not fall.",
                        cover : require("./dialogImages/F7Rc1Q1g.jpeg")
                        },
                       {
    
                        
                        position:[2.36,-1.00], //d
                        "body": "Always keep your workplace tidy so that you can move about safely, avoiding falls, trips or slips.",
                       },
                       {
    
        
                        position:[1.84,-1.94],
                        "body": "Manual handling of loads over 20 kg should be done by two people, to divide the physical effort.",
                        cover : require("./dialogImages/DdxZjhq8.jpeg")
                      },
                       {
    
        
                        position:[1.78,-1.70], //d
                        "body": "Use the auxiliary equipment available, such as a pallet truck lift, for better ergonomic posture. You should always adjust the pallet truck to the height at which you will place the load on the pallet. ",
                        cover : require("./dialogImages/nzYv8H30.jpeg")
                       },
                       {
    
        
                        position:[1.60,-1.70],// d 6
                        "body": "Descending safely from a stepladder or ladder is essential to avoid falling. Descend safely by placing both hands on the ladder and positioning your feet correctly on the rungs.",
                        cover : require("./dialogImages/91Y-Zq5g.jpeg")
                       },
                       {
    
        
                        position:[1.86,-1.50], //d 7
                        "body": "Hot plastic material (purge) should be placed in the appropriate (non-flammable) metal container.",
                        cover : require("./dialogImages/o38fpGaQ.jpeg")
                       },
                       {
    
        
                        position:[1.46,-1.86],
                        "body": "Always keep your workplace clean and tidy so that you can move around safely, untidy objects can cause incidents.",
                        cover :[
                            require("./dialogImages/kzrFXpQA.jpeg"),
                            require("./dialogImages/_YuhISo8.jpeg")
                            
                        ] 
                       },]},
           
            {
                ac:true, 
                "game":"quiz",
                "MODULE": "Assembly",
                "Scenario": "Scene 1",
                "ID": "circ-75",
                "Section": "movement of people",
                "Number of the area to be filmed (same as on the floor plan)": 75,
                "Area": "Movement of people outside",
                "Sectiontitle": "Outdoor circulation - Precautions to be taken",
                "Risk to represent": "hit-and-run",
                "Scenery (what should be filmed\/photographed)": "1-Film the employee entering the entrance and instead of going through the pedestrian path and the respective crosswalk, he goes in the middle of the road 2-A forklift is coming towards him in reverse with a load",
                "number of collaborators": 2,
                "Action of each employee present in the scenario": "contributor 1-action1 contributor 2-action 2",
                "Equipment and objects to have prepared": "forklift",
                "VIDEO 360 OR PHOTO 360?": "video",
                "- Video runs in its entirety, with incorrect behaviors, the quiz appears and then starts 2D video with corrective actions?= 1 360 video + 1 2D video": "360 video + 2D video",
                "startTime": null,
                "General observations": null,
                "Question": "When walking outside on the plant, where should you walk?\n\nIdentify the correct answer.",
                ans:[ "You should walk on the road, but close to the crosswalk.",
                "In places designated\/marked for pedestrian circulation, such as crosswalks and footpaths.",
                 "You should walk on the road, there is no risk of being run over. ",
                "You can walk wherever you want. ",]
                ,ca:[1]},
               {
                "game":"quiz",
                "MODULE": "Assembly",
                "Scenario": "Scene 1",
                "ID": "covid-78",
                "Section": "COVID-19",
                "Number of the area to be filmed (same as on the floor plan)": 78,
                "Area": "COVID 19 - preventive measures",
                "Sectiontitle": "COVID 19 preventive measures - Hand hygiene",
                "Risk to represent": "WAIT FOR CONFIRMATION FROM ANA, OK from Ana fadista",
                "Scenery (what should be filmed\/photographed)": "1- Hand disinfection scenario in the canteen",
                "number of collaborators": 2,
                "Action of each employee present in the scenario": "collaborator 1 and 2- action 1 and action 2",
                "Equipment and objects to have prepared": null,
                "VIDEO 360 OR PHOTO 360?": "video",
                "- Video runs in its entirety, with incorrect behaviors, the quiz appears and then starts 2D video with corrective actions?= 1 360 video + 1 2D video": " Video (already with correct actions) is interrupted to present a quiz and then continues to show the correct way to proceed? = 1 only 360 video",
                "startTime": 5,
                "General observations": null,
                "Question": "During the COVID-19 pandemic the company implemented several prevention measures.\n\nIdentify the correct answer.",
                ans:[ "The canteen is a low contagion risk site for COVID-19, so I do not need to disinfect my hands on entry.",
                "I already had COVID-19, I no longer need to comply with the prevention rules.",
                 "I should always disinfect my hands upon entering any enclosed space that is shared with more people in order to prevent contagion.",
                "The use of mask and distancing is sufficient for the prevention of COVID-19 in closed places.",]
                ,ca:[2]},
                {
                    "game":"quiz",
                    "MODULE": "Assembly",
                    "Scenario": "Scene 1",
                    "ID": "prints-2",
                    "Section": "prints (SRK, PK2, SPR, Workshop, M54, Micro K, Nano)",
                    "Number of the area to be filmed (same as on the floor plan)": 2,
                    "Area": "PK2",
                    "Sectiontitle": "Manual handling of loads - reels or buckets of wire",
                    "Risk to represent": "131-Incorrect postures",
                    "Scenery (what should be filmed\/photographed)": "1-Film the employee going to the wire bucket area \/ video stop \/ 2-film the employee asking a colleague for help to divide physical effort, and correctly change the buckets",
                    "number of collaborators": 2,
                    "Action of each employee present in the scenario": "collaborator 1-action 1 and 2 collaborator 2- action 2",
                    "Equipment and objects to have prepared": "wire buckets",
                    "VIDEO 360 OR PHOTO 360?": "video",
                    "- Video runs in its entirety, with incorrect behaviors, the quiz appears and then starts 2D video with corrective actions?= 1 360 video + 1 2D video": " Video (already with correct actions) is interrupted to present a quiz and then continues to show the correct way to proceed? = 1 only 360 video",
                    "startTime":6,
                    "General observations": "AT",
                    "Question": "During the movement of the reel or wire buckets, as a weight of 20kg or more, how should you proceed?\n\nIdentify all correct answers.",
                    ans:[ "Move them by yourself (reels or buckets)",
                    "Asking a colleague for help and share the effort",
                    "Use the anti-cut gloves",
                    "All of the above are correct"]
                    ,ca:[1,2]},
                {
                    "game":"quiz",
                    "MODULE": "Assembly",
                    "Scenario": "Scene 1",
                    "ID": "prints-5",
                    "Section": "prints (SRK, PK2, SPR, Workshop, M54, Micro K, Nano)",
                    "Number of the area to be filmed (same as on the floor plan)": 5,
                    "Area": "nano",
                    "Sectiontitle": "Ergonomics - Prevention of musculoskeletal disorders",
                    "Risk to represent": "150 - thermal contacts \/ Overload",
                    "Scenery (what should be filmed\/photographed)": " 1- Film the employee in the overmolding machine area \/ stop video \/ 2- film the employee correctly seated using the task support cart to place the plugs",
                    "number of collaborators": 1,
                    "Action of each employee present in the scenario": "collaborator 1- action 1 and action 2",
                    "Equipment and objects to have prepared": "overmolding machine",
                    "VIDEO 360 OR PHOTO 360?": "Video",
                    "- Video runs in its entirety, with incorrect behaviors, the quiz appears and then starts 2D video with corrective actions?= 1 360 video + 1 2D video": " Video (already with correct actions) is interrupted to present a quiz and then continues to show the correct way to proceed? = 1 only 360 video",
                    "startTime": 7 ,
                    "General observations": "Show the employee how to place the plugs without the help of the ergonomic cart, and how to do it correctly.",
                    "Question": "To perform the task of putting the lid on the baguette, how would you proceed?\n\nIdentify the correct answer.",
                    ans:[ "I should be sitting in the chair with the baguette set propped up on the floor and putting the lids on.",
                    "I should be sitting in the chair with the baguette set resting on the pallet and putting the lids on.",
                    "I must be sitting in the chair with the support trolley to task putting the lids on the baguettes.",
                    "All of the above are correct."]
                    ,ca:[2]},
                    {
                    "game":"find",
                        "MODULE": "Assembly",
                    "Scenario": "Scene 1",
                    "ID": "prints-1",
                    "Section": "prints (SRK, PK2, SPR, Workshop, M54, Micro K, Nano)",
                    "Area": "nano",
                    "Sectiontitle": "Personal Protective Equipment - Tin baths cleaning",
                    "Question": "Identify all the PPE you must use in the maintenance of tin bath.\n\nSelect all the correct options.",
                
                    ans:[
                        require("./assets/q1/a1.png"),
                        require("./assets/q1/a2.png"),
                        require("./assets/q1/a3.png"),
                        require("./assets/q1/a4.png"),
                        require("./assets/q1/a5.png"),
                        require("./assets/q1/a6.png"),
                        require("./assets/q1/a7.png"),
                        require("./assets/q1/a8.png"),
                        require("./assets/q1/a9.png"),
                        require("./assets/q1/a10.png"),
                        require("./assets/q1/a11.png"),
                    ],
                    ca:[0,1,2,3,4,10],
                    "startTime": 8 ,
                    "360 Vídeo or Image link": "https:\/\/www.dropbox.com\/s\/8wx8qahqqrad87e\/prints-1.mp4?dl=0"
                },
            ],
            2:[
                {
                    as:[27,36],
                    Sectiontitle:"Find all hazards in the location shown",
                    
                    "MODULE": "Assembly",
                    "Scenario": "Scenario 2",
                    "ID": "hv-9",
                    "Secção": "HV (EVC, Minik Hv, Workshop, HCR)",
                    "Area": " Mini K HV                      ",
                    "game":"risk",
                    "Kuula  (link)": "https:\/\/kuula.co\/post\/NFgl9\/collection\/7qbyZ"
                    
                ,hotspots :[
                    {
                        position:[1.52,-3.22],
                        "body": "Whenever you use chemical products, or work with welding, check that the extraction is functional and correctly positioned in the workplace.",
                    },
                    {
                    position:[1.90,0.42],
                        "body": "Contaminated empty containers should be placed in the (red) hermetic container with pedal bin.",
                    },
                    {
                    position:[1.76,-0.00],
                        "body": "Manual presses should always be fixed to the work bench. Check that all screws are correctly placed. If you notice that screws are missing or loosened, alert your shift leader\/ mechanic.",
                    },
                    {
                    position:[1.92,1.02],
                        "body": "Whenever there is contact with hot surfaces, wear the appropriate PPE for the task, such as anti-heat protective gloves. The image shows some examples of types of heat-protection gloves.",
                    cover:require("./dialogImages/hv-9-4.PNG")
                    },
                    {
                    position:[2.16,-1.90],
                        "body": "Anti-fatigue mats help prevent lower back pain and discomfort\/ fatigue in legs and feet from long periods of standing, especially on cold, hard floors. Work standing healthily and safely, in any environment. ",
                    },
                    {
                    position:[1.70,-2.62],
                        "body": "Whenever carrying out manual tasks, wear protective gloves in accordance with the glove policy and the risk to which you are exposed, taking into account the nature of the task you are to perform. ",
                        cover:require("./dialogImages/hv-9-6.jpg")
                  
                    },
                ]    
                },
                {
                        Sectiontitle:"Find all hazards in the location shown",
                 
                    "MODULE": "Assembly",
                 "Scenario": "Scenario 2",
                 "ID": "hv-10",
                 "Secção": "HV (EVC, Minik Hv, Workshop, HCR)",
                 "Area": "HCR ",
                 "game":"risk",
                 "Kuula  (link)": "https:\/\/kuula.co\/post\/NFglH\/collection\/7qbyZ"
                 ,hotspots :[
                     {
                      position: [2.28,-1.58],
                        "body": "Use the footrest correctly, thus improving ergonomic posture. You should not sit cross-legged, but at a 90° angle to the knee (lower and upper leg).",
                 cover : require("./dialogImages/5sJMHFT4.jpeg")
                            },
                  {
                   position: [1.74,1.08],
                    "body": "Always wear protective gloves when carrying out manual tasks. Change gloves according to the nature of the task you are currently doing and the risk you are exposed to.",
                 cover : require("./dialogImages/eh6uRA-4.jpeg")
                         },
                  {
                   position: [1.78,-0.50],
                    "body": "Use the auxiliary equipment available, such as a pallet truck lift, for better ergonomic posture. You should always adjust the pallet truck to the height at which you will place the load on the pallet. ",
                 cover : require("./dialogImages/Gq-_XKrc.jpeg")
                         },
                  {
                   position: [1.68,-1.54],
                    "body": "All mandatory PPE must be used correctly and failure to use it is the employee's responsibility. If the situation is recurrent, disciplinary action may be taken.",
                 cover : require("./dialogImages/0MVicw2w.jpeg")
                         },
                  {
                   position: [1.86,-1.70],
                    "body": " For all manual tasks where there is a risk of cutting with sharp edges, always use the anti-cut gloves to prevent minor injuries.",
                    cover :[
                        require("./dialogImages/hCa3dcMg.jpeg"),
                        require("./dialogImages/Aa5VggJw.jpeg"), 
                      ]
                         },
                ]
                },
                {
                    Sectiontitle:"Find all hazards in the location shown",
             
                "MODULE": "Assembly",
             "Scenario": "Scenario 2",
             "ID": "hv-8",
             "Secção": "HV (EVC, Minik Hv, Workshop, HCR)",
             "Area": "EVC",
             "game":"risk",
             "Kuula  (link)": "https:\/\/kuula.co\/post\/NFdQv\/collection\/7qbyZ"
             ,hotspots :[
                 {
                  position:[1.82,-2.30],
                    "body": "Whenever transporting or moving materials from one place to another, ensure that they are placed in the marked places so as not to obstruct work equipment or walkways.",
             cover : require("./dialogImages/3xdOTzNU.jpeg")
                  
                      },
              {
               position:[1.74,0.22],
                "body": "Whenever there is contact with hot surfaces, wear the appropriate PPE for the task, such as heat-protective gloves. When induction\/resistance welding task, use 11-423 gloves.",
                cover :[
                    require("./dialogImages/6aJRs8jg.jpeg"), 
                    require("./dialogImages/eZieQuiw.png"),
                  ]
                     },
              {
                position:[1.80,-3.10],
                "body": "When using blades, level 3, 4 or 5, as well as hand tools, you should wear the anti-cut protection gloves.",
                cover: require("./dialogImages/hv-8-5hyflex-11-724.jpg")
            
            },
              {
                position:[1.64,-3.16],
                "body": "Manual presses should always be fixed on the workbench, thus avoiding their possible fall when pressing the handle\/lever.",
                cover : require("./dialogImages/uhtLXiIc.jpeg")
               },
              {
               position:[2.32,-1.38],
               "body": "Use the footrests correctly, thus improving ergonomic posture.",
               cover :  require("./dialogImages/UExQl8Qk.jpeg"),
            },
            ]
            },
                {
                    "game":"quiz",
                "MODULE": "Assembly",
                "Scenario": "Scenario 2",
                "ID": "hv-7",
                "Section": "HV(EVC, Minik Hv, Workshop, HCR)",
                "Number of the area to be filmed (same as on the floor plan)": 7,
                "Area": "EVC",
                "Sectiontitle": "Ergonomics - Prevention of musculoskeletal disorders",
                "Risk to represent": " 132- Musculoskeletal Injuries",
                "Scenery (what should be filmed\/photographed)": "1- Shoot the employee putting his hands behind his back \/ stop video \/ 2- Shoot the employee asking a colleague for help to change places according to the rotation plan, take a break, hydrate and resume the task in the new work position",
                "number of collaborators": 2,
                "Action of each employee present in the scenario": "collaborator 1- action 1 and 2",
                "Equipment and objects to have prepared": "Bottle of water",
                "VIDEO 360 OR PHOTO 360?": "Video",
                "- Video runs in its entirety, with incorrect behaviors, the quiz appears and then starts 2D video with corrective actions?= 1 360 video + 1 2D video": " Video (already with correct actions) is interrupted to present a quiz and then continues to show the correct way to proceed? = 1 only 360 video",
                "startTime": 3 ,
                "General observations": "showing the turnover",
                "Question": "What should you do when you have muscle pain? In this situation what would you do?\n\nIdentify all the correct answers.",
                ans:[ "I continue to work and later go to the family doctor \/ hospital complaining of muscle pain.",
                "I'm not coming to work tomorrow, I have muscle pain, I didn't take the break when I should have, or job rotation.",
                "I stop immediately and go ask my colleague to change place with me complying with the rotation plan.", 
                "I stop to take a break, stretch, hydrate and resume the task."]
                ,ca:[2,3]},
            
            ],
            3:[
                {
                    "game":"quiz",
                    "MODULE": "Assembly",
                    "Scenario": "Scenario 3",
                    "ID": "BDR-11",
                    "Section": "BDR",
                    "Number of the area to be filmed (same as on the floor plan)": 11,
                    "Area": "BDR",
                    "Sectiontitle": "Multiple risks in the workplace - Safety rules",
                    "Risk to represent": null,
                    "Scenery (what should be filmed\/photographed)": " 1- Standing on the mat 2- Gloves 3- Glasses 4- Signage",
                    "number of collaborators": null,
                    "Action of each employee present in the scenario": null,
                    "Equipment and objects to have prepared": null,
                    "VIDEO 360 OR PHOTO 360?": "video",
                    "- Video runs in its entirety, with incorrect behaviors, the quiz appears and then starts 2D video with corrective actions?= 1 360 video + 1 2D video": " Video (already with correct actions) is interrupted to present a quiz and then continues to show the correct way to proceed? = 1 only 360 video",
                    "startTime": 20 ,
                    "General observations": "New location - mars8-100",
                    "Question": "In your workplace, what are the safety rules or working procedures that you should take into account?\n\nIdentify all correct answers.",
                    ans:[ "Wear PPE, such as protective gloves (anti-heat, anti-cutting, resistant to chemicals, etc.), goggles, hearing protection and safety shoes.",
                    "Be aware of the safety signs, know what they mean and the precautions to take in view of the risks you may be exposed to.",
                    "Use equipment\/accessories to reduce ergonomic risk, such as anti-fatigue mats, footrests, trolleys etc....",
                    "Keep your workplace clean and tidy.",]
                    ,ca:[0,1,2,3]},
            
            ],
            4:[
                {
                Sectiontitle:"Find all hazards in the location shown",
         
            "MODULE": "Assembly",
         "Scenario": "Scenario 4",
         "ID": "semi-16",
         "Secção": "Semiautomatics (SRF, SL1, Workshop, Mini B)",
         "Area": "Mini B",
         "game":"risk",
         "Kuula  (link)": "https:\/\/kuula.co\/post\/NFgbR\/collection\/7qbyZ"
          ,hotspots :[
          {
    position:[4.54,4.78],
            "body": "It is forbidden for two or more employees to work simultaneously on a machine in normal production operation; with the exception of maintenance activities where the machine is switched off\/ locked down with LOTO. ",
          },{
    position:[2.14,-1.32],
            "body": "Oil spills on assembly area must be contained with the absorbent cloths\/pads\/sausages provided in the anti-spill kit.",
    },
    {
    position:[4.16,7.56],
        "body": "When using ladders or steps, always keep both feet at the same level in order to maintain the balance of the body, making it easier to lift the material to supply the work equipment correctly. ",
    },
    {
    position:[1.98,-0.46],
        "body": "Contaminated rags should be placed in the (red) hermetic container with pedal bin.",
    },
        {
         position:[4.26,5.28],
            "body": "Whenever using chemical products you should wear the chemical protective gloves (nitrile) in accordance with the glove policy.",
         cover : require("./dialogImages/1kIzLzjQ.jpeg")
          
         },
        ]
        },
                {
                    as:[12],
                    Sectiontitle:"Find all hazards in the location shown",
             
                "MODULE": "Assembly",
             "Scenario": "Scenario 4",
             "ID": "semi-14",
             "Secção": "Semiautomatics (SRF, SL1, Workshop, Mini B)",
             "Area": "SL1",
             "game":"risk",
             "Kuula  (link)": "https:\/\/kuula.co\/post\/NFglT\/collection\/7qbyZ"
              ,hotspots :[
              {
        position:[4.64,3.86],
                "body": "Whenever you fill the bowl feeder, keep a correct posture when moving the load.",
        }, {
        position:[2.24,0.28],
            "body": "Oil spills on assembly area must be contained with the absorbent cloths\/pads\/sausages provided in the anti-spill kit.",
        },
        {
        position:[4.42,1.70],
            "body": "Work equipment, e.g. microscopes, magnifying glasses, should be switched off when not in use. \"Be a green employee\". ",
        },
        {
        position:[4.34,1.40],
            "body": "Anti-fatigue mats help prevent lower back pain and discomfort\/fatigue in legs and feet from long periods of standing, especially on cold, hard floors. Work standing healthily and safely, in any environment.",
        },
            {
             position:[4.44,0.94],
                "body": "Always keep your workplace tidy so that you can move about safely, avoiding falls, trips or slips.",
             cover : require("./dialogImages/4H2HRh-A.jpeg")
                 },
            {
        position:[4.78,1.36],
                "body": "All mandatory PPE must be in good condition and used correctly. Failure to use them may be subject to disciplinary action.",
        },
        {
        position:[4.58,1.18],
            "body": "When handling loads manually, bring the load close to your body (arms should not be outstretched), bend your legs, keep your back straight and place the box on the pallet.",
        },
            ]
            },
           {
                Sectiontitle:"Find all hazards in the location shown",
         
            "MODULE": "Assembly",
         "Scenario": "Scenario 4",
         "ID": "semi-13",
         "Secção": "Semiautomatics (SRF, SL1, Workshop, Mini B)",
         "Area": "SRF",
         "game":"risk",
         "Kuula  (link)": "https:\/\/kuula.co\/post\/NFgld\/collection\/7qbyZ"
          ,hotspots :[
    {
    position:[1.84,-0.80],
        "body": "Use the auxiliary equipment available, such as a pallet truck lift, for better ergonomic posture. You should always adjust the pallet truck to the height at which you will place the load on the pallet. ",
    },
    {
    position:[1.88,-0.98],
        "body": "You should always adjust the chair to suit your workstation (seat and lower back). Use the footrest correctly, thus improving ergonomic posture.",
    },
          {
           position:[1.64,0.20],
            "body": "All emergency exits must be unobstructed and freely accessible, facilitating movement in the event of an emergency.",
         cover : require("./dialogImages/kIXsI24A.jpeg")
                 },
          {
    position:[1.74,0.34],
            "body": "Emergency or fire-fighting equipment must be properly secured (on the wall or on the hose bracket). Use the hose bracket to secure the hose correctly in place. ",
            cover : require("./dialogImages/semi-13-4.jpg")
    
        },
    {
    position:[1.74,-1.24],
        "body": "You should always keep the routes unobstructed. Place the trolleys in the designated places (5S markings) for this purpose.",
    },
    {
    position:[2.32,-1.60],
        "body": "Anti-fatigue mats help prevent lower back pain and discomfort\/ fatigue in legs and feet from long periods of standing, especially on cold, hard floors. Work standing healthily and safely, in any environment. ",
    },
          {
           position:[1.88,-1.72],
            "body": "All mandatory PPE must be in good condition and used correctly. Protective gloves must be worn on both hands during the work task. ",
         cover : require("./dialogImages/KHPOLh-g.jpeg")
                }
        ]
        },
                {
                    Sectiontitle:"Find all hazards in the location shown",
             
                "MODULE": "Assembly",
             "Scenario": "Scenario 4",
             "ID": "semi-15",
             "Secção": "Semiautomatics (SRF, SL1, Workshop, Mini B)",
             "Area": "Workshop",
             "game":"risk", 
             "Kuula  (link)": "https:\/\/kuula.co\/post\/NFgbg\/collection\/7qbyZ"
              ,hotspots :[
              {
            position:[1.96,-1.30],
                "body": "For all manual tasks where there is a risk of cutting with sharp edges, always use the anti-cut gloves to prevent minor injuries.",
                cover :[
                    require("./dialogImages/nEZtfQNA.jpeg"),
                    require("./dialogImages/CMOaTwoA.jpeg"), 
                  ]
              
            }, {
            position:[1.74,-3.60],
            "body": "When using equipment with rotating elements, it is forbidden to wear protective gloves, as this could get caught in the protective glove and cause entrapment\/ entanglement of the hand\/finger. ",
             cover : require("./dialogImages/aLtC-bi0.jpeg")
                 },
            {
             position:[1.76,-0.10],
                "body": "Emergency or fire-fighting equipment must be marked, unobstructed and easily accessible, facilitating its use.",
             cover : require("./dialogImages/Ly8deQVc.jpeg")
                 },
            {
             position:[1.88,-2.00],
                "body": "It is strictly forbidden to use the compressed air pistol to clean the suit\/clothing, or other uses that are not inherent to the job task.",
             cover : require("./dialogImages/0MzCemck.jpeg")
                 },
            {
             position:[1.96,-0.76],
                "body": "In workplaces, it is forbidden to have water bottles next to chemical product containers, thus preventing inadvertent ingestion of chemicals due to lack of labelling or misidentification of the secondary container.",
            },
            {
             position:[1.74,-1.20],
                "body": "Eating in the workplace is strictly forbidden.",
             cover :require("./dialogImages/semi-15-6 2.jpg"), 

                 },
            {
            position:[1.30,0.90],
                "body": "Chemical cabinets must always be locked and the key kept with the person in charge of the work area. If you need access, ask your shift leader for the key. In this way, we avoid that employees from other areas have access to chemical products, and risks associated with their manipulation, that they are not aware of. ",
            },
            {
            position:[1.94,-0.94],
            "body": "Be aware of danger pictograms on chemical product labelling. If the pictogram is orange and black (old danger signs) you must inform your shift leader\/ supervisor and remove it from use. ",
            },
            ]
            },
                {
                    "game":"quiz",
                    "MODULE": "Assembly",
                    "Scenario": "Scenario 4",
                    "ID": "minis-25",
                    "Section": "minis (IP55, Fluxplate, SRFA, Workshop)",
                    "Number of the area to be filmed (same as on the floor plan)": 25,
                    "Area": "SRFA",
                    "Sectiontitle": "Chemical - Care for cleaning and maintenance of leibingers",
                    "Risk to represent": "8.6 Poor maintenance (ex: changing solenoid valves) - 160-Direct or Indirect Electrical Contacts",
                    "Scenery (what should be filmed\/photographed)": "Film the employee performing maintenance à leibinger, every step of the answers",
                    "number of collaborators": 1,
                    "Action of each employee present in the scenario": "collaborator 1- action 1",
                    "Equipment and objects to have prepared": null,
                    "VIDEO 360 OR PHOTO 360?": "Video",
                    "- Video runs in its entirety, with incorrect behaviors, the quiz appears and then starts 2D video with corrective actions?= 1 360 video + 1 2D video": " Video (already with correct actions) is interrupted to present a quiz and then continues to show the correct way to proceed? = 1 only 360 video",
                    "startTime":  5,
                    "General observations": "All answers are true",
                    "Question": "When handling chemical products, what should I take into account? \n\nIdentify all correct answers.",
                    ans:[ "Handle the chemical with chemical protective gloves.",
                    "After using the chemical, I should store it in its respective chemical cabinet. ",
                    "Place contaminated waste, e.g. used disposable gloves and empty contaminated packaging in the appropriate container.",
                    "Lock the chemical cabinet and hand the key to my shift leader.",]
                    ,ca:[0,1,2,3]},
                
            ],
             5:[
                {
                    "game":"find",
                    "MODULE": "Assembly",
                    "Scenario": "Scenario 5",
                    "ID": "sensores-17",
                    "Section": "sensors (P449 and P623, PLCD)",
                    "Area": "P449",
                    "Sectiontitle": "Various hazards in the workplace - Signalling",
                    "Question": "What safety signs do you see at this workplace?\n\nSelect all options that are correct.",
                    ans:[
                    require("./assets/q2/a1.png"),
                    require("./assets/q2/a2.png"),
                    require("./assets/q2/a3.png"),
                    require("./assets/q2/a4.png"),
                    require("./assets/q2/a5.png"),
                    require("./assets/q2/a6.png"),
                    require("./assets/q2/a7.png"),
                    require("./assets/q2/a8.png"),
                    require("./assets/q2/a9.png"),
                    require("./assets/q2/a10.png"),
                    require("./assets/q2/a11.png"),
                    require("./assets/q2/a12.png"),
                    require("./assets/q2/a13.png"),
                    ],   
                    ca:[0,1,8,9,10,11,12],
                    "startTime": 20,
                    "360 Vídeo or Image link": "https:\/\/www.dropbox.com\/s\/ssgtzszdyu53u25\/sensores-17.png?dl=0"
                },
        
            ],
            6:[
                {
                    Sectiontitle:"Find all hazards in the location shown",
             
                "MODULE": "Assembly",
             "Scenario": "Scenario 6",
             "ID": "sensores-21-pt2",
             "Secção": "Sensores (P449 e P623, PLCD)",
             "Area": "PLCD",
             "game":"risk",
             "Kuula  (link)": "https:\/\/kuula.co\/post\/NFgbV\/collection\/7qbyZ"
              ,hotspots :[
                {
                    position:[1.66,4.72],
                    "body": "During the transportation/handling of the WT's, you should grab the metal handles at the corners of the WT's for better handling, thus preventing them from falling.",            
                }, {
                    position:[1.66,-0.20],
                    "body": "Manual handling of loads over 20 kg should be carried out by two persons, to divide the physical effort, until the cable reel is placed on the lifting plate of the machine.",
                },
                {
                    position:[1.56,1.52],
                    "body": "The visual inspection equipment with non-ionising radiation (e.g. magnifying glass) should be adjusted to the height of each employee, remaining below eye level, avoiding exposure to optical radiation.",
                    cover : require("./dialogImages/qn2KtVTw.jpeg")
                 }]
                },
                {
                Sectiontitle:"Find all hazards in the location shown",
         
            "MODULE": "Assembly",
         "Scenario": "Scenario 6",
         "ID": "sensores-21-pt1",
         "Secção": "Sensores (P449 e P623, PLCD)",
         "Area": "PLCD",
         "game":"risk",
         "Kuula  (link)": "https:\/\/kuula.co\/post\/NFgbV\/collection\/7qbyZ"
          ,hotspots :[
          {
            position:[1.74,0.74],
            "body": "Whenever there is contact with hot surfaces, wear the appropriate PPE for the task, such as anti-heat protective gloves. The image shows some examples of types of heat-protection gloves.",
         cover : require("./dialogImages/_-_lo74c.jpeg")
            
              }, {
         position:[2.24,-2.52],
                "body": "Anti-fatigue mats help prevent lower back pain and discomfort\/fatigue in legs and feet from long periods of standing, especially on cold, hard floors. Work standing healthily and safely, in any environment.",
         cover : require("./dialogImages/5elDl_mc.jpeg")
             },
        {
         position:[1.94,-0.40],
            "body": "Do not use the bottom bar of the workbench to support your feet. Use the footrest correctly for a better ergonomic posture. You should not sit cross-legged, but at a 90° angle to the knee (lower and upper leg).",
         cover : require("./dialogImages/Two3bVKw.jpeg")
             }]},
                {
                    ac:true,
                    "game":"quiz",
                    "MODULE": "Assembly",
                    "Scenario": "Scenario 6",
                    "ID": "minis-18",
                    "Section": "sensors (P449 and P623, PLCD)",
                    "Number of the area to be filmed (same as on the floor plan)": 18,
                    "Area": "MINI B",
                    "Sectiontitle": "Circulation and cleaning - Precautions to be taken",
                    "Risk to represent": null,
                    "Scenery (what should be filmed\/photographed)": "1- wet floor 2- employees do not respect the signs and cross while the cleaning lady cleans the floor in the area",
                    "number of collaborators": 1,
                    "Action of each employee present in the scenario": "collaborator 1- action 1 and action 2",
                    "Equipment and objects to have prepared": "BECAUSE",
                    "VIDEO 360 OR PHOTO 360?": "video",
                    "- Video runs in its entirety, with incorrect behaviors, the quiz appears and then starts 2D video with corrective actions?= 1 360 video + 1 2D video": " Video (already with correct actions) is interrupted to present a quiz and then continues to show the correct way to proceed? = 1 only 360 video",
                    "startTime": null ,
                    "General observations": "Use half mask with filters, PQ CMR - photo at the end as a corrective measure in the purge task",
                    "Question": "During floor cleaning when the place is interdicted to the passage of people, what should you do?\n\nIdentify the correct answer.",
                    ans:[ "Respect the signs and do not walk on the area, as you may slip and fall.",
                    "Ask permission to pass, and move around the area with care.",
                    "Walk on the area and do not respect the signs.",
                    "None of the previous answers.",]
                    ,ca:[0]},
            ],
            7:[ 
                {
                    as:[5],
    
                        Sectiontitle:"Find all hazards in the location shown",
                 
                    "MODULE": "Assembly",
                 "Scenario": "Scenario 7",
                 "ID": "minis-24",
                 "Secção": "Minis (IP55, Fluxplate, SRFA, Workshop)",
                 "Area": "SRFA",
                 "game":"risk",
                 "Kuula  (link)": "https:\/\/kuula.co\/post\/NFgVj\/collection\/7qbyZ"
                  ,hotspots :[
                  {
            position:[1.80,-0.00],
                    "body": " When moving wire buckets weighing approximately 20 kg, you should always ask for the help of a colleague to share the physical effort involved in the movement, in order to prevent future musculoskeletal injuries.",
            
                  },  {
            position:[2.18,-0.46],
                    "body": " Workplace organization is fundamental. It should keep the corridors clear, preventing tripping and slipping, falling objects and, above all, serious incidents. Apply the 5S tool.",
                   },
                   {
                    position:[2.04,-0.78],
                    "body": " For all manual tasks where there is a risk of cutting with sharp edges, always use the anti-cut gloves to prevent minor injuries.",
                 cover : require("./dialogImages/AEqcmz9Q.jpeg")
                           },
                   {
            position:[1.56,-3.28],
                    "body": " Electrical cabinets should always be closed, thus eliminating the risk of electrocution. Only authorized employees (BA4) may access them.",
            },
            {
            position:[1.62,-3.96],
                "body": " When moving loads with work equipment (e.g. hand pallet trucks) in areas of poor visibility in the aisles, you should look towards the parabolic mirrors suspended from the ceiling to avoid colliding with pedestrians or other work equipment in circulation.",
            },
            {
            position:[1.84,-0.88],
                "body": " In places where the floor is damaged, you should redouble your attention while moving around the area, avoiding possible falls, trips or slips. ",
            },
            {
            position:[2.40,-0.28],
                "body": " Damaged work equipment should not be used at work sites. Inform your shift leader \/ supervisor and remove equipment from use.",
            },
                ]
                },{
                    as:[92],
    
                Sectiontitle:"Find all hazards in the location shown",
         
            "MODULE": "Assembly",
         "Scenario": "Scenario 7",
         "ID": "minis-22",
         "Secção": "Minis (IP55, Fluxplate, SRFA, Workshop)",
         "Area": "IP55",
         "game":"risk",
         "Kuula  (link)": "https:\/\/kuula.co\/post\/NFgVR\/collection\/7qbyZ"
          ,hotspots :[
          {
    position:[1.76,-2.38],
            "body": "Whenever you fill the bowl feeder, keep a correct posture when moving the load.",
    
          },{
    position:[1.66,-1.50],
            "body": "Chemicals must be stored in a dedicated cabinet. Check if the extraction system of the cabinet is functional (green light).",
           },
           {
            position:[1.64,-2.20],
            "body": "Whenever there is contact with hot surfaces, wear the appropriate PPE for the task, such as anti-heat protective gloves. The image shows some examples of types of heat-protection gloves.",
         cover : require("./dialogImages/tQO_wyaw.jpeg")
                   },
           {
    position:[2.10,0.54],
            "body": "Workplace organization is fundamental. It should keep the corridors clear, preventing tripping and slipping, falling objects and, above all, serious incidents. Apply the 5S tool.",
    },
    {
    position:[2.20,-0.54],
        "body": "Work tools must be stowed in the appropriate tool trolleys\/ mechanical cases. ",
    },
        ]
        },
            {
                "game":"quiz",
                "MODULE": "Assembly",
                "Scenario": "Scenario 7",
                "ID": "minis-23",
                "Section": "minis (IP55, Fluxplate, SRFA, Workshop)",
                "Number of the area to be filmed (same as on the floor plan)": 23,
                "Area": "SRFA",
                "Sectiontitle": "Personal Protective Equipment - PPE's",
                "Risk to represent": null,
                "Scenery (what should be filmed\/photographed)": "1-Film the employee working, see that they have damaged gloves (routes) \/stop video\/ (go to remove new ones from the PPE machine (Do what you used to do in this situation)",
                "number of collaborators": 1,
                "Action of each employee present in the scenario": "collaborator 1- action 1",
                "Equipment and objects to have prepared": "PPE machine",
                "VIDEO 360 OR PHOTO 360?": "Video",
                "- Video runs in its entirety, with incorrect behaviors, the quiz appears and then starts 2D video with corrective actions?= 1 360 video + 1 2D video": " Video (already with correct actions) is interrupted to present a quiz and then continues to show the correct way to proceed? = 1 only 360 video",
                "startTime": 4 ,
                "General observations": "Film the employee when he checks that they have damaged gloves",
                "Question": "I notice that my protective gloves are not in good condition. What should I do?\n\nIdentify the correct answer.",
                ans:[ "I keep working with my damaged  gloves, I need to finish the job fast.",
                "I ask my shift leader for the protective gloves I need to continue the task.",
                "I go to the PPE machine to remove the gloves that are available for my area\/ task.",
                "I borrow it from my colleague, he has an extra pair.",]
                ,ca:[2]},
            
            ],
            8:[
                {
                    as:[13],
    
                        Sectiontitle:"Find all hazards in the location shown",
                 
                    "MODULE": "Assembly",
                 "Scenario": "Scenario 8",
                 "ID": "micros-29",
                 "Secção": "Micros (Micro A, Micro 3, Workshop)",
                 "Area": "Micro 3",
                 "game":"risk",
                 "Kuula  (link)": "https:\/\/kuula.co\/post\/NFgV2\/collection\/7qbyZ"
                  ,hotspots :[
                  {
            position:[1.88,-1.74],
                    "body": "When using steps, always keep both feet at the same level in order to keep the balance of the body, preventing possible falls. ",
            
            }, {
            position:[1.86,-5.02],
                "body": "Use the footrest correctly, thus improving ergonomic posture. You should not sit cross-legged, but at a 90° angle to the knee (lower and upper leg).",
            },
            {
            position:[1.68,-5.14],
                "body": "All mandatory PPE must be used correctly and failure to use it is the employee's responsibility. If the situation is recurrent, disciplinary action may be taken.",
            },
            {
            position:[1.66,-2.06],
                "body": "For all manual tasks where there is a risk of cutting with sharp edges, always use the anti-cut gloves to prevent minor injuries.",
                cover : require("./dialogImages/z46jd7Lg.jpeg")
         
            },
            {
            position:[1.82,-2.86],
                "body": "Work equipment, e.g. microscopes, magnifying glasses, should be switched off when not in use. \"Be a green employee\". ",
            },
                ]
                },
               
                {
                    "game":"quiz",
                    "MODULE": "Assembly",
                    "Scenario": "Scenario 8",
                    "ID": "micros-26",
                    "Section": "micros (Micro A, Micro 3, Workshop)",
                    "Number of the area to be filmed (same as on the floor plan)": 26,
                    "Area": "Micro A",
                    "Sectiontitle": "Machine Maintenance - Safety protection and care",
                    "Risk to represent": "130-Overeffort",
                    "Scenery (what should be filmed\/photographed)": "1- remove protection while wearing gloves; Screws and replaces protection",
                    "number of collaborators": 1,
                    "Action of each employee present in the scenario": "collaborator 1- action 1",
                    "Equipment and objects to have prepared": "protection",
                    "VIDEO 360 OR PHOTO 360?": "Video",
                    "- Video runs in its entirety, with incorrect behaviors, the quiz appears and then starts 2D video with corrective actions?= 1 360 video + 1 2D video": " Video (already with correct actions) is interrupted to present a quiz and then continues to show the correct way to proceed? = 1 only 360 video",
                    "startTime": 33 ,
                    "General observations": "Shoot in the middle of line 1",
                    "Question": "In a repair where it is necessary to remove safety devices and\/or fixed guards from the machine, what should you do?\n\nIdentify all correct answers.",
                    ans:[ "Apply the LOTO procedure and  signalling with \"Machine under Repair\" tag.",
                    "Once the repair is complete, make sure that the guard is correctly positioned on the machine and secured with 1 screw.",
                    "Use the anti-cut gloves.",
                    "Once the repair has been completed, take care to place the guard correctly on the machine and fix it with the 4 screws.",]   
                    ,ca:[0,2,3]},
                {
                    "game":"quiz",
                    "MODULE": "Assembly",
                    "Scenario": "Scenario 8",
                    "ID": "micros-27",
                    "Section": "micros (Micro A, Micro 3, Workshop)",
                    "Number of the area to be filmed (same as on the floor plan)": 27,
                    "Area": "Micro A",
                    "Sectiontitle": "waste hazard - final destination",
                    "Risk to represent": null,
                    "Scenery (what should be filmed\/photographed)": "1-Film the collaborator with a used contaminated rag and then deposit it in the right place",
                    "number of collaborators": 1,
                    "Action of each employee present in the scenario": "collaborator 1- action 1",
                    "Equipment and objects to have prepared": "rag",
                    "VIDEO 360 OR PHOTO 360?": "Video",
                    "- Video runs in its entirety, with incorrect behaviors, the quiz appears and then starts 2D video with corrective actions?= 1 360 video + 1 2D video": " Video (already with correct actions) is interrupted to present a quiz and then continues to show the correct way to proceed? = 1 only 360 video",
                    "startTime": 20,
                    "General observations": "Shoot in the middle of line 1",
                    "Question": "Where do you put your rags and empty contaminated packaging after using a chemical?\n\nIdentify the correct answer.",
                    ans:[ "Leave it at the maintenance local, later the cleaning lady will collect the waste.",
                    "Throw the contaminated rag in the general waste container as well as the empty packaging.",
                    "Place the contaminated rag in the container for contaminated rags and the packaging in the container for contaminated packaging.",
                    "Place the contaminated rag in the contaminated rag container and the empty contaminated packaging inside the chemical cabinet.",]
                    ,ca:[2]},
                {
                    ac:true,
                    "game":"quiz",
                    "MODULE": "Assembly",
                    "Scenario": "Scenario 8",
                    "ID": "micros-28",
                    "Section": "micros (Micro A, Micro 3, Workshop)",
                    "Number of the area to be filmed (same as on the floor plan)": 28,
                    "Area": "Micro A",
                    "Sectiontitle": "Machine Maintenance - Safety protection and care",
                    "Risk to represent": null,
                    "Scenery (what should be filmed\/photographed)": "1- Film the employee performing maintenance on a machine. Finish the job and leave the guards on the floor. 2- Meanwhile the other colleague starts to work with the machine without the protections.",
                    "number of collaborators": 2,
                    "Action of each employee present in the scenario": "collaborator 1- action 1 collaborator 2- action 2",
                    "Equipment and objects to have prepared": "protections",
                    "VIDEO 360 OR PHOTO 360?": "Video",
                    "- Video runs in its entirety, with incorrect behaviors, the quiz appears and then starts 2D video with corrective actions?= 1 360 video + 1 2D video": "Video runs in its entirety, with incorrect behaviors, the quiz appears and then starts 2D video with corrective actions?= 1 360 video + 1 2D video",
                    "startTime": null ,
                    "General observations": null,
                    "Question": "What safety precautions should be taken when a mechanic carries out maintenance on a machine?\n\nIdentify the wrong answer.",
                    ans:[ "Use the anti-cut gloves.",
                    "Apply the LOTO procedure and  signalling with \"Machine under Repair\" tag.",
                    "Being alone in operating the machine during maintenance\/ checking.",
                    "The mechanic must wait for the operator to arrive to validate and close the door, but in the meantime he can continue producing.",]
                    ,ca:[3],invert:true},
                {
                    "game":"quiz",
                    "MODULE": "Assembly",
                    "Scenario": "Scenario 8",
                    "ID": "micros-30",
                    "Section": "micros (Micro A, Micro 3, Workshop)",
                    "Number of the area to be filmed (same as on the floor plan)": 30,
                    "Area": "Micro 3",
                    "Sectiontitle": "Tin bath Maintenance - Precautions to be taken",
                    "Risk to represent": "150 - thermal contacts\/210 - fire",
                    "Scenery (what should be filmed\/photographed)": "1-Film maintenance of the tin bath, employee using the PPE and precautions to be taken, and place the tin residue in the correct container (film the hazard symbology associated with the location",
                    "number of collaborators": 1,
                    "Action of each employee present in the scenario": "collaborator 1- action 1",
                    "Equipment and objects to have prepared": "tin residue correct container",
                    "VIDEO 360 OR PHOTO 360?": "video",
                    "- Video runs in its entirety, with incorrect behaviors, the quiz appears and then starts 2D video with corrective actions?= 1 360 video + 1 2D video": " Video (already with correct actions) is interrupted to present a quiz and then continues to show the correct way to proceed? = 1 only 360 video",
                    "startTime":  39,
                    "General observations": "Film tin bath maintenance",
                    "Question": "During the maintenance task of the tin bath, what safety procedures should you take into account?\n\nIdentify all correct answers.",
                    ans:[ "Wear all PPE necessary for the task, such as visor, protective shoes, protective apron, anti-heat protective gloves.",
                    "Put up the \"Machine under maintenance\" tag.",
                    "Place the tin waste on the task support trolley. ",
                    "All of the above.",]
                    ,ca:[0,1]},
                    
            ],
            9:[
            {
            Sectiontitle:"Find all hazards in the location shown",
     
        "MODULE": "Assembly",
     "Scenario": "Scenario 9",
     "ID": "empac-45",
     "Secção": "Packaging",
     "Area": "Packaging",
     "game":"risk",
     "Kuula  (link)": "https:\/\/kuula.co\/post\/NFCfF\/collection\/7qbyZ"
      ,hotspots :[
      {
        position:[4.64,15.68],
        "body": "For all manual tasks where there is a risk of cutting with sharp edges, always use the anti-cut gloves to prevent minor injuries.",
        cover :[
            require("./dialogImages/f-he7xqw.jpeg"), 
            require("./dialogImages/_ybBrj3w.jpeg"),
          ]
    
      }, {
        position: [4.18,11.36],
        "body": " Workplace organization is fundamental. It should keep the corridors clear, preventing tripping and slipping, falling objects and, above all, serious incidents. Apply the 5S tool.",
     cover : require("./dialogImages/lZ9IYhsc.jpeg")
               },
       {
        position: [4.52,15.08],
        "body": "Use the work equipment correctly by adjusting the lift bench to your height to improve your ergonomic posture. ",
     cover : require("./dialogImages/3kJrqggs.jpeg")
               },
       {
    position: [4.40,14.16],
        "body": "Keep circulation routes unobstructed and clean, thus allowing safe circulation in the workspace.  ",
       },
       {
        position: [4.60,14.34],
        "body": "Workplace organization is fundamental. It should keep the corridors clear, preventing tripping and slipping, falling objects and, above all, serious incidents. Apply the 5S tool.",
     cover : require("./dialogImages/uHfw7FU0.jpeg")
               },
       {
        position: [4.22,14.26],
        "body": "Use the load handling aids available at your workstation to avoid inappropriate ergonomic postures, or the possibility of falls at the same level.",
     cover : require("./dialogImages/PqH_IvxE.jpeg")
               },
       {
        position: [4.30,14.78],
        "body": "When moving loads manually, bring the load close to your body (arms should not be outstretched), bend your legs, keep your back straight and place\/ remove the box on the pallet. Placing your foot on the pallet may cause you to fall\/slide.",
     cover : require("./dialogImages/y8bpqJqk.jpeg")
               },
      ]
    },
                {
                    "game":"quiz",
                    "MODULE": "Assembly",
                    "Scenario": "Scenario 9",
                    "ID": "Empac-46",
                    "Section": "packaging",
                    "Number of the area to be filmed (same as on the floor plan)": 46,
                    "Area": "washing the boxes",
                    "Sectiontitle": "Clean and tidy - Danger of falling on same level, slipping",
                    "Risk to represent": "2.1 spilland (liquid - water flowing from the boxes) - fall at the same level\/slip",
                    "Scenery (what should be filmed\/photographed)": "Water on the floor, wet floor and not signposted",
                    "number of collaborators": 0,
                    "Action of each employee present in the scenario": null,
                    "Equipment and objects to have prepared": "watering hole",
                    "VIDEO 360 OR PHOTO 360?": null,
                    "- Video runs in its entirety, with incorrect behaviors, the quiz appears and then starts 2D video with corrective actions?= 1 360 video + 1 2D video": null,
                    "startTime": 11 ,
                    "General observations": null,
                    "Question": "I am at my workplace and notice a puddle of water on the floor. What should I do immediately?\n\nIdentify all correct answers.",
                    ans:[ "Sign the site to avoid possible falls, slips on the floor.",
                    "Alert my shift leader and\/or the Isporeco employee to clean the floor.",
                    "If I have the necessary means to carry out the cleaning (mop, bucket) I must do it.",
                    "As it's already the end of the shift, I don't need to do anything, in the meantime the floor dries out.",],
                    ca:[0,1,2]
                },
            ],
            10:[
                {
                    "game":"find",
                    "MODULE": "Assembly",
                    "Scenario": "Scenario 10",
                    "ID": "eng.pro-47",
                    "Section": "Eng. product (lab Eng., Lab HV, Lab measurement)",
                    "Area": "Eng. Product - Lab Eng",
                    "Sectiontitle": "Various hazards in the workplace - Signalling",
                    "Question": "What safety signs do you see in this workplace, as well as the obligatory PPE's?\n\nSelect all options that are correct.",
                    ans:[
                        require("./assets/q3/1.png"),
                        require("./assets/q3/2.png"),
                        require("./assets/q3/3.png"),
                        require("./assets/q3/4.png"),
                        require("./assets/q3/5.png"),
                        require("./assets/q3/6.png"),
                        require("./assets/q3/7.png"),
                        require("./assets/q3/8.png"),
                        require("./assets/q3/9.png"),
                        require("./assets/q3/10.png"),
                        require("./assets/q3/11.png"),
                        require("./assets/q2/a8.png"),
                        require("./assets/q3/13.png"),
                    ],   
                    ca:[0,1,2,3,5],
                    "startTime": 16,
                    "360 Vídeo or Image link": "https:\/\/www.dropbox.com\/s\/diacrp0pkrx8h46\/eng.pro-47.mp4?dl=0"
                },
                {
                    "game":"quiz",
                    "MODULE": "Assembly",
                    "Scenario": "Scenario 10",
                    "ID": "eng.pro-48",
                    "Section": "Eng. product (lab Eng., Lab HV, Lab measurement)",
                    "Number of the area to be filmed (same as on the floor plan)": 48,
                    "Area": "Eng. Product - Lab HV",
                    "Sectiontitle": "Soldering with a soldering iron - Precautions to be taken",
                    "Risk to represent": null,
                    "Scenery (what should be filmed\/photographed)": "tin baths",
                    "number of collaborators": null,
                    "Action of each employee present in the scenario": null,
                    "Equipment and objects to have prepared": null,
                    "VIDEO 360 OR PHOTO 360?": "video",
                    "- Video runs in its entirety, with incorrect behaviors, the quiz appears and then starts 2D video with corrective actions?= 1 360 video + 1 2D video": " Video (already with correct actions) is interrupted to present a quiz and then continues to show the correct way to proceed? = 1 only 360 video",
                    "startTime": 24 ,
                    "General observations": null,
                    "Question": "When soldering with a soldering iron, what safety procedures should you take into account?\n\nIdentify all correct answers.",
                    ans:[ "Switch on the existing extraction system on the workbench and use the PPE suitable for the task, such as anti-heat gloves, safety goggles and safety shoes.",
                    "I must perform the task on a bench without extraction, the task is quick. ",
                    "Should I use the hood (if I am welding alone) and wear the PPE suitable for the task such as anti-heat gloves, safety goggles, safety shoes",
                    "I should use the manual lathe to hold the piece and make the work easier.",]
                    ,ca:[0,2,3]
                },
            ],
            11:[
                {
                    as:[11],
                Sectiontitle:"Find all hazards in the location shown",
         
            "MODULE": "Assembly",
         "Scenario": "Scenario 11",
         "ID": "qualidade-49",
         "Secção": "Qualidade (Quiosques, Lab Metrologia, Lab. qualidade)",
         "Area": "Quality Lab",
         "game":"risk", 
         "Kuula  (link)": "https:\/\/kuula.co\/post\/NFCq3\/collection\/7qbyZ"
          ,hotspots :[
          {
            position: [1.74,0.50],
            "body": "In work equipment where there is ionizing radiation, such as x-rays, the use of an individual dosimeter to control radiation exposure is mandatory.",
         cover : require("./dialogImages/YwgLdPy4.jpeg")
                 
          }, {
         position: [1.68,1.04],
            "body": "Places with restricted or conditioned access should only be used by employees from the area or authorized employees. This rule is due to the fact that employees from outside the area are not aware of all the specific risks inherent to it.",
         cover : require("./dialogImages/_-LUQg_0.jpeg")
             },
        {
         position: [1.60,-1.58],
            "body": "Whenever there is contact with hot surfaces, wear the appropriate PPE for the task, such as anti-heat protection gloves.",
         cover : require("./dialogImages/Qj3u4q3w.jpeg")
             },
        {
         position: [1.80,-1.68],
            "body": "When using blades, level 3, 4 or 5, as well as hand tools, you should wear the anti-cut protection gloves.",
         cover : require("./dialogImages/3bwQWOZc.jpeg")
             },
        {
         position: [1.80,-1.28],
            "body": "All trolleys for carrying hand tools, or others, should have locked wheels to prevent the trolley from moving inadvertently when not in use.",
         cover : require("./dialogImages/By7XVE7w.jpeg")
             },
        {
         position: [1.68,-1.40],
            "body": "Emergency or fire-fighting equipment must be marked, unobstructed and easily accessible, facilitating its use.",
         cover : require("./dialogImages/_KZaTofg.jpeg")
             },
          ]
        },
                {
                    "game":"find",
                    "MODULE": "Assembly",
                    "Scenario": "Scenario 11",
                    "ID": "qualidade-50",
                    "Section": "Quality(Kiosks, Metrology Lab, Quality Lab)",
                    "Area": "Living Towers Room - in the lab",
                    "Sectiontitle": "Personal Protective Equipment - Tin bath",
                    "Question": "Identify the PPE's you should use in the tin bath.\n\nSelect all correct options.",
                    ans:[
                        require("./assets/q4/1.png"),
                        require("./assets/q4/2.png"),
                        require("./assets/q4/3.png"),
                        require("./assets/q4/4.png"),
                        require("./assets/q4/5.png"),
                        require("./assets/q4/6.png"),
                        require("./assets/q4/7.png"),
                        require("./assets/q4/8.png"),
                        require("./assets/q4/9.png"),
                        require("./assets/q4/10.png"),
                        require("./assets/q4/11.png"),
                        require("./assets/q4/12.png"),
                        require("./assets/q4/13.png"),
                        require("./assets/q4/14.png"),
                    ], 
                    ca:[5,6,7,8,12,13],
                    "startTime": 12,
                    "360 Vídeo or Image link": "https:\/\/www.dropbox.com\/s\/7fkd3amld6jsfn4\/qualidade-50.mp4?dl=0"
                },
                {
                    "game":"quiz",
                    "MODULE": "Assembly",
                    "Scenario": "Scenario 11",
                    "ID": "qualidade-51",
                    "Section": "Quality(Kiosks, Metrology Lab, Quality Lab)",
                    "Number of the area to be filmed (same as on the floor plan)": 51,
                    "Area": "Living Towers Room",
                    "Sectiontitle": "Tin bath - Precautions to be taken",
                    "Risk to represent": "contact with hot surfaces",
                    "Scenery (what should be filmed\/photographed)": "1-When the task of the tin bath starts, turn on the hood light, turn on the traction, lower the hood door",
                    "number of collaborators": 1,
                    "Action of each employee present in the scenario": "collaborator 1- action 1",
                    "Equipment and objects to have prepared": "hood",
                    "VIDEO 360 OR PHOTO 360?": "Video",
                    "- Video runs in its entirety, with incorrect behaviors, the quiz appears and then starts 2D video with corrective actions?= 1 360 video + 1 2D video": " Video (already with correct actions) is interrupted to present a quiz and then continues to show the correct way to proceed? = 1 only 360 video",
                    "startTime": 5 ,
                    "General observations": "Take a picture",
                    "Question": "When you start the tin bath task - Identify all the scenarios you think are correct.  Identify all correct answers.",
                    ans:[ "Switch on the hood light.",
                    "Switch on the hood extraction.",
                    "Remove the relay by hand from the tin bath, as I have the anti-heat gloves on.",
                    "Lower the hood door.",]
                ,ca:[0,1,3]},
                {
                    "game":"quiz",
                    "MODULE": "Assembly",
                    "Scenario": "Scenario 11",
                    "ID": "qualidade-52",
                    "Section": "Quality(Kiosks, Metrology Lab, Quality Lab)",
                    "Number of the area to be filmed (same as on the floor plan)": 52,
                    "Area": "Living Towers Room",
                    "Sectiontitle": "Safety systems - Precautions to be taken",
                    "Risk to represent": "Cuts \/ pinching \/ perforations",
                    "Scenery (what should be filmed\/photographed)": "1-Use a manual press, check if it is attached to the bench",
                    "number of collaborators": 1,
                    "Action of each employee present in the scenario": "collaborator 1- action 1",
                    "Equipment and objects to have prepared": "hand press",
                    "VIDEO 360 OR PHOTO 360?": "Photograph",
                    "- Video runs in its entirety, with incorrect behaviors, the quiz appears and then starts 2D video with corrective actions?= 1 360 video + 1 2D video": "Photograph",
                    "startTime": 7 ,
                    "General observations": "take picture",
                    "Question": "What is the first step you should take into account when using a manual press?\n\nIdentify the correct answer.",
                    ans:[ "Check that the safeguards are functional.",
                    "Check that the danger signs are fitted on the press and are the correct ones.",
                    "Check if the press is fixed on the workbench.",
                    "Check if the workbench is clean and tidy.",]
                ,ca:[2]},
                {
                    ac:true,
                    "game":"quiz",
                    "MODULE": "Assembly",
                    "Scenario": "Scenario 11",
                    "ID": "qualidade-53",
                    "Section": "Quality(Kiosks, Metrology Lab, Quality Lab)",
                    "Number of the area to be filmed (same as on the floor plan)": 53,
                    "Area": "Line kiosks",
                    "Sectiontitle": "Work equipment - Malfunctions",
                    "Risk to represent": "Cuts \/ pinching \/ perforations",
                    "Scenery (what should be filmed\/photographed)": "1-Film the employee starting work equipment maintenance (measuring equipment) - try to fix it yourself with a tool",
                    "number of collaborators": 1,
                    "Action of each employee present in the scenario": "collaborator 1- action 1",
                    "Equipment and objects to have prepared": "tool",
                    "VIDEO 360 OR PHOTO 360?": "Video",
                    "- Video runs in its entirety, with incorrect behaviors, the quiz appears and then starts 2D video with corrective actions?= 1 360 video + 1 2D video": "Video runs in its entirety, with incorrect behaviors, the quiz appears and then starts 2D video with corrective actions?= 1 360 video + 1 2D video",
                    "startTime": null ,
                    "General observations": "take picture",
                    "Question": "When using a work equipment (e.g. a compensograph), I notice that it is broken. What should I do?\n\nIdentify the wrong answer.",
                    ans:[ "Lock out work equipment until repair is carried out by maintenance\/ electronics.",
                    "Open a WO for electronics ",
                    "I should try to repair the equipment problem, as it is something easy to fix.",
                    "Signal the work equipment as broken.",]
                    ,ca:[2],invert:true},       
            ],
        }
        ,aram:{
           1:[ 
            {
                ac:true, 
                "game":"quiz",
                "MODULE": "Warehouse",
                "Scenario": "Scene 1",
                "ID": "circ-75",
                "Section": "movement of people",
                "Number of the area to be filmed (same as on the floor plan)": 75,
                "Area": "Movement of people outside",
                "Sectiontitle": "Outdoor circulation - Precautions to be taken",
                "Risk to represent": "hit-and-run",
                "Scenery (what should be filmed\/photographed)": "1-Film the employee entering the entrance and instead of going through the pedestrian path and the respective crosswalk, he goes in the middle of the road 2-A forklift is coming towards him in reverse with a load",
                "number of collaborators": 2,
                "Action of each employee present in the scenario": "contributor 1-action1 contributor 2-action 2",
                "Equipment and objects to have prepared": "forklift",
                "VIDEO 360 OR PHOTO 360?": "video",
                "- Video runs in its entirety, with incorrect behaviors, the quiz appears and then starts 2D video with corrective actions?= 1 360 video + 1 2D video": "360 video + 2D video",
                "startTime": null,
                "General observations": null,
                "Question": "When walking outside on the plant, where should you walk?\n\nIdentify the correct answer.",
                ans:[ "You should walk on the road, but close to the crosswalk.",
                "In places designated\/marked for pedestrian circulation, such as crosswalks and footpaths.",
                 "You should walk on the road, there is no risk of being run over. ",
                "You can walk wherever you want. ",]
                ,ca:[1]},
               {
                   "game":"quiz",
                "MODULE": "Warehouse",
                "Scenario": "Scene 1",
                "ID": "covid-78",
                "Section": "COVID-19",
                "Number of the area to be filmed (same as on the floor plan)": 78,
                "Area": "COVID 19 - preventive measures",
                "Sectiontitle": "COVID 19 preventive measures - Hand hygiene",
                "Risk to represent": "WAIT FOR CONFIRMATION FROM ANA, OK from Ana fadista",
                "Scenery (what should be filmed\/photographed)": "1- Hand disinfection scenario in the canteen",
                "number of collaborators": 2,
                "Action of each employee present in the scenario": "collaborator 1 and 2- action 1 and action 2",
                "Equipment and objects to have prepared": null,
                "VIDEO 360 OR PHOTO 360?": "video",
                "- Video runs in its entirety, with incorrect behaviors, the quiz appears and then starts 2D video with corrective actions?= 1 360 video + 1 2D video": " Video (already with correct actions) is interrupted to present a quiz and then continues to show the correct way to proceed? = 1 only 360 video",
                "startTime": 5,
                "General observations": null,
                "Question": "During the COVID-19 pandemic the company implemented several prevention measures.\n\nIdentify the correct answer.",
                ans:[ "The canteen is a low contagion risk site for COVID-19, so I do not need to disinfect my hands on entry.",
                "I already had COVID-19, I no longer need to comply with the prevention rules.",
                 "I should always disinfect my hands upon entering any enclosed space that is shared with more people in order to prevent contagion.",
                "The use of mask and distancing is sufficient for the prevention of COVID-19 in closed places.",]
                ,ca:[2]},
                {
                    as:[9,13,14,25],
                    Sectiontitle:"Warehouse - driving forklift",
             
                "MODULE": "Warehouse",
             "Scenario": "Scenario 1",
             "ID": "arm-56",
             "Secção": "Armazém (Receção, Expedição, Armazém de PQ, Armazém Azul, VKL)",
             "Area": "Warehouse - driving forklift",
             "game":"risk",
             "Kuula  (link)": "https:\/\/kuula.co\/post\/NFCqW\/collection\/7qbyZ"
              ,hotspots :[
              {
                position: [1.46,-1.74],
                "body": " It is forbidden to drive machinery or work equipment and at the same time use a mobile phone (talking, texting, etc.). You must stop and\/or park the forklift and answer the call, for example, in safety.",
             cover : require("./dialogImages/ZCmv2Crg.jpeg")
                 
              },{
                position: [1.62,-2.00],
                "body": " In work spaces where there is mixed circulation (forklift trucks and pedestrians), and where there are exit doors and gates to the exterior, pedestrians should always give preference to using the door, so as not to come across the forklift in the same exit\/entry area (gate).",
             cover : require("./dialogImages/vcvJ0q2A.jpeg")
                
            },
               {
                position: [1.62,-0.92],
                "body": " The use of high visibility clothing (waistcoats) creates a safe working environment, increasing employee motivation and productivity. It promotes teamwork and safety for all.",
             cover : require("./dialogImages/CYtF46ig.jpeg")
                       },
               {
                position: [1.80,-1.74],
                "body": " In workplaces where there is mixed traffic, the forklift driver should honk at the entrance or at places where pedestrians approach.",
             cover : require("./dialogImages/L6RAT8sw.jpeg")
                       },
               {
                position: [1.86,-1.12],
                "body": "When driving the forklift, loads should not be moved with the forks raised, and they should always be placed at a height of approximately 0.15 m above the ground.",
             cover : require("./dialogImages/dQK9TkAM.jpeg")
                       },
               {
                position: [1.74,-0.16],
                "body": "When driving the forklift, when the load blocks the angle of vision, driving should be in reverse gear.",
             cover : require("./dialogImages/dF6-5xt8.jpeg")
                       },
               {
                position: [1.72,1.20],
                "body": "Keep circulation routes unobstructed and clean, thus allowing safe circulation in the workspace. ",
             cover : require("./dialogImages/Te1Z6dzw.jpeg")
                       },
              ]
            },
            {
                as:[6,41,42],
    
            Sectiontitle:"Find all hazards in the location shown",
     
        "MODULE": "Warehouse",
     "Scenario": "Scenario 1",
     "ID": "arm-54",
     "Secção": "Armazém (Receção, Expedição, Armazém de PQ, Armazém Azul, VKL)",
     "Area": "Warehouse - Reception / Dispatch",
     "game":"risk",  
     "Kuula  (link)": "https:\/\/kuula.co\/post\/NFCqR\/collection\/7qbyZ"
      ,hotspots :[
      {
        position: [1.64,1.40],
        "body": " The use of high visibility clothing (waistcoats) reduces the risk of incidents and injuries in the workplace, in environments where forklift trucks and pedestrians are in motion at the same time.",
     cover : require("./dialogImages/p7WWztMM.jpeg")
              
      }, {
        position: [1.52,-1.02],
        "body": " All mandatory PPE must be used correctly and failure to use it is the employee's responsibility. If the situation is recurrent, disciplinary action may be taken.",
     cover : require("./dialogImages/1v1TfvfY.jpeg")
               },
       {
    position:[1.72,-1.62],
        "body": " When walking in mixed traffic areas (forklifts and pedestrians), pay special attention and wear a high-visibility waistcoat. If you drive forklift trucks, you should always honk your horn when entering and leaving the area. As a pedestrian, you should use the mirrors to see the roadway and pay attention to the forklift's position light (blue light).",
    },
    {
    position:[1.76,2.60],
    "body": " Before positioning the forks on the pallet, check that the load is correctly stacked and that the pallet is not damaged. Depending on the direction in which you are going to turn the equipment, determine which hand is most appropriate for holding the pallet truck. Make sure the path is clear of obstacles. Always walk, never run, and do not stand in the turning radius. Be aware of your ergonomic posture when moving the load. If the load is very heavy, prefer the use of a stacker.",
       },
       {
        position:[1.64,1.68],
        "body": " For all manual tasks where there is a risk of cutting with sharp edges, always use the anti-cut gloves to prevent minor injuries.",
     cover : require("./dialogImages/21irppXg.jpeg")
                },
      ]
    },
    
    {
        as:[15],
            Sectiontitle:"Find all hazards in the location shown",
     
        "MODULE": "Warehouse",
     "Scenario": "Scenario 1",
     "ID": "arm-57",
     "Secção": "Armazém (Receção, Expedição, Armazém de PQ, Armazém Azul, VKL)",
     "Area": "Warehouse - Chemical/refrigerator product container ",
     "game":"risk", 
     "Kuula  (link)": "https:\/\/kuula.co\/edit\/n1\/collection\/7qbyZ"
      ,hotspots :[
      {
        position:[2.04,-6.90],
        "body": "Chemical products should be stored in suitable retention basins, avoiding possible spills on the ground during transportation or storage of the products.",
     cover : require("./dialogImages/S8Mb99pU.jpeg")
              
      }, {
    position:[1.54,-7.36],
        "body": "Chemical cabinets or chemical containers must be locked at all times and the key kept with the person in charge of the work area. If you need access, ask for the key. In this way, we avoid that employees from other areas have access to chemical products, and risks associated with their handling, that they are not aware of. ",
    },
    {
    position:[1.80,-7.32],
    "body": "All chemicals must be stored in the appropriate places, in accordance with the risk compatibility matrix. They should preferably be kept in their original packaging and properly labelled.",
       },
      ]
    }
     ] 
    
                
        },
        administrativa : {
            1: [
                {
                    ac:true, 
                    "game":"quiz",
                    "MODULE": "Administrative",
                    "Scenario": "Scene 1",
                    "ID": "circ-75",
                    "Section": "movement of people",
                    "Number of the area to be filmed (same as on the floor plan)": 75,
                    "Area": "Movement of people outside",
                    "Sectiontitle": "Outdoor circulation - Precautions to be taken",
                    "Risk to represent": "hit-and-run",
                    "Scenery (what should be filmed\/photographed)": "1-Film the employee entering the entrance and instead of going through the pedestrian path and the respective crosswalk, he goes in the middle of the road 2-A forklift is coming towards him in reverse with a load",
                    "number of collaborators": 2,
                    "Action of each employee present in the scenario": "contributor 1-action1 contributor 2-action 2",
                    "Equipment and objects to have prepared": "forklift",
                    "VIDEO 360 OR PHOTO 360?": "video",
                    "- Video runs in its entirety, with incorrect behaviors, the quiz appears and then starts 2D video with corrective actions?= 1 360 video + 1 2D video": "360 video + 2D video",
                    "startTime": null,
                    "General observations": null,
                    "Question": "When walking outside on the plant, where should you walk?\n\nIdentify the correct answer.",
                    ans:[ "You should walk on the road, but close to the crosswalk.",
                    "In places designated\/marked for pedestrian circulation, such as crosswalks and footpaths.",
                     "You should walk on the road, there is no risk of being run over. ",
                    "You can walk wherever you want. ",]
                    ,ca:[1]},
                   {
                       "game":"quiz",
                    "MODULE": "Administrative",
                    "Scenario": "Scene 1",
                    "ID": "covid-78",
                    "Section": "COVID-19",
                    "Number of the area to be filmed (same as on the floor plan)": 78,
                    "Area": "COVID 19 - preventive measures",
                    "Sectiontitle": "COVID 19 preventive measures - Hand hygiene",
                    "Risk to represent": "WAIT FOR CONFIRMATION FROM ANA, OK from Ana fadista",
                    "Scenery (what should be filmed\/photographed)": "1- Hand disinfection scenario in the canteen",
                    "number of collaborators": 2,
                    "Action of each employee present in the scenario": "collaborator 1 and 2- action 1 and action 2",
                    "Equipment and objects to have prepared": null,
                    "VIDEO 360 OR PHOTO 360?": "video",
                    "- Video runs in its entirety, with incorrect behaviors, the quiz appears and then starts 2D video with corrective actions?= 1 360 video + 1 2D video": " Video (already with correct actions) is interrupted to present a quiz and then continues to show the correct way to proceed? = 1 only 360 video",
                    "startTime": 5,
                    "General observations": null,
                    "Question": "During the COVID-19 pandemic the company implemented several prevention measures.\n\nIdentify the correct answer.",
                    ans:[ "The canteen is a low contagion risk site for COVID-19, so I do not need to disinfect my hands on entry.",
                    "I already had COVID-19, I no longer need to comply with the prevention rules.",
                     "I should always disinfect my hands upon entering any enclosed space that is shared with more people in order to prevent contagion.",
                    "The use of mask and distancing is sufficient for the prevention of COVID-19 in closed places.",]
                    ,ca:[2]} ,
             {
                 Sectiontitle:"Find all hazards in the location shown",
          
             "MODULE": "Administrative",
          "Scenario": "Scenario 1",
          "ID": "bc-74",
          "Secção": "BC",
          "Area": "BC - offices",
          "game":"risk",
          "Kuula  (link)": "https:\/\/kuula.co\/post\/NDKpY\/collection\/7qbyZ"
           ,hotspots :[
           {
     position: [2.14,-1.80],
             "body": "Workplace organization is fundamental. It should keep the corridors clear, preventing tripping and slipping, falling objects and, above all, serious incidents. Apply the 5S tool.",
     },{
     position: [2.12,0.26],
         "body": "You should adjust the chair so that the arms are parallel to the table, and check the backrest for the correct positioning of the lumbar curvature, so that the spine is supported. Use the footrest to ensure that your legs are at a right angle, to help stabilize your body posture.",
     },
     {
     position: [1.72,0.30],
         "body": "The monitor should be adjusted to eye height. It is recommended that you take frequent breaks to relieve eye fatigue. Adjust the brightness of the screen if necessary.",
     },
     {
     position: [1.82,-0.86],
         "body": "Place the waste in the appropriate containers for the type of waste you wish to dispose of. ",
     },
     {
     position: [1.68,0.86],
         "body": "Workplace organization is fundamental. It should keep the corridors clear, preventing tripping and slipping, falling objects and, above all, serious incidents. Apply the 5S tool.",
     },
     {
     position: [1.76,-3.74],
         "body": "Give preference to using a larger monitor, rather than a laptop screen. A larger screen size will allow you to see all your work on another scale, improving your productivity and reducing eye fatigue.",
     },
     {
     position: [1.70,-1.94],
         "body": "You should keep the corridors clear, preventing tripping, slipping, falling objects and, above all, serious accidents. Watching where you go around, consciously and constantly, is fundamental.",
     }
           ]
         }
        ]
         }
    },
    false:{
        ficha : {
            1:[ 
                {
                    ac:true, 
                    "game":"quiz",
                    "MODULE": "Pre-Prefabrication",
                    "Scenario": "Scene 1",
                    "ID": "circ-75",
                    "Area": "Circulação de pessoas no exterior",
                    "Sectiontitle": "Circulação exterior",
                    "startTime": null,
                    "Question": "Quando circula a pé no exterior da fábrica por onde deve circular sempre o peão?\n\nIdentifique a resposta correta.",
                    ans:[ "Deve circular pela estrada, mas junto da passadeira.",
                    "Somente nos locais destinados\/assinalados à circulação de peões, como, as passadeiras e caminhos pedestres.",
                     "Deve circular pela estrada, não existe risco de atropelamento. ",
                    "Deve circular somente na delimitaçºao da passadeira de peões. ",]
                    ,ca:[1]},
                   {
                       "game":"quiz",
                    "MODULE": "Pre-Prefabrication",
                    "Scenario": "Scene 1",
                    "ID": "covid-78",
                    "Area": "COVID 19 - medidas preventivas",
                    "Sectiontitle": "Medidas preventivas COVID 19 - Higiene das mãos",
                    "startTime": 5,
                    "Question": "Durante a pandemia Covid-19 a empresa implementou várias medidas de prevenção. \n\nIdentifique a resposta correta.",
                    ans:[ "A cantina é um local de baixo risco de contágio para a covid-19, por isso não preciso desinfetar as mãos à entrada.",
                    "Já tive covid-19, já não preciso cumprir com as regras de prevenção.",
                     "Devo sempre desinfetar as mãos à entrada de qualquer espaço fechado que seja partilhado com mais pessoas a fim de prevenir contágios para a covid-19.",
                    "O uso de máscara e distância de segurança é suficiente para a prevenção da covid-19 em locais fechados.",]
                    ,ca:[2]},
                   {  
                        as:[8],
                        Sectiontitle:"Encontre todos os riscos na área",
                        "MODULE": "Pre-Prefabrication",
                        "Scenario": "Scenario 1",
                        "ID": "stamp-35",
                        "Secção": "Stamping (Cunhagem e armazem, Ferramentaria, Bilhers)",
                        "Area": "Bilhers",
                        "game":"risk", 
                        "Kuula  (link)": "https:\/\/kuula.co\/post\/NFxSz\/collection\/7qbyZ"
                        ,hotspots :[
                            {
                                position:[3.80,-3.70],
                                "body": "Todos os carrinhos de transporte de ferramentas manuais, ou outros, devem ter as rodas travadas, evitando assim que o carrinho se desloque inadvertidamente quando não está em utilização.",
                                cover : require("./dialogImages/u5OWuDtI.jpeg")
                                    
                            },{
                                position:[4.72,1.12],
                                "body": "Mesmo que esteja a executar tarefas com baixo risco de corte ou onde exista a isenção de utilização de luvas anti-corte, deve trazer as luvas sempre consigo no 'clip das luvas', assim estão sempre acessiveis caso precise de as utilizar.",
                                cover : require("./dialogImages/zGsNqK58.jpeg")
                            },
                            {
                                position:[4.54,2.02],
                                "body": "Coloque os resíduos nos contentores adequados para o tipo de resíduos que pretende eliminar.",
                                cover : require("./dialogImages/dfpFGB-w.jpeg")
                            },
                            {
                                position:[4.50,1.20],
                                "body": "Trabalhos em altura requerem a utilização de equipamentos auxiliares em bom estado de conservação e de acordo com as regras de segurança. Apoiar sempre os dois pés na base da plataforma ou no degrau do escadote, de forma a manter o equilibrio do corpo e não cair.",
                                cover : require("./dialogImages/PKgXaJ9A.jpeg")
                            },
                            {
                                position:[4.52,-2.26],
                                "body": "Não deve utilizar o telemóvel enquanto circula pelos corredores envolventes das áreas de trabalho. A desatenção para com o meio envolvente pode causar quedas ao mesmo nível, tropeções e escorregadelas.",
                                cover : require("./dialogImages/OuN-w5Pw.jpeg")
                            },
                        ]
                    },
        
        {
            as:[27,50,55],
            // as:[3,7,10],
                Sectiontitle:"Encontre todos os riscos na área",
         
            "MODULE": "Pre-Prefabrication",
         "Scenario": "Scenario 1",
         "ID": "stamp-36",
         "Secção": "Stamping (Cunhagem e armazem, Ferramentaria, Bilhers)",
         "Area": "Armazém das bandas - Stamping",
         "game":"risk",
         "Kuula  (link)": "https:\/\/kuula.co\/post\/NFxSS\/collection\/7qbyZ"
          ,hotspots :[
          {
    position:[4.36,-5.02],
            "body": "A organização no local de trabalho é fundamental. Deve manter os corredores livres, prevenindo tropeções e escorregadelas, queda de objetos e, principalmente acidentes graves. Aplique a ferramenta 5S.",
    
          }, {
            position:[4.62,-4.80],
            "body": "Trabalhe de acordo com as regras, respeite a sinalética de segurança implementada na fábrica, tais como, sinais de perigo, proibição, obrigação, emergência e combate a incêndios, e outros.",
         cover : require("./dialogImages/BaEl6_-M.jpeg")
                   },
           {
            position:[4.72,-4.48],
            "body": "É proibido conduzir máquinas ou equipamentos de trabalho, e ao mesmo tempo utilizar o telemóvel (falar, escrever mensagens, etc..). Deve parar e\/ou estacionar o empilhador e atender a chamada, por exemplo, em segurança.",
         cover : require("./dialogImages/h25u-ojg.jpeg")
                   },
           {
    position:[4.28,-5.48],
            "body": "É proibido trepar ou colocar-se em cima de uma palete para alcançar um nivel superior da estante. Deve utilizar um escadote ou plataforma para aceder ao material que precisa num nível mais alto.",
           },
        ]
        },
                {
                    as:[12],
                    Sectiontitle:"Encontre todos os riscos na área",
             
                "MODULE": "Pre-Prefabrication",
             "Scenario": "Scenario 1",
             "ID": "stamp-31-pt2",
             "Secção": "Stamping (Cunhagem e armazem, Ferramentaria, Bilhers)",
             "game":"risk",
             "Area":"Stamping",
             "Kuula  (link)": "https:\/\/kuula.co\/post\/NFgVz\/collection\/7qbyZ"
              ,hotspots :[
              {
                position:[1.46,-4.30],
                "body": "De modo a minimizar a exposição ao ruído, deve deixar sempre a cabine de insonorização da prensa fechada, depois de colocar máquina em funcionamento.",
             cover : require("./dialogImages/Dm9eoFeU.jpeg")
                
              },{
                position:[1.62,-2.00],
                "body": "Para todas as tarefas manuais onde exista risco de corte com arestas vivas, utilize sempre as luvas de proteção anti-corte para prevenir possíveis ferimentos.",
                cover :[
                    require("./dialogImages/en04Mkzw.jpeg"),
                    require("./dialogImages/DjUPdqPA.jpeg"), 
                  ]
                       },
               {
            position:[1.62,-1.12],
                "body": "Ao circular em áreas de circulação mista (empilhadores e peões) redobre a atenção. Se conduzir empilhadores, deve sempre buzinar ao entrar e sair dos espaços. Enquanto peão, deve utilizar os espelhos para visualizar o caminho de circulação e estar atento à luz de presença do empilhador (luz azul).",
               },
               {
                position:[2.08,-1.46],
                "body": "Manter o local de trabalho organizado e limpo é fundamental para as atividades que fazemos diariamente. Evitamos distrações, perda de tempo na procura do que é essencial, perda de concentração, e um maior stress.",
             cover : require("./dialogImages/HIZr54xs.jpeg")
                       },
               {
                position:[1.56,-1.72],
                "body": "Todos os EPI´s obrigatórios devem ser usados corretamente e a sua não utilização é da responsabilidade do colaborador. Caso a situação seja recorrente, pode ser alvo de um processo disciplinar.",
             cover : require("./dialogImages/Crkrek44.jpeg")
                       },
               {
                position:[1.82,-1.96],
                "body": "Deve sempre acautelar que as proteções de segurança físicas móveis estão colocadas no local adequado à sua função. Deste modo elimina o risco de contacto com arestas vivas da banda.",
             cover : require("./dialogImages/EhPx7SAQ.jpeg")
                      },
               {
                position:[2.52,-0.54],
                "body": "Mantenha os caminhos de circulação desobstruidos e limpos, permitindo assim uma circulação no espaço de trabalho de forma segura.",
             cover : require("./dialogImages/VnutjQTg.jpeg")
                       },
            ]
            },
                {
                    ac:true,
                    "game":"quiz",
                    "MODULE": "Pre-Prefabrication",
                    "Scenario": "Scene 1",
                    "ID": "stamp-31-pt1",
                    "Area": "stamping",
                    "Sectiontitle": "Perigos vários no local de trabalho",
                    "startTime": 4,
                    "Question": "Quando vai buscar uma ferramenta à estante, utilizando o carrinho de transporte, qual o procedimento de segurança que deve ter em conta?\n\nIdentifique a resposta correta.",
                    ans:[ "Encostar o carrinho à estante, retirando a ferramenta da estante sozinho, puxando-a, a ferramenta não é pesada.",
                    "Encostar o carrinho à estante, com a ajuda de outro colaborador do sector puxar a ferramenta para o carrinho e colocar as trancas laterais.",
                    "Encostar o carrinho à estante, travá-lo, com a ajuda de outro colaborador do sector puxar a ferramenta para o carrinho, e colocar as trancas laterais.",
                    "Todas as anteriores",]
                    ,ca:[2]},
                { 
                    ac:true,
                    "game":"quiz",
                    "MODULE": "Pre-Prefabrication",
                    "Scenario": "Scene 1",
                    "ID": "stamp-32",
                    "Area": "Ferramentaria Stamping",
                    "Sectiontitle": "Ergonomia - movimentação de cargas",
                    "startTime":4,
                    "Question": "Durante o transporte da ferramenta para a estante como deve proceder?\n\Identifique a resposta errada.",
                    ans:[ "Transportar a ferramenta com as proteções laterais do carrinho de transporte colocadas.",
                    "Transportar a ferramenta no stacker, pois a ferramenta é maior que o carrinho e não permite colocar as proteções laterais corretamente.",
                    "Pedir ajuda ao meu colega para dividir esforços e colocar a ferramenta na estante das ferramentas pois pesa 30Kg.",
                    "Transportar a ferramenta no carrinho de transporte sem as proteções laterais colocadas, pois a ferramenta é maior que o carrinho.",]
                    ,ca:[3],invert:true},
            
                {
                    ac:true,
                    "game":"quiz",
                    "MODULE": "Pre-Prefabrication",
                    "Scenario": "Scene 1",
                    "ID": "stamp-33",
                    "Area": "Ferramentaria Stamping",
                    "Sectiontitle": "Perigo de queda em altura - ferramentas de trabalho",
                    "startTime":4,
                    "Question": "Durante a reparação de uma placa da ferramenta na bancada de trabalho, que procedimentos de segurança devo ter em conta?\n\nIdentifique a resposta correta.",
                    ans:[ "Abandonar o sector ou afastar-me da bancada, e deixar a placa da ferramenta ao alto.",
                    "Colocar a placa da ferramenta na bancada de trabalho mais ao centro de forma a não cair.",
                    "Deixar a placa da ferramenta proximo da zona do rebordo da bancada para ser mais facil transportar para o carrinho",
                    "Nenhuma das anteriores",]
                    ,ca:[1]},
            
                { 
                    "game":"quiz",
                    "MODULE": "Pre-Prefabrication",
                    "Scenario": "Scene 1",
                    "ID": "stamp-34",
                    "Area": "Ferramentaria Stamping",
                    "Sectiontitle": "Sistemas de segurança - cuidados a ter",
                    "startTime":9,
                    "Question": "Quando inicia o trabalho no esmeril, o que faria nesta situação?\/Identifique a resposta correta..",
                    ans:[ "Inicio o trabalho sem a proteção do esmeril colocada, pois é uma tarefa rápida.",
                    "Baixo a proteção do esmeril para fazer o trabalho",
                    "Utilizo as luvas de proteção ao corte\/calor para manipular a peça no esmeril.",
                    "Não verifico o estado da mó antes de utilizar o esmeril.",]
                    ,ca:[1]},
            ],
            2:[
                {
                    as:[45,61,80],
    
                Sectiontitle:"Encontre todos os riscos na área",
         
            "MODULE": "Pre-Prefabrication",
         "Scenario": "Scenario 2",
         "ID": "galv-37",
         "Secção": "Plating (linha produtiva, Armazém de PQ, ETARI)",
         "Area": "Galvânica",
         "game":"risk",  
         "Kuula  (link)": "https:\/\/kuula.co\/post\/NFxSr\/collection\/7qbyZ"
          ,hotspots :[
          {
            position:[4.86,-5.00],
            "body": "É proibido passar por baixo da ponte rolante enquanto esta está em funcionamento. Antes de realizar a movimentação mecânica de cargas, verifique\n- se o peso da carga está de acordo com a capacidade do equipamento;  \n- o meio envolvente, de modo a não chocar contra objetos enquanto manipula a carga.",
         cover : require("./dialogImages/AQQc9k4c.jpeg")
                  
          },{
            position:[3.94,-2.40],
            "body": "Os equipamentos de elevação de cargas auxiliares devem sempre ser utilizados no seu posto de trabalho, de modo a evitar posturas ergonómicas desadequadas..",
         cover : require("./dialogImages/76zBJ_Vg.jpeg")
                   },
           {
            position:[4.54,-3.06],
            "body": "Locais com acesso restrito ou condicionado, só devem ser utilizados por colaboradores do setor ou colaboradores autorizados. Esta regra deve-se ao facto de colaboradores externos à secção não terem conhecimento de todos os riscos específicos inerentes à mesma.",
         cover : require("./dialogImages/91S6K7Vw.jpeg")
                   },
           {
            position:[4.80,-3.68],
            "body": "Quando subir ou descer escadas deve fazê-lo de modo seguro, com as mãos no corrimão e sem a utilização de telemóvel.",
         cover : require("./dialogImages/ycZMMgQg.jpeg")
                   },
           {
            position:[4.44,-5.64],
            "body": "A organização no local de trabalho é fundamental. Deve manter os corredores livres, prevenindo tropeções e escorregadelas, queda de objetos e, principalmente acidentes graves. Aplique a ferramenta 5S.",
         cover : require("./dialogImages/N_TBdl_Q.jpeg")
                   },
           {
            position:[4.36,-5.24],
            "body": "Derrames de água\/óleo devem ser contidos (se tiver os meios apropriados, deve limpar o chão) e sinalizados, evitando quedas ao mesmo nível e escorregadelas.",
         cover : require("./dialogImages/RRyz65Zw.jpeg")
            
           },
           {
            position:[4.60,-4.98],
            "body": "Nunca deixe o gancho da ponte rolante ou grua em suspensão a um nível mais baixo próximo do corpo, pode bater-lhe ou ser atingido.",
         cover : require("./dialogImages/zLKNmtaQ.jpeg")
                   },
           {
            position:[4.72,-4.16],
            "body": "Todos os EPI´s obrigatórios devem ser usados corretamente e a sua não utilização é da responsabilidade do colaborador. Caso a situação seja recorrente, pode ser alvo de um processo disciplinar.",
         cover : require("./dialogImages/hn5JpoCA.jpeg")
                   },
           {
    position:[4.16,-2.92],
            "body": "Quando efetuar movimentações manuais de cargas, deve aproximar a carga do corpo (sem ter os braços esticados), fletir as pernas, manter as costas direitas, e colocar a caixa na palete.",
           },
        ]
        },
        
        {
            as:[13,47,51,74,82],
                Sectiontitle:"Encontre todos os riscos na área",
         
            "MODULE": "Pre-Prefabrication",
         "Scenario": "Scenario 2",
         "ID": "lab-39",
         "Secção": "Lab. Plating",
         "Area": "Lab. Galvânica",
         "game":"risk",
         "Kuula  (link)": "https:\/\/kuula.co\/post\/NFxSB\/collection\/7qbyZ"
          ,hotspots :[
          {
            position:[4.52,4.30],
            "body": "Sempre que utiliza produtos químicos deve de utilizar as luvas de proteção química (nitrilo), de acordo com a política das luvas. Deve utilizar corretamente os seus EPI´s, as luvas de proteção devem estar colocadas nas duas mãos.",
            cover :[
                require("./dialogImages/VzXs4Kmw.jpeg"), 
                require("./dialogImages/X_PBcRBQ.jpeg"),
              ]
            
          },{
            position:[4.76,4.36],
            "body": "Deve utilizar corretamente os seus EPI´s. Os óculos de proteção devem estar colocados sobre a face, ou em caso de necessidade extra, deve utilizar uma viseira de proteção.",
         cover : require("./dialogImages/jvS3pxJQ.jpeg")
               
        },
           {
            position:[4.54,2.60],
            "body": "Em equipamentos de trabalho onde existe radiação ionizante, como raio-x, é obrigatória a utilização de dosimetro individual de controlo da exposição a radiação.",
         cover : require("./dialogImages/sDi-KrHA.jpeg")
               },
           {
            position:[4.46,6.62],
            "body": "É expressamente proibido comer no local de trabalho.",
         cover : require("./dialogImages/gTIPim7g.jpeg")
             },
           {
            position:[4.18,3.88],
            "body": "Em laboratório, ou outros locais de trabalho, onde existe a presença e manipulação de produtos químicos é proibido ter garrafas de água.",
         cover : require("./dialogImages/u99AK4MA.jpeg")
            },
           {
            position:[4.68,1.30],
            "body": "Sempre que utiliza produtos químicos na hotte, verifique se a extração está funcional, de modo a reduzir o risco de exposição a agentes químicos perigosos.",
         cover : require("./dialogImages/wXxJiqRQ.jpeg")
             },
           {
            position:[4.32,1.64],
            "body": "Panos contaminados devem ser colocados no contentor (vermelho) hermético com pedal.",
         cover : require("./dialogImages/opa_D0lA.jpeg")
             },
           {
            position:[4.58,4.62],
            "body": "Produtos químicos ou restos de amostras, devem ser colocados no funil S.C.A.T, que por sua vez tem ligação direta à ETARI para tratamento e eliminação.",
         cover : require("./dialogImages/COWSXLkg.jpeg")
              },
          ]
        },
        
        {
                Sectiontitle:"Encontre todos os riscos na área",
         
            "MODULE": "Pre-Prefabrication",
         "Scenario": "Scenario 2",
         "ID": "recozi-40",
         "Secção": "Recozimento",
         "Area": "Reecozimento",
         "game":"risk", 
         "Kuula  (link)": "https:\/\/kuula.co\/post\/NDvzR\/collection\/7qbyZ"
          ,hotspots :[
          {
            position:[4.58,8.50],
            "body": "Equipamentos de emergência ou de combate a incêndios devem estar sinalizados, desobstruídos e facilmente acessíveis, facilitando a sua utilização.",
         cover : require("./dialogImages/vhKaNVnc.jpeg")
                   
          },{
            position:[4.52,8.04],
            "body": "Utilize os equipamentos auxiliares disponiveis, tais como porta-paletes elevatório, para uma melhor postura ergonómica. Deve ajustar sempre o porta- paletes à altura a que vai colocar a carga na palete.",
         cover : require("./dialogImages/6T5rd49Y.jpeg")
                   },
           {
            position:[4.66,7.46],
            "body": "Nunca deixe a carga em suspensão no gancho da ponte rolante ou grua. A carga pode cair ou desprender-se e atingir-lhe ou bater-lhe no corpo.",
         cover : require("./dialogImages/bWMJ0vmQ.jpeg")
                   },
           {
            position:[4.90,7.32],
            "body": "Ao efetuar movimentação mecânica de cargas com a ponte rolante ou grua, deve sempre colocar a carga no chão ou em local seguro, assim que terminar a operação.",
         cover : require("./dialogImages/2IW0BcIs.jpeg")
                   },
           {
    position:[4.54,7.06],
            "body": "Na movimentação manual de cargas (MMC), o peso e volume da carga são os fatores que apresentam maior risco a quem as movimenta. Noutras situações, apesar de não haver um grande peso a deslocar, o perigo reside na repetição e adoção de posturas incorretas. Utilize as técnicas corretas de MMC para a prevenção de lesões musculoesqueléticas.",
           },
           {
            position:[4.30,6.70],
            "body": "Quando efetuar movimentações manuais de cargas, deve aproximar a carga do corpo (sem ter os braços esticados), fletir as pernas, manter as costas direitas, e colocar a caixa na palete.",
         cover : require("./dialogImages/F29eyAXw.jpeg")
                   },
          ]
        },
                { 
                "game":"quiz",
                "MODULE": "Pre-Prefabrication",
                "Scenario": "Scenario 2",
                "ID": "apq-38",
                "Area": "Armazém de Produtos Quimicos",
                "Sectiontitle": "Produtos Químicos - armazenamento, rotulagem, instruções de segurança quimicos, acesso restrito",
                "startTime": 9,
                "Question": "Indique porque este armazém é considerado uma área de acesso restrito?\n\nIdentifique a resposta correta.",
                ans:[ "É um local onde são armazenadas matérias primas valiosas",
                "É um local onde são armazenados produtos químicos perigosos para o homem..",
                "É um local com estantes de armazenamento de materiais em altura.",
                "Nenhuma das anteriores",]
                ,ca:[1]
            },
            ],
            3:[
                {
                Sectiontitle:"Encontre todos os riscos na área",
         
            "MODULE": "Pre-Prefabrication",
         "Scenario": "Scenario 3",
         "ID": "mold-41",
         "Secção": "Molding (Produção, Ferramentaria, sala de secagem, sala de escolhas)",
         "Area": "Molding",
         "game":"risk",
         "Kuula  (link)": "https:\/\/kuula.co\/post\/NFCf9\/collection\/7qbyZ"
          ,hotspots :[
            {
                position: [1.66,-1.82],
                "body": "Sempre que exista contacto com superfícies quentes, e necessidade de trabalhar ou efetuar reparação no interior da máquina deve utilizar os manguitos de proteção anti-calor, prevenindo queimaduras nos membros superiores.",
             cover : require("./dialogImages/oVLEB-cQ.jpeg")
                
               },
          {
            position:[1.90,-1.74],
            "body": "Derrames de óleo na Pré-Fabricação devem ser contidos com os panos\/mantas absorventes existentes nos contentores MEWA.",
         cover : require("./dialogImages/RW8E7IGg.jpeg")
                   
          }, {
            position: [4.96,7.62],
            "body": "Descer em segurança pelo escadote ou escada é fundamental para não cair. Faça a descida em segurança, apoiando as duas mãos no escadote ou escada, e posicionando os pés corretamente nos degraus.",
         cover : require("./dialogImages/e3Oikf8c.jpeg")
                   },
           {
    position: [4.66,7.78],
            "body": "Nunca deixe a carga em suspensão no gancho da ponte rolante ou grua. Esta pode cair\/desprender-se e atingi-lo ou bater-lhe no corpo. Deve ser sempre colocada no chão ou em local seguro, assim que termine a operação.",
    },
    {
    position: [4.58,7.92],
        "body": "Nunca deixe a carga em suspensão no gancho da ponte rolante ou grua. A carga pode cair ou desprender-se e atingir-lhe ou bater-lhe no corpo. Caso detete esta situação e não for manobrador do equipamento, não fique indiferente deve alertar o supervisor/\/chefe de equipa para a coreção imediata da situação.",
           }
          
          ]
        },
       
        {
                Sectiontitle:"Encontre todos os riscos na área",
         
            "MODULE": "Pre-Prefabrication",
         "Scenario": "Scenario 3",
         "ID": "mold-43",
         "Secção": "Molding (Produção, Ferramentaria, sala de secagem, sala de escolhas)",
         "Area": "PLCD\/ Inlay (ed.19)",
         "game":"risk", 
         "Kuula  (link)": "https:\/\/kuula.co\/post\/NFCfc\/collection\/7qbyZ"
          ,hotspots :[
          {
            position:[4.42,7.96],
            "body": "Verifique se o tapete anti-fadiga está bem posicionado na plataforma, de modo a não perder a visibilidade sobre os degraus, evitando possíveis quedas em desnível.",
         cover : require("./dialogImages/ZCoo38IU.jpeg")
                  
          }, {
    position:[4.54,9.18],
            "body": "Sempre que utiliza x-atos, nível 3, 4 ou 5, bem como ferramentas manuais, deve utilizar as luvas de proteção anti-corte.",
        },
           {
            position:[4.44,7.14],
            "body": "A organização no local de trabalho é fundamental. Deve manter os corredores livres, prevenindo tropeções e escorregadelas, queda de objetos e, principalmente acidentes graves. Aplique a ferramenta 5S.",
         cover : require("./dialogImages/pM_ed1Q8.jpeg")
                   },
           {
            position:[5.10,7.56],
            "body": "Sempre que a ponte rolante está em funcionamento  não devemos efetuar trabalhos em altura nas proximidades ou subir às máquinas para efetuar trabalhos (limpeza, reparação, outros…), podendo ser atingido pela carga durante a movimentação do equipamento.",
         cover : require("./dialogImages/_-H9dqZM.jpeg")
                   },
           {
    position:[4.12,7.96],
            "body": "Mantenha os caminhos de circulação desobstruídos e limpos, permitindo assim uma circulação segura no espaço de trabalho. Não deve passar por cima de paletes para encurtar caminho.",
           },
           {
            position:[4.64,9.84],
            "body": "Utilize a estrutura da plataforma para se encostar e pausar, não deve sentar-se sobre a mesma elevando os pés do solo, podendo ocorrer desequilíbrio e quedas em altura.",
         cover : require("./dialogImages/R7dE_OpA.jpeg")
                   },
           {
            position:[4.50,10.36],
            "body": "Manter o local de trabalho organizado e limpo é fundamental para as atividades que fazemos diariamente. Evitamos distrações, perda de tempo na procura do que é essencial, perda de concentração, e um maior stress.",
         cover : require("./dialogImages/8-sCLwlE.jpeg")
                   },
          ]
        },
       
        {
                Sectiontitle:"Encontre todos os riscos na área",
         
            "MODULE": "Pre-Prefabrication",
         "Scenario": "Scenario 3",
         "ID": "mold-44",
         "Secção": "Molding (Produção, Ferramentaria, sala de secagem, sala de escolhas)",
         "Area": "Ferramentaria Molding",
         "game":"risk",
         "Kuula  (link)": "https:\/\/kuula.co\/post\/NFCfX\/collection\/7qbyZ"
          ,hotspots :[
          {
    position:[4.58,7.42],
            "body": `Antes e durante a movimentação mecânica de cargas com a ponte rolante, verifique: ^^ - se o peso da carga está de acordo com a capacidade do equipamento; ^^ - o meio envolvente, de modo a não chocar contra objetos enquanto manipula a carga;^^ - altura a que transporta a carga;^^ - se as sirenes estão funcionais para alertar os outros colaboradores do setor;^^ - o balanço da carga reduzindo a velocidade do transporte, se necessário.\n`,
    
    }, {
    position:[5.16,7.14],
        "body": "Todos os equipamentos de trabalho, tais como porta-paletes, escadas, escadotes, plataformas, entre outros, são sujeitos a verificações periódicas de segurança, pelo dept. da Manutenção, sendo colocado um selo de inspeção visivel, que ateste a sua validade.",
    },
    {
    position:[4.38,7.54],
        "body": "Componentes e materiais de trabalho (como por exemplo moldes, placas metálicas, ferramentas, etc...) devem ser sempre colocados o mais ao centro na bancada de trabalho, de forma a não cair.",
    },
    {
    position:[4.40,7.26],
        "body": "A organização no local de trabalho é fundamental. Deve manter os corredores livres, prevenindo tropeções e escorregadelas, queda de objetos e, principalmente acidentes graves. Aplique a ferramenta 5S.",
    },
    {
    position:[4.82,7.42],
        "body": "Nunca deixe o gancho da ponte rolante ou grua em suspensão a um nível mais baixo próximo do corpo, pode bater-lhe ou ser atingindo.",
    },
    {
    position:[4.42,9.04],
        "body": "Deve reajustar o banco ou cadeira à sua altura, ajustando a sua postura, de forma a promover uma melhor circulação sanguínea, reduzindo a carga muscular estática associada à postura na posição sentada.",
    },
           {
            position:[4.50,7.88],
            "body": "Sempre que utiliza produtos químicos deve utilizar as luvas de proteção química (nitrilo), de acordo com a política das luvas.",
         cover : require("./dialogImages/IIFyMVhA.jpeg")
                   },
          ]
        },
                {
                "game":"quiz",
                "MODULE": "Pre-Prefabrication",
                "Scenario": "Scenario 3",
                "ID": "mold-42",
                "Area": "Corredor da Pré-Fab - octobines de material plástico",
                "Sectiontitle": "Perigo escorregar e cair",
                "startTime": 16,
                "Question": "Cairam residuos plásticos para o chão durante o transporte do mesmo, que faria nesta situação?\n\nIdentifique a resposta correta.",
                ans:[ "O handyman deve deixar o local limpo ",
                "Pedir ao GFS para limpar o material do chão",
                "Pedir à Isporeco para limpar o chão ",
                "Abandonar o local e não limpar, não é da minha responsabilidade.",],
            ca:[0]}
    ],
            4:[
            {
                "game":"quiz",
                "MODULE": "Pre-Prefabrication",
                "Scenario": "Scenario 4",
                "ID": "entrada-58",
                "Area": "Revisão de Entrada",
                "Sectiontitle": "Equipamentos de elevação de cargas",
                "startTime":7,
                "Question": "Quando utiliza a plataforma de elevação de cargas, que procedimentos de segurança deve ter em conta para transportar uma carga até ao primeiro andar?\n\nIdentifique a resposta correta.",
                ans:[ "Como a carga não é muito pesada, 25kg aproximadamente, faço eu o transporte manual da carga sozinho.",
                "Utilizo a plataforma de transporte de cargas para subir em simultaneo com a carga.",
                    "Coloco somente a carga no interior da plataforma e aciono o botão de subida\/descida da mesma para efetuar o transporte.",
                "Nenhuma das respostas anteriores.",]
                ,ca:[2]},
            ],
            5:[{
                Sectiontitle:"Encontre todos os riscos na área",
         
            "MODULE": "Pre-Prefabrication",
         "Scenario": "Scenario 5",
         "ID": "tool_z1-59",
         "Secção": "Toolshop Zona 1",
         "Area": "Toolshop Zona 1 - fresadora",
         "game":"risk",
         "Kuula  (link)": "https:\/\/kuula.co\/post\/NFCqy\/collection\/7qbyZ"
          ,hotspots :[
          {
            position: [1.72,-1.72],
            "body": "Para todas as tarefas manuais onde exista risco de corte com arestas vivas, utilize sempre as luvas de proteção anti-corte para prevenir pequenos ferimentos.",
         cover : require("./dialogImages/7XCT2DSY.jpeg")
            
          }, {
            position: [1.86,1.58],
            "body": "A organização no local de trabalho é fundamental. Deve manter os corredores livres, prevenindo tropeções e escorregadelas, queda de objetos e, principalmente acidentes graves. Aplique a ferramenta 5S.",
         cover : require("./dialogImages/zZMfDX6g.jpeg")
                   },
           {
            position: [2.02,-1.62],
            "body": "Os panos contaminados devem ser colocados no contentor hermético dos resíduos contaminados (contentor vermelho).",
         cover : require("./dialogImages/YNewt-JI.jpeg")
               
        },
           {
            position: [1.68,-1.02],
            "body": "Sempre que utiliza produtos químicos deve utilizar as luvas de proteção química (nitrilo), de acordo com a política das luvas.",
            cover :[
                require("./dialogImages/4d_V03mQ.jpeg"), 
                require("./dialogImages/3AjuMMnQ.jpeg"),
              ]
            //  ,Z3KLxH5w
           },
          ]
        },
       
        {
                Sectiontitle:"Encontre todos os riscos na área",
         
            "MODULE": "Pre-Prefabrication",
         "Scenario": "Scenario 5",
         "ID": "tool_z2-60",
         "Secção": "Toolshop Zona 2",
         "Area": "Toolshop Zona 2 - erosão",
         "game":"risk",
         "Kuula  (link)": "https:\/\/kuula.co\/post\/NFCKk\/collection\/7qbyZ"
         ,hotspots :[
          {
    position:[2.3,3],
            "body": "Derrames devem ser contidos com os panos\/almofadas\/chouriços\/mantas absorventes existentes nos contentores MEWA.",
    
          },{
            position:[1.64,-0.92],
            "body": "Verifique todos os dispositivos de segurança da máquina quando começa a trabalhar, se detetar alguma anomalia deve reportar ao seu chefe de equipa\/ supervisor.",
         cover : require("./dialogImages/4TOASyMg.jpeg")
            
           },
           {
            position:[1.94,-1.72],
            "body": "Para todas as tarefas manuais onde exista risco de corte com arestas vivas, utilize sempre as luvas de proteção anti-corte para prevenir pequenos ferimentos.",
         cover : require("./dialogImages/4cfKB_ag.jpeg")
                   },
           {
            position:[1.62,-2.60],
            "body": "Sempre que utiliza produtos químicos deve utilizar as luvas de proteção química (nitrilo), de acordo com a política das luvas.",
         cover : require("./dialogImages/d0YkdiKo.jpeg")
                   },
           {
            position:[2,1.9],
            "body": "Componentes e materiais de trabalho (como por exemplo, moldes, placas metálicas, ferramentas, etc...), devem ser sempre colocados o mais ao centro na bancada de trabalho, de forma a não cair.",
         cover : require("./dialogImages/jG82-Wws.jpeg")
                   },
           {
            position:[1.44,-1.16],
            "body": "Equipamentos de trabalho, por exemplo, microscópios, lupas, outras luminárias, devem ser desligados quando não estão em utilização. \"Seja um colaborador verde\". ",
         cover : require("./dialogImages/TH0DHiNs.jpeg")
                   },
           {
            position:[2.42,1.42],
            "body": "Manter o local de trabalho organizado e limpo é fundamental para as atividades que fazemos diariamente. Utilize os contentores apropriados para colocar os resíduos do setor.",
         cover : require("./dialogImages/lBITVfow.jpeg")
                   },
           {
            position:[1.46,-2.50],
            "body": "Todos os EPI´s obrigatórios devem ser usados corretamente e a sua não utilização é da responsabilidade do colaborador. Caso a situação seja recorrente, pode ser alvo de um processo disciplinar.",
         cover : require("./dialogImages/1vsA4EX8.jpeg")
                   },
          ]
        },
            {
                "game":"quiz",
                "MODULE": "Pre-Prefabrication",
                "Scenario": "Scenario 5",
                "ID": "tool_z3-61",
                "Area": "Toolshop Zona 3 - SPW",
                "Sectiontitle": "Ergonomia - movimentação manual de cargas",
                "startTime": 8,
                "Question": "Quando utiliza o Kardex\/Megamat, como deve proceder o colaborador? E qual deve ser o melhor posicionamento  de uma peça pesada no seu interior?\n\nIdentifique todas as respostas corretas.",
                ans:[ "Manter-se perto do kardex durante o ciclo de funcionamento.",
                "Virar as costas durante o movimento do ciclo do kardex",
                    "Afastar-se, observar e esperar que o ciclo de funcionamento esteja terminado para retirar a peça.",
                "Colocar a peça no interior do Kardex\/Megamat o mais próximo da barriga possivel.",]
                ,ca:[2,3]
            }],
            6:[
                {
                    "game":"quiz",
                    "MODULE": "Pre-Prefabrication",
                    "Scenario": "Scenario 7",
                    "ID": "gfs-68",
                    "Area": "GFS - Oficina Eletrónica - assistência na linha a uma avaria",
                    "Sectiontitle": "Manutenções eletrónicas",
                    "startTime": 8,
                    "Question": "Quais os critérios de segurança a ter em conta durante trabalhos de manutenção eletrónica da linha, por exemplo mudar ligações eletricas, sensores, ligações pneumáticas, colocação de dispositivos de segurança, outros?\n\nIdentifique todas as respostas corretas.",
                    ans:[ "Sinalizar a máquina com o sistema de segurança LOTO, desligar as energias da máquina.",
                    "Utilizar os Epi´s obrigatórios para a realização da tarefa, tais como, óculos de segurança\/palas, sapatos de segurança, proteção auditiva, luvas de proteção, outros...",
                        "Verificar a máquina depois da manutenção, colocar a máquina em funcionamento, remover a sinalética e bloqueio. ",
                    "Informar o colaborador que a máquina já está operacional, os dispositivos de segurança estão ativos e pode continuar o trabalho.",]
                    ,ca:[0,1,2,3]},
                    { 
                    "game":"quiz",
                    "MODULE": "Pre-Prefabrication",
                    "Scenario": "Scenario 7",
                    "ID": "gfs-69",
                    "Area": "GFS - Coberturas",
                    "Sectiontitle": "Acesso a cobertura - telhado",
                    "startTime": 8 ,
                    "Question": "Quais os critérios de segurança a ter em conta no acesso em altura ao telhado (coberturas)?\n\nIdentifique todas as respostas corretas.",
                    ans:[ "Devem estar no mesmo local a trabalhar no minimo duas pessoas",
                    "Efetuar o preenchimento do registo de acesso ao telhado (cobertura).",
                        "Utilizar todo os EPI´s obrigatório (sapatos de segurança, óculos de proteção, arnés de segurança, luvas de proteção anti-corte)",
                    "Nos locais onde existe guarda-corpos devo colocar também a linha de vida.",]
                    ,ca:[0,1,2]},
                    {
                        "game":"quiz",
                    "MODULE": "Pre-Prefabrication",
                    "Scenario": "Scenario 7",
                    "ID": "gfs-70",
                    "Area": "GFS- Tarefas de riscos elétrico",
                    "Sectiontitle": "Manutenções - ligações elétricas",
                    "startTime":3  ,
                    "Question": "Quais os critérios de segurança a ter em conta durante trabalhos com ligações elétricas?\n\nIdentifique todas as respostas corretas.",
                    ans:[ "Utilizar os EPI´s  necessários à tarefa, tais como, viseira com capacete de proteção, sapatos de segurança, luvas dielétricas. ",
                    "Devem estar no mesmo local a trabalhar no mínimo duas pessoas",
                        "O segundo colaborador que está a acompanhar o trabalho, só está a supervisionar, não precsia estar a utilizar os EPI´s.",
                    "Nenhuma das respostas anteriores.",]
                    ,ca:[0,1]},
                    {
                        Sectiontitle:"Encontre todos os riscos na área",
                 
                    "MODULE": "Assembly",
                 "Scenario": "Scenario 7",
                 "ID": "gfs-73",
                 "Secção": "Prints (SRK, PK2, SPR, Workshop, M54, Micro K, Nano)",
                 "Area": "GFS oficina GFS + manutenção de máquina",
                 "game":"risk",
                 "Kuula  (link)": "https:\/\/kuula.co\/post\/NFdQk\/collection\/7qbyZ"
                  ,hotspots :[
                  {
                position:[1.76,-3.30],
                    "body": "A organização no local de trabalho é fundamental. Deve manter os corredores livres, prevenindo tropeções e escorregadelas, queda de objetos e, principalmente acidentes graves. Aplique a ferramenta 5S."
                },
                {
                position:[1.98,-2.56],
                "body": "As ferramentas de trabalho (entre outros materiais) devem ser sempre colocadas o mais ao centro na bancada de trabalho, de forma a não cair.",
                cover : require("./dialogImages/Znkp9NWg.jpeg")
    
            },
                {
                position:[1.62,0.14],
                "body": "Nunca deixe a carga em suspensão no gancho da ponte rolante/ grua ou diferencial. Esta pode cair/ desprender-se e atingi-lo no corpo.",
                },
                {
                position:[1.80,0.28],
                "body": "Manter o local de trabalho organizado e limpo é fundamental para as atividades que fazemos diariamente. Evitamos distrações, perda de tempo na procura do que é essencial, perda de concentração, e um maior stress.",
                cover : require("./dialogImages/WXA7Ysrg.jpeg") 
               
            },
                {
                position:[1.82,-0.82],
                "body": "Deve manter todos os produtos químicos que não estão em utilização direta armazenados no armário dedicado. Dê preferencia ao uso de embalagens originais.Caso sejam recipientes secundários, verifique se o produto químico está devidamente rotulado e o recipiente em bom estado de conservação.",
                cover : require("./dialogImages/5Jw3I87g.jpeg") 
              
            },
                {
                position:[1.78,-1.84],
                "body": "Todos os EPI´s obrigatórios devem estar em bom estado de conservação e serem utilizados corretamente. As luvas de proteção devem estar colocadas nas duas mãos durante a tarefa de trabalho.",
                cover : require("./dialogImages/yJv5CeKA.png") 
            },
            { 
                position:[1.70,-0.11],
                "body": "Nos locais de trabalho é proibido ter garrafas de água junto de embalagens de produtos químicos, prevenindo assim a ingestão inadvertida de produtos químicos, devido à ausência de rotulagem ou má identificação do recipiente secundário.",
            },
                ]
                },
            ],
            7:[
                {
                ac:true,
                "game":"quiz",
                "MODULE": "Pre-Prefabrication",
                "Scenario": "Scenario 8",
                "ID": "corredor-79",
                "Area": "Circulação Mista",
                "Sectiontitle": "Circulação mista - precauções a serem tomadas",
                "startTime":null  ,
                "Question": "Durante a colocação de octobines nas estantes da pré-fabricação, que cuidados de segurança devem ser acautelados?\n\nIdentifique todas as respostas corretas.",
                ans:[ "O peão deve estar atento à manobra do condutor de empilhador não passando por trás do mesmo.",
                "O peão deve circular sempre na passagem de peões delimitada, esperando que o condutor de empilhador efetue a manobra até sair do local",
                "O condutor de epilhador deve buzinar sempre quando inicia a marcha no empilhador",
                "O condutor de empilhador deve circular de marcha atrás uma vez que a carga lhe tapa a visibilidade durante o transporte, e estar atento à circulação dos peões.",]
                ,ca:[0,1,2,3]}
            ],
            
        },
    
        Assembly : {
            1:[
                 
        {
            Sectiontitle:"Encontre todos os riscos na área",
     
        "MODULE": "Assembly",
     "Scenario": "Scenario 1",
     "ID": "prints-6",
     "Secção": "Prints (SRK, PK2, SPR, Workshop, M54, Micro K, Nano)",
     "Area": "Armazém prints",
     "game":"risk",
     "Kuula  (link)": "https:\/\/kuula.co\/post\/NFdQk\/collection\/7qbyZ"
      ,hotspots :[
      {
    position:[1.34,-1.92],
        "body": "Objetos mal posicionados podem cair e potenciar acidentes de trabalho."
    },
    {
    position:[1.98,-1.76],
    "body": "É proibido trepar ou colocar-se em cima de uma palete para alcançar um nivel superior da estante.",
    },
    {
    position:[2.10,-2.10],
    "body": "Paletes danificadas devem ser imediatamente substituidas por outras em bom estado de conservação.",
    },
    {
    position:[1.58,-1.42],
    "body": "Utilize sempre o corrimão para se agarrar e ajudar na descida de uma plataforma ou escada.",
    },
    {
    position:[1.82,-1.56],
    "body": "Todos os equipamentos de trabalho, tais como porta-paletes, escadas, escadotes, plataformas, entre outros, são sujeitos a verificações periódicas de segurança, pelo dept. da Manutenção, sendo colocado um selo de inspeção visivel, que ateste a sua validade.",
    },
    {
    position:[2.24,-0.72],
    "body": "Todos os produtos químicos devem estar acondicionados em bacias de retenção ou no armário de produtos químicos.",
    },
    ],
    },
                {
                    Sectiontitle:"Encontre todos os riscos na área",
             
                "MODULE": "Assembly",
             "Scenario": "Scenario 1",
             "ID": "prints-4",
             "Secção": "Prints (SRK, PK2, SPR, Workshop, M54, Micro K, Nano)",
             "Area": "Micro K",
             "game":"risk",
             "Kuula  (link)": "https:\/\/kuula.co\/post\/NFdQY\/collection\/7qbyZ"
              ,hotspots :[
              {
             position:[1.86,-1.18],
                "body": "É proibido trabalhar com máquinas de porta aberta em modo automático, se detetar alguma anomalia deve reportar ao seu chefe de equipa.",
             cover : require("./dialogImages/xzLEWvQw.jpeg")
            
                   
            },
              {
             position:[2.22,-4.10],
                "body": "Os quadros elétricos devem estar sempre fechados, eliminando assim o risco de eletrocussão. Só colaboradores autorizados (BA4) é que podem aceder.",
             cover : require("./dialogImages/5jDq77dc.jpeg")
             
                },
            {
             position:[2.10,-2.50], 
                
             "body": "Não olhar diretamente para o feixe do laser. Todos os módulos com laser têm proteções físicas para evitar o acesso livre ao feixe do laser.",
             cover : require("./dialogImages/3EWUI8BQ.jpeg")
             
                },
            {
             position:[1.50,-1.58],
                "body": "Todos os EPI´s obrigatórios devem ser usados corretamente e a sua não utilização é da responsabilidade do colaborador.",
             cover : require("./dialogImages/mJZGx-Es.jpeg")
             
                },
            {
             position:[1.66,0.10],
                "body": "Equipamentos de emergência ou de combate a incêndios devem estar sinalizados, desobstruídos e facilmente acessíveis, facilitando a sua utilização.",
             cover : require("./dialogImages/6lB6WbTA.jpeg")
             
                },
            {
        position:[1.22,1.06],
                "body": "Verifique todos os dispositivos de segurança da máquina quando começa a trabalhar, se detetar alguma anomalia deve reportar ao seu chefe de equipa\/supervisor.",
            },
            ]
            },
                {
                    Sectiontitle:"Encontre todos os riscos na área",
                    "MODULE": "Assembly",
                    "Scenario": "Scenario 1",
                    "ID": "prints-3",
                    "Secção": "Prints (SRK, PK2, SPR, Workshop, M54, Micro K, Nano)",
                    "Area": "SPR", 
                    "game":"risk",
                    "Kuula  (link)": "https:\/\/kuula.co\/post\/NFdQP\/collection\/7qbyZ",
                    hotspots :[{
    
        
                        position:[1.40,-1.50], //d
                        "body": "Sempre que exista contacto com superficies quentes, utilize o EPI apropriado à tarefa, tal como as luvas de proteção anti-calor.Visualize, na imagem, alguns exemplos de tipos de luvas de proteção anti-calor.",
                         cover :[
                            require("./dialogImages/KFiGp43A.jpeg"),
                            require("./dialogImages/LhjB81OA.jpeg"),
                            require("./dialogImages/up8Dx5Ow.png"),
                          ]
                    },
                       {
    
        
                        position:[1.66,-1.50], //dfff
                        "body": "Trabalhos em altura requerem a utilização de equipamentos auxiliares em bom estado de conservação e de acordo com as regras de segurança. Apoiar sempre os dois pés na base da plataforma ou no degrau do escadote, de forma a manter o equilibrio do corpo e não cair.",
                        cover : require("./dialogImages/F7Rc1Q1g.jpeg")
                        },
                       {
    
        
                        position:[2.36,-1.00], //daaaa
                        "body": "Mantenha sempre o seu local de trabalho arrumado de modo a circular em segurança, evitando quedas, tropeções ou escorregadelas.",
                       },
                       {
    
        
                        position:[1.84,-1.94], // dtttt
                        "body": "Movimentações manuais de cargas superiores a 20 kg devem ser realizadas por duas pessoas, para dividir o esforço fisico.",
                        cover : require("./dialogImages/DdxZjhq8.jpeg")
                      },
                       {
    
        
                        position:[1.78,-1.70], //dppppp
                        "body": "Utilize os equipamentos auxiliares disponiveis, tais como porta-paletes elevatório, para uma melhor postura ergonómica. Deve ajustar sempre o porta-paletes à altura a que vai colocar a carga na palete.",
                        cover : require("./dialogImages/nzYv8H30.jpeg")
                       },
                       {
    
        
                        position:[1.60,-1.70], //oooooo
                        "body": "Descer em segurança de um escadote ou escada é fundamental para não cair. Faça a descida em segurança, apoiando as duas mãos no escadote ou escada, e posicionando os pés corretamente nos degraus.",
                        cover : require("./dialogImages/91Y-Zq5g.jpeg")
                       },
                       {
    
        
                        position:[1.86,-1.50],
                        "body": "Material plástico quente (purga) deve ser colocado no contentor metálico (não inflamável) adequado.",
                        cover : require("./dialogImages/o38fpGaQ.jpeg")
                       },
                       {
    
        
                        position:[1.46,-1.86], //;;;;;
                        "body": "Mantenha sempre o seu local de trabalho limpo e arrumado, de modo a circular em segurança, objetos desarrumados potenciam acidentes de trabalho.",
                        cover :[
                            require("./dialogImages/kzrFXpQA.jpeg"),
                            require("./dialogImages/_YuhISo8.jpeg")
                            
                        ] 
                       },]},
           
            {
                ac:true, 
                "game":"quiz",
                "MODULE": "Assembly",
                "Scenario": "Scene 1",
                "ID": "circ-75",
                "Area": "Circulação de pessoas no exterior",
                "Sectiontitle": "Circulação exterior ",
                "startTime": null,
                "Question": "Quando circula a pé no exterior da fábrica por onde deve circular sempre o peão?\n\nIdentifique a resposta correta.",
                ans:[ "Deve circular pela estrada, mas junto da passadeira.",
                "Somente nos locais destinados\/assinalados à circulação de peões, como as passadeiras e caminhos pedestres.",
                 "Deve circular pela estrada, não existe risco de atropelamento.",
                "Deve circular somente na delimitação da passadeira de peões.",]
                ,ca:[1]},
               {
                "game":"quiz",
                "MODULE": "Assembly",
                "Scenario": "Scene 1",
                "ID": "covid-78",
                "Area": "COVID 19 - medidas preventivas",
                "Sectiontitle": "Medidas preventivas COVID 19 - Higiene das mãos",
                "startTime": 5,
                "Question": "Durante a pandemia Covid-19 a empresa implementou várias medidas de prevenção.\n\nIdentifique a resposta correta.",
                ans:[ "A cantina é um local de baixo risco de contágio para a covid-19, por isso não preciso de desinfetar as mãos à entrada.",
                "Já tive covid-19, já não preciso cumprir com as regras de prevenção.",
                 "Devo sempre desinfetar as mãos à entrada de qualquer espaço fechado que seja partilhado com mais pessoas a fim de prevenir contágios para a covid-19.",
                "O uso de máscara e distância de segurança é suficiente para a prevenção da covid-19 em locais fechados.",]
                ,ca:[2]},
                {
                    "game":"quiz",
                    "MODULE": "Assembly",
                    "Scenario": "Scene 1",
                    "ID": "prints-2",
                    "Area": "PK2",
                    "Sectiontitle": "Movimentação de bandas ou baldes de fio",
                    "startTime":6,
                    "Question": "Durante a movimentação da banda ou baldes de fio como deve proceder?\n\nIdentifique todas as respostas corretas.",
                    ans:[ "Fazer o transporte sozinho (bandas ou baldes)",
                    "Pedir ajuda a um colega e dividir o esforço",
                    "Utilizar as luvas anti-corte",
                    "Todas as anteriores estão corretas"]
                    ,ca:[1,2]},
                {
                    "game":"quiz",
                    "MODULE": "Assembly",
                    "Scenario": "Scene 1",
                    "ID": "prints-5",
                    "Area": "nano",
                    "Sectiontitle": "Ergonomia - prevenção de lesões musculo esqueléticas",
                    "startTime": 7 ,
                    "Question": "Na tarefa de colocação da tampa na baguete, como faria?\n\nIdentifique a resposta correta.",
                    ans:[ "Colaborador está sentado na cadeira com o conjunto de baguetes apoiado no chão e coloca as tampas.",
                    "Colaborador está sentado na cadeira com o conjunto de baguetes apoiado na palete e coloca as tampas.",
                    "Colaborador está sentado na cadeira com o carrinho de apoio à tarefa a colocar as tampas na baguete.",
                    "Todas as anteriores estão corretas"]
                    ,ca:[2]},
                    {
                    "game":"find",
                        "MODULE": "Assembly",
                    "Scenario": "Scene 1",
                    "ID": "prints-1",
                    "Area": "nano",
                    "Sectiontitle": "Equipamentos de proteção individual - Limpeza de banhos de estanho",
                    "Question": "Identifique todos os EPI´s que deve utilizar na manutenção do banho de estanho.\n\nSelecione todos as opções que estão corretas.",
                
                    ans:[
                        require("./assets/q1/a1.png"),
                        require("./assets/q1/a2.png"),
                        require("./assets/q1/a3.png"),
                        require("./assets/q1/a4.png"),
                        require("./assets/q1/a5.png"),
                        require("./assets/q1/a6.png"),
                        require("./assets/q1/a7.png"),
                        require("./assets/q1/a8.png"),
                        require("./assets/q1/a9.png"),
                        require("./assets/q1/a10.png"),
                        require("./assets/q1/a11.png"),
                    ],
                    ca:[0,1,2,3,4,10],
                    "startTime": 8 ,
                    "360 Vídeo or Image link": "https:\/\/www.dropbox.com\/s\/8wx8qahqqrad87e\/prints-1.mp4?dl=0"
                },
            ],
            2:[
                {
                    as:[27,36],
                    Sectiontitle:"Encontre todos os riscos na área",
                    
                    "MODULE": "Assembly",
                    "Scenario": "Scenario 2",
                    "ID": "hv-9",
                    "Secção": "HV (EVC, Minik Hv, Workshop, HCR)",
                    "Area": " Mini K HV                      ",
                    "game":"risk",
                    "Kuula  (link)": "https:\/\/kuula.co\/post\/NFgl9\/collection\/7qbyZ"
                    
                ,hotspots :[
                    {
                        position:[1.52,-3.22],
                        "body": "Sempre que utiliza produtos quimicos, ou trabalha com soldadura, verifique se a extração está funcional e corretamente posicionada no local de trabalho.",
                    },
                    {
                    position:[1.90,0.42],
                        "body": "Embalagens vazias contaminadas devem ser colocadas no contentor (vermelho) hermético com pedal.",
                    },
                    {
                    position:[1.76,-0.00],
                        "body": "Prensas manuais devem estar sempre fixas na bancada de trabalho. Verifique se todos os parafusos estão corretamente colocados. Caso detete a falta de parafusos ou que estes estão desapertados, deve alertar o seu chefe de equipa\/mecânico.",
                    },
                    {
                    position:[1.92,1.02],
                        "body": "Sempre que exista contacto com superficies quentes, utilize o EPI apropriado à tarefa, tal como as luvas de proteção anti-calor.Visualize, na imagem, alguns exemplos de tipos de luvas de proteção anti-calor.",
                        cover:require("./dialogImages/hv-9-4.PNG")
                    },
                    {
                    position:[2.16,-1.90],
                        "body": "Os tapetes anti-fadiga ajudam a prevenir dores na região lombar e desconforto\/cansaço nas pernas e pés, com o passar de longos períodos de trabalho em pé, especialmente em pisos frios e duros. Trabalhe em pé de forma saudável e segura, em qualquer ambiente.",
                    },
                    {
                    position:[1.70,-2.62],
                        "body": "Sempre que efetua tarefas manuais, utilize as luvas de proteção, de acordo com a política das luvas e com o risco a que está exposto, tendo em conta a natureza da tarefa que vai realizar.",
                        cover:require("./dialogImages/hv-9-6.jpg")
                    },
                ]    
                },
                {
                        Sectiontitle:"Encontre todos os riscos na área",
                 
                    "MODULE": "Assembly",
                 "Scenario": "Scenario 2",
                 "ID": "hv-10",
                 "Secção": "HV (EVC, Minik Hv, Workshop, HCR)",
                 "Area": "HCR ",
                 "game":"risk",
                 "Kuula  (link)": "https:\/\/kuula.co\/post\/NFglH\/collection\/7qbyZ"
                 ,hotspots :[
                     {
                      position: [2.28,-1.58],
                        "body": "Utilize o apoio de pés de forma correta, melhorando assim a postura ergonómica. Não deve estar sentado de pernas cruzadas, mas sim, fazendo um ângulo de 90º com o joelho (parte inferior e parte superior da perna).",
                 cover : require("./dialogImages/5sJMHFT4.jpeg")
                            },
                  {
                   position: [1.74,1.08],
                    "body": "Sempre que efetua tarefas manuais, utilize as luvas de proteção. Troque de luvas, de acordo com a natureza da tarefa que está a desempenhar no momento e com o risco a que está exposto no seu local de trabalho.",
                 cover : require("./dialogImages/eh6uRA-4.jpeg")
                         },
                  {
                   position: [1.78,-0.50],
                    "body": "Utilize os equipamentos auxiliares disponiveis, tais como porta-paletes elevatório, para uma melhor postura ergonómica. Deve ajustar sempre o porta- paletes à altura a que vai colocar a carga na palete.",
                 cover : require("./dialogImages/Gq-_XKrc.jpeg")
                         },
                  {
                   position: [1.68,-1.54],
                    "body": "Todos os EPI´s obrigatórios devem ser usados corretamente e a sua não utilização é da responsabilidade do colaborador. Caso a situação seja recorrente, pode ser alvo de um processo disciplinar.",
                 cover : require("./dialogImages/0MVicw2w.jpeg")
                         },
                  {
                   position: [1.86,-1.70],
                    "body": "Para todas as tarefas manuais onde exista risco de corte com arestas vivas, utilize sempre as luvas de proteção anti-corte para prevenir pequenos ferimentos.",
                    cover :[
                        require("./dialogImages/hCa3dcMg.jpeg"),
                        require("./dialogImages/Aa5VggJw.jpeg"), 
                      ]
                         },
                ]
                },
                {
                    Sectiontitle:"Encontre todos os riscos na área",
             
                "MODULE": "Assembly",
             "Scenario": "Scenario 2",
             "ID": "hv-8",
             "Secção": "HV (EVC, Minik Hv, Workshop, HCR)",
             "Area": "EVC",
             "game":"risk",
             "Kuula  (link)": "https:\/\/kuula.co\/post\/NFdQv\/collection\/7qbyZ"
             ,hotspots :[
                 {
                  position:[1.82,-2.30],
                    "body": "Sempre que transportar ou movimentar materiais de um sítio para outro, tenha em atenção que os deve colocar nos locais assinalados para não obstruir equipamentos de trabalho ou corredores de passagem.",
             cover : require("./dialogImages/3xdOTzNU.jpeg")
                  
                      },
              {
               position:[1.74,0.22],
                "body": "Sempre que exista contacto com superficies quentes, utilize o EPI apropriado à tarefa, tal como as luvas de proteção anti-calor. Na soldadura por indução\/resistência deve utilizar as luvas 11-423.",
                cover :[
                    require("./dialogImages/6aJRs8jg.jpeg"), 
                    require("./dialogImages/eZieQuiw.png"),
                  ]
                     },
              {
                position:[1.80,-3.10],
                "body": "Sempre que utiliza x-atos, nível 3, 4 ou 5, bem como ferramentas manuais, deve utilizar as luvas de proteção anti-corte.",
                cover: require("./dialogImages/hv-8-5hyflex-11-724.jpg")
            },
              {
               position:[1.64,-3.16],
                "body": "Prensas manuais devem estar sempre fixas na bancada de trabalho, evitando assim a sua possível queda quando pressiona o manipulo\/alavanca.",
             cover : require("./dialogImages/uhtLXiIc.jpeg")
               
                  },
              {
               position:[2.32,-1.38],
                "body": "Utilize os apoios de pés corretamente, melhorando assim a postura ergonómica.",
             cover : [
                require("./dialogImages/UExQl8Qk.jpeg"),
            ]
                     },
            ]
            },
                {
                    "game":"quiz",
                "MODULE": "Assembly",
                "Scenario": "Scenario 2",
                "ID": "hv-7",
                "Area": "EVC",
                "Sectiontitle": "Ergonomia - prevenção de lesões musculo esqueléticas",
                "startTime": 3 ,
                "Question": "O que deve fazer quando têm dor muscular? Nesta situação o que faria?\n\nIdentifique todas as respostas corretas.",
                ans:[ "Continuo a trabalhar e mais tarde vou ao médico de família\/hospital com dores musculares.",
                "Amanhã não venho trabalhar, estou com dores musculares, não fiz a pausa quando devia, ou rotatividade do posto de trabalho.",
                "Paro de imediato e vou pedir à minha colega para trocar comigo de lugar cumprindo o plano de rotatividade.", 
                "Paro para fazer uma pausa, alongar, hidratar e retomar a tarefa."]
                ,ca:[2,3]},
            
            ],
            3:[
                {
                    "game":"quiz",
                    "MODULE": "Assembly",
                    "Scenario": "Scenario 3",
                    "ID": "BDR-11",
                    "Area": "BDR",
                    "Sectiontitle": "Perigos vários no local de trabalho",
                    "startTime": 20 ,
                    "Question": "No seu local de trabalho, quais as regras de segurança ou procedimentos de trabalho que deve ter em conta?\n\nIdentifique todas as respostas corretas.",
                    ans:[ "Utilizar os EPI´s, tais como, luvas de proteção (anti-calor, anti-corte, para produtos quimicos, outras), óculos de proteção, protetores auditivos e sapatos de proteção.",
                    "Estar atento à sinalética de segurança, saber o que significa e cuidados a ter em conta fase aos riscos que possa estar exposto.",
                    "Utilizar equipamentos\/acessórios para reduzir o risco ergonómico, tais como, tapete anti-fadiga.",
                    "Manter o seu local de trabalho limpo e arrumado",]
                    ,ca:[0,1,2,3]},
            
            ],
            4:[
                {
                Sectiontitle:"Encontre todos os riscos na área",
         
            "MODULE": "Assembly",
         "Scenario": "Scenario 4",
         "ID": "semi-16",
         "Secção": "Semiautomatics (SRF, SL1, Workshop, Mini B)",
         "Area": "Mini B",
         "game":"risk",
         "Kuula  (link)": "https:\/\/kuula.co\/post\/NFgbR\/collection\/7qbyZ"
          ,hotspots :[
          {
    position:[4.54,4.78],
            "body": "É proibido trabalharem dois ou mais colaboradores em simutâneo numa máquina, em operação normal de produção; com exceção de atividades de manutenção em que a máquina está desligada\/bloqueada com sistema LOTO. ",
          },{
    position:[2.14,-1.32],
            "body": "Derrames de óleo na montagem devem ser contidos com os panos\/almofadas\/chouriços absorventes existentes no kit anti-derrames.",
    },
    {
    position:[4.16,7.56],
        "body": "Quando utiliza escadas ou degraus, mantenha sempre os dois pés apoiados ao mesmo nível de forma a manter o equilibrio do corpo, facilitando a elevação do material para abastecer os equipamentos de trabalho de forma correta.",
    },
    {
    position:[1.98,-0.46],
        "body": "Panos contaminados devem ser colocados no contentor hermético dos resíduos contaminados (contentor vermelho).",
    },
        {
         position:[4.26,5.28],
            "body": "Sempre que utiliza produtos químicos deve utilizar as luvas de proteção química (nitrilo), de acordo com a política das luvas.",
         cover : require("./dialogImages/1kIzLzjQ.jpeg")
          
         },
        ]
        },
                {
                    as:[12],
                    Sectiontitle:"Encontre todos os riscos na área",
             
                "MODULE": "Assembly",
             "Scenario": "Scenario 4",
             "ID": "semi-14",
             "Secção": "Semiautomatics (SRF, SL1, Workshop, Mini B)",
             "Area": "SL1",
             "game":"risk",
             "Kuula  (link)": "https:\/\/kuula.co\/post\/NFglT\/collection\/7qbyZ"
              ,hotspots :[
              {
        position:[4.64,3.86],
                "body": "Sempre que abastece o vibrador, mantenha uma postura correta na movimentação da carga.",
        }, {
        position:[2.24,0.28],
            "body": "Derrames de óleo na montagem devem ser contidos com os panos\/almofadas\/chouriços absorventes existentes no kit anti-derrames.",
        },
        {
        position:[4.42,1.70],
            "body": "Equipamentos de trabalho, por exemplo, microscópios, lupas, devem ser desligados quando não estão em utilização. \"Seja um colaborador verde\". ",
        },
        {
        position:[4.34,1.40],
            "body": "Os tapetes anti-fadiga ajudam a prevenir dores na região lombar e desconforto\/cansaço nas pernas e pés, com o passar de longos períodos de trabalho em pé, especialmente em pisos frios e duros. Trabalhe em pé de forma saudável e segura, em qualquer ambiente.",
        },
            {
             position:[4.44,0.94],
                "body": "Mantenha sempre o seu local de trabalho arrumado de modo a circular em segurança, evitando quedas, tropeções ou escorregadelas.",
             cover : require("./dialogImages/4H2HRh-A.jpeg")
                 },
            {
        position:[4.78,1.36],
                "body": "Todos os EPI´s obrigatórios devem estar em bom estado de conservação e serem utilizados corretamente. A não utilização dos mesmos pode ser sujeita a processo disciplinar.",
        },
        {
        position:[4.58,1.18],
            "body": "Quando efetuar movimentações manuais de cargas, deve aproximar a carga do corpo (sem ter os braços esticados), fletir as pernas, manter as costas direitas, e colocar a caixa na palete.",
        },
            ]
            },
           {
                Sectiontitle:"Encontre todos os riscos na área",
         
            "MODULE": "Assembly",
         "Scenario": "Scenario 4",
         "ID": "semi-13",
         "Secção": "Semiautomatics (SRF, SL1, Workshop, Mini B)",
         "Area": "SRF",
         "game":"risk",
         "Kuula  (link)": "https:\/\/kuula.co\/post\/NFgld\/collection\/7qbyZ"
          ,hotspots :[
    {
    position:[1.84,-0.80],
        "body": "Utilize os equipamentos auxiliares disponiveis, tais como porta-paletes elevatório, para uma melhor postura ergonómica. Deve ajustar sempre o porta- paletes à altura a que vai colocar a carga na palete.",
    },
    {
    position:[1.88,-0.98],
        "body": "Deve ajustar sempre a cadeira de acordo com o seu posto de trabalho (assento e zona lombar). Utilize o apoio de pés de forma correta, melhorando assim a postura ergonómica.",
    },
          {
           position:[1.64,0.20],
            "body": "Todas as saídas de emergência devem estar desobstruídas e de livre acesso, facilitando a sua circulação em caso de emergência.",
         cover : require("./dialogImages/kIXsI24A.jpeg")            
        },
          {
    position:[1.74,0.34],
            "body": "Equipamentos de emergência ou de combate a incêndios devem estar fixos corretamente (na parede ou no suporte próprio). Utilize o suporte próprio da mangueira para a colocar corretamente no sítio.",
            cover : require("./dialogImages/semi-13-4.jpg")
 
        },
    {
    position:[1.74,-1.24],
        "body": "Deve sempre manter os caminhos desobstruidos. Coloque os carrinhos nos locais designados (marcações 5S) para o efeito.",
    },
    {
    position:[2.32,-1.60],
        "body": "Os tapetes anti-fadiga ajudam a prevenir dores na região lombar e desconforto\/cansaço nas pernas e pés, com o passar de longos períodos de trabalho em pé, especialmente em pisos frios e duros. Trabalhe em pé de forma saudável e segura, em qualquer ambiente.",
    },
          {
           position:[1.88,-1.72],
            "body": "Todos os EPI´s obrigatórios devem estar em bom estado de conservação e serem utilizados corretamente. As luvas de proteção devem estar colocadas nas duas mãos durante a tarefa de trabalho.",
         cover : require("./dialogImages/KHPOLh-g.jpeg")
                }
        ]
        },
                {
                    Sectiontitle:"Encontre todos os riscos na área",
             
                "MODULE": "Assembly",
             "Scenario": "Scenario 4",
             "ID": "semi-15",
             "Secção": "Semiautomatics (SRF, SL1, Workshop, Mini B)",
             "Area": "Oficina",
             "game":"risk", 
             "Kuula  (link)": "https:\/\/kuula.co\/post\/NFgbg\/collection\/7qbyZ"
              ,hotspots :[
              {
            position:[1.96,-1.30],
                "body": "Para todas as tarefas manuais onde exista risco de corte com arestas vivas, utilize sempre as luvas de proteção anti-corte para prevenir pequenos ferimentos.",
                cover :[
                    require("./dialogImages/nEZtfQNA.jpeg"),
                    require("./dialogImages/CMOaTwoA.jpeg"), 
                  ]
              
            }, {
            position:[1.74,-3.60],
            "body": "Durante a utilização de equipamentos com elementos rotativos é proibida a utilização de luvas de proteção, podendo dar-se o caso de ficar aprisionado com a luva de proteção e causando entrapamento\/entalamento da mão\/dedo. ",
             cover : require("./dialogImages/aLtC-bi0.jpeg")
                 },
            {
             position:[1.76,-0.10],
                "body": "Equipamentos de emergência ou de combate a incêndios devem estar sinalizados, desobstruídos e facilmente acessíveis, facilitando a sua utilização.",
             cover : require("./dialogImages/Ly8deQVc.jpeg")
                 },
            {
             position:[1.88,-2.00],
                "body": "É expressamente proibido utilizar a pistola de ar comprimido para limpar o fato\/roupa ou outras utilizações que não sejam inerentes à tarefa do posto de trabalho.",
             cover : require("./dialogImages/0MzCemck.jpeg")
                 },
            {
             position:[1.96,-0.76],
                "body": "Nos locais de trabalho é proibido ter garrafas de água junto de embalagens de produtos químicos, prevenindo assim a ingestão inadvertida de produtos químicos, devido à ausência de rotulagem ou má identificação do recipiente secundário.",
            },
            {
             position:[1.74,-1.20],
                "body": "É expressamente proibido comer no local de trabalho.",
             cover :[
                require("./dialogImages/semi-15-6 2.jpg"), 
              ]
            },
            {
            position:[1.30,0.90],
                "body": "Os armários de produtos químicos devem estar sempre fechados e a sua chave com o responsável da área de trabalho. Caso necessite de acesso, solicite a chave ao seu chefe de equipa. Deste modo, evitamos que colaboradores de outras áreas tenham acesso a produtos químicos, e riscos associados à sua manipulação, que desconhecem.",
            },
            {
            position:[1.94,-0.94],
            "body": "Esteja atento aos pictogramas de perigo presentes na rotulagem dos produtos quimicos. Caso o pictograma seja laranja e preto (antiga simbologia de perigo) deve informar o seu chefe de equipa\/supervisor e retirá-lo de utilização.",
            },
            ]
            },
                {
                    "game":"quiz",
                    "MODULE": "Assembly",
                    "Scenario": "Scenario 4",
                    "ID": "minis-25",
                    "Area": "SRFA",
                    "Sectiontitle": "Produtos Químicos - cuidados a ter na limpeza e manutenção de leibingers",
                    "startTime":  5,
                    "Question": "Durante a manipulação de produtos quimicos, o que devo ter em conta?\n\nIdentifique todas as respostas corretas.",
                    ans:[ "Manipular o produto quimico com luvas de proteção quimicas.",
                    "Depois de utilizar o produto quimico, devo guardá-lo no respetivo armário de produtos quimicos. ",
                    "Colocar os residuos contaminados, por exemplo, as luvas descartáveis usadas e embalagens contaminadas vazias no contentor respetivo.",
                    "Trancar o armário de produtos quimicos e entregar a chave ao meu chefe de equipa.",]
                    ,ca:[0,1,2,3]},
                
            ],
             5:[
                {
                    "game":"find",
                    "MODULE": "Assembly",
                    "Scenario": "Scenario 5",
                    "ID": "sensores-17",
                    "Area": "P449",
                    "Sectiontitle": "Perigos vários no local de trabalho",
                    "Question": "Indique a sinalização de segurança que visualiza neste local de trabalho.\n\nSelecione todos as opções que estão corretas.",
                    ans:[
                    require("./assets/q2/a1.png"),
                    require("./assets/q2/a2.png"),
                    require("./assets/q2/a3.png"),
                    require("./assets/q2/a4.png"),
                    require("./assets/q2/a5.png"),
                    require("./assets/q2/a6.png"),
                    require("./assets/q2/a7.png"),
                    require("./assets/q2/a8.png"),
                    require("./assets/q2/a9.png"),
                    require("./assets/q2/a10.png"),
                    require("./assets/q2/a11.png"),
                    require("./assets/q2/a12.png"),
                    require("./assets/q2/a13.png"),
                    ],   
                    ca:[0,1,8,9,10,11,12],
                    "startTime": 20,
                    "360 Vídeo or Image link": "https:\/\/www.dropbox.com\/s\/ssgtzszdyu53u25\/sensores-17.png?dl=0"
                },
        
            ],
            6:[
                {
                    Sectiontitle:"Encontre todos os riscos na área",
             
                "MODULE": "Assembly",
             "Scenario": "Scenario 6",
             "ID": "sensores-21-pt2",
             "Secção": "Sensores (P449 e P623, PLCD)",
             "Area": "PLCD",
             "game":"risk",
             "Kuula  (link)": "https:\/\/kuula.co\/post\/NFgbV\/collection\/7qbyZ"
              ,hotspots :[
                {
                    position:[1.66,4.72],
                    "body": "Durante o transporte/manipulação dos WT's, deve agarrar nas pegas metálicas nos cantos dos WT's para uma melhor manipulação, evitando assim a sua queda.",            
                }, {
                    position:[1.66,-0.20],
                    "body": "Movimentação manual de cargas superiores a 20 kg deve ser realizada por duas pessoas, para dividir o esforço fisico, até que a bobine do cabo seja colocada na pá elevatória da máquina.",
                },
                {
                    position:[1.56,1.52],
                    "body": "Os equipamentos de inspeção visual com radiação não ionizante (ex: lupa) devem ser ajustados à altura de cada colaborador, permanecendo abaixo do nível dos olhos, evitando a exposição à radiação ótica.",
                    cover : require("./dialogImages/qn2KtVTw.jpeg")
                 }]
                },
                {
                Sectiontitle:"Encontre todos os riscos na área",
         
            "MODULE": "Assembly",
         "Scenario": "Scenario 6",
         "ID": "sensores-21-pt1",
         "Secção": "Sensores (P449 e P623, PLCD)",
         "Area": "PLCD",
         "game":"risk",
         "Kuula  (link)": "https:\/\/kuula.co\/post\/NFgbV\/collection\/7qbyZ"
          ,hotspots :[
          {
            position:[1.74,0.74],
            "body": "Sempre que exista contacto com superficies quentes, utilize o EPI apropriado à tarefa, tal como as luvas de proteção anti-calor. Na imagem visualize alguns exemplos de tipos de luvas de proteção anti- calor.",
         cover : require("./dialogImages/_-_lo74c.jpeg")
            
              }, {
         position:[2.24,-2.52],
                "body": "Os tapetes anti-fadiga ajudam a prevenir dores na região lombar e desconforto\/cansaço nas pernas e pés, com o passar de longos períodos de trabalho em pé, especialmente em pisos frios e duros. Trabalhe em pé de forma saudável e segura, em qualquer ambiente.",
         cover : require("./dialogImages/5elDl_mc.jpeg")
             },
        {
         position:[1.94,-0.40],
            "body": "Não deve utilizar a barra inferior da bancada de trabalho para apoiar os pés. Utilize o apoio de pés de forma correta para uma melhor postura ergonómica. Não deve estar sentado de pernas cruzadas, mas sim, fazendo ângulo de 90º com o joelho (parte inferior e parte superior da perna).",
         cover : require("./dialogImages/Two3bVKw.jpeg")
             }]},
                {
                    ac:true,
                    "game":"quiz",
                    "MODULE": "Assembly",
                    "Scenario": "Scenario 6",
                    "ID": "minis-18",
                    "Area": "MINI B",
                    "Sectiontitle": "Produtos Químicos - cuidados a ter na sua utilização",
                    "startTime": null ,
                    "Question": "Durante a limpeza do chão onde o local está interdito de passagem de colaboradores, o que deve fazer?\n\nIdentifique a resposta correta.",
                    ans:[ "Respeitar a sinalética e não circular no local, podendo escorregar e cair.",
                    "Pedir autorização para passar, e circular com cuidado pelo local",
                    "Circular no local e não respeitar a sinalética.",
                    "Nenhuma das respostas anteriores.",]
                    ,ca:[0]},
            ],
            7:[ 
                {
                    as:[5],
    
                        Sectiontitle:"Encontre todos os riscos na área",
                 
                    "MODULE": "Assembly",
                 "Scenario": "Scenario 7",
                 "ID": "minis-24",
                 "Secção": "Minis (IP55, Fluxplate, SRFA, Workshop)",
                 "Area": "SRFA",
                 "game":"risk",
                 "Kuula  (link)": "https:\/\/kuula.co\/post\/NFgVj\/collection\/7qbyZ"
                  ,hotspots :[
                  {
            position:[1.80,-0.00],
                    "body": "Para a movimentação de baldes de fio, com aproximadamente 20 kg, deve sempre solicitar a ajuda de um colega para dividir o esforço físico existente na movimentação, de modo a prevenir futuras lesões musculoesqueléticas.",
            
                  },  {
            position:[2.18,-0.46],
                    "body": "A organização no local de trabalho é fundamental. Deve manter os corredores livres, prevenindo tropeções e escorregadelas, queda de objetos e, principalmente acidentes graves. Aplique a ferramenta 5S.",
                   },
                   {
                    position:[2.04,-0.78],
                    "body": "Para todas as tarefas manuais onde exista risco de corte com arestas vivas, utilize sempre as luvas de proteção anti-corte para prevenir pequenos ferimentos.",
                 cover : require("./dialogImages/AEqcmz9Q.jpeg")
                           },
                   {
            position:[1.56,-3.28],
                    "body": "Os quadros elétricos devem estar sempre fechados, eliminando assim o risco de eletrocussão. Só colaboradores autorizados (BA4) é que podem aceder.",
            },
            {
            position:[1.62,-3.96],
                "body": "Durante a movimentação de cargas com equipamentos de trabalho, (por ex: porta-paletes manual) em zonas de fraca visibilidade nos corredores, deve olhar para os espelhos parabólicos suspensos no teto, para evitar chocar contra peões ou outros equipamentos de trabalho em circulação.",
            },
            {
            position:[1.84,-0.88],
                "body": "Em locais onde o pavimento se encontra danificado deve redobrar a atenção enquanto circula pela área, evitando possíveis quedas, tropeções ou escorregadelas.",
            },
            {
            position:[2.40,-0.28],
                "body": "Os equipamentos de trabalho danificados não devem ser utilizados nos locais de trabalho. Informe o seu chefe de equipa\/supervisor e retire o equipamento de utilização.",
            },
                ]
                },{
                    as:[92],
    
                Sectiontitle:"Encontre todos os riscos na área",
         
            "MODULE": "Assembly",
         "Scenario": "Scenario 7",
         "ID": "minis-22",
         "Secção": "Minis (IP55, Fluxplate, SRFA, Workshop)",
         "Area": "IP55",
         "game":"risk",
         "Kuula  (link)": "https:\/\/kuula.co\/post\/NFgVR\/collection\/7qbyZ"
          ,hotspots :[
          {
    position:[1.76,-2.38],
            "body": "Sempre que abastece o vibrador, mantenha uma postura correta na movimentação da carga.",
    
          },{
    position:[1.66,-1.50],
            "body": "Os produtos químicos devem estar armazenados em armário dedicado. Verifique se o sistema de extração do armário está funcional (luz verde).",
           },
           {
            position:[1.64,-2.20],
            "body": "Sempre que exista contacto com superficies quentes, utilize o EPI apropriado à tarefa, tal como as luvas de proteção anti-calor. Na imagem visualize alguns exemplos de tipos de luvas de proteção anti- calor.",
         cover : require("./dialogImages/tQO_wyaw.jpeg")
                   },
           {
    position:[2.10,0.54],
            "body": "A organização no local de trabalho é fundamental. Deve manter os corredores livres, prevenindo tropeções e escorregadelas, queda de objetos e, principalmente acidentes graves. Aplique a ferramenta 5S.",
    },
    {
    position:[2.20,-0.54],
        "body": "As ferramentas de trabalho devem estar acondicionadas nos carrinhos de ferramentas\/estojos de mecânicos apropriados.",
    },
        ]
        },
            {
                "game":"quiz",
                "MODULE": "Assembly",
                "Scenario": "Scenario 7",
                "ID": "minis-23",
                "Area": "SRFA",
                "Sectiontitle": "Equipamentos de proteção individual - EPI´s",
                "startTime": 4 ,
                "Question": "Verifico que as minhas luvas de proteção não estão em bom estado. Nesta situação o que faria?\n\nIdentifique a resposta correta.",
                ans:[ "Continuo a trabalhar com as minhas luvas danificadas\/rotas, preciso terminar o trabalho rápido",
                "Peço ao meu chefe de equipa as luvas de proteção que preciso para continuar a tarefa.",
                "Dirijo-me à máquina de EPI´s para retirar as luvas que estão disponiveis para o meu sector\/tarefa a desempenhar.",
                "Peço emprestado ao meu colega, tem umas a mais.",]
                ,ca:[2]},
            
            ],
            8:[
                {
                    as:[13],
    
                        Sectiontitle:"Encontre todos os riscos na área",
                 
                    "MODULE": "Assembly",
                 "Scenario": "Scenario 8",
                 "ID": "micros-29",
                 "Secção": "Micros (Micro A, Micro 3, Workshop)",
                 "Area": "Micro 3",
                 "game":"risk",
                 "Kuula  (link)": "https:\/\/kuula.co\/post\/NFgV2\/collection\/7qbyZ"
                  ,hotspots :[
                  {
            position:[1.88,-1.74],
                    "body": "Quando utiliza degraus, mantenha sempre os dois pés apoiados ao mesmo nível de forma a manter o equilibrio do corpo, prevenindo possíveis quedas em desnível.",
            
            }, {
            position:[1.86,-5.02],
                "body": "Utilize o apoio de pés de forma correta, melhorando assim a postura ergonómica. Não deve estar sentado de pernas cruzadas, mas sim, fazendo um ângulo de 90º com o joelho (parte inferior e parte superior da perna).",
            },
            {
            position:[1.68,-5.14],
                "body": "Todos os EPI´s obrigatórios devem ser usados corretamente e a sua não utilização é da responsabilidade do colaborador. Caso a situação seja recorrente, pode ser alvo de um processo disciplinar.",
            },
            {
            position:[1.66,-2.06],
                "body": "Para todas as tarefas manuais onde exista risco de corte com arestas vivas, utilize sempre as luvas de proteção anti-corte para prevenir pequenos ferimentos.",
                cover : require("./dialogImages/z46jd7Lg.jpeg")
            
            },
            {
            position:[1.82,-2.86],
                "body": "Equipamentos de trabalho, por exemplo, microscópios, lupas, devem ser desligados quando não estão em utilização. \"Seja um colaborador verde\". ",
         
            },
                ]
                },
               
                {
                    "game":"quiz",
                    "MODULE": "Assembly",
                    "Scenario": "Scenario 8",
                    "ID": "micros-26",
                    "Area": "Micro A",
                    "Sectiontitle": "Manutenção de máquinas - proteções de segurança e cuidados a ter",
                    "startTime": 33 ,
                    "Question": "Durante uma reparação em que seja necessário retirar dispositivos de segurança e\/ou partes moveis da máquina o que deverá fazer?\n\nIdentifique todas as respostas corretas.",
                    ans:[ "Sinalizar a máquina com o sistema de segurança LOTO",
                    "Depois de terminada a reparação, ter o cuidado de colocar corretamente a proteção na máquina e fixar com 1 parafuso.",
                    "Utilizar as luvas de proteção anti-corte.",
                    "Depois de terminada a reparação, ter o cuidado de colocar corretamente a proteção na máquina e fixar com os 4 parafusos.",]   
                    ,ca:[0,2,3]},
                {
                    "game":"quiz",
                    "MODULE": "Assembly",
                    "Scenario": "Scenario 8",
                    "ID": "micros-27",
                    "Area": "Micro A",
                    "Sectiontitle": "Residuos perigosos - destino final na linha",
                    "startTime": 22 ,
                    "Question": "Onde coloca os trapos e as embalagens contaminados vazias depois de utilizar um produto químico?\n\nIdentifique a resposta correta.",
                    ans:[ "Deixar no local da manutenção, mais tarde a senhora da limpeza recolhe os residuos.",
                    "Deitar o trapo sujo no lixo geral, tal como a embalagem vazia.",
                    "colocar o trapo contaminado no recipiente no contentor dos trapos contamidados e a embalagem no contentor das embalagens contaminadas",
                    "colocar o trapo contaminado no recipiente no contentor dos trapos contamidados e a embalagem contaminada dentro do armário de PQ.",]
                    ,ca:[2]},
                {
                    ac:true,
                    "game":"quiz",
                    "MODULE": "Assembly",
                    "Scenario": "Scenario 8",
                    "ID": "micros-28",
                    "Area": "Micro A",
                    "Sectiontitle": "Manutenção de máquinas - proteções de segurança e cuidados a ter",
                    "startTime": null ,
                    "Question": "Durante uma manutenção a uma máquina que cuidados de segurança deve ter?\n\nIdentifique a resposta errada.",
                    ans:[ "utilizar as luvas de proteção anti-corte",
                    "colocar a sinalética \" Máquina em manutenção \".",
                    "Estar sozinho a acionar a máquina durante a manutenção\/verificação.",
                    "O colaborador que efetuou a manutenção deve aguardar que o colaborador da máquina chegue para validar e fechar a porta, mas entretanto pode ir produzindo.",]
                    ,ca:[3],invert:true},
                {
                    "game":"quiz",
                    "MODULE": "Assembly",
                    "Scenario": "Scenario 8",
                    "ID": "micros-30",
                    "Area": "Micro 3",
                    "Sectiontitle": "Perigos vários no local de trabalho",
                    "startTime":  39,
                    "Question": "Durante a tarefa de manutenção do banho de estanho, que procedimentos de segurança deve ter em conta?\n\nIdentifique todas as respostas corretas.",
                    ans:[ "Utilizar todos os EPI´s necessários à tarefa, tais como, capacete e viseira, sapatos de proteção, avental de proteção, luvas de proteção anti-calor.",
                    "colocar a sinalética \"Máquina em manutenção\".",
                    "Colocar os residuos do estanho no recipiente do carrinho de manutenção de apoio à tarefa.",
                    "Todas as anteriores",]
                    ,ca:[0,1]},
                    
            ],
            9:[
            {
            Sectiontitle:"Encontre todos os riscos na área",
     
        "MODULE": "Assembly",
     "Scenario": "Scenario 9",
     "ID": "empac-45",
     "Secção": "Packaging",
     "Area": "Empacotamento",
     "game":"risk",
     "Kuula  (link)": "https:\/\/kuula.co\/post\/NFCfF\/collection\/7qbyZ"
      ,hotspots :[
      {
        position:[4.64,15.68],
        "body": "Para todas as tarefas manuais onde exista risco de corte com arestas vivas, utilize sempre as luvas de proteção anti-corte para prevenir pequenos ferimentos.",
        cover :[
            require("./dialogImages/f-he7xqw.jpeg"), 
            require("./dialogImages/_ybBrj3w.jpeg"),
          ]
    
      }, {
        position: [4.18,11.36],
        "body": "A organização no local de trabalho é fundamental. Deve manter os corredores livres, prevenindo tropeções e escorregadelas, queda de objetos e, principalmente acidentes graves. Aplique a ferramenta 5S.",
     cover : require("./dialogImages/lZ9IYhsc.jpeg")
               },
       {
        position: [4.52,15.08],
        "body": "Utilize os equipamentos de trabalho de forma correta, ajustando a bancada elevatória à sua altura, para melhorar a sua postura ergonómica.",
     cover : require("./dialogImages/3kJrqggs.jpeg")
               },
       {
    position: [4.40,14.16],
        "body": "Keep circulation routes unobstructed and clean, thus allowing safe circulation in the workspace.  ",
       },
       {
        position: [4.60,14.34],
        "body": "A organização no local de trabalho é fundamental. Deve manter os corredores livres, prevenindo tropeções e escorregadelas, queda de objetos e, principalmente acidentes graves. Aplique a ferramenta 5S.",
     cover : require("./dialogImages/uHfw7FU0.jpeg")
               },
       {
        position: [4.22,14.26],
        "body": "Utilize os equipamentos auxiliares de transporte de cargas disponíveis no seu posto de trabalho, de modo a evitar posturas ergonómicas desadequadas, ou possibilidade de quedas ao mesmo nível.",
     cover : require("./dialogImages/PqH_IvxE.jpeg")
               },
       {
        position: [4.30,14.78],
        "body": "Quando efetuar movimentações manuais de cargas, deve aproximar a carga do corpo (sem ter os braços esticados), fletir as pernas, manter as costas direitas, e colocar\/retirar a caixa na palete. Colocar o pé em cima da palete pode fazer com que possa cair\/escorregar.",
     cover : require("./dialogImages/y8bpqJqk.jpeg")
               },
      ]
    },
                {
                    "game":"quiz",
                    "MODULE": "Assembly",
                    "Scenario": "Scenario 9",
                    "ID": "Empac-46",
                    "Area": "Lavagem das caixas",
                    "Sectiontitle": "Perigo de quedas ao mesmo nível",
                    "startTime": 11 ,
                    "Question": "Estou a trabalhar no meu local de trabalho e deteto uma poça de àgua no chão. O que devo fazer de imediato?\n\nIdentifique todas as respostas corretas.",
                    ans:[ "Sinalizar o local para evitar possiveis quedas, escorregadelas no chão.",
                    "Alertar o meu chefe de equipa e\/ou a colaboradora da Isporeco para efetuar a limpeza do chão.",
                    "Se tenho os meios necessários para efetuar a limpeza (esfregona, balde) devo efetuar a limpeza do chão.",
                    "Como já está no final do turno, não preciso limpar o chão, entretanto seca.",],
                    ca:[0,1,2]
                },
            ],
            10:[
                {
                    "game":"find",
                    "MODULE": "Assembly",
                    "Scenario": "Scenario 10",
                    "ID": "eng.pro-47",
                    "Area": "Eng. Product - Lab Eng",
                    "Sectiontitle": "Perigos vários no local de trabalho - Sinalização",
                    "Question": "Indique toda a sinalização de segurança que visualiza neste local de trabalho assim como os EPI´s obrigatórios.\n\nSelecione todos as opções que estão corretas.",
                    ans:[
                        require("./assets/q3/1.png"),
                        require("./assets/q3/2.png"),
                        require("./assets/q3/3.png"),
                        require("./assets/q3/4.png"),
                        require("./assets/q3/5.png"),
                        require("./assets/q3/6.png"),
                        require("./assets/q3/7.png"),
                        require("./assets/q3/8.png"),
                        require("./assets/q3/9.png"),
                        require("./assets/q3/10.png"),
                        require("./assets/q3/11.png"),
                        require("./assets/q2/a8.png"),
                        require("./assets/q3/13.png"),
                    ],   
                    ca:[0,1,2,3,5],
                    "startTime": 16,
                    "360 Vídeo or Image link": "https:\/\/www.dropbox.com\/s\/diacrp0pkrx8h46\/eng.pro-47.mp4?dl=0"
                },
                {
                    "game":"quiz",
                    "MODULE": "Assembly",
                    "Scenario": "Scenario 10",
                    "ID": "eng.pro-48",
                    "Area": "Eng. Product - Lab HV",
                    "Sectiontitle": "Soldadura - Cuidados a ter",
                    "startTime": 24 ,
                    "Question": "Durante a tarefa manual de soldadura, que procedimentos de segurança deve ter em conta?\n\nIdentifique todas as respostas corretas.",
                    ans:[ "Ligar a extração fixa da bancada de trabalho, utilizar os EPI´s destinados à tarefa tais como, luvas de proteção anti-calor, óculos de proteção, sapatos de segurança",
                    "Devo realizar a tarefa na bancada ao pé do ferro de soldar sem a extração, a tarefa é rápida",
                    "Se estiver a trabalhar sozinho na bancada de trabalho devo utilizar a hotte destinada ao trabalho, utilizar os EPI´s destinados à tarefa tais como, luvas de proteção anti-calor, óculos de proteção, sapatos de segurança",
                    "Devo posicionar a peça no torno para segurar a peça e facilitar o trabalho.",]
                    ,ca:[0,2,3]
                },
            ],
            11:[
                {
                    as:[11],
                Sectiontitle:"Encontre todos os riscos na área",
         
            "MODULE": "Assembly",
         "Scenario": "Scenario 11",
         "ID": "qualidade-49",
         "Secção": "Qualidade (Quiosques, Lab Metrologia, Lab. qualidade)",
         "Area": "Lab. Qualidade",
         "game":"risk", 
         "Kuula  (link)": "https:\/\/kuula.co\/post\/NFCq3\/collection\/7qbyZ"
          ,hotspots :[
          {
            position: [1.74,0.50],
            "body": "Em equipamentos de trabalho onde existe radiação ionizante, como raio-x, é obrigatória a utilização de dosimetro individual de controlo da exposição a radiação.",
         cover : require("./dialogImages/YwgLdPy4.jpeg")
                 
          }, {
         position: [1.68,1.04],
            "body": "Locais com acesso restrito ou condicionado, só devem ser utilizados por colaboradores do setor ou colaboradores autorizados. Esta regra deve-se ao facto de colaboradores externos à secção não terem conhecimento de todos os riscos específicos inerentes à mesma.",
         cover : require("./dialogImages/_-LUQg_0.jpeg")
             },
        {
         position: [1.60,-1.58],
            "body": "Sempre que exista contacto com superficies quentes, utilize o EPI apropriado à tarefa, tal como as luvas de proteção anti-calor.",
         cover : require("./dialogImages/Qj3u4q3w.jpeg")
             },
        {
         position: [1.80,-1.68],
            "body": "Sempre que utiliza x-atos, nível 3, 4 ou 5, bem como ferramentas manuais, deve utilizar as luvas de proteção anti-corte.",
         cover : require("./dialogImages/3bwQWOZc.jpeg")
             },
        {
         position: [1.80,-1.28],
            "body": "Todos os carrinhos de transporte de ferramentas, ou outros, devem ter as rodas travadas, evitando assim que o carrinho se desloque inadvertidamente quando não está em utilização.",
         cover : require("./dialogImages/By7XVE7w.jpeg")
             },
        {
         position: [1.68,-1.40],
            "body": "Equipamentos de emergência ou de combate a incêndios devem estar sinalizados, desobstruídos e facilmente acessíveis, facilitando a sua utilização.",
         cover : require("./dialogImages/_KZaTofg.jpeg")
             },
          ]
        },
                {
                    "game":"find",
                    "MODULE": "Assembly",
                    "Scenario": "Scenario 11",
                    "ID": "qualidade-50",
                    "Area": "Sala das Torres de vida",
                    "Sectiontitle": "Equipamentos de proteção individual - banho de estanho",
                    "Question": "Identifique todos os EPI´s que deve utilizar no banho de estanho.\n\nSelecione todas as opções que estão corretas.",
                    ans:[
                        require("./assets/q4/1.png"),
                        require("./assets/q4/2.png"),
                        require("./assets/q4/3.png"),
                        require("./assets/q4/4.png"),
                        require("./assets/q4/5.png"),
                        require("./assets/q4/6.png"),
                        require("./assets/q4/7.png"),
                        require("./assets/q4/8.png"),
                        require("./assets/q4/9.png"),
                        require("./assets/q4/10.png"),
                        require("./assets/q4/11.png"),
                        require("./assets/q4/12.png"),
                        require("./assets/q4/13.png"),
                        require("./assets/q4/14.png"),
                    ], 
                    ca:[5,6,7,8,12,13],
                    "startTime": 12,
                    "360 Vídeo or Image link": "https:\/\/www.dropbox.com\/s\/7fkd3amld6jsfn4\/qualidade-50.mp4?dl=0"
                },
                {
                    "game":"quiz",
                    "MODULE": "Assembly",
                    "Scenario": "Scenario 11",
                    "ID": "qualidade-51",
                    "Area": "Sala das Torres de vida",
                    "Sectiontitle": "Banho de estanho - cuidados a ter",
                    "startTime": 4 ,
                    "Question": "Quando inicia a tarefa do banho de estanho. Identifique todos os cenário que considera corretos.",
                    ans:[ "Ligar a luz da hotte",
                    "Ligar a extração da hotte",
                    "Retirar o relé com a mão do banho de estanhar, uma vez que tenho as luvas anti-calor.",
                    "Baixar a porta da hotte.",]
                ,ca:[0,1,3]},
                {
                    "game":"quiz",
                    "MODULE": "Assembly",
                    "Scenario": "Scenario 11",
                    "ID": "qualidade-52",
                    "Area": "Sala das Torres de vida",
                    "Sectiontitle": "Sistemas de segurança - cuidados a ter",
                    "startTime": 7 ,
                    "Question": "Qual é o primeiro passo que deve ter em conta na utilização de uma prensa manual?\n\nIdentifique a resposta correta.",
                    ans:[ "Verificar se as proteções estão ativas",
                    "Verificar se a sinalização de perigo está colocada na presnsa e é correta.",
                    "Verificar se a prensa está fixa na bancada de trabalho",
                    "Verificar se a bancada de trabalho está limpa e arrumada",]
                ,ca:[2]},
                {
                    ac:true,
                    "game":"quiz",
                    "MODULE": "Assembly",
                    "Scenario": "Scenario 11",
                    "ID": "qualidade-53",
                    "Area": "Quiosques linha",
                    "Sectiontitle": "Equipamentos de trabalho - avarias",
                    "startTime": null ,
                    "Question": "Durante a utilização de um equipamanto de trabalho (ex. compensografo), deteto que está avariado. O que devo fazer?\n\nIdentifique a resposta errada.",
                    ans:[ "Bloquear o equipamento de trabalho até que a reparação seja feita pela manutenção\/electrónica.",
                    "Abrir uma OT para a eléctronica",
                    "Devo tentar reparar o problema do equipamento pois é algo fácil de reparar.",
                    "Sinalizar o equipamento de trabalho como avariado.",]
                    ,ca:[2],invert:true},       
            ],
        }
        ,aram:{
           1:[ 
            {
                ac:true, 
                "game":"quiz",
                "MODULE": "Warehouse",
                "Scenario": "Scene 1",
                "ID": "circ-75",
                "Area": "Circulação de pessoas no exterior",
                "Sectiontitle": "Circulação exterior ",
                "startTime": null,
                "Question": "Quando circula a pé no exterior da fábrica por onde deve circular sempre o peão? \n\nIdentifique a resposta correta.",
                ans:[ "Deve circular pela estrada, mas junto da passadeira.",
                "Somente nos locais destinados\/assinalados à circulação de peões, como, as passadeiras e caminhos pedestres.",
                 "Deve circular pela estrada, não existe risco de atropelamento.",
                "Deve circular somente na delimitação da passadeira de peões.",]
                ,ca:[1]},
               {
                   "game":"quiz",
                "MODULE": "Warehouse",
                "Scenario": "Scene 1",
                "ID": "covid-78",
                "Area": "COVID 19 - preventive measures",
                "Sectiontitle": "Medidas preventivas COVID 19 - Higiene das mãos",
                "startTime": 5,
                "Question": "Durante a pandemia Covid-19 a empresa implementou várias medidas de prevenção. Indique a resposta mais correta.\n\nIdentifique a resposta correta.",
                ans:[ "A cantina é um local de baixo risco de contágio para a covid-19, por isso não preciso desinfetar as mãos à entrada.",
                "Já tive covid-19, já não preciso cumprir com as regras de prevenção.",
                 "Devo sempre desinfetar as mãos à entrada de qualquer espaço fechado que seja partilhado com mais pessoas a fim de prevenir contágios para a covid-19.",
                "O uso de máscara e distância de segurança é suficiente para a prevenção da covid-19 em locais fechados.",]
                ,ca:[2]},
                {
                    as:[9,13,14,25],
                    Sectiontitle:"Armazém - Condução Empilhador",
             
                "MODULE": "Warehouse",
             "Scenario": "Scenario 1",
             "ID": "arm-56",
             "Secção": "Armazém (Receção, Expedição, Armazém de PQ, Armazém Azul, VKL)",
             "Area": "Armazém - condução empilhador",
             "game":"risk",
             "Kuula  (link)": "https:\/\/kuula.co\/post\/NFCqW\/collection\/7qbyZ"
              ,hotspots :[
              {
                position: [1.46,-1.74],
                "body": "É proibido conduzir máquinas ou equipamentos de trabalho e, ao mesmo tempo, utilizar o telemóvel (falar, escrever mensagens, etc..). Deve parar e\/ou estacionar o empilhador e atender a chamada, por exemplo, em segurança.",
             cover : require("./dialogImages/ZCmv2Crg.jpeg")
                 
              },{
                position: [1.62,-2.00],
                "body": "Em espaços de trabalho onde há circulação mista (empilhadores e peões), e existem portas e portões de saída para o exterior, o peão deve dar sempre preferência à utilização da porta, para não se cruzar com o empilhador na mesma zona de saída\/entrada (portão).",
             cover : require("./dialogImages/vcvJ0q2A.jpeg")
                
            },
               {
                position: [1.62,-0.92],
                "body": "A utilização de vestuário de alta visibilidade (coletes) cria um ambiente de trabalho seguro, aumentando a motivação e a produtividade dos colaboradores. Promove o trabalho de equipa e a segurança de todos.",
             cover : require("./dialogImages/CYtF46ig.jpeg")
                       },
               {
                position: [1.80,-1.74],
                "body": " Em espaços de trabalho onde existe circulação mista, o condutor de empilhador deve buzinar na entrada ou em locais onde se aproximem peões.",
             cover : require("./dialogImages/L6RAT8sw.jpeg")
                       },
               {
                position: [1.86,-1.12],
                "body": "Durante a condução do empilhador, não deve movimentar as cargas com os garfos elevados, devendo estes estar sempre colocados a uma altura, aproximadamente, de 0,15 m relativamente ao chão.",
             cover : require("./dialogImages/dQK9TkAM.jpeg")
                       },
               {
                position: [1.74,-0.16],
                "body": "Durante a condução do empilhador, quando a carga tapa o ângulo de visão, a condução deve ser realizada em marcha atrás.",
             cover : require("./dialogImages/dF6-5xt8.jpeg")
                       },
               {
                position: [1.72,1.20],
                "body": "Mantenha os caminhos de circulação desobstruídos e limpos, permitindo assim uma circulação segura no espaço de trabalho.",
             cover : require("./dialogImages/Te1Z6dzw.jpeg")
                       },
              ]
            },
            {
                as:[6,41,42],
    
            Sectiontitle:"Encontre todos os riscos na área",
     
        "MODULE": "Warehouse",
     "Scenario": "Scenario 1",
     "ID": "arm-54",
     "Secção": "Armazém (Receção, Expedição, Armazém de PQ, Armazém Azul, VKL)",
     "Area": "Armazém -  Receção/ Expedição",
     "game":"risk",  
     "Kuula  (link)": "https:\/\/kuula.co\/post\/NFCqR\/collection\/7qbyZ"
      ,hotspots :[
      {
        position: [1.64,1.40],
        "body": "A utilização de vestuário de alta visibilidade (coletes) reduz o risco de acidentes e ferimentos no local de trabalho, em ambientes onde existe, por exemplo, circulação de empilhadores e peões em simultâneo.",
     cover : require("./dialogImages/p7WWztMM.jpeg")
              
      }, {
        position: [1.52,-1.02],
        "body": "Todos os EPI´s obrigatórios devem ser usados corretamente e a sua não utilização é da responsabilidade do colaborador. Caso a situação seja recorrente, pode ser alvo de um processo disciplinar.",
     cover : require("./dialogImages/1v1TfvfY.jpeg")
               },
       {
    position:[1.72,-1.62],
        "body": "Ao circular em áreas de circulação mista (empilhadores e peões) redobre a atenção e utilize o colete de alta visibilidade. Se conduzir empilhadores, deve sempre buzinar ao entrar e sair dos espaços. Enquanto peão, deve utilizar os espelhos para visualizar o caminho de circulação e estar atento à luz de presença do empilhador (luz azul).",
    },
    {
    position:[1.76,2.60],
    "body": "Antes de posicionar os garfos na palete, verifique se a carga está corretamente empilhada e se a palete não está danificada. Dependendo da direção pela qual vai rodar o equipamento, determine qual a mão mais apropriada para segurar o porta-paletes. Assegure-se que o caminho está livre de obstáculos. Caminhe sempre, nunca corra, e não fique no raio de viragem. Esteja atento à sua postura ergonómica durante a movimentação da carga. Se a carga for muito pesada, dê preferencia à utilização de stacker.",
       },
       {
        position:[1.64,1.68],
        "body": "Para todas as tarefas manuais onde exista risco de corte com arestas vivas, utilize sempre as luvas de proteção anti-corte para prevenir possíveis ferimentos.",
     cover : require("./dialogImages/21irppXg.jpeg")
                },
      ]
    },
    
    {
        as:[15],
            Sectiontitle:"Encontre todos os riscos na área",
     
        "MODULE": "Warehouse",
     "Scenario": "Scenario 1",
     "ID": "arm-57",
     "Secção": "Armazém (Receção, Expedição, Armazém de PQ, Armazém Azul, VKL)",
     "Area": "Armazém - Contentor de produtos Quimicos/ frigorificos",
     "game":"risk", 
     "Kuula  (link)": "https:\/\/kuula.co\/edit\/n1\/collection\/7qbyZ"
      ,hotspots :[
      {
        position:[2.04,-6.90],
        "body": "Os produtos químicos devem ser acondicionados em bacias de retenção adequadas para o efeito, evitando possíveis derrames no solo durante o transporte ou acondicionamento dos produtos.",
     cover : require("./dialogImages/S8Mb99pU.jpeg")
              
      }, {
    position:[1.54,-7.36],
        "body": "Os armários de produtos químicos ou contentores de produtos químicos devem estar sempre fechados e a sua chave com o responsável da área de trabalho. Caso necessite de acesso, solicite a chave. Deste modo, evitamos que colaboradores de outras áreas tenham acesso a produtos químicos, e riscos associados à sua manipulação, que desconhecem.",
    },
    {
    position:[1.80,-7.32],
    "body": "Todos os produtos químicos devem ser armazenados nos locais adequados, de acordo com a matriz de compatibilidade de risco. Devem ser mantidos, preferencialmente, nas embalagens originais e devidamente rotulados.",
       },
      ]
    }
     ] 
    
                
        },
        administrativa : {
            1: [
                {
                    ac:true, 
                    "game":"quiz",
                    "MODULE": "Administrative",
                    "Scenario": "Scene 1",
                    "ID": "circ-75",
                    "Area": "Circulação de pessoas no exterior",
                    "Sectiontitle": "Circulação exterior",
                    "startTime": null,
                    "Question": "Quando circula a pé no exterior da fábrica por onde deve circular sempre o peão? \n\nIdentifique a resposta correta.",
                    ans:[ "Deve circular pela estrada, mas junto da passadeira.",
                    "Somente nos locais destinados\/assinalados à circulação de peões, como, as passadeiras e caminhos pedestres.",
                     "Deve circular pela estrada, não existe risco de atropelamento.",
                    "Deve circular somente na delimitação da passadeira de peões.",]
                    ,ca:[1]},
                   {
                       "game":"quiz",
                    "MODULE": "Administrative",
                    "Scenario": "Scene 1",
                    "ID": "covid-78",
                    "Area": "COVID 19 - medidas preventivas",
                    "Sectiontitle": "Medidas preventivas COVID 19 - Higiene das mãos",
                    "startTime": 5,
                    "Question": "Durante a pandemia Covid-19 a empresa implementou várias medidas de prevenção.\n\nIdentifique a resposta correta.",
                    ans:[ "A cantina é um local de baixo risco de contágio para a covid-19, por isso não preciso desinfetar as mãos à entrada.",
                    "Já tive covid-19, já não preciso cumprir com as regras de prevenção.",
                     "Devo sempre desinfetar as mãos à entrada de qualquer espaço fechado que seja partilhado com mais pessoas a fim de prevenir contágios para a covid-19.",
                    "O uso de máscara e distância de segurança é suficiente para a prevenção da covid-19 em locais fechados.",]
                    ,ca:[2]} ,
             {
                 Sectiontitle:"Encontre todos os riscos na área",
          
             "MODULE": "Administrative",
          "Scenario": "Scenario 1",
          "ID": "bc-74",
          "Secção": "BC",
          "Area": "BC - escritórios",
          "game":"risk",
          "Kuula  (link)": "https:\/\/kuula.co\/post\/NDKpY\/collection\/7qbyZ"
           ,hotspots :[
           {
     position: [2.14,-1.80],
             "body": "A organização no local de trabalho é fundamental. Deve manter os corredores livres, prevenindo tropeções, quedas e escorregadelas, queda de objetos e, principalmente acidentes graves. Aplique a ferramenta 5S.",
     },{
     position: [2.12,0.26],
         "body": "Deve regular a cadeira de forma a que os braços fiquem paralelos à mesa, e verificar o encosto para o o correto posicionamento da curvatura lombar, de forma a que a coluna se mantenha apoiada. Utilize o apoio de pés para garantir que as pernas ficam em ângulo reto, de modo a ajudar a estabilizar a postura do corpo.",
     },
     {
     position: [1.72,0.30],
         "body": "O monitor deve estar ajustado à altura dos olhos. É recomendado que faça pausas com frequência, aliviando assim a fadiga visual. Regule também a intensidade luminosa do ecrã, se necessário.",
     },
     {
     position: [1.82,-0.86],
         "body": "Coloque os resíduos nos contentores adequados para o tipo de resíduos que pretende eliminar.",
     },
     {
     position: [1.68,0.86],
         "body": "A organização no local de trabalho é fundamental. Deve manter os corredores livres, prevenindo tropeções e escorregadelas, queda de objetos e, principalmente acidentes graves. Aplique a ferramenta 5S.",
     },
     {
     position: [1.76,-3.74],
         "body": "Dê preferencia ao uso de um monitor de maiores dimensões, em vez da utilização do ecrã do portátil. Uma maior dimensão do ecrã vai permitir que veja todo o seu trabalho noutra escala, melhorando a sua produtividade e diminuindo a fadiga visual.",
     },
     {
     position: [1.70,-1.94],
         "body": "Deve manter os corredores livres, prevenindo tropeções, escorregadelas, queda de objetos e, principalmente, acidentes graves. Olhar por onde circula, de forma consciente e constante, é fundamental.",
     }
           ]
         }
        ]
        }
    }
   
 }
 

 const fakeataall = { 
    true:{ 1:[
    {
            Sectiontitle:"Find all hazards in the area",
        
        "MODULE": "Assembly",
        "Scenario": "Scenario 2",
        "ID": "hv-10",
        "Secção": "HV (EVC, Minik Hv, Workshop, HCR)",
        "Area": "HCR ",
        "game":"risk",
        "Kuula  (link)": "https:\/\/kuula.co\/post\/NFglH\/collection\/7qbyZ"
        ,hotspots :[
            {
                position: [1.86,-1.70],
                "body": " For all manual tasks where there is a risk of cutting with sharp edges, always use the anti-cut gloves to prevent minor injuries.",
                cover :[
                    require("./dialogImages/hCa3dcMg.jpeg"),
                    require("./dialogImages/Aa5VggJw.jpeg"), 
                ]
            },
            {
                position: [1.68,-1.54],
                "body": "All mandatory PPE must be used correctly and failure to use it is the employee's responsibility. If the situation is recurrent, disciplinary action may be taken.",
                cover : require("./dialogImages/0MVicw2w.jpeg")
            },
            {
                position: [2.28,-1.58],
                "body": "Use the footrest correctly, thus improving ergonomic posture. You should not sit cross-legged, but at a 90° angle to the knee (lower and upper leg).",
                cover : require("./dialogImages/5sJMHFT4.jpeg")
            },
            {
                position: [1.74,1.08],
                "body": "Always wear protective gloves when carrying out manual tasks. Change gloves according to the nature of the task you are currently doing and the risk you are exposed to.",
                cover : require("./dialogImages/eh6uRA-4.jpeg")
            },
            {
                position: [1.78,-0.50],
                "body": "Use the auxiliary equipment available, such as a pallet truck lift, for better ergonomic posture. You should always adjust the pallet truck to the height at which you will place the load on the pallet. ",
                cover : require("./dialogImages/Gq-_XKrc.jpeg")
            },  
        ]
    },
]},
false:{ 1:[
    {
            Sectiontitle:"Find all hazards in the location shown",
        
        "MODULE": "Assembly",
        "Scenario": "Scenario 2",
        "ID": "hv-10",
        "Secção": "HV (EVC, Minik Hv, Workshop, HCR)",
        "Area": "HCR ",
        "game":"risk",
        "Kuula  (link)": "https:\/\/kuula.co\/post\/NFglH\/collection\/7qbyZ"
        ,hotspots :[
            {
                position: [1.86,-1.70],
                "body": " For all manual tasks where there is a risk of cutting with sharp edges, always use the anti-cut gloves to prevent minor injuries.",
                cover :[
                    require("./dialogImages/hCa3dcMg.jpeg"),
                    require("./dialogImages/Aa5VggJw.jpeg"), 
                ]
            },
            {
                position: [1.68,-1.54],
                "body": "Todos os EPI´s obrigatórios devem ser usados corretamente e a sua não utilização é da responsabilidade do colaborador. Caso a situação seja recorrente, pode ser alvo de um processo disciplinar.",
                cover : require("./dialogImages/0MVicw2w.jpeg")
            },
            {
                position: [2.28,-1.58],
                "body": "Use the footrest correctly, thus improving ergonomic posture. You should not sit cross-legged, but at a 90° angle to the knee (lower and upper leg).",
                cover : require("./dialogImages/5sJMHFT4.jpeg")
            },
            {
                position: [1.74,1.08],
                "body": "Always wear protective gloves when carrying out manual tasks. Change gloves according to the nature of the task you are currently doing and the risk you are exposed to.",
                cover : require("./dialogImages/eh6uRA-4.jpeg")
            },
            {
                position: [1.78,-0.50],
                "body": "Use the auxiliary equipment available, such as a pallet truck lift, for better ergonomic posture. You should always adjust the pallet truck to the height at which you will place the load on the pallet. ",
                cover : require("./dialogImages/Gq-_XKrc.jpeg")
            },  
        ]
    },
    
]}

}

export {examData , videos ,fakeataall }
