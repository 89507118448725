import * as React from 'react';
import MiniPaper from "./MiniPaper" 
import HintsBtn from "./HintsBtn"
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';

import { ReactComponent as quizIcon } from "../Score/quiz.svg"; 
import { ReactComponent as warning } from "../Score/warning.svg"; 
import { ReactComponent as search } from "../Score/search.svg"; 



export default function SimplePaper({texts ,txtlang,colored,game,shotHintsRef,isTutorial,displayHS,showHint,totlaHotspots,activeHotspot ,willshowHints }) {
  function getTitle(game){
    if(game==='find'){
      return [texts.fre[txtlang],search]
    }else if(game==="risk"){
      return [texts.rhg[txtlang],warning]
    }else{
      return [texts.qz[txtlang],quizIcon]
    } 
  }
  return (
    <Box  sx={{
      width:"96%",
      p:"0px 20px",
      display:{xs:"none",md:'flex'},
      justifyContent: "space-between",
      alignItems:"center",
      position:"fixed",
      zIndex:100,
      bottom:"0px"}}>
        <Box></Box>
      <Stack direction="row" spacing={1} >
        <Typography 
        sx={{
          fontWeight: 700,
          fontSize: "20px",
          textAlign: "center",
          color: "#FFFFFF",
          textTransform:"uppercase"
        }}
        >
          
        </Typography>
        <MiniPaper  icon={getTitle(game)[1]}  title = {getTitle(game)[0]} Nonumber = {true} denominator={activeHotspot} numerator={totlaHotspots}/>

        {displayHS?<>
          
          <HintsBtn lableshow={texts.showh[txtlang]} lablehide={texts.hideh[txtlang]} shotHintsRef={shotHintsRef} isTutorial={isTutorial} willShowHints={willshowHints}   showHint={showHint}/>
          <MiniPaper colored={colored} ishp={true} title = {texts.hs[txtlang]} denominator={activeHotspot} numerator={totlaHotspots}/>
        </>:null}
      </Stack>

    </Box>  
    );
}
