import React, {useState,useEffect } from "react";
 
import Box from '@mui/material/Box'; 
import Grid from '@mui/material/Grid'; 
import SvgIcon from '@mui/material/SvgIcon'; 
import { ReactComponent as selected } from './icons/selected.svg'; 
import { useHistory } from "react-router-dom";
import scoresuceedsfx from "../../staticData/sounds/score-screen-sucess.wav";
import useSound from "use-sound"; // ALEX

export default function Scenariosss(props) {
  const history = useHistory(); 
    const {  txtlang, texts ,setselected,setDoneModule,data, tokenExams,settokenExams,
            setActiveScenario,ActiveScenario,completedScenario, setcompletedScenario,
            playclicksfx}=props 

    const keys = Object.keys(data)
    const hovered ={
        background:"white",
        color:"#2E4957"
    }
    const [playscoresuceedsfx] = useSound(scoresuceedsfx, {
        volume:  0.5,
      });

    const boxStyle={
        height:"100px",
        fontFamily: 'Avenir',
        fontWeight: 900,
        fontSize: "28px",
        lineHeight: "38px",
        display: 'flex',
        alignItems: 'center',
        color: '#FFFFFF',
        paddingLeft:"32px",
        transition: "all 0.25s ease",
        background:"rgba(255, 255, 255, 0.3)",

        '@media (max-width:800px)': {
            fontSize: "16px",
            lineHeight: "25px",
            height:"40px",
            padding:"5px 30px"
        },
        
    } 

    const completedAllScenarios =()=>{ 
      
       const notcompleted = keys.filter(k=>{
            const notAttended = data[k].filter(exams=> !tokenExams.includes(exams.ID))
            return notAttended.length?true:false
        })

        if(notcompleted.length==0){
            setDoneModule() 
            setselected([])
            // if (caclulateScore()) {
                    // playscorefailedfx();
            
                    playscoresuceedsfx();
                //   } else {
                //     playscorefailedfx();
                //   }

            history.push("/scoreSucceed")
            // history.push("/areas")
        }

    }

    const IsDone=(scenarioIndex)=>{
        let value = false
        if(tokenExams.length){ 
            const afterFilter = data[scenarioIndex].filter(d=> !tokenExams.includes(d.ID))
            if(afterFilter.length === 0){
                value=true
            }

        }
        return value
    }
    
    const handleClick= (index)=>{
        playclicksfx()
        if(!IsDone(index)){
                const newData ={}
                newData[index]=data[index]
                setActiveScenario(newData)
            }
    } 
    useEffect(() => {  
        completedAllScenarios()
        setActiveScenario({})
     }, []);
  return ( <Grid container sx={{
        maxWidth:"1240px",
        marginTop:'50px',            
        scrollbarColor: "#6969dd #e0e0e0",
        maxHeight:"calc(100vh - 290px)",
        overflowY:"auto",
        '@media (max-width:800px)': {
            marginTop:'40px',                
        }
        }} spacing={1.3} >
        {keys.map((s,index)=>
        <Grid key={index} sx={{ width:"300px"}} item xl={3} lg={4} sm={12} xs={12}>
   
            <Box 
            onClick={()=>handleClick(s)} 
             sx={ActiveScenario[s]===undefined ? {...boxStyle, '&:hover':!IsDone(s)?{...hovered,cursor:"pointer",}:"",
                background:(IsDone(s))?"#E98300":"rgba(255, 255, 255, 0.3)"}:
             {...boxStyle,...hovered,}}  >
            {(IsDone(s))? <SvgIcon sx={{width:30,height:30,marginRight:"10px"}}  component={selected} inheritViewBox /> :null}
                {texts.s2[txtlang]} {s} 
            </Box>

            </Grid>
        )}                   
        </Grid>
       );
} 