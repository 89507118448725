import React, {useState ,useEffect } from "react";
import Button from '@mui/material/Button'; 
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import SvgIcon from '@mui/material/SvgIcon'; 

import { ReactComponent as correct } from './correct.svg';
import { ReactComponent as wrong } from './wrong.svg';


import useSound from 'use-sound'
import clicksfx from '../../staticData/sounds/check answer.wav';

export default function Quiz({texts,txtlang,AudioMute,validateAnswer,Selected,handleSelect,handleSubmit,SetIsActive,IsActive,data,openQuiz,setopenQuiz}) {
  const [playQuizsfx] = useSound(clicksfx,{volume: AudioMute?0:0.5,soundEnabled:!AudioMute});
   
  const feedbackIconStyle={
    width:40,
    height:40,
    display:{xs:"none",md:"inline"}
  } 
  const renderBackgroundColor =(typer , index)=>{
        const isSelected = Selected.includes(index) 
        let types
    
          if(data.ca.includes(index)===true){
            types =1
          }else if(data.ca.includes(index)===false && isSelected){
            types = -1
          }else{
            types = 10
          }
    
        if(!typer ){
          if(isSelected ){
            return "rgba(233, 131, 0, 0.5)"
          }else{
            return "#EBECEC"
        }
        } else{
          if(types===1){
            return "rgba(143, 184, 56, 0.3)"
          }else if(types==-1){
            return "rgba(188, 31, 0, 0.3)"
          }else{
              return "#EBECEC"
          }
        }
      }

    const renderIcon =(index)=>{
        const isSelected = Selected.includes(index)
    
        let types
          if(data.ca.includes(index)===true){
            types =1
          }else if(data.ca.includes(index)===false && isSelected){
            types = -1
          }else{
            types = 10
          }
        
        if(IsActive){
          if(types ==1){
            return <SvgIcon sx={feedbackIconStyle } component={correct} inheritViewBox />
          }else if(types ==-1) {
            return <SvgIcon sx={feedbackIconStyle } component={wrong} inheritViewBox />
          }
        }else{
          return null
        }
      }
 
    const title1={
        fontWeight: "500",
//         font-size: 12px;
// line-height: 16px;
        fontSize: {md:"18px",xs:"12px"},
        lineHeight: {md:"60px",xs:'16px'},
        textTransform: "uppercase",
        color: "#E98300",

    }
    const title2={
        fontFamily:"AvenirBold",
        fontWeight: "800",
        marginTop:{xs:"10px"},
        fontSize: {md:"32px",xs:"20px"},
        lineHeight: {md:"44px",xs:"27px"},
        color: "#2E4957",
    }
    const choicesTextStyle = {
        fontWeight: "500",
        fontSize: {xs:"14px",md:"18px"},
        lineHeight:{xs:"19px",md:"36px"},
        color: "#000000",
    }
    const choiceBoxStyle ={
        textAlign:"left",
        padding:{md:"19px 30px 12px",xs:"13px 15px 8px"},
        marginTop:"10px",
        display:"flex",
        justifyContent:"space-between",
        cursor:"pointer",
        transition: "all 0.3s ease",
    }
    const choiceContainer={
        marginTop:{md:"40px",xs:"20px"},

    }
  return ( 
    <DialogContent sx={{
        padding:{md:`${IsActive?"40px":"30px"} 60px 52px`,xs:"90px 20px"},
        textAlign:{xs:'center',md:"left"}
        }} >
      <Typography variant='body1' sx={title1} color="primary" component="h1" >
      {texts.ques[txtlang]}
      </Typography>
      <Typography color="secondary" variant='h4' sx={title2}>
        {data.Question}
      </Typography>
      <Box sx={choiceContainer}>
          {data.ans.map((choice,index)=> <Box key={index} onClick={()=>handleSelect(index)}
           sx={{...choiceBoxStyle , background:renderBackgroundColor(IsActive,index)} }>
          <Typography sx={choicesTextStyle} gutterBottom>
          {choice}
          </Typography>
          {renderIcon(index)}
          </Box> )}
     
          <Box sx={{marginTop:{md:"40px",xs:"20px"},display:"flex",justifyContent:"flex-end"}}  > 
           {Selected.length? <Button onClick={()=>{
            validateAnswer()
            if(!IsActive){
              playQuizsfx()
            }
            }} sx={{
                padding:{md:"17px 48px",xs:"14px 20px"},
                fontSize:{xs:"12px",md:"18px"},
                lineHeight:{xs:"16px",md:"36px"},
                letterSpacing: "0.1em",
                fontWeight:500,
                width:{md:"300px",xs:"100%"}
               }}  variant="contained" >
           {IsActive?texts.cont[txtlang] :texts.checkans[txtlang]}
              </Button>:(IsActive? <Button onClick={()=>validateAnswer()} sx={{
                  padding:{md:"17px 48px",xs:"14px 20px"},
                  letterSpacing: "0.1em",
                  fontWeight:500,
                  width:{md:"300px",xs:"100%"}
                 }}  variant="contained" >
             {texts.cont[txtlang]}
              </Button>:null)}

              
           </Box>
      </Box>
      </DialogContent>
    
  );
}