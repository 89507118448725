import * as React from 'react';
import Button from '@mui/material/Button'; 
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import hotspotIcon from "./speaker.png"
import CustomDialogTitle from "./DialogTitle"
 
export default function CustomizedDialogs({texts,txtlang,playclicksfx,dialogBtnRef,isTutorial,handleClose,openDialog,data}) {
  const {title , body , cover}=data
  const  btnstyle= {
      padding:"10px 90px",
      letterSpacing: "0.1em",
      fontWeight:500, 
      width:{xs:"100%",md:"300px"},

    }
    const tutbtnstyle={
      padding:"10px 90px",
      letterSpacing: "0.1em",
      fontWeight:500, 
      cursor:"default",
      "&:hover":{
        background:"#E98300",
        boxShadow:"none"
      }
      }
  return ( 
      <Dialog
        onClose={()=>{
          if(!isTutorial)
          handleClose()
        }}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
        sx={{
          '& .MuiPaper-root ': {
            '@media (max-width:600px)': {
                height:"100vh",
                maxHeight:"100vh",
             },
            // maxWidth:{md:"70%",xs:"100vw"},
            margin:{xs:"0",md:"32px"},
            maxWidth:{md:cover?"1015px":"1000px", xs:"100vw"},
            padding: {md:`${cover ? "20px 50px 45px":"60px 80px 130px"}`,xs:"120px 20px 45px"},
        }
        }}>
        <CustomDialogTitle isdialog={true} id="customized-dialog-title" onClose={()=>{
          if(!isTutorial)
          handleClose()
        }}/>

        <Grid container spacing={2} sx={{alignItems:"center"}} >
          {cover? <Grid sx={{justifyContent:"center",alignItems:"center"}} container item md={cover.length===2?6:5} xs={12}>
            
            {cover.length===3?<>
              <Grid item xs={6}>
                <img width="100%" src={cover[0]}></img>
              </Grid>
              <Grid item xs={6}>
                <img width="130%" src={cover[1]}></img>
              </Grid>
              <Grid item xs={12}>
                <img width="100%" src={cover[2]}></img>
              </Grid>
            </>:(cover.length===2?<> <Grid item xs={6}>
                <img width="100%"  src={cover[0]}></img>
              </Grid>
              <Grid item xs={6}>
                <img width="100%"  src={cover[1]}></img>
              </Grid></>:<Grid item xs={12}>
                <img width="100%"  src={cover}></img>
              </Grid>)}
              
          </Grid>:null}
          <Grid item md={cover?(cover.length===2?6:7):12} xs={12}>
            <DialogContent sx={{paddingTop: {md:"50px",xs:"10px"}, paddingLeft:{xs:"0",md:"25px"}, paddingRight:{md:cover?"100px":"35px", xs:"0"}}} >
            <Typography variant='body1' sx={{display:{xs:"none",md:"block"},fontWeight:400, textTransform:"uppercase"}} color="primary" component="h1"  gutterBottom>
              <img  src={hotspotIcon}></img>
            </Typography>
            <Typography variant='body1' sx={{
              fontWeight:400,
               textTransform:"uppercase",
               fontSize:{xs:"12px",md:"18px"}
               }} color="primary" component="h1"  gutterBottom>
             {texts.found[txtlang]}
            </Typography>
            <Typography color="secondary" variant='h4' sx={{fontWeight:"bold",fontSize:"44px"}} gutterBottom>
              {title}
            </Typography>
            <Typography sx={{lineHeight:{md:2.2,xs:"26px"}, fontSize:{md:"16px",xs:"14px"}}} gutterBottom>
              {body.split("^^").map((b=><>{b} <br/></>))} 
            </Typography>
            </DialogContent>
            <DialogActions sx={{justifyContent:"flex-start", paddingLeft:{xs:"0",md:"25px"}}}  > 
              <Button ref={dialogBtnRef} className="tutDialogBtn" id="dialogBtn" sx={isTutorial?tutbtnstyle:btnstyle}  

                variant="contained" onClick={()=>{
               playclicksfx()
               if(!isTutorial)
                handleClose()
              }}>
                {texts.got[txtlang]}
              </Button>
            </DialogActions>

          </Grid>
        </Grid>
      </Dialog> 
  );
}