import React, {useState } from "react";
import { Link } from "react-router-dom";
 
 
import Box from '@mui/material/Box'; 
import Typography from '@mui/material/Typography';  
import Container from '@mui/material/Container'; 
import Button from '@mui/material/Button';  
 
import SvgIcon from '@mui/material/SvgIcon'; 
import { ReactComponent as pointerTopLeft } from './topleft.svg';
import { ReactComponent as pointerTopRight } from './topright.svg';
import { ReactComponent as pointerBottomRight } from './bottomright.svg';
import { ReactComponent as dragmouse } from './dragmouse.svg';
import { ReactComponent as cursor } from './cursor.svg';
import { Redirect ,useHistory } from "react-router-dom";

const getIcon = (index)=>{
    if(index===0) return pointerTopLeft
    else if (index===1) return pointerTopRight
    else if(index===2) return pointerBottomRight
}
const getPos = (el)=>{
    var rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top  + 60, left: rect.left + scrollLeft + 60 }
}

export default function InteractiveTuT({ texts,txtlang,playclicksfx , shotHintsRef,dialogBtnRef,seenTutorial,setcloseRotateAnimation,showHint,setopenDialog,AnimateNumberChange,stepIndex,title,body,isScenarios,step,setstepIndex,clickHotspot}) {
    const [cursorPos,setcursorPos] = useState({top:0,left:0}) 
    const history = useHistory();
    
    const handleGotitClick  = ()=>{
        playclicksfx()
        console.log("stepIndex",stepIndex)
        if(stepIndex==0){
            const el = document.getElementsByClassName("h1")
           const {top,left} =  getPos(el[0])
           setcursorPos({top:window.innerHeight/2,left:window.innerWidth/2})
            setcloseRotateAnimation(false)
            AnimateNumberChange()
        }else if (stepIndex==1){
            clickHotspot()

            
        }else if(stepIndex==2){
            setopenDialog(true)

            // const el = document.getElementById("dialogBtn")
            // const elpo = document.getElementsByClassName("tutDialogBtn")

            // console.log("EL",elpo)
        //    const {top,left} =  getPos(elpo[0])
        setTimeout(()=>{
            console.log("DDD",dialogBtnRef)
            const dim = dialogBtnRef.current.getBoundingClientRect()
            setcursorPos({top:dim.y+dim.height/2,left:dim.x + dim.width/2})

        },100)

// Y
        }else if(stepIndex==3){
            setopenDialog(false)
             const dim = shotHintsRef.current.getBoundingClientRect()
            setcursorPos({top:dim.y+dim.height/8,left:dim.x + dim.width/2})

            
        }else if(stepIndex == 4){
            showHint()
            const el = document.getElementsByClassName("h0")
           const {top,left} =  getPos(el[0])
           setcursorPos({top:top+40,left:left+40})
           console.log("TOPP",top)
           console.log("lPP",left)
        }
         if(stepIndex===5){
             seenTutorial()
          history.push("/riskhunt");
          setcloseRotateAnimation(false)
        setstepIndex(0)
        }else{
            setstepIndex(e=>e+1)
        }
    }

  return ( 
    <>
     
    <Box 
    sx={{
        zIndex:1000000,
        transition:"0.3s linear all",
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        alignItems:"center",
        width:"370px",
        // background:"#333",
        position:"fixed",
        left:step.pos[0], 
        top:step.pos[1],
    }}>
        {stepIndex===0? <SvgIcon className="cursorDrag"  sx={{width:30 , height:34, position: "fixed",
         left:step.location[0] + step.dim[0]/2 - 15 ,
          top:step.location[1] + step.dim[1]/2 - 15 , transform: "translateX(-150px)"
        }}
            component={dragmouse} inheritViewBox /> :   <SvgIcon  sx={{
                width:22 , height:35, position: "fixed",
                transition:"1s all ease",
                zIndex:100000000,
                 left:cursorPos.left ,
                  top:cursorPos.top  
                }}
                    component={cursor} inheritViewBox />  }
            
        <SvgIcon  sx={{width:175 , height:175, position: "relative", 
         left: step.arrowDirection===0?"-68px":(step.arrowDirection===1?"68px": "275px"), top: step.arrowDirection===2?"270px":"-7px"}}
            component={getIcon(step.arrowDirection)} inheritViewBox />
       
            

        
        <Box sx={{
        background:"#fff",
        padding:"16px 0 ",
        textAlign:"center"
        }}>

        <Typography sx={{
            marginBottom:"30px",
            fontWeight: 700,
            fontSize: "24px",
            textAlign: "center",
            color: "#E98300",
        }}>
        {step.title} 
        </Typography>

        <Typography sx={{
            marginBottom:"30px",
            fontWeight: 500,
            fontSize: "16px",
            textAlign: "center",
            color: "#000",
            padding:"0 40px",
            lineHeight: "19px",

        }}>
        {step.body.length>1?
        <>{step.body.map(sb=><> <div>{sb} </div> <br/> </> )}</>:step.body[0]
        } 
        </Typography>

        <Button 
        onClick={()=>{
            handleGotitClick()
        }}
        sx={{
            width:"200px"
        }} variant="contained" >
            {texts.got[txtlang]}
        </Button>
        </Box>
    </Box>
    </>

  );
}