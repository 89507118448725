import React, {useState } from "react";

import cover from "./thankcover.png"
import { Link } from "react-router-dom";
import emailjs from '@emailjs/browser';

import Box from '@mui/material/Box'; 
import Typography from '@mui/material/Typography';  
import Container from '@mui/material/Container'; 
import Button from '@mui/material/Button'; 
import Stack from '@mui/material/Stack'; 

export default function CustomizedDialogs({ resetStates,texts,txtlang,playclicksfx,title,body,isScenarios}) {
  const {Thank ,Sent, retrnh ,plyother}=texts
   

  const titleStyle={ 
    //   width:"600px",
    fontFamily: 'Avenir',
    fontWeight: 900,
    fontSize: "24px",
    lineHeight: "70px",
    textTransform: "uppercase",
    color: "#2E4957",
    
}

const infoTextStyle={
    width:"600px",
    textAlign:"center",
    fontFamily: 'Avenir',
    fontWeight: 900,
    fontSize: "90px",
    lineHeight: "90px",    
    textTransform: "uppercase",
    color: "#2E4957",
}
 
const btnstyle={
    width:"340px",
    padding:"20px 0px" , 
    fontWeight:500,
    fontSize: "18px",
    lineHeight: "25px", 
    letterSpacing:"0.1em",
}

const sendEmail = (e) => {
  playclicksfx()
  e.preventDefault();

  emailjs.send('default_service', 'contact_form', {
    test:"TE CONNECTEVITY",
    message:"Hello evryone \n Thanks",
    from_name:"MAHHHHHMOUD HAMMMAD",
    to_name:"ALI",
    my_htmt:<div>
      <h1 style={{color:"red"}}>this is header</h1>
    </div>
  }, 'KAU8JZU94VBRJ047X')
    .then((result) => {
        console.log(result);
        console.log(result.text);
    }, (error) => {
        console.log(error.text);
    });
};

  return ( 
     <Box 
     sx={{
         background:`url(${cover})`,
         width:"100vw",
         height:"100vh",
         backgroundSize: "cover",
         backgroundPositionY: "center",
         backgroundPositionX: "center",
         
     }}>
        <Box 
        sx={{
            background:"rgba(255, 255, 255, 0.85)",
            width:"100vw",
            height:"100vh", 
            display:"flex",
            paddingLeft:"40px",
            display:"flex",
            flexDirection:"column",
            alignItems:"center",
            justifyContent:"center",

        }}>
  
            <Typography sx={titleStyle}  >
            {Thank[txtlang]}
            </Typography> 
            
            <Typography sx={infoTextStyle}  >
            {Sent[txtlang]}
            </Typography>
         
           
            <Stack sx={{marginTop:"80px"}} direction="row" spacing={1} >
           
              <Button component={Link} to="/" onClick={()=>resetStates()} sx={btnstyle} variant="contained" color="secondary">
                {retrnh[txtlang]}
              </Button>


              <Button component={Link} to="/areas" sx={btnstyle}  variant="contained" color="primary">
               {plyother[txtlang]}
              </Button>

                 </Stack>
        </Box>
    </Box>
  );
}