import React, {useState ,useEffect } from "react";

import Box from '@mui/material/Box'; 
import Tutorial from "../Tutorial/TutorialRiskHunting"
import Dialog from '@mui/material/Dialog';
import CustomDialogTitle from "../Dialog/DialogTitle"
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import staticData from "../../staticData/staticData"


// get game name
// pause video
// stop slider

export default function PopupTutorial({stexts , txtlang,isPT , gameName , openInfo,handleInfobuttonclicked}) {
  
  const AlltutorialTexts = {
    risk:staticData[isPT].riskHuntingTexts,
    quiz:staticData[isPT].quizTexts,
    find:staticData[isPT].findTexts
  }
  
  useEffect(() => {  
        
     }, []);

  return ( 
    <Dialog
    onClose={()=>{ }}
    aria-labelledby="customized-dialog-title"
    open={openInfo}
    sx={{
        zIndex:1000000,
        margin:0,
      '& .MuiPaper-root ': {
        margin:0,
        maxWidth:"100%",
        zIndex:1000000,
        maxHeight:"100%",
    }
    }}>
         <IconButton
            aria-label="close"
            onClick={()=>{handleInfobuttonclicked(false)}}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
    <Tutorial stexts={stexts} txtlang={txtlang} handleInfobuttonclicked={handleInfobuttonclicked}  playclicksfx={()=>{}} AudioMute={true}  seenTutorial={()=>{}} gameName={gameName} texts={AlltutorialTexts[gameName]}/>
  </Dialog> 
  );
}