import React, {useEffect,useState } from "react";
 
import Box from '@mui/material/Box'; 
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import IconButton from '@mui/material/IconButton';
import { ReactComponent as logo } from './icons/logo.svg';
import { ReactComponent as close } from './icons/close.svg';
import { ReactComponent as info } from './icons/info.svg';
import { ReactComponent as home } from './icons/home.svg';
import { ReactComponent as EN } from './icons/EN.svg';
import { ReactComponent as PT } from './icons/PT.svg';
import { ReactComponent as StarIcon } from '../../assets/icons/NotesIcon.svg';
import { ReactComponent as miniLogo } from '../../assets/icons/logo.svg';
import { ReactComponent as MuteIcon } from '../../assets/icons/mute.svg';
import { ReactComponent as unMuteIcon } from '../../assets/icons/volume.svg';
import { ReactComponent as menuPhone } from '../../assets/icons/menuPhone.svg';

import SvgIcon from '@mui/material/SvgIcon'; 
import { Link ,useLocation,useHistory} from "react-router-dom";

export default function Sidebar(props) {
    const {setopenPhoneSidebar,setSidebarPhone,openPhoneSidebar,SidebarPhone,isPT,switchPT,handleInfobuttonclicked,handleConfirmationOpen,AudioMute,muteAudio} = props
     const history = useHistory();

    const onlyEN = ['/tutorial360','/areas','/scenarios','/intro','/register']
    const onlygames = ['/riskhunt']
    const location = useLocation();
    const {pathname} = location
   const showOnlyEN = onlyEN.includes(pathname)
   const showOnlyInfo = onlygames.includes(pathname)
const showEN = pathname !=="/riskhunt"
   const toggleMute = (m)=>{
   const videotag = document.getElementById("video")
    console.log("videotag",videotag)
    videotag.muted=m
    muteAudio(m)
   }
   const handleHomeClicked =()=>{
     if(pathname === "/riskhunt"){
       handleConfirmationOpen()
     }else{
      history.push("/")
     }
   }
 const iconstyle={
  marginLeft:"10px",
     width:35,
     height:26,
     cursor:"pointer",
 }
 const btnstyle={
  fontWeight: 900,
  fontSize: "14px",
  letterSpacing: "0.1em",
  color:"#2E4957",
  justifyContent:"flex-end",
  marginBottom:"20px"
}
  return ( 
    <>
      <Box>
   

        
     <Box 
     sx={{
         position:"absolute",
         background:openPhoneSidebar?`#fff`:"#fff0",
         overflow:"hidden",
         height:openPhoneSidebar?"100vh":"0",
         top:0,
         width:"100vw",
         display:"flex",
         justifyContent:"center",
         transition:"0.4s ease-out all",
         zIndex:1001
     }}>
          <SvgIcon sx={{
            width:20,
            height:20,
            cursor:"pointer",
            position:"absolute",
            top:"20px",
            right:"20px"
          }}  component={close} 
            inheritViewBox onClick={()=>setopenPhoneSidebar(false)} />
            
        <Box sx={{
          border:"1px solid red",
          width:"100%",
          display:"flex",
          flexDirection:"row",
          justifyContent:"flex-end",
          padding:"77px 20px 40px 0"
          // alignItems:"flex-end"

          }}>
          <Stack sx={{ justifyContent:"space-between",alignItems:"flex-end", marginBottom:2,zIndex:1000,}} direction="column" spacing={2.5} >
          <Button
            sx={btnstyle}
            startIcon={ <SvgIcon  sx={iconstyle}  component={home} inheritViewBox />}>
            Micros
          </Button>   

            <Box>

            <Box>
            <Button
            onClick={()=>{
              switchPT()
              setopenPhoneSidebar(false)
            
            }}
            sx={btnstyle}
            endIcon={ <SvgIcon  sx={{...iconstyle,width:35*0.8,height:26*0.8,paddingRight:"2px", paddingLeft:"4px"}}  component={isPT?EN:PT} inheritViewBox />}>
            Language
          </Button> 
          </Box>
          
            {/* {showOnlyInfo?<SvgIcon onClick={()=>handleInfobuttonclicked(true)} sx={iconstyle}  component={info} inheritViewBox />:null} */}
          <Box>
            <Button
            onClick={()=>{
              setopenPhoneSidebar(false)
              handleInfobuttonclicked(true)
            }}
            sx={btnstyle}
            endIcon={ <SvgIcon sx={iconstyle}  component={info} inheritViewBox />}>
            Help
          </Button>    
          </Box>

          <Box>

          <Button
            onClick={()=>{
              setopenPhoneSidebar(false)
              handleHomeClicked()
            }
            }
            sx={btnstyle}
            endIcon={ <SvgIcon sx={iconstyle}  component={home} inheritViewBox />}>
            Home
          </Button>   
          </Box>
 
          </Box>
          </Stack> 

           {/* {setSidebarPhone?<>
           {showEN?<SvgIcon  onClick={()=>switchPT()} sx={iconstyle} component={!isPT?EN:PT} inheritViewBox />:null} 
            {AudioMute?<SvgIcon onClick={()=>toggleMute(false)} sx={iconstyle} component={unMuteIcon} inheritViewBox />:
            <SvgIcon onClick={()=>toggleMute(true)} sx={iconstyle} component={MuteIcon} inheritViewBox />
          }
            
            {!showOnlyEN?<> 
                <SvgIcon onClick={handleHomeClicked} sx={iconstyle}  component={home} inheritViewBox />
            </>:null}
           
           </>:
           null            } 
            */}
    
          </Box>
        </Box>
    </Box></>
  );
}

