import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer.js';

const selected = `
<div class="hotspotContainer">
<svg class="discovered" width="105" height="105" viewBox="0 0 105 105" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle class="cc1" cx="52.5" cy="52.5" r="52.5" fill="#E98300" fill-opacity="0.1"/>
<circle class="cc2" cx="52.5" cy="52.5" r="37.5" fill="#E98300" fill-opacity="0.3"/>
<circle cx="52.5" cy="52.5" r="22.5" fill="#E98300"/>
<path  d="M63.5469 47.0469L50.7969 59.7969C50.5781 60.0156 50.3125 60.125 50 60.125C49.6875 60.125 49.4219 60.0156 49.2031 59.7969L42.4531 53.0469C42.2344 52.8281 42.125 52.5625 42.125 52.25C42.125 51.9375 42.2344 51.6719 42.4531 51.4531C42.6719 51.2344 42.9375 51.125 43.25 51.125C43.5625 51.125 43.8281 51.2344 44.0469 51.4531L50 57.4062L61.9531 45.4531C62.1719 45.2344 62.4375 45.125 62.75 45.125C63.0625 45.125 63.3281 45.2344 63.5469 45.4531C63.7656 45.6719 63.875 45.9375 63.875 46.25C63.875 46.5625 63.7656 46.8281 63.5469 47.0469Z" fill="white"/>
</svg>
 </div>
`
const notselected =`
<div class="hotspotContainer">
<svg class="notdiscovered" width="105" height="105" viewBox="0 0 105 105" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle class="c1" cx="52.5" cy="52.5" r="52.5" fill="white" fill-opacity="0.1"/>
<circle class="c2" class="rectText" cx="52.5" cy="52.5" r="37.5" fill="white" fill-opacity="0.3"/>
<circle class="c3" cx="52.5" cy="52.5" r="22.5" fill="white" fill-opacity="0.5"/>
<circle class="c4" cx="52.5" cy="52.5" r="7.5" fill="white"/>
</svg>
</div>
`
function CreateIcon(handleClickOpen,id,isHint,data,isTutorial){
  const hotspot2 = document.createElement( 'div' );
  hotspot2.classList.add("hp")
  hotspot2.classList.add(`h${id}`)
  hotspot2.innerHTML= isHint?notselected:selected 
  hotspot2.style.opacity="0";
  let meshhh
  if(!isTutorial){
    hotspot2.addEventListener("click",(e)=>handleClickOpen(id,meshhh,e,data))
    hotspot2.addEventListener("touchstart",(e)=>handleClickOpen(id,meshhh,e,data))
  }

  meshhh = new CSS2DObject( hotspot2 )
  return  meshhh

}  
   function renderLabel( phi,theta, scene,index,handleClickOpen,data,isTutorial) { 
       replaceTexture(phi,theta, scene,index,handleClickOpen,true,data,isTutorial)
       replaceTexture(phi,theta, scene,index,handleClickOpen,false,data,isTutorial)
  }

  function replaceTexture( phi,theta, scene,index,handleClickOpen,isHint,data,isTutorial){
    const objjj =  CreateIcon(handleClickOpen,index,isHint,data,isTutorial)
    objjj.position.set( phi* 5, theta, theta * 5 );
    objjj.position.setFromSphericalCoords(9.1,phi,theta)
    objjj.index = index
    objjj.name = isHint?`hint${index}`:index
    objjj.visible=true
    scene.add(objjj) 
  }

  export default renderLabel