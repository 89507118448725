import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import Slider from "@mui/material/Slider";
import { useHistory } from "react-router-dom";
import SubQuiz from "./SubQuiz";
let notplayed = true;
const fixFormat = (num) => {
  if (num < 10) {
    num = "0" + String(num);
  }
  return num;
};

const formatTime = (time) => {
  time = Math.floor(time);
  let seconds = Math.floor(time / 60);
  let minutes = fixFormat(Math.floor(time % 60));
  return [seconds, minutes];
};
let intervalID;

export default function Quiz({
  texts,
  txtlang,
  AudioMute,
  playclicksfx,
  playQuizTimerFirst,
  playredTimersfxsfx,
  stopsfx,
  handleSubmit,
  SetIsActive,
  IsActive,
  data,
  openQuiz,
  setopenQuiz,
  stopQuizTimerFirst,
}) {
  const [Selected, SetSelected] = useState([]);
  const [isHurry, setisHurry] = useState(false);
  const [time, Settime] = useState(0);
  const history = useHistory();
  useEffect(() => {
    startTimer();
    return function cleanup() {
      notplayed = true;

      clearInterval(intervalID);
      SetIsActive(false);
    };
  }, []);

  const startTimer = () => {
    intervalID = setInterval(() => {
      Settime((t) => {
        if (t >= 90) {
          clearInterval(intervalID);
          console.log("AAAAAAAAAAAAAA");
          handleSubmit(false);
          stopsfx();
        } else if (t >= 70 && notplayed) {
          notplayed = false;
          console.log("HURRRY");
          setisHurry(true);
          stopQuizTimerFirst();
          playredTimersfxsfx();
        } else if (notplayed) {
          playQuizTimerFirst();
        }
        return t + 1;
      });
    }, 1000);
  };

  const handleSelect = (index) => {
    playclicksfx();
    if (!IsActive) {
      const oldf = index;
      let old = [...Selected];
      if (old.includes(oldf)) {
        old = old.filter((f) => f !== oldf);
      } else {
        old.push(oldf);
      }
      SetSelected(old);
    }
  };
  const validateAnswer = () => {
    stopsfx();
    notplayed = false;

    const correctAnswers = data.ca;
    const userSelection = Selected;
    if (Selected.length !== correctAnswers.length) {
      handleSubmit(false);
      stopQuizTimerFirst();

    } else {
      stopQuizTimerFirst();
      let allCorrect = true;
      for (let s of Selected) {
        const isIncluded = correctAnswers.includes(s);
        if (isIncluded == false) {
          allCorrect = false;
          break;
        }
      }
      handleSubmit(allCorrect);
    }
  };

  const sliderStyle={
    marginTop:{md:"-12px",xs:"-19px"},

    height: "10px",
    "& .MuiSlider-rail": {
      borderRadius: "0",
      color: isHurry ? "rgba(188, 31, 0, 0.3)" : "rgba(233, 131, 0, 0.3)",
      transition: "all 1s linear",
    },
    "& .MuiSlider-track": {
      borderRadius: "0",
      color: isHurry ? "#CD202C" : "#E98300",
      transition: "all 1s linear",
    },
    "& .MuiSlider-thumbColorPrimary": {
      width: "0px",
      height: "15px",
      transition: "all 1s linear",
      marginTop:{xs:"-10px",md:"0"},

      '@media (max-width:800px)': {
        transform:"rotateZ(180deg)",
      },
    },
    "& .MuiSlider-valueLabelOpen": {
      borderRadius: "0",
      background: isHurry ? "#CD202C" : "#E98300",
      fontFamily: "Avenir",
      fontWeight: 800,
      fontSize: {md:"16px",xs:"12px"},
      '@media (max-width:800px)': {
        padding: "0px 8px 2px",
      },
      lineHeight: "22px",
      letterSpacing: "0.1em",
      transition: "all 1s linear",
    },
    "& .MuiSlider-valueLabelLabel":{
      '@media (max-width:800px)': {
        transform:"rotateZ(180deg)",
      },
      display:{xs:"block",md:"inline"}
    }
  }
   
  return ( 
      <Dialog
        onClose={()=>console.log("attempt to close")}
        aria-labelledby="customized-dialog-title"
        open={openQuiz}
        sx={{
          '& .MuiPaper-root ': {
            maxWidth:{md:"70%",xs:"100vw"},
            maxHeight:{xs:"100vh"},
            margin:{xs:"0"},
            borderTopRightRadius: 0,
            borderTop:{
              md:IsActive?"10px solid #E98300":"",
              xs:IsActive?"16px solid #E98300":""
            }
        }, 
          "& .MuiPaper-root":{
            overflowY:"visible",
            '@media (max-width:800px)': {
              height:"100%",
              overflowY:"scroll"
            },
          }
        }}>
 
        <Grid container spacing={2} sx={{alignItems:"center"}} >
          <Grid container item xs={12}>
        {!IsActive? (<Slider
              max={90}
              disabled
              sx={sliderStyle}
              valueLabelFormat={(t) => {
                const [m, s] = formatTime(90 - t);
                return `${m}:${s}`;
              }}
              value={time}
              aria-label="Small"
              valueLabelDisplay="on"
            />
          ) : null}

          <SubQuiz
            texts={texts}
            txtlang={txtlang}
            AudioMute={AudioMute}
            validateAnswer={validateAnswer}
            Selected={Selected}
            handleSelect={handleSelect}
            handleSubmit={handleSubmit}
            SetIsActive={SetIsActive}
            IsActive={IsActive}
            data={data}
            openQuiz={openQuiz}
            setopenQuiz={setopenQuiz}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
}
