import * as React from 'react';
import cover from "./cover.png"
 
import Box from '@mui/material/Box'; 
import Typography from '@mui/material/Typography';
 
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as box } from "../Areas/icons/box.svg"
import { ReactComponent as fabrica } from "../Areas/icons/fabrica.svg"
import { ReactComponent as montagem } from "../Areas/icons/montagem.svg"
import { ReactComponent as pc } from "../Areas/icons/pc.svg"

export default function CustomizedDialogs({loadedDone,title,loadingProgress,Sectiontitle,moduleName} ) {

    let part1,part2
    if(Sectiontitle!==undefined){
        [part1 , part2] = Sectiontitle.split("-")
    }
    const LineStyle={
        background:"#E98300",
        height:"9px", 
        transition: "all 0.3s ease"
    }
    const fontStyle= {
        fontFamily:"AvenirBold",
        fontWeight: "900",
        fontSize: "80px",
        lineHeight: "115px",
        display: "flex",
        alignItems: "center",
        color:"white",
        letterSpacing: "0.02em",
        textTransform: "uppercase",
        marginTop:"30px",
        marginBottom:"20px"
    }
    const buttonStyle={ 

        minWidth: "200px",
        borderRadius: "1px",
        boxShadow: "none",
        padding: "15px 40px", 
        fontWeight: "900",
        fontSize: "37px",
        lineHeight: "51px",
        letterSpacing: "0.04em",
        color: "#fff",
        outline: "0",
        justifyContent: "center",
        border: "0",
        margin: "0",
        display:"inline-flex",
        textAlign:"center",
        verticalAlign: "middle",
        textTransform: "uppercase",
        backgroundColor: "#E98300",
        marginTop:"80px",
    }
    const smallTitle={
        fontFamily:"AvenirBold",
        fontWeight: 400,
        fontSize: '32px',
        lineHeight: '70px',
        textTransform: 'uppercase',
        color: '#FFFFFF',
        marginBottom:"70px"
    }
const selectIcon = (moduleName)=>{
    if(moduleName === "Pre-Prefabrication"){
        return fabrica
    }else if (moduleName === "Assembly"){
        return montagem 
    }else if(moduleName === "Administrative"){
        return pc
    }
}
  return ( 
     <Box 
     sx={{
         background:`url(${cover})`,
         width:"100vw",
         height:"100vh",
         backgroundSize: "cover",
         backgroundPositionY: "center",
         backgroundPositionX: "center",
     }}>
        <Box 
        sx={{
            background:"rgba(46, 73, 87, 0.85)",
            width:"100vw",
            height:"100vh", 
            display:"flex",
            alignItems:"center"
        }}>
            <Box 
            sx={{
                width:"100%",  
                padding:"0 228px"
            }}>
                <Typography sx={smallTitle}  >
                    {moduleName=="Warehouse"?
                    <SvgIcon sx={{width:"100px",height:"90px", marginRight:" -3px", marginBottom:"-36px" }}  component={box} inheritViewBox /> :
                    <SvgIcon sx={{width:"41px",height:"40px", marginRight:"20px", marginBottom:"-7px" }}  component={selectIcon(moduleName)} inheritViewBox />
                    }

                {title}
                </Typography> 
                <Box sx={{...LineStyle, 
                position: "relative" ,
                left:"490px", 
                left: "-228px",
                width:`calc(${loadingProgress*100}% )`}}></Box>
               <Typography sx={fontStyle}  >
               {part1} 
                </Typography> 
                <Box sx={{...LineStyle, 
                    width:"150%",
                    position:"absolute",
                    left:`calc(${100 - loadingProgress*100}% + 228px)`
                    }}></Box>
                
               {part2?<Typography sx={buttonStyle}>
                    {part2}
                 </Typography>:null }  
            </Box>
            
        </Box>
        <Box sx={{
            fontFamily: "Avenir",
            position: "absolute",
            bottom: "65px",
            left: "49%",
            fontSize: "32px",
            color: "#fff",
            fontWeight: "bold",
        }} >
            {!loadedDone?<>{Math.round(loadingProgress*100)}%</>:"Get ready"}
                
            </Box>
    </Box>
  );
}