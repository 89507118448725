import React from "react";

import SvgIcon from "@mui/material/SvgIcon";
import Stack from "@mui/material/Stack";

import { ReactComponent as left } from "../icons/left.svg";
import { ReactComponent as right } from "../icons/right.svg";

export default function NavigateBtn({
  handleInfobuttonclicked,
  setwillroute,
  setActiveIndex,
  ActiveIndex,
  length,
  playclicksfx
}) {
  
  function handleClick(direction) {
    playclicksfx()
    if (direction == -1 && ActiveIndex == 0) {
      setActiveIndex(length - 1);
    } else if (direction == 1 && ActiveIndex == length - 1) {
      setActiveIndex(0);
      if(handleInfobuttonclicked !==undefined){
        handleInfobuttonclicked(false)
      }else{
        setwillroute(true);
      }
    } else {
      // setwillroute(false)
      setActiveIndex(ActiveIndex + direction);
    }
  }
  const btnStyle = {
    cursor: "pointer",
    width: {md:60,xs:40},
    height: {md:60,xs:40},
    opacity: 1,
    transition: "0.3s all ease",
    "&:hover": {
    opacity: 0.7,
    },
  };

  return (
    <Stack direction="row" spacing={{md:2.5,xs:1.5}}>
      {ActiveIndex !== 0 ? (
        <SvgIcon
          sx={btnStyle}
          onClick={() => handleClick(-1)}
          component={left}
          inheritViewBox
        />
      ) : null}
      <SvgIcon
        sx={btnStyle}
        onClick={() => handleClick(1)}
        component={right}
        inheritViewBox
      />
    </Stack>
  );
}
