import React, {useEffect, useRef,useState } from "react"; 
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
 
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as cupIcon } from "../../assets/icons/supIcon.svg"
import { ReactComponent as win } from "../../assets/icons/win.svg"
// value={[0,5,10]}
//0 time , 1 GameScore , 2 totalScore
export default function SimplePaper({isPT,istimeleft,txtlang,title,value,id,colored}) { 
    const [type, val1,val2] = value
    const styles={
      fontSize:"48px",
      letterSpacing: "0.03em",
    }
    function renderValue(){
      if(type ===0){
          return <Typography sx={styles}   >{val1}:{val2}</Typography>
      } else if (type===1){
        
        return <Typography  sx={styles}  >{val1}<Typography component="span" sx={{...styles, fontSize:"24px"}}> /{val2} </Typography> </Typography>
      }   else{
          return <Typography sx={styles} >{val1}</Typography>
      }    
    }
   console.log("txtlang",isPT)
  //  console.log("txtlang",txtlang =="English version")
  return (
    <Box
    id={id}
      sx={{
        zIndex:1,
        display: 'flex',
        flexWrap: 'wrap',
        '& > :not(style)': {
          textAlign:"center",
          minWidth:150, 
          height:97,
        p:"24px 20px 18px",
        display:"flex",
        color:"#fff",
        justifyContent:"space-between",
         flexDirection : "column",
        justifyContent: "center",
        alignItems: "center", 
        fontWeight:300
        },
      }}
    >
      <Box   
      sx={{ 
        zIndex:1,
          background: (theme) => !colored&&id?theme.palette.primary.main:theme.palette.secondary.main
       }} >
        <Typography sx={{ 
            color: "rgba(255, 255, 255, 0.5)", 
            fontSize: "16px",
            letterSpacing: "0.1em",
            textTransform: "uppercase",
            display:"flex",
            alignItems:"center",
            fontWeight:"bold",
            marginLeft: "-7px"
            }}  >
               
            <SvgIcon sx={{
              width:type==0?12:18, 
              margin:"0px 8px"
            }}  component={type==0?cupIcon:win} inheritViewBox /> 
             {title}
  
        </Typography> 
            {renderValue()}
      </Box>
     
    </Box>
  );
}
