
import * as THREE from 'three';
import { CSS2DRenderer, CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer.js';

import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import {onDocumentMouseClick,ononDocumentMouseMove}from "./interactions"
import  {GUI} from 'three/examples/jsm/libs/dat.gui.module';
import TWEEN from "@tweenjs/tween.js";
// ./jsm/libs/lil-gui.module.min.js
import drawHotspot from "./drawhotspot"
import Stats from "stats-js"; 
let AnimationFrameID 
let handleHotspotClick,labelRenderer,nextGame
const stats = new Stats();
// phi , theta, 0->hidden, 1->visible, 2->hint


function AnimateNumberChange(end, start, setRad) {
    let controlsTarget =  new THREE.Vector3(0, 0, 0);
    let target2 =  new THREE.Vector3(-9.0, -0.99, 0.27);
    const tempph = new THREE.Spherical().setFromVector3(target2)
    console.log("TTTTT",tempph)
    tempph.radius=3
    tempph.theta= tempph.theta+0.04

    let target = new THREE.Vector3().setFromSpherical(tempph)

    // camera.rotation.x = x*200
    // camera.rotation.y = y*200
    // camera.rotation.z = z*200
    const tween = new TWEEN.Tween(controlsTarget).to(target, 2000);

    tween.onUpdate(() => {
        const { x ,y,z} = controlsTarget;
        controls.target = new THREE.Vector3(x, y, z);
        // camera.rotateX(x*10)
        // camera.rotation.x = x*10

        // i+=1
    camera.rotation.x = x*200
    camera.rotation.y = y*200
    camera.rotation.z = z*200
        // camera.lookAt({x:controlsTarget.x,y:controlsTarget.y,z:controlsTarget.z})

        // TODO do action here
        // controls.target = new THREE.Vector3(x, y, z);

        return x;
    });

    tween.easing(TWEEN.Easing.Linear.None);
    tween.start();
    console.log("HHHHH",tween)
}

const API = {
    phi: 1.5, 
    theta: -1.5, 
}; 
let testHotspot
// let gui = new GUI( { title: 'Intensity' } );
// gui.add( API, 'phi', 0, Math.PI *2, 0.02 ).name('phi').onChange(()=>updatepos(API.phi,API.theta));
// gui.add( API, 'theta', 0, Math.PI *2, 0.02 ).name('phi').onChange(()=>updatepos(API.phi,API.theta));


function updatepos(phi,theta){
    testHotspot.position.setFromSphericalCoords(10,phi,theta)
}
        
    let camera, scene, renderer,controls;

    const hotspotGroup = new THREE.Group()
    hotspotGroup.name="hotspots"
function createhotspot(phi,theta){
    const geometry = new THREE.SphereGeometry( 0.5, 60, 40 );
    // invert the geometry on the x-axis so that all of the faces point inward
    geometry.scale(   1, 1, 1 );
    // let pos = new THREE.Spherical(10,0,Math.PI/2)
    const material = new THREE.MeshBasicMaterial(   );

    testHotspot = new THREE.Mesh( geometry, material );
    testHotspot.position.setFromSphericalCoords(10,phi,theta)
    // scene.add( testHotspot );
}

const quality = window.devicePixelRatio
// const quality = 0.4
 let generalData 
// function handleMClick(e){
//      onDocumentMouseClick(e,handleHotspotClick)
// }

function rotatecamera (){

}
function handleKeyDowm(e){
    console.log("camera",camera)
    console.log("camera",controls)
    if(e.keyCode==37){
        //left
        API.theta +=0.02
        
    }else if(e.keyCode==39){
        API.theta -=0.02
        //right
    }else if(e.keyCode==40){
        API.phi +=0.02
        // dowm
    }else if(e.keyCode==38){
        API.phi -=0.02
        // up 
    }else if(e.keyCode==32){
        navigator.clipboard.writeText(`[${API.phi.toFixed(2)},${API.theta.toFixed(2)}],`)
    } else if(e.keyCode ==68){
        API.theta -=0.2

// rgiht
    } else if(e.keyCode ==65){
        API.theta +=0.2

    } else if(e.keyCode ==83){
        API.phi +=0.2

    } else if(e.keyCode ==87){
        // up
        API.phi -=0.2

    }else if(e.keyCode ==70){
        nextGame()
    }
        updatepos(API.phi,API.theta)

}

function init ( handleClickOpen , data ,AllHotSpotsDisovered ,thetexture, istutorial ) { 
    console.log("istutorial",istutorial)
    nextGame = AllHotSpotsDisovered
    generalData = data
    handleHotspotClick = (id,selectedObject,e)=> handleClickOpen(id,selectedObject,e,generalData)
    const container = document.getElementById( 'canvContainer' );

    camera = new THREE.PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 1, 1100 );
//     x: 0.5346188953591097
// y: 0.07508124978094023
// z: 0.11852907578789068

    camera.position.x = 0.93
    camera.position.y = 0.07
    camera.position.z = 0.11
    scene = new THREE.Scene();

    renderer = new THREE.WebGLRenderer();
    renderer.setPixelRatio(quality );
    renderer.setSize( window.innerWidth, window.innerHeight );
    // container.appendChild( renderer.domElement );
 
    container.addEventListener("keydown", handleKeyDowm, false);
    // container.addEventListener("click", handleMClick, false);
    // container.addEventListener("mousemove", ononDocumentMouseMove, false);
    // container.addEventListener("touchstart", handleMClick, false);

    window.addEventListener( 'resize', onWindowResize );

    createvideo(thetexture)
    generalData.forEach(({position},index)=>{
       const [phi,theta] = position
        // drawHotspot(phi,theta,hotspotGroup,index,true)
        drawHotspot(phi,theta,hotspotGroup,index,handleHotspotClick,generalData,istutorial)
       
    })
    scene.add(hotspotGroup)
    // helper()
    // helper(1)
    // helper(2)

  stats.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom
    // document.body.appendChild(stats.dom);

    // ----------------
     

    labelRenderer = new CSS2DRenderer();
    labelRenderer.setSize( window.innerWidth, window.innerHeight );
    labelRenderer.domElement.style.position = 'absolute';
    labelRenderer.domElement.style.top = '0px';
    container.appendChild( labelRenderer.domElement );
    container.appendChild( renderer.domElement );
 
    //----------------
    setupControls(istutorial);

}
let texture ,material,mesh
function createvideo(thetexture){
    const geometry = new THREE.SphereGeometry( 10, 60, 40 );
    // invert the geometry on the x-axis so that all of the faces point inward
    geometry.scale( - 1, 1, 1 );

     material = new THREE.MeshBasicMaterial( { map: thetexture } );

     mesh = new THREE.Mesh( geometry, material );
        createhotspot(1.5,-1.5)

    scene.add( mesh );
}



function helper(type){
    const size = 100;
    const divisions = 40;
    
    const gridHelper = new THREE.GridHelper( size, divisions,0xff0000 );
    if (type==1){
        gridHelper.rotateZ(Math.PI/2)
    }else if (type==2){
        gridHelper.rotateX(Math.PI/2)
    }
    scene.add( gridHelper );
}

function onWindowResize() {

    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();

    renderer.setSize( window.innerWidth, window.innerHeight );
    labelRenderer.setSize( window.innerWidth, window.innerHeight );

}

function animate() {
    // stats.begin();
    AnimationFrameID = requestAnimationFrame( animate );
    update();
    // stats.end();
}
function update() {


    renderer.render( scene, camera );
    labelRenderer.render( scene, camera );
    TWEEN.update();
    controls.update(); 
}


function setupControls(istutorial) {
    console.log("istutorial",istutorial)
    controls = new OrbitControls(camera, labelRenderer.domElement);
   
     controls.target = new THREE.Vector3(-0.15, 0, 0);
   
     controls.zoomSpeed = 2; 
     controls.rotateSpeed = 0.6; 
     

    //  controls.maxDistance = 20;
     controls.maxDistance = 4;
     controls.minDistance = 0.01;
     controls.enableKeys= false 
     if(istutorial){
         controls.enabled=false
     }
    
   }

   function removeHots (){
        hotspotGroup.children.forEach(c=>{ 
            hotspotGroup.remove(c) 

        })
    update();

     }
   function Cleanup(){
    const container = document.getElementById( 'canvContainer' );

    cancelAnimationFrame(AnimationFrameID) 
    removeHots()
    removeHots()
    removeHots() 
    removeHots() 
    removeHots() 
    removeHots() 
    // scene.remove(hotspotGroup)
    generalData.hotspots = []
    // controls=null
    // camera = null
    // texture.dispose()
    // material.dispose()
    renderer.dispose()
    
        if(labelRenderer.domElement.parentNode){
            labelRenderer.domElement.parentNode.removeChild(labelRenderer.domElement)
            renderer.domElement.parentNode.removeChild(renderer.domElement)
        }
    

    // container.removeEventListener("click", handleMClick);
    // container.addEventListener("mousemove", ononDocumentMouseMove, false);
    // container.removeEventListener("touchstart", handleMClick);

    window.removeEventListener( 'resize', onWindowResize );
   }
const updatematerial = (t)=>{
    mesh.material =  new THREE.MeshBasicMaterial( { map: t } );
    animate()
}

export {init,animate , camera,hotspotGroup,scene,Cleanup,removeHots,updatematerial,AnimateNumberChange}