import React, {useState ,useEffect } from "react";

import cover from "../Thankyou/thankcover.png"
import lostcover from "./lost.png"
import { Link } from "react-router-dom";
 
import Box from '@mui/material/Box'; 
import Typography from '@mui/material/Typography';  
import Container from '@mui/material/Container'; 
import Button from '@mui/material/Button'; 
import Stack from '@mui/material/Stack'; 
import { ReactComponent as smile } from './smile.svg';
import { ReactComponent as sad } from './sad.svg';
import { ReactComponent as marked } from './marked.svg';
import SvgIcon from '@mui/material/SvgIcon'; 
import Grid from '@mui/material/Grid'; 
import Drawer from '@mui/material/Drawer'; 
import ReviewResults from "./ReviewResults" 


const fixFormat = num =>{
    if (num<10){
        num = "0" + String(num)
    }
    return num
  }
  
  const formatTime = time=>{
    time = Math.floor(time)
    let seconds =  (Math.floor(time/60))
    let minutes =fixFormat(Math.floor(time%60))
    return [seconds , minutes]
  }  
  
  const fixTxt={
    Assembly:{
        "English version":"Assembly",
        "Portuguese version": "Montagem",
    },
    ficha:{
        "English version":"Prefabrication",
        "Portuguese version": "Pré-Fabricação",
    },
    administrativa:{
        "English version":"Administrative area",
        "Portuguese version": "Área administrativa",
    },
    aram:{
        "English version":"Warehouse",
        "Portuguese version": "Armazém",
    }
  }

export default function CustomizedDialogs({texts,txtlang,finsihedModule,playclicksfx,UserTime,detailedScore,handleConfirmationOpen}) {
  const {totTime,scorelow,totScore,ts,ops,weldone,scoreh,and,ended,are,is,nwcompleted,ca,revres ,sendRes ,plyagain}=texts
    const [openDrawer,setopenDrawer] = useState(false)
    const [score,setscore] = useState(0)
    const [correctAns, setCorrectAns] = useState(0)
    const [incorrectAns, setIncorrectAns] = useState(0)
    // const suceed = true
    const suceed = score>80?true:false

    const [seconds, minutes] = formatTime(UserTime)

  const titleStyle={ 
    fontFamily: 'Avenir',
    fontWeight: 800,
    fontSize: "24px",
    lineHeight: "70px",
    textTransform: "uppercase",
    color: "#2E4957",
    '@media  (max-width:800px)': {
       textAlign:"center",
       fontSize:"12px",
      }
    
}

const infoTextStyle={
    width:{md:"792px",xs:"100%"}, // TODO may comment
    fontFamily: 'AvenirBold',
    fontWeight: 900,
    fontSize: {md:"90px", xs:"38px"},
    lineHeight: {md:"90px", xs:"44px"},    
    textTransform: "uppercase",
    color: "#2E4957",
    textAlign:{md:"left",xs:"center"},
    marginTop:{md:"0",xs:"-10px"}

    // '@media  (max-width:800px)': {
    //     textAlign:"center",
    //     fontSize:"38px",
    //      border:"1px solid red"
    //    }
    
}
 
const btnstyle={
    width:{md:"340px",xs:"100%"},
    padding:{md:"20px 0px",xs:"14px 0px"} , 
    fontWeight:500,
    fontSize: {md:"18px",xs:"12px"},
    lineHeight: "25px", 
    letterSpacing:"0.1em",
}
const bodyTextStyle={
    fontWeight: 500,
//     font-size: 14px;
// line-height: 26px;
    fontSize: {md:"26px",xs:"14px"},
    lineHeight:{md: "36px",xs:"26px"},
    color: "#2E4957",
    maxWidth:{md:"600px",xs:"100%" },
    padding:{md:0, xs:"0 40px"},
    marginTop:"10px",
    textAlign:{md:"left",xs:"center"}

}
const boxStyle={
    height:{md:"237px",xs:"140px"},
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",

}

const boxTitleStyle={
    // marginTop:"-10px",
fontWeight: 900,
fontSize: {md:"16px",xs:"10px"},
lineHeight: "22px",
textAlign: "center",
letterSpacing: "0.1em",
textTransform:" uppercase",
color: "#FFFFFF",

}
const boxBodyStyle={
    marginTop:"10px",
    fontWeight: 900,
    fontSize: {md:"80px",xs:"40px"},
    lineHeight: {md:"109px",xs:"55px"},
    letterSpacing: "0.03em",
    textTransform: "uppercase",
    color: "#FFFFFF",
    textAlign:"center"
}
const tryagainStyle={
    fontWeight: 500,
    fontSize: {md:"18px",xs:"12px"},
    lineHeight: {md:"25px",xs:"16px"},
    textAlign: "center",
    letterSpacing: "0.1em",
    textDecoration: "underline",
    textTransform: "uppercase",
    color: "#2E4957",
    marginTop:{md:"20px",xs:"10px"}
}

const containerStyle={
    background:"white",
    width:"50vw",
    height:"100vh",
    // position:"fixed",
    paddingLeft:"148px",
    display:"flex",
    justifyContent:"center",
    flexDirection:"column"
}

const toggleDrawer=(val)=>{
    playclicksfx()
    setopenDrawer(val)
} 
useEffect(() => {  
    const {risk, quiz, find} = detailedScore
    let acumulatedScore = risk[0] + quiz[0] + find[0] 
    let TotalPossibleScore = risk[1] + quiz[1] + find[1]
    const sucessRatio = Math.round((acumulatedScore/TotalPossibleScore)*100)
    // setscore(82)
    setscore(sucessRatio)

    setCorrectAns(acumulatedScore/10)
    setIncorrectAns((TotalPossibleScore - acumulatedScore)/10)
 
 }, []);

  return ( <>
        <Drawer
            anchor="right"
            open={openDrawer}
            onClose={()=>toggleDrawer(false)}
          >
            <Box sx={containerStyle} > 
            <ReviewResults texts={texts} txtlang={txtlang} detailedScore={detailedScore} toggleDrawer={toggleDrawer}/>
            </Box>
        </Drawer>

     <Box 
     sx={{
         background:`url(${suceed?cover:lostcover})`,
         width:"100vw",
         height:"100vh",
         backgroundSize: "cover",
         backgroundPositionY: "center",
         backgroundPositionX: "center",
     }}>
        <Box 
        sx={{
            background:"rgba(255, 255, 255, 0.85)",
            width:"100vw",
            height:"100vh", 
            display:"flex",
            // paddingLeft:"40px",
            display:"flex",
            flexDirection:"column",
            alignItems:"center",
            justifyContent:"center",

        }}>
  
        <Container  maxWidth="xl">
            <Typography sx={titleStyle}  >
                {ended[txtlang]}
            </Typography> 
            
            {suceed?<Typography sx={infoTextStyle}  >
                {weldone[txtlang][0]}
                <Typography sx={{display:{md:"block",xs:"none"}}} component={"br"}></Typography>
                {weldone[txtlang][1]}

                <SvgIcon sx={{ marginLeft:{md:"30px",xs:"10px"},width:{md:67,xs:30},height:{md:67,xs:30} }}
                component={smile} inheritViewBox />
            </Typography>:<Typography sx={infoTextStyle}  >
                {ops[txtlang][0]}
                <SvgIcon sx={{ display:{md:"none",xs:"inline"}, marginLeft:{md:"30px",xs:"10px"},width:{md:67,xs:30},height:{md:67,xs:30}} }
                component={sad} inheritViewBox />
                <Typography  component={"br"}></Typography>

                {ops[txtlang][1]}

                <SvgIcon sx={{ display:{md:"inline",xs:"none"}, marginLeft:{md:"30px",xs:"10px"},width:{md:67,xs:30},height:{md:67,xs:30}} }
                component={sad} inheritViewBox />
            </Typography>}
            
            <Typography sx={bodyTextStyle}  >
            {suceed? <>{finsihedModule.map((e,index)=><span> {fixTxt[e][txtlang]}{index==finsihedModule.length-2? and[txtlang]:(index!==finsihedModule.length-1?",":"") } </span>)} <>{finsihedModule.length>1?are[txtlang]:is[txtlang]} {nwcompleted[txtlang]}{txtlang==="Portuguese version"?(finsihedModule.length>1?"s":""):""} </>
                <SvgIcon sx={{ marginLeft:"8px", marginBottom:"0px",idth:18,height:18} }
                component={marked} inheritViewBox />
                </>:scoreh[txtlang] }
  
            </Typography>
       
            <Grid container sx={{
              justifyContent:"center",
              marginTop:{md:'50px',xs:"20px"},            
        }} spacing={{xs:1,md:2.5}} >
         
        <Grid  item lg={2.42}  sm={6} xs={6}>
            <Box sx={{...boxStyle, background:"#8FB838"}}>
                <Typography sx={boxTitleStyle}  >
                    {ca[txtlang]}
                </Typography>
                <Typography sx={boxBodyStyle}  >
                    {correctAns}
                </Typography>
            </Box>
        </Grid>
        <Grid  item lg={2.42}  sm={6} xs={6}>
            <Box sx={{...boxStyle,background: "#BC1F00"}}>
            <Typography sx={boxTitleStyle}  >
            {scorelow[txtlang]}
                </Typography>
                <Typography sx={boxBodyStyle}  >
                    {incorrectAns}
                </Typography>
            </Box>
        </Grid>     <Grid  item lg={3.58}  sm={5} xs={6}  >
            <Box sx={{...boxStyle,background:"#747678"}}>
            <Typography sx={boxTitleStyle}  >
                    {totTime[txtlang]}
                </Typography>
                <Typography sx={boxBodyStyle}  >
                     {seconds}:{minutes}
                </Typography>
            </Box>
        </Grid>     <Grid  item lg={3.58}  xs={6} >
            <Box sx={{...boxStyle,background:suceed?"#8FB838":"#BC1F00"}}>
            <Typography sx={boxTitleStyle}  >
                {ts[txtlang]}
                </Typography>
                <Typography sx={boxBodyStyle}  >
                {score}
                <Typography component="span" sx={{...boxBodyStyle,
                fontSize: {md:"48px",xs:"24px"},lineHeight: "66px"}} >/100 </Typography>
                </Typography>
            </Box>
        </Grid>
        </Grid>
        </Container>
        {suceed?<> 

            {/* sx={{marginTop:"80px"}}  */}
            <Stack sx={{marginTop:{md:"80px",xs:"18px"},width:"calc(100% - 32px)" , justifyContent:"center"}} direction={{md:"row",xs:"column"}} spacing={1} >
                <Button sx={btnstyle} variant="contained" color="secondary" onClick={()=>toggleDrawer(true)}>
                    {revres[txtlang]}
                </Button> 
                
                <Button  component={Link} to={"finish"}sx={{...btnstyle}}  variant="contained" color="primary">
                {sendRes[txtlang] }
                </Button>
            </Stack>
              <Button onClick={handleConfirmationOpen} sx={tryagainStyle}  color="primary">
                {plyagain[txtlang]}
                </Button>       
        </>: <Button  onClick={handleConfirmationOpen} 
        sx={{...btnstyle,marginTop:{md:"80px",xs:"18px"},width:{md:"340px",xs:"calc(100% - 32px)"}}}  variant="contained" color="primary">
             {plyagain[txtlang]}
                </Button>}

        </Box>
    </Box>
  </>

  );
}