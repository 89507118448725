import * as React from 'react';
import MiniPaper from "./MiniPaper" 
import SquarePaper from "./SquarePaper"  
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import AccountBtn from "./AccountBtn"
import { ReactComponent as StarIcon } from '../../assets/icons/logo.svg';
import { ReactComponent as progrs } from './prog.svg';
import { ReactComponent as scor } from './scor.svg';
import { ReactComponent as hotspot } from './hotspot.svg';
import { ReactComponent as show } from './show.svg';
import SvgIcon from '@mui/material/SvgIcon';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as HideEye } from "../../assets/icons/HideEye.svg"
import { ReactComponent as cupIcon } from "../../assets/icons/supIcon.svg"


// TODO: REMOVe it
const fixFormat = num =>{
  if (num<10){
      num = "0" + String(num)
  }
  return num
}

const formatTime = time=>{
  time = Math.floor(time)
  let seconds = fixFormat (Math.floor(time/60))
  let minutes =fixFormat(time%60)
  return [seconds , minutes]
}

export default function GameHeader({isPT,texts,txtlang,willshowHints,showHint,activeHotspot ,totlaHotspots,isTut,disableTotalTime,skipGame,TotalScore,gameIndex,totalGames,timeLeft,settimeLeft,gameScore,maximumScore,colored}) {
  const[seconds,minutes] = formatTime(timeLeft)
  const {gs,ts,tl,prog}=texts
  const valuestyle = {
    background:'#2E4957',
    color:"white",
    margin:" 0 4px",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "19px",
    letterSpacing: "0.03em",
    padding:"4px 9px",
    display:"flex",
    justifyContent:"center",
    alignItems:"center"
  }

return (
  <>
    <Box  sx={{
      width:"100%",  
      display:{xs:'flex',md:"none"}, 
      justifyContent: "flex-end", 
      position:"absolute",
      zIndex:100,
      transform:"rotate(90deg) translateY(50%) translateX(50%)",
      position:"absolute",
      right:"-50vw",
      top:"80px"
      

}}>

      <Box sx={{
        display: "flex",

        // position:"absolute",
        // right:"0px",
        // top:"200px"

      }}>
        <Box sx={valuestyle}>
        <SvgIcon sx={{
          width:"10.5px",
          height:"12px",
          transform:"rotate(-90deg)",
          marginRight:"9px"

        }}  component={progrs} inheritViewBox />

        {gameIndex}/{totalGames}
        </Box>
          <Box sx={valuestyle}>
          <SvgIcon sx={{
          width:"12px",
          height:"13.5px",
          transform:"rotate(-90deg)",
          marginRight:"9px"

        }}  component={scor} inheritViewBox />
          {gameScore}/{maximumScore}
        </Box>
        <Box sx={valuestyle}>
          <SvgIcon sx={{
          width:"12px",
          height:"13.5px",
          transform:"rotate(-90deg)",
          marginRight:"9px"

        }}  component={scor} inheritViewBox />
          {TotalScore}
        </Box>
        
        {disableTotalTime?<>
          <Box sx={valuestyle}>
        <SvgIcon sx={{
          width:"19px",
          height:"19px",
          // transform:"rotate(-90deg)",
          marginRight:"9px"

        }}  component={cupIcon} inheritViewBox />
         {seconds}:{minutes}
        </Box>

        <Box sx={valuestyle}>
        <SvgIcon sx={{
          width:"19px",
          height:"19px",
          transform:"rotate(-90deg)",
          marginRight:"9px"

        }}  component={hotspot} inheritViewBox />
        {activeHotspot}/{totlaHotspots}
        </Box>

        <IconButton
          onClick={()=> showHint()}

            sx={{
              width:"34px",
              height:"34px",
              transform:"rotate(-90deg)",
              marginLeft:"4px",
              background:"#E98300",
              borderRadius:"0"
            }}
          >
             <SvgIcon sx={{
              width:14*1.1,
              height:18*1.1,
        }}  component={ willshowHints?show:HideEye} inheritViewBox />
          </IconButton>
        </>:null}
       
      </Box>
    </Box>  

      <Box  sx={{
      width:"100%",  
      display:'flex', 
      justifyContent: "flex-end", 
      position:"absolute",
      zIndex:100}}>
    <Stack sx={{display:{xs:"none",md:"flex"}}} direction="row" spacing={1.5} >
  <Button variant='contained' onClick={skipGame}>Skip</Button>

      <MiniPaper title = {prog[txtlang]} denominator={gameIndex} numerator={totalGames}/>
    {disableTotalTime?<SquarePaper istimeleft={true} title = {tl[txtlang]} value={[0,seconds,minutes]}/>:null}
      <SquarePaper  isPT={isPT} txtlang={txtlang} colored={colored} id="gameScore" title = {gs[txtlang]} value={[1,gameScore,maximumScore]}/>
      <SquarePaper txtlang={txtlang} title = {ts[txtlang]} value={[2,TotalScore]}/>
      <AccountBtn/>
    </Stack>
    
    </Box>  
    </>
    );
  }
  