import React, {useState ,useEffect } from "react";

import Box from '@mui/material/Box'; 
import Typography from '@mui/material/Typography';
import SvgIcon from '@mui/material/SvgIcon';  
import { ReactComponent as icon } from '../Tutorial/icons/rotateVideos.svg';

export default function ReadyQuestion({txt,ReadyQuestionpopup }) {
    const BodyStyle={ 
        marginTop:"25px",
        fontFamily: 'Avenir',
        fontWeight: 900,
        fontSize: "30px",
        lineheight: "68px",
        display: "flex",
        textTransform: "uppercase",
        color: "#FFFFFF",
    }
    useEffect(() => {  
        setTimeout(()=>{
            ReadyQuestionpopup(false)
        },5000)
     }, []);

  return ( 
     <Box 
     sx={{
         position:"fixed",
         width:"100vw",
         height:"100vh",
         backgroundSize: "cover",
         backgroundPositionY: "center",
         backgroundPositionX: "center",
     }}>
        <Box 
        sx={{
            width:"100vw",
            height:"100vh", 
            display:"flex",
        }}>
            <Box 
            sx={{
                display:"flex",
                alignItems:"center",
                justifyContent:"center",
                flexDirection:"column",
                width:"100%"
            }}>
            <Typography sx={BodyStyle}  >
                {txt}
            </Typography>
            </Box>
        </Box>
       
    </Box>
  );
}