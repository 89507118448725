import React, { useState, useEffect, lazy } from "react";
import Dialog from "../Dialog/Dialog";
import GameFooter from "../GameFooter/GameFooter";
import GameHeader from "../GameFooter/GameHeader";
import Splash from "../Splash/Splash";
import Ready from "../Ready/Ready";
import Quiz from "../Quiz/Quiz";
import FRE from "../FRE/FRE";
import Autostop from "../Autostop/Autostop";
import Riskpop from "../Riskpop/Riskpop";
import HurryUp from "../HurryUp/HurryUp";
import ReadyQuestion from "./ReadyQuestion";
import * as THREE from "three";

import {
  init,
  animate,
  Cleanup,
  scene,
  updatematerial,
} from "../../three/start";
import { Redirect, useHistory } from "react-router-dom";

import ScoreAnimation from "../scoreAnimation";
import VideoController from "../../three/VideoController";
import InteractionAnimation from "./Interaction";
import { videos } from "../../staticData/QuizData";
import useSound from "use-sound"; // ALEX
import hotspothintsfx from "../../staticData/sounds/hotspot-show-hints.wav";
import hotspotfoundsfx from "../../staticData/sounds/hotspot-hotspotfound.wav";
import redTimersfx from "../../staticData/sounds/red timer-quiz questions.wav";
import finishmodulesfx from "../../staticData/sounds/return-to-scenario-screen-after-completing-scenario.wav";
import scoresuceedsfx from "../../staticData/sounds/score-screen-sucess.wav";
import scorefailedfx from "../../staticData/sounds/score-screen-failed.wav";
import quizFirstSound from "../../staticData/sounds/quiz first 1.10 minute.mp3";

import {
  playHotspotMusic,
  playredTimersfxsfx,
  stop,
  playQuizTimerFirst,
  stopQuizTimerFirst,
} from "./utils";

let intervalID, videotag;
let Timestamp = true;
let autostopIndex = 0;
let autostopFlag = [false, false, false, false, false, false];
let timeWithNoInteractino = 0;
let playcorrectionvideo = false;
// completedScenario={completedScenario} setcompletedScenario={setcompletedScenario}
export default function RiskHunt({
  muteAudio,
  texts,
  txtlang,
  setCurrentExam,
  CurrentExam,
  muteBackgeound,
  playbackgroundfx,
  setmuteBackground,
  AudioMute,
  playclicksfx,
  gameIndex,
  setgameIndex,
  TutorialShown,
  finsihedModule,
  setUserTime,
  setdetailedScore,
  detailedScore,
  risk,
  TotalScore,
  setTotalScore,
  tokenExams,
  settokenExams,
  completedScenario,
  setcompletedScenario,
  loading,
  setLoading,
  ActiveScenario,
  setActiveScenario,
  isPT
}) {
  // const [playhotspotsfx] = useSound(hotspotfoundsfx, {
  //   volume: AudioMute ? 0 : 0.5,
  //   soundEnabled: !AudioMute,
  // }); // ALEX (click on hotspot sound)
  console.log("AudioMute",AudioMute)
  const [playshowhotspotsfx] = useSound(hotspothintsfx, {
    volume: AudioMute ? 0 : 0.5,
    soundEnabled: !AudioMute,
  });
  // const [playredTimersfxsfx, { stop }] = useSound(redTimersfx, {
  //   volume: 0.25,
  //   soundEnabled: !AudioMute,
  // });
  // const [playQuizTimerFirst, ] = useSound(
  //   quizFirstSound,
  //   { volume: 0.25, soundEnabled: !AudioMute }
  // );

  const [playfinishmodulesfx] = useSound(finishmodulesfx, {
    volume: AudioMute ? 0 : 0.5,
    soundEnabled: !AudioMute,
  });
  const [playscoresuceedsfx] = useSound(scoresuceedsfx, {
    volume: AudioMute ? 0 : 0.5,
    soundEnabled: !AudioMute,
  });
  const [playscorefailedfx] = useSound(scorefailedfx, {
    volume: AudioMute ? 0 : 0.5,
    soundEnabled: !AudioMute,
  });

  const SceneID = Object.keys(ActiveScenario)[0];
  const corrupted = Object.keys(ActiveScenario).length === 0;

  const [gameScore, setGameScore] = useState(0);
  const [isImg, setisImg] = useState(false);
  const [count, setCount] = useState({ x: 0, y: 0 });
  const [initScorePosition, setinitScorePosition] = useState({ x: 0, y: 0 });
  const [colored, setcolored] = useState(true);
  const [Played, setPlayed] = useState(false);
  const [loadingProgress, setloadingProgress] = useState(0);
  const [timeLeft, settimeLeft] = useState(300);
  const [data, setData] = useState({});
  const [ActiveID, setActiveID] = useState(0);
  const [openDialog, setopenDialog] = useState(false);
  const [willshowHints, setwillshowHints] = useState(true);
  const [currentTime, setcurrentTime] = useState(0);
  const [openQuiz, setopenQuiz] = useState(false);
  const [openFRE, setopenFRE] = useState(false);
  const [IsActive, SetIsActive] = useState(false);
  const [closeRotateAnimation, setcloseRotateAnimation] = useState(false);
  const [ReadyQuestionpopup, setReadyQuestionpopup] = useState(false);
  const [correctionVideo, setcorrectionVideo] = useState(false);
  const [PlayedcorrectionVideo, setPlayedcorrectionVideo] = useState(false);
  const [openstopPopup, setopenstopPopup] = useState(false);
  const [hasCorrection, sethasCorrection] = useState(false);
  const [openrestartPopup, setopenrestartPopup] = useState(false);
  const [openHurryUp, setopenHurryUp] = useState(false);

  const handleHurryUpClose = () => {
    timeWithNoInteractino = 0;
    playclicksfx();
    setopenHurryUp(false);
  };

  const history = useHistory();
  let animationTimeoutId, activeHotspot, totlaHotspots, maximumScore;

  const reset = () => {
    playbackgroundfx();
    setcorrectionVideo(false);
    Cleanup();
    blob = undefined;
    blobObj = undefined;
    clearInterval(intervalID);
    clearTimeout(animationTimeoutId);
    videotag = document.getElementById("video");
    setcurrentTime(0);
    setopenFRE(false);
    setopenQuiz(false);
    setopenDialog(false);
    setPlayed(false);
    settimeLeft(300);
    setGameScore(0);
    setinitScorePosition({ x: 500, y: -4000 });
    setopenHurryUp(false)
    autostopIndex = 0;
    timeWithNoInteractino = 0;
    autostopFlag = autostopFlag.map((e) => false);
    setPlayedcorrectionVideo(false);
    playcorrectionvideo = false;

    videotag.src = "";
  };
  useEffect(() => {
    if (!corrupted) {
      const d = randomStart();

      videotag = document.getElementById("video");
      return function cleanup() {
        const notAteemptedExams = scenarioData;
        const thisGame = notAteemptedExams[gameIndex];

        // TODO fix that
        if (thisGame.game == "quiz" && TutorialShown[0] === 0) {
        } else if (thisGame.game == "find" && TutorialShown[2] === 0) {
        } else if (thisGame.game == "risk" && TutorialShown[1] === 0) {
        } else {
          reset();
        }
      };
    }
  }, []);
  if (corrupted) {
    return <Redirect to={"/scenarios"} />;
  }
  const scenarioData = Object.values(ActiveScenario)[0];

  if (data.hotspots) {
    activeHotspot = data.hotspots.filter((d) => d.visible).length;
    totlaHotspots = data.hotspots.length;
  }

  if (data.game == "risk") {
    maximumScore = totlaHotspots * 10;
  } else {
    maximumScore = 10;
  }

  var blob, blobObj;
  function preloadVideo(data) {
    playbackgroundfx();
    const splits = videos[data.ID][0].split(".");
    const fileExtenstion = splits[splits.length - 1];
    if (fileExtenstion == "png") {
      setisImg(true);

      const loader = new THREE.TextureLoader();
      setLoading(true);

      loader.load(
        videos[data.ID][0],
        (texture) => {
          setLoading(false);

          init(handleClickOpen, data.hotspots, AllHotSpotsDisovered, texture);
          const old = { ...detailedScore };
          if (data.game == "risk") {
            old.risk[1] += data.hotspots.length * 10;
            setdetailedScore(old);
          }
          animate();
        },
        // onProgress callback currently not supported
        function (prog) {},

        // onError callback
        function (err) {
          console.error("An error happened.");
        }
      );
    } else {
      setisImg(false);
      var videotag = document.getElementById("video");
      setLoading(true);
      var xhr = new XMLHttpRequest();
      xhr.open("GET", videos[data.ID][0], true);
      xhr.responseType = "arraybuffer";

      xhr.onload = function (oEvent) {
        const texture = new THREE.VideoTexture(videotag);
        setLoading(false);
        blob = new Blob([oEvent.target.response], {
          type: "video/yourvideosmimmetype",
        });
        blobObj = URL.createObjectURL(blob);
        videotag.src = blobObj;
        videotag.currentTime = 1;
        init(handleClickOpen, data.hotspots, AllHotSpotsDisovered, texture);
        const old = { ...detailedScore };
        if (data.game == "risk") {
          old.risk[1] += data.hotspots.length * 10;
          setdetailedScore(old);
        }
        animate();
        setloadingProgress(0);
        setTimeout(() => {
          Timestamp = true;
        }, 1000);
      };

      xhr.onprogress = function (oEvent) {
        let contentLength = videos[data.ID][1] * 512;
        if (oEvent.lengthComputable) {
          var percentComplete = oEvent.loaded / contentLength;
          setloadingProgress(percentComplete);
        } else {
          var percentComplete = oEvent.loaded / contentLength;
          setloadingProgress(percentComplete);
        }
      };
      xhr.onerror = function (err) {
        // alert("NETWORK ERROR")
        console.log("ERROR ON LOADIN THE VIDEO", err);
      };
      xhr.onabort = function (ERR) {
        // alert("NETWORK ERROR")
        console.log("ABORTED", ERR);
      };
      xhr.send();
    }
  }

  const lazyLoad = (vid) => {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", vid, true);
    xhr.responseType = "arraybuffer";
    xhr.onload = function (oEvent) {
      const blob2 = new Blob([oEvent.target.response], {
        type: "video/yourvideosmimmetype",
      });
      const blobObject = URL.createObjectURL(blob2);
      setcorrectionVideo(blobObject);
    };

    xhr.onprogress = function (oEvent) {};
    xhr.onerror = function (err) {
      alert("NETWORK ERROR");
    };
    xhr.onabort = function (ERR) {
      alert("NETWORK ERROR");
    };
    xhr.send();
  };
  const handleSubmit = (isCorrect) => {
    if (!IsActive) {
      const old = { ...detailedScore };
      if (isCorrect) {
        old[data.game][0] += 10;

        setGameScore((s) => s + 10);
        setTotalScore(TotalScore + 10);
      }
      old[data.game][1] += 10;
      setdetailedScore(old);

      SetIsActive(true);
    } else {
      if (isImg) {
        AllHotSpotsDisovered();
      }
      setopenDialog(false);
      setopenQuiz(false);
      setopenFRE(false);
      setPlayed(true);
      playVideo();
    }
  };

  const AllHotSpotsDisovered = (acCheck) => {
    restart();
    reset();
  };
  const startCorrectionVideo = () => {
    setPlayedcorrectionVideo(true);
    playcorrectionvideo = true;
    const vtag = document.getElementById("video");

    Cleanup();

    setcurrentTime(0);
    setopenFRE(false);
    setopenQuiz(false);
    setopenDialog(false);
    setPlayed(false);
    settimeLeft(300);
    clearInterval(intervalID);

    setinitScorePosition({ x: 500, y: -4000 });
    //  setgameIndex(gameIndex+1)
    blob = undefined;
    blobObj = undefined;

    vtag.src = correctionVideo;

    const texture = new THREE.VideoTexture(vtag);
    updatematerial(texture);
    init(handleClickOpen, data.hotspots, AllHotSpotsDisovered, texture);
  };

  const attemptExam = () => {
    const ExamData = scenarioData[gameIndex - 1];

    let examObject = [...tokenExams];
    if (examObject.length) {
      examObject.push(ExamData.ID);
    } else {
      examObject = [ExamData.ID];
    }
    settokenExams(examObject);
    return examObject;
  };

  const randomStart = () => {
    videotag = document.getElementById("video")
    videotag.muted = false
    const notAteemptedExams = scenarioData;
    const thisGame = notAteemptedExams[gameIndex];
    if (thisGame.game == "quiz" && TutorialShown[0] === 0) {
      history.push("/quiz");
    } else if (thisGame.game == "find" && TutorialShown[2] === 0) {
      history.push("/findElement");
    } else if (thisGame.game == "risk" && TutorialShown[1] === 0) {
      history.push("/riskHuntTutorial");
    } else {
      if (thisGame.hotspots == undefined) {
        thisGame.hotspots = [];
      }

      setCurrentExam(thisGame);
      setData(thisGame);
      preloadVideo(thisGame);
      setgameIndex(gameIndex + 1);
      if (thisGame.ac == true) {
        sethasCorrection(true);
        lazyLoad(videos[`${thisGame.ID} ac`][0]);
      }
      videotag.loop = thisGame.game == "risk"?true:false
      return thisGame;
    }
  };

  const restart = () => {
    const attempted = attemptExam();

    const length = scenarioData.length;

    muteAudio(false);
    playbackgroundfx();
    if (gameIndex === length) {
      console.log("*********",finsihedModule)
      if (finsihedModule.length < 3) {
      console.log("FIXXXXXXXXXX")

        playfinishmodulesfx();
        const newS = [...completedScenario, SceneID];
        setcompletedScenario(newS);
        setActiveScenario({});
        setgameIndex(0);
        muteAudio(false);
        playbackgroundfx();
        history.push("/scenarios");

        // const keys = Object.keys(scenarioData)

        // const notcompleted = keys.filter(k=>{
        // const notAttended = scenarioData[k].filter(exams=> !tokenExams.includes(exams.ID))
        // return notAttended.length?true:false
        // })

        // if(notcompleted.length==0){ 
            
        //   if (caclulateScore()) {
        //     // playscorefailedfx();
    
        //     playscoresuceedsfx();
        //   } else {
        //     playscorefailedfx();
        //   }
        //   playbackgroundfx();
        //     // history.push("/areas")
        // }
      } else {
      console.log("SCENARIOOOOOOO")

      
        completedAllScenarios(attempted);
      }
    } else {
      randomStart();
      setopenQuiz(true);
    }
  };

  function visability(selectedData, value) {
    selectedData.element.style.opacity = value;
  }
  function getTargetPosition() {
    let sc = document.getElementById("gameScore").getBoundingClientRect();
    const y = sc.bottom - 40;
    const x = sc.left + 40;
    setCount({ x, y });
  }

  const handleClickOpen = (id, selectedObject, e, dataHotspots) => {
    setActiveID(id);
    let selectedData = [...dataHotspots];
    visability(selectedObject, 1);
    if (selectedObject.name == "hint" + id) {
      // playhotspotsfx(); // ALEX (It's played here), but sometimes it doens't for no reason
      playHotspotMusic();
      visability(selectedObject, 0);
      selectedObject.visible = false;
      setwillshowHints(true);
      const hotspotParent = scene.getChildByName("hotspots");
      const h2 = hotspotParent.getChildByName(id);
      if (h2.element.style.opacity == "0") {
        timeWithNoInteractino = 0;
        visability(h2, 1);
        selectedData[id].visible = true;
        data.hotspots = selectedData;
        // setData(data)
        const { clientX, clientY } = e;
        setinitScorePosition({ x: clientX - 20, y: clientY - 20 });
        getTargetPosition({ clientX, clientY });

        pausevideo();
        animationTimeoutId = setTimeout(() => {
          setcolored(false);
          setopenDialog(true);
          setTotalScore((prev) => prev + 10);
          setGameScore((prev) => prev + 10);
          const old = { ...detailedScore };
          old.risk[0] += 10;
          setdetailedScore(old);
          setTimeout(() => setcolored(true), 1000);
          setopenstopPopup(false);
        }, 2000);
      }
    } else {
      setopenDialog(true);
    }
  };

  const showHint = () => {
    playshowhotspotsfx();
    const index = data.hotspots.findIndex((d) => d.visible === undefined);
    const hotspotParent = scene.getChildByName("hotspots");
    const h = hotspotParent.getChildByName(`hint${index}`);

    if (h) {
      visability(h, willshowHints ? 1 : 0);
    }
    setwillshowHints(!willshowHints);
  };

  const handleClose = () => {
    if (data.hotspots && activeHotspot === totlaHotspots) {
      AllHotSpotsDisovered();
    } else {
      playVideo();
      setopenDialog(false);
    }
  };

  // TODO refactor it
  const tickTime = () => {
    settimeLeft((t) => {
      const ct = videotag.currentTime;
      const tt = videotag.duration;
      if (timeWithNoInteractino >= 90) {
        setopenHurryUp(true);
        timeWithNoInteractino = 0;
      }

      if (CurrentExam.as !== undefined) {
        if (CurrentExam.as[autostopIndex]) {
          if (
            !autostopFlag[autostopIndex] &&
            ct >= CurrentExam.as[autostopIndex]
          ) {
            autostopFlag[autostopIndex] = true;
            handleToggleAutoPause(true);
            autostopIndex += 1;
          }
        }
      }

      if (CurrentExam.game === "risk") {
        timeWithNoInteractino += 1;
        if (ct >= tt - 1) {
          pausevideo();
          setopenrestartPopup(true);
        }
      }else if ( videotag.ended) {
        
          if (correctionVideo !== false && playcorrectionvideo == false) {
            startCorrectionVideo();
          } else {
            AllHotSpotsDisovered(CurrentExam.ac);
          }
        }
      
      // console.log("videotag",videotag)
      // console.log("videotag",videotag.ended)

      
      if (!playcorrectionvideo) {
        if (CurrentExam.startTime == null) {
          if (Timestamp && ct >= tt - 5) {
            Timestamp = false;
            if (CurrentExam.game == "quiz") {
              PlayQuiz();
            } else if (CurrentExam.game == "find") {
              PlayFRE();
            }
          }
        } else {
          if (
            t >= 300 - CurrentExam.startTime - 1 &&
            t <= 300 - CurrentExam.startTime
          ) {
            if (CurrentExam.game == "quiz") {
              PlayQuiz();
            } else if (CurrentExam.game == "find") {
              PlayFRE();
            }
          }
        }
      }
      if (t == 0) {
      //  MAY BE REMOVED
        AllHotSpotsDisovered();
      }
      return t - 1;
    });

    setUserTime((old) => {
      return old + 1;
    });
  };

  const Interval = () => {
    videotag = document.getElementById("video");

    intervalID = setInterval(() => {
      setcurrentTime(Math.ceil(videotag.currentTime));
      tickTime();
    }, 1000);
  };

  const pausevideo = () => {
    videotag.pause();
  };
  const playVideo = () => {
    videotag = document.getElementById("video");
    videotag.addEventListener("ended", () => console.log("HEYY"), false);

    videotag.play();
  };

  const PlayQuiz = () => {
    pausevideo();

    setReadyQuestionpopup(true);

    setTimeout(() => {
      setopenQuiz(true);
    }, 10000);
  };
  const PlayFRE = () => {
    pausevideo();

    setReadyQuestionpopup(true);

    setTimeout(() => {
      setopenFRE(true);
    }, 10000);
  };

  const caclulateScore = () => {
    const { risk, quiz, find } = detailedScore;
    let acumulatedScore = risk[0] + quiz[0] + find[0];
    let TotalPossibleScore = risk[1] + quiz[1] + find[1];
    const sucessRatio = Math.round(
      (acumulatedScore / TotalPossibleScore) * 100
    );
    const suceed = sucessRatio > 80 ? true : false;
    return suceed;
  };

  const completedAllScenarios = (attempted) => {
    
    const keys = Object.keys(ActiveScenario);
    const notcompleted = keys.filter((k) => {
      const notAttended = ActiveScenario[k].filter(
        (exams) => !attempted.includes(exams.ID)
      );
      return notAttended.length ? true : false;
    });

    console.log("notcompleted",notcompleted)
    if (notcompleted.length == 0) {
      if (caclulateScore()) {
        // playscorefailedfx();

        playscoresuceedsfx();
      } else {
        playscorefailedfx();
      }
      playbackgroundfx();
      history.push("/scoreSucceed");

      // setDoneModule()
      // setselected([])
      //  history.push("/areas");
    }else{
      playfinishmodulesfx();

    }
  };
  const restartVideo = () => {
    videotag.currentTime = 1;
    setcurrentTime(0);
    setopenrestartPopup(false);
    playVideo();
  };
  const handleToggleAutoPause = (willOpen) => {
    stop();
    if (willOpen) {
      setopenstopPopup(true);
      pausevideo();
    } else {
      setopenstopPopup(false);
      playVideo();
    }
  };
  return (
    <>
      {openstopPopup ? (
        <Autostop
          texts={texts}
          txtlang={txtlang}
          playredTimersfxsfx={playredTimersfxsfx}
          stopsfx={stop}
          handlestopPopup={handleToggleAutoPause}
          openstopPopup={openstopPopup}
        />
      ) : null}
      {!loading ? (
        <>
          {/* <div style={{color:"red",position:"fixed",top:"100px",left:"100px"}}>
      <p>
        {CurrentExam.ID}
        </p>
      Correction video  {CurrentExam.ac==true ?"yes":"no"}
    </div> */}
          <HurryUp
            texts={texts}
            txtlang={txtlang}
            handleHurryUpClose={handleHurryUpClose}
            openHurryUp={openHurryUp}
          />
          {openrestartPopup ? (
            <Riskpop
              txtlang={txtlang}
              remainingHotspots={totlaHotspots - activeHotspot}
              openrestartPopup={openrestartPopup}
              restartVideo={restartVideo}
              playclicksfx={playclicksfx}
            />
          ) : null}
          {ReadyQuestionpopup ? (
            <ReadyQuestion
              txt={texts.look[txtlang]}
              ReadyQuestionpopup={setReadyQuestionpopup}
            />
          ) : null}
          {closeRotateAnimation ? (
            <InteractionAnimation
              txt={texts.inter[txtlang]}
              setcloseRotateAnimation={setcloseRotateAnimation}
            />
          ) : null}
          <GameHeader
            totlaHotspots={totlaHotspots}
            willshowHints={willshowHints}
            activeHotspot={activeHotspot}
            showHint={showHint}
            texts={texts}
            txtlang={txtlang}
            disableTotalTime={CurrentExam.game === "risk"}
            skipGame={AllHotSpotsDisovered}
            gameIndex={gameIndex}
            totalGames={scenarioData.length}
            settimeLeft={settimeLeft}
            timeLeft={timeLeft}
            colored={colored}
            gameScore={gameScore}
            TotalScore={TotalScore}
            maximumScore={maximumScore}
            isPT={isPT}
          />
          <ScoreAnimation count={count} init={initScorePosition} />
          <GameFooter
            texts={texts}
            txtlang={txtlang}
            colored={colored}
            game={CurrentExam.game}
            displayHS={data.game == "risk"}
            showHint={showHint}
            activeHotspot={activeHotspot}
            totlaHotspots={totlaHotspots}
            willshowHints={willshowHints}
          />
          {data.hotspots && data.hotspots[ActiveID] ? (
            <Dialog
              texts={texts}
              txtlang={txtlang}
              playclicksfx={playclicksfx}
              data={data.hotspots[ActiveID]}
              handleClickOpen={handleClickOpen}
              handleClose={handleClose}
              openDialog={openDialog}
            />
          ) : null}
          {!isImg &&
          CurrentExam.game &&
          videotag &&
          !isNaN(videotag.duration) ? (
            <VideoController
              playclicksfx={playclicksfx}
              openstopPopup={openstopPopup}
              disableSlider={CurrentExam.game !== "risk"}
              setcurrentTime={setcurrentTime}
              currentTime={currentTime}
              totalTime={videotag.duration}
              settimeLeft={settimeLeft}
              Played={Played}
              setPlayed={setPlayed}
            />
          ) : null}
          {openQuiz ? (
            <Quiz
              texts={texts}
              txtlang={txtlang}
              AudioMute={AudioMute}
              playclicksfx={playclicksfx}
              playQuizTimerFirst={playQuizTimerFirst}
              playredTimersfxsfx={playredTimersfxsfx}
              stopQuizTimerFirst={stopQuizTimerFirst}
              stopsfx={stop}
              handleSubmit={handleSubmit}
              SetIsActive={SetIsActive}
              IsActive={IsActive}
              restart={restart}
              openQuiz={true}
              data={CurrentExam}
              setopenQuiz={setopenQuiz}
            />
          ) : null}
          {CurrentExam.Question && openFRE ? (
            <FRE
              texts={texts}
              txtlang={txtlang}
              AudioMute={AudioMute}
              playclicksfx={playclicksfx}
              playredTimersfxsfx={playredTimersfxsfx}
              playQuizTimerFirst={playQuizTimerFirst}
              stopQuizTimerFirst={stopQuizTimerFirst}
              stopsfx={stop}
              handleSubmit={handleSubmit}
              SetIsActive={SetIsActive}
              IsActive={IsActive}
              restart={restart}
              setopenFRE={setopenFRE}
              data={CurrentExam}
              openQuiz={openFRE}
            />
          ) : null}
          {!Played ? (
            <Ready
              texts={texts}
              txtlang={txtlang}
              playclicksfx={() => {
                //  setmuteBackground();
                playclicksfx();
                muteBackgeound();
              }}
              hasCorrection={hasCorrection}
              sethasCorrection={sethasCorrection}
              setcloseRotateAnimation={setcloseRotateAnimation}
              Interval={Interval}
              setPlayed={setPlayed}
            />
          ) : null}
        </>
      ) : (
        <Splash
          moduleName={CurrentExam.MODULE}
          title={CurrentExam.Area}
          Sectiontitle={CurrentExam.Sectiontitle}
          loadingProgress={loadingProgress}
        />
      )}
      <audio
        id="hotspot_sound"
        src={hotspotfoundsfx}
        muted={AudioMute}
      ></audio>
      <audio id="quiz_first" src={quizFirstSound} muted={AudioMute} loop />
      <audio id="quiz_second" src={redTimersfx} muted={AudioMute} loop />
    </>
  );
}
