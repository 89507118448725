import React, {useState ,useEffect } from "react";
import Button from '@mui/material/Button'; 
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box'; 
import Slider from '@mui/material/Slider';


import useSound from 'use-sound'
import clicksfx from '../../staticData/sounds/check answer.wav';

let notplayed = true
const fixFormat = num =>{
  if (num<10){
      num = "0" + String(num)
  }
  return num
}

const formatTime = time=>{
  time = Math.floor(time)
  let seconds =  (Math.floor(time/60))
  let minutes =fixFormat(Math.floor(time%60))
  return [seconds , minutes]
}  



export default function FRE({texts , txtlang,AudioMute, playQuizTimerFirst, stopQuizTimerFirst, playclicksfx,playredTimersfxsfx,stopsfx,data,handleSubmit,SetIsActive,openQuiz,IsActive,restart,setopenFRE}) {
  console.log("FRE")
  const [playAnswersfx] = useSound(clicksfx,{volume:AudioMute?0:0.7});
  const [Selected, SetSelected] = useState([])
  const [isHurry, setisHurry] = useState(false)
  const [time, Settime] = useState(0)
  let intervalID

  useEffect(() => {
    startTimer()
    return function cleanup(){
      clearInterval(intervalID)
      notplayed=true

      SetIsActive(false)
    }
  }, []);
 
  const startTimer=()=>{
      intervalID = setInterval(()=>{
       Settime(t=>{
          console.log("AAAAAAAAAA", t)
          if(t>=90){
            clearInterval(intervalID)
            handleSubmit()
            stopsfx()
          }else if(t>=70 && notplayed){
            notplayed=false
            setisHurry(true)
            stopQuizTimerFirst();
            playredTimersfxsfx()
          } else if (notplayed){
            playQuizTimerFirst();
          }
          return t+1
        })
    },1000)
  }
  const handleSelect = (index)=>{
    playclicksfx()
    if(!IsActive){
      const oldf = index
        let old = [...Selected]
        if(old.includes(oldf)){
            old = old.filter(f=>f !== oldf)
        }else{
            old.push(oldf)
        }
        SetSelected(old)
    }
  }
  const validateAnswer=()=>{
    const correctAnswers = data.ca
    const userSelection = Selected
    stopsfx()
    notplayed = false;
    if(Selected.length !== correctAnswers.length){ 
      stopQuizTimerFirst();

      handleSubmit(false)
    }else{
      stopQuizTimerFirst();
      let allCorrect = true
  
      for(let s of Selected){
        const isIncluded = correctAnswers.includes(s)
        if(isIncluded == false){
          allCorrect = false
          break
        }
      }  
      handleSubmit(allCorrect)
    }
  }
  const renderBackgroundColor =(typer , index)=>{
    const isSelected = Selected.includes(index)

    let types

      if(data.ca.includes(index)===true){
        types =1
      }else if(data.ca.includes(index)===false && isSelected){
        types = -1
      }else{
        types = 10
      }

    if(!typer ){
      if(isSelected ){
        return "rgba(233, 131, 0, 0.5)"
      }else{
        return "#EBECEC"
    }
    } else{
      if(types===1){
        return "rgba(143, 184, 56, 0.3)"
      }else if(types==-1){
        return " rgba(205, 32, 44, 0.3)"
      }else{
          return "#EBECEC"
      }
    }
  }
  const sliderStyle={
    marginTop:"-12px",

    height:"10px",
    "& .MuiSlider-rail":{
      borderRadius:"0",
      color: isHurry?"rgba(188, 31, 0, 0.3)":"rgba(233, 131, 0, 0.3)",
      transition: "all 1s linear",

    },
    "& .MuiSlider-track":{
      borderRadius:"0",
      color: isHurry?"#CD202C":"#E98300",
      transition: "all 1s linear",
    },
    "& .MuiSlider-thumbColorPrimary":{
      width:"0px",
      height:"15px",
      transition: "all 1s linear",
    },
    "& .MuiSlider-valueLabelOpen":{
      borderRadius:"0",
      background: isHurry?"#CD202C":"#E98300",
      fontFamily: 'Avenir',
      fontWeight: 800,
      fontSize: "16px",
      lineHeight: "22px",
      letterSpacing: "0.1em",
      transition: "all 1s linear",
    }
  }
    const title1={
        fontWeight: "500",
        fontSize: "18px",
        lineHeight: "60px",
        textTransform: "uppercase",
        color: "#E98300",

    }
    const title2={
        fontFamily:"AvenirBold",
        fontWeight: "800",
        fontSize: "32px",
        lineHeight: "44px",
        color: "#2E4957",
    }
    const choiceBoxStyle ={
        padding:"0",
        height:"163px",
        padding:"10px 20px 10px 0",
        cursor:"pointer",
        transition: "all 0.3s ease",
    }
    const choiceContainer={
        // width:"570px",
        marginTop:"30px"
    }

  return ( 
      <Dialog 
        aria-labelledby="customized-dialog-title"
        open={true}
        sx={{
          '& .MuiPaper-root ': {
            // maxWidth:"69%",
            maxWidth:{md:"1100px",xs:"100vw"},
            maxHeight:{xs:"100vh"},
            borderTopRightRadius: 0,
            borderTop:IsActive?"10px solid #E98300":"",
            
        }, 
        "& .MuiPaper-root":{
          overflowY:"visible"
        }
        }}>
 
        <Grid container spacing={2} sx={{alignItems:"center"}} >
            <Grid container item xs={12}>
            {!IsActive? <Slider
                max={90}
                disabled
                sx={sliderStyle} 
                valueLabelFormat={(t)=>{
                    const [m,s] = formatTime(90-t)
                    return `${m}:${s}`
                    }
                }
                value={time}
                aria-label="Small"
                valueLabelDisplay="on"
                /> :null} 
            </Grid>
          <Grid container  item xs={12} sx={{
                padding:`0 40px 40px`
              }} >


          {/* <Grid item xs={5} sx={{marginTop:IsActive?"20px":"",paddingLeft:"40px", justifyContent:"center",alignItems:"center", display:"flex"}}>
            <img height="98%" width="100%" src={cover}></img>
        </Grid> */}
        <Grid item xs={12} sx={{paddingLeft:"30px"}}>
            <DialogContent >
            <Typography variant='body1' sx={title1} color="primary" component="h1" >
            { texts.ques[txtlang] }
            </Typography>
            <Typography color="secondary" variant='h4' sx={title2}>
              {data.Question}
            </Typography>
            <Grid container sx={choiceContainer}>
                {data.ans.map((choice,index)=> <Grid item xs={4} md={2}key={index} onClick={()=>handleSelect(index)}
                 sx={{...choiceBoxStyle } }>
                    <img width="100%" height="100%" style={{background:renderBackgroundColor(IsActive,index)}} src={choice}/> 
                </Grid> )}
  
            </Grid>
            <Box sx={{marginTop:"34px",display:"flex",justifyContent:"flex-end"}}  > 
              {Selected.length?  <Button onClick={()=>{
                playclicksfx()
                validateAnswer()
                if(!IsActive){
                  playAnswersfx()
                }
              }
              } sx={{padding:"17px 48px" ,letterSpacing: "0.1em", fontWeight:500,width:"300px"}}  variant="contained" >
                    {IsActive?texts.cont[txtlang] :texts.checkans[txtlang]}
              </Button>:(IsActive? <Button onClick={()=>{
                validateAnswer()
                playclicksfx()   
            }} sx={{padding:"17px 48px" ,letterSpacing: "0.1em", fontWeight:500,width:"300px"}}  variant="contained" >
            {texts.cont[txtlang]}
              </Button>:null)}
                 </Box>
            </DialogContent>
          
            </Grid>

          </Grid>
        </Grid>
      </Dialog> 
  );
}