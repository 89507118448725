import React, {useState ,useEffect} from "react";
 
import Box from '@mui/material/Box'; 
import Typography from '@mui/material/Typography'; 
import Grid from '@mui/material/Grid'; 
import SvgIcon from '@mui/material/SvgIcon'; 
import { ReactComponent as montagem } from './icons/montagem.svg';
import { ReactComponent as fabrica } from './icons/fabrica.svg';
import { ReactComponent as pc } from './icons/pc.svg';
import { ReactComponent as box } from './icons/box.svg';
import { ReactComponent as box2 } from './icons/box2.svg';
import { Link,useHistory } from "react-router-dom";
 
export default function CustomizedDialogs({txtlang , texts,playclicksfx,finsihedModule,setselected,selected}) {
    let {warehouse,admin,ficha,assemp} = texts
    

    const history = useHistory();

    const selectedStyle={
        background:"white",
        color:"#2E4957",
        transition: "all 0.5s ease",

        '& .MuiTypography-root ': {
            color:"#747678"         
        },
        '& .gray ': {
            fill:"#747678",       
            transition: "all 0.5s ease",
  
        },
        '& .a_box ': {
            animation:" box 0.6s linear forwards",
            transition: "all 0.5s ease",

        },
        '& .boxLeft ': {
            animation: "box2 0.6s linear forwards",
            transition: "all 0.5s ease",
        },
        '& .pc1 ': { 
            animation: "pcFade  0.4s   linear forwards",
            animationDelay:"0.1s"
        },
        '& .pc2 ': { 
            animation: "pcFade  0.4s  linear forwards",
            animationDelay:"0.4s"
        },
        '& .pc3 ': { 
            animation: "pcFade  0.4s   linear forwards",
            animationDelay:"0.7s"

        },
        '& .pc4 ': { 
            animation: "pcFade  0.4s  linear forwards",
            animationDelay:"1s"

        },
        '& .pc5 ': { 
            animation: "pcFade  0.4s   linear forwards",
            animationDelay:"1.3s"

        },
        '& .pc6 ': { 
            animation: "pcFade  0.4s   linear forwards",
            animationDelay:"1.6s"

        },
        '& .pc7 ': { 
            animation: "pcFade  0.4s  linear forwards",
            animationDelay:"1.9s"

        },
        '& .cputop ':{
            animation: "cpu1 0.4s linear forwards"
        },
        '& .cpubottom ':{
            animation: "cpu2 0.4s linear forwards"
        },
        '& .cputleft ':{
            animation: "cpu3 0.4s linear forwards"
        },
        '& .cpuright ':{
            animation: "cpu4 0.4s linear forwards"
        },       
        '& .ficha1 ':{
            transition: "all 0.3s ease",
            // transform: "translate(-10px ,10px)",
            animation: "ficha1 0.6s ease-out forwards"

        },
        '& .ficha2 ':{
            transition: "all 0.3s ease",
            // transform: "translate(10px ,-10px)"
            animation: "ficha2 0.6s ease-out forwards"
        },
              
    }
    const boxIcon = {
        position:"absolute",

        '@media (min-width:800px)': {
            transform:"translateX(-170px)"
            },
    }

    const boxStyle={
        margin:"0 10px 20px",
        width:"351px",
        height:"354px",
        background: "rgba(255, 255, 255, 0.3)",
        fontFamily: 'Avenir',
        fontWeight: 900,
        fontSize: "28px",
        lineHeight: "38px",
        display: 'flex',
        flexDirection:"column",
        alignItems: 'center',
        justifyContent:"space-around",
        color: '#FFFFFF',
        transition: "all 0.2s ease",
        cursor:"pointer",
        '&:hover':selectedStyle,

        '@media (max-width:800px)': {
        alignItems: 'center',
        padding:"0 20px",
        justifyContent:"space-between",
        flexDirection:"row",
        // border:"1px solid red",
        width:"calc(100% - 35px) ",
        margin:"0 0px 20px",

        // width:"320px",

        height:"80px",
        },
    }

    const title={
        marginTop: "-100px",
        fontFamily: 'Avenir',
        fontWeight: 900,
        fontSize: "24px",
        lineHeight: "33px", 
        color: "#FFFFFF",
        '@media (max-width:800px)': {
            fontSize: "18px",
            marginTop: "0",

        },
    }
    
    const data = [
        {id:"Assembly", titleLable:assemp[txtlang],icon:montagem,width:120, height:120},
        {id:"ficha",titleLable:ficha[txtlang],icon:fabrica,width:120, height:120},
        {id:"administrativa",titleLable:admin[txtlang],icon:pc,width:157, height:100},
        {id:"aram",titleLable:warehouse[txtlang],icon:box,width:335, height:120},
    ]
    const handleClick= (value)=>{
        playclicksfx()
        if(!finsihedModule.includes(value)){
            const oldf = value
            let old = [...selected]
    
            if(old.includes(oldf)){
                old = old.filter(f=>f !== oldf)
            }else{
                old.push(oldf)
            } 
            setselected(old)
        }else{
            console.log("ALREADY EXITSTs")
        }
    }

    const renderDeskBox = (d,index)=> <Box key={index} onClick={()=>handleClick(d.id)} 
             sx={(!selected.includes(d.id) && !finsihedModule.includes(d.id))? boxStyle:{...boxStyle,...selectedStyle}}  >
         <div className="att">
             {d.id =="aram"?<Box sx={{marginBottom:"115px"}}> 
             <SvgIcon  sx={{width:d.width , height:d.height,...boxIcon}}
            component={d.icon} inheritViewBox /> 
             <SvgIcon  sx={{width:d.width , height:d.height,...boxIcon}}
            component={box2} inheritViewBox /> 
             </Box> : <SvgIcon  sx={{width:d.width , height:d.height}}
            component={d.icon} inheritViewBox /> 
            }
         </div>
               <Typography sx={title}>
               {d.titleLable}
               </Typography>
            </Box>
    
    const renderPhoneBox  = (d,index)=> <Box key={index} onClick={()=>handleClick(d.id)} 
    sx={(!selected.includes(d.id) && !finsihedModule.includes(d.id))? boxStyle:{...boxStyle,...selectedStyle}}  >
    <div className="att">
            {d.titleLable =="Warehouse"?<Box sx={{marginBottom:"40px",marginRight:"80px",marginLeft:"-30px"}}> 
            <SvgIcon  sx={{width:d.width/3 , height:d.height/3,...boxIcon}}


        component={d.icon} inheritViewBox /> 
            <SvgIcon  sx={{width:d.width/3 , height:d.height/3,...boxIcon}}
        component={box2} inheritViewBox /> 
            </Box> : <SvgIcon  sx={{width:d.width/3 , height:d.height/3}}
        component={d.icon} inheritViewBox /> 
        }
        </div>
        
        <Box sx={{
            display:"flex",
             justifyContent:"flex-start",
             width:'100%',
             paddingLeft:"20px"}}>
        <Typography sx={title}>
        {d.titleLable}
        </Typography>
        </Box>
   </Box>

const renderBox=(d,index)=><>
        <Box sx={{display:{xs:"block",sm:"none"},width:"100%"}}>
            {renderPhoneBox(d,index)}
        </Box>
        <Box sx={{display:{xs:"none",sm:"block"}}}>
            {renderDeskBox(d,index)}
        </Box>
    </>

    useEffect(() => {  
        // if(finsihedModule.length===4){
        //     history.push("/scoreSucceed")
        // }
     }, []);

  return ( <Grid container sx={{
        width:"100%",
        // border:"1px solid red",
        justifyContent:"center",
        marginTop:'50px',                
        }} spacing={1.3} >
            {data.map((d,index)=>renderBox(d,index))}
        </Grid>
       );
}