import React, {useState ,useEffect, useRef } from "react";
import Dialog from "../Dialog/Dialog"
import GameFooter from "../GameFooter/GameFooter"
import GameHeader from "../GameFooter/GameHeader"
import Splash from "../Splash/Splash"
import Ready from "../Ready/Ready"
import Quiz from "../Quiz/Quiz"
import FRE from "../FRE/FRE"
import Autostop from "../Autostop/Autostop"
import * as THREE from 'three';

import Box from '@mui/material/Box';
import {init,animate,Cleanup,scene,removeHots,updatematerial,AnimateNumberChange} from "../../three/start";
import { Redirect ,useHistory } from "react-router-dom";

import ScoreAnimation from "../scoreAnimation" 
import VideoController from "../../three/VideoController"
import {videos, fakeataall} from "../../staticData/QuizData" 
import useSound from 'use-sound'
import hotspothintsfx from '../../staticData/sounds/hotspot-show-hints.wav';
import hotspotfoundsfx from '../../staticData/sounds/hotspot-hotspotfound.wav';
import redTimersfx from '../../staticData/sounds/red timer-quiz questions.wav';
import finishmodulesfx from '../../staticData/sounds/return-to-scenario-screen-after-completing-scenario.wav';
import scoresuceedsfx from '../../staticData/sounds/score-screen-sucess.wav';
import scorefailedfx from '../../staticData/sounds/score-screen-failed.wav';

import { FocusOverlay } from 'react-focus-component';
import TUT from "../InteractiveTut/InteractiveTut"
 


let intervalID ,videotag
let Timestamp = true
let autostopIndex=0
let autostopFlag=[false,false,false,false,false,false]
// completedScenario={completedScenario} setcompletedScenario={setcompletedScenario} 
export default function RiskHunt({isPT, texts,txtlang,AudioMute,playclicksfx,seenTutorial , setTutorialShown,finsihedModule,setUserTime,
  setdetailedScore, detailedScore,risk,
    tokenExams , settokenExams,completedScenario,setcompletedScenario,loading,
    setLoading,ActiveScenario,setActiveScenario}) {
      const {t1,t2,t3,t4,t5,t6,rhtip} =texts
      const steps=[
        {
          arrowDirection:0,
          title:`${rhtip[txtlang]}1`,
          body:t1[txtlang],
          location:[window.innerWidth * 0.5 - 400 , window.innerHeight * 0.5 -200],
          dim:[800,400],
          pos:[window.innerWidth * 0.5 + 410, window.innerHeight * 0.5 - 20]
        },
        {
          arrowDirection:0,
          title:`${rhtip[txtlang]}2`,
          body:t2[txtlang],
          location:[window.innerWidth * 0.5 - 200 , window.innerHeight * 0.5 -200],
          dim:[400,400],
          pos:[window.innerWidth * 0.5 + 210, window.innerHeight * 0.5 - 20]
        },
        {
          arrowDirection:1,
          title:`${rhtip[txtlang]}3`,
          body:t3[txtlang],
          location:[window.innerWidth * 0.5 - 100 ,0],
          dim:[1800, window.innerHeight * 0.5+100],
          pos:[window.innerWidth * 0.5 - 420, window.innerHeight * 0.5 - 20]
        },
        {
          arrowDirection:0,
          title:`${rhtip[txtlang]}4`,
          body:t4[txtlang],
          location:[window.innerWidth * 0.5 ,0],
          dim:[0, window.innerHeight * 0.5+200],
          pos:[window.innerWidth - 500, window.innerHeight * 0.5 - 100]
        },
        {
          arrowDirection:2,
          title:`${rhtip[txtlang]}5`,
          body:t5[txtlang],
          location:[window.innerWidth * 0.5 -100 ,window.innerHeight * 0.5 -100],
          dim:[window.innerWidth * 0.5+100, window.innerHeight * 0.5+100],
          pos:[window.innerWidth*0.5 ,  window.innerHeight * 0.5-580]
        },
        {
          arrowDirection:2,
          title:`${rhtip[txtlang]}6`,
          body:t6[txtlang],
          location:[window.innerWidth * 0.5 -100 ,window.innerHeight * 0.5 -100],
          dim:[window.innerWidth * 0.5+100, window.innerHeight * 0.5+100],
          pos:[window.innerWidth*0.5 ,  window.innerHeight * 0.5-580]
        },
    ]
    const dialogBtnRef = useRef();
    const shotHintsRef = useRef();

    const [playhotspotsfx] = useSound(hotspotfoundsfx,{volume: AudioMute?0:0.5,soundEnabled:!AudioMute});
    const [playshowhotspotsfx] = useSound(hotspothintsfx,{volume: AudioMute?0:0.5,soundEnabled:!AudioMute});
    const [playredTimersfxsfx,{stop}] = useSound(redTimersfx,{volume: AudioMute?0:0.3,soundEnabled:!AudioMute});
    const [playfinishmodulesfx] = useSound(finishmodulesfx,{volume: AudioMute?0:0.5,soundEnabled:!AudioMute});
    const [playscoresuceedsfx] = useSound(scoresuceedsfx,{volume: AudioMute?0:0.5,soundEnabled:!AudioMute});
    const [playscorefailedfx] = useSound(scorefailedfx,{volume: AudioMute?0:0.5,soundEnabled:!AudioMute});
    const fakeata = fakeataall[isPT]
    const SceneID = Object.keys(fakeata)[0]
    const corrupted = Object.keys(fakeata).length ===0
    const [TotalScore,setTotalScore] = useState(0);
  
    const [gameScore, setGameScore] = useState(0);
    const [isImg, setisImg] = useState(false);
    const [count, setCount] = useState({x:0,y:0});
    const [initScorePosition, setinitScorePosition] = useState({x:0,y:0});
    const [colored, setcolored] = useState(true); 
    const [Played,setPlayed]= useState(false)
    const [loadingProgress,setloadingProgress]=useState(0)
    const [timeLeft,settimeLeft] = useState(300)
    const [data,setData] = useState({})
    const [ActiveID, setActiveID] = useState(0);
    const [openDialog, setopenDialog] = useState(false);
    const [willshowHints, setwillshowHints] = useState(true);
    const [currentTime,setcurrentTime] = useState(0) 
    const [openQuiz,setopenQuiz] = useState(false) 
    const [openFRE,setopenFRE] = useState(false) 
    const [gameIndex,setgameIndex]=useState(0)
    const [IsActive, SetIsActive] = useState(false)
    const [CurrentExam, setCurrentExam] = useState({})
    const [closeRotateAnimation, setcloseRotateAnimation] = useState(true)
    const [correctionVideo, setcorrectionVideo ] = useState(false)
    const [PlayedcorrectionVideo, setPlayedcorrectionVideo ] = useState(false) 
    const [openstopPopup, setopenstopPopup ] = useState(false) 
    const [stepIndex, setstepIndex] = useState(0);
    const [activeHotspot, setactiveHotspot] = useState(0);
    
    const history = useHistory();
    let animationTimeoutId , totlaHotspots,maximumScore

   const reset = ()=>{
    Cleanup()
    blob= undefined
    blobObj=undefined
    clearInterval(intervalID)
    clearTimeout(animationTimeoutId)
    videotag = document.getElementById("video")
    setcurrentTime(0)
    setopenFRE(false)
    setopenQuiz(false)
    setopenDialog(false)
    setPlayed(false)
    settimeLeft(300)
    setGameScore(0)
    setinitScorePosition({x:500,y:-4000})
    Timestamp=true
    autostopIndex=0
    autostopFlag = autostopFlag.map(e=>false)
    console.log("autostopFlag",autostopFlag)
    // setPlayedcorrectionVideo(false)

    //  setgameIndex(gameIndex+1)
       
    videotag.src =''
   }
  useEffect(() => {  
   if(!corrupted){ 
     const d =  randomStart() 
     console.log("HIII",d)
    //  setPlayed(true)

    //  preloadVideo(d)
    //  if(d.hotspots ==undefined){
    //    d.hotspots=[]
    // }
     videotag = document.getElementById("video")
     videotag.muted = true
     return function cleanup(){
      seenTutorial()
      reset()
      }
    }

}, []);
if(corrupted){

     videotag = document.getElementById("video")
     videotag.muted = false
  return <Redirect to={"/scenarios"} />
}
    const scenarioData = Object.values(fakeata)[0] 
    const gameData =scenarioData[gameIndex]
    if(data.hotspots){
        totlaHotspots = data.hotspots.length  
    }

    if(data.game=='risk'){
       maximumScore= totlaHotspots *10 
    }else{
      maximumScore= 10 
    }

    var blob ,blobObj
    function preloadVideo(data){
      const splits = videos[data.ID][0].split('.')
      const fileExtenstion = splits[splits.length-1]
      if(fileExtenstion=='png'){
        setisImg(true)
    
        const loader = new THREE.TextureLoader();
        setLoading(true)

        loader.load( 
          videos[data.ID][0],
          function ( texture ) {             
            setLoading(false)

            init( handleClickOpen , data.hotspots ,AllHotSpotsDisovered,texture,true);
          const old = {...detailedScore} 
          if(data.game == 'risk'){
            old.risk[1] +=  data.hotspots.length *10
            // setdetailedScore(old)
          }
          animate();
          },
          // onProgress callback currently not supported
          function(prog){
          },

          // onError callback
          function ( err ) {
            console.error( 'An error happened.' );
          }
        );
      }else{
        setisImg(false)
        var videotag = document.getElementById("video")
        setLoading(true)
        var xhr = new XMLHttpRequest();
        xhr.open("GET", videos[data.ID][0], true);
        xhr.responseType = "arraybuffer";
    
        xhr.onload = function(oEvent) {
 
          const texture = new THREE.VideoTexture( videotag );
          setLoading(false)
          blob = new Blob([oEvent.target.response], {type: "video/yourvideosmimmetype"});
          blobObj = URL.createObjectURL(blob);
          videotag.src =blobObj 
          videotag.currentTime = 1;
          init( handleClickOpen , data.hotspots ,AllHotSpotsDisovered,texture,true);
          // const old = {...detailedScore} 
          if(data.game == 'risk'){
            // old.risk[1] +=  data.hotspots.length *10
            // setdetailedScore(old)

          }
          animate();
          setloadingProgress(0)
        };

        xhr.onprogress = function(oEvent) { 
          let contentLength = videos[data.ID][1]*512
             if (oEvent.lengthComputable) {
                 var percentComplete = oEvent.loaded/contentLength;
                 setloadingProgress(percentComplete)
             }
             else {
               var percentComplete = oEvent.loaded/contentLength;
               setloadingProgress(percentComplete)
             }
         }
         xhr.onerror = function(err){
            alert("NETWORK ERROR")
           console.log("ERROR ON LOADIN THE VIDEO",err)
         }
         xhr.onabort = function(ERR){
            alert("NETWORK ERROR")
           console.log("ABORTED",ERR)
         }
        xhr.send();
      }
    }
 
    const lazyLoad = (vid)=>{ 
        var xhr = new XMLHttpRequest();
        xhr.open("GET",vid, true);
        xhr.responseType = "arraybuffer";
    
        xhr.onload = function(oEvent) {
          const blob2 = new Blob([oEvent.target.response], {type: "video/yourvideosmimmetype"});
          const blobObject = URL.createObjectURL(blob2); 
          setcorrectionVideo(blobObject)           
        };
  
        xhr.onprogress = function(oEvent) {
         }
         xhr.onerror = function(err){
            alert("NETWORK ERROR")

          console.log("ERROR ON LOADIN THE VIDEO",err)
        }
        xhr.onabort = function(ERR){
          console.log("ABORTED",ERR)
          alert("NETWORK ERROR")

        }
        xhr.send();
    }
    const handleSubmit=(isCorrect)=>{
      if(!IsActive){
        // const old = {...detailedScore}
        if(isCorrect){
          // old[data.game][0] += 10
           
          setGameScore(s=>s+10)
          setTotalScore(TotalScore+10)

        }
        // old[data.game][1] += 10
        // setdetailedScore(old)

        SetIsActive(true) 
        
      }else{
        if(isImg){
          AllHotSpotsDisovered()
        }
        setopenDialog(false);
        setopenQuiz(false)
        setopenFRE(false)
        setPlayed(true)
        playVideo()
      }
    }
  
    const AllHotSpotsDisovered = (acCheck)=>{
      restart()
      reset()      
    }
   const startCorrectionVideo = ()=>{
    setPlayedcorrectionVideo(true)

    const vtag = document.getElementById("video")

    Cleanup()
     
    setcurrentTime(0)
    setopenFRE(false)
     setopenQuiz(false)
    setopenDialog(false)
    setPlayed(false)
    settimeLeft(300)
    setGameScore(0)
    clearInterval(intervalID)

    setinitScorePosition({x:500,y:-4000})
    //  setgameIndex(gameIndex+1)
    blob= undefined
    blobObj=undefined
    
    vtag.src =correctionVideo
    
    const texture = new THREE.VideoTexture( vtag ) 
    updatematerial(texture) 
    init( handleClickOpen , data.hotspots ,AllHotSpotsDisovered,texture);

    }

    const GetNotAtemptedExams =()=>{
      // const examObject = {...tokenExams}
      // let notAteempted 
      // if(tokenExams[SceneID]){
      //    notAteempted = scenarioData.filter(s=>{
      //     return !examObject[SceneID].includes(s.ID)
      //   })  
      //   }else{
      //     notAteempted = scenarioData
      //   }
         
      //   return notAteempted
        return scenarioData
    }

    const attemptExam = ()=>{
      // GetNotAtemptedExams()
      const ExamData =scenarioData[gameIndex-1]
      
      let examObject = [...tokenExams]
      if(examObject.length){
        examObject.push(ExamData.ID)
      }else{
        examObject=[ExamData.ID]
      } 
      // settokenExams(examObject)
      return examObject
    }

    const randomStart = ()=>{
     const notAteemptedExams = scenarioData
      const thisGame = notAteemptedExams[gameIndex] 
      if(thisGame.hotspots ==undefined){
        thisGame.hotspots=[]
     }
     
     console.log("Game:",thisGame)
      setCurrentExam(thisGame)
      setData(thisGame)
      // setgameIndex(randIndex)
      preloadVideo(thisGame)
      // setgameIndex(gameIndex+1)
      const CorrectionVidUrl = videos[`${thisGame.ID} ac`]
      if(thisGame.ac==true){
        lazyLoad(videos[`${thisGame.ID} ac`])
      }
      return thisGame

    }
    const restart = ()=>{
      const attempted=   attemptExam() 

      const length = scenarioData.length

      if(gameIndex === length ){
        if(finsihedModule.length<3){
          playfinishmodulesfx()
          const newS = [...completedScenario,SceneID]
          setcompletedScenario(newS)
          setActiveScenario({})

          videotag = document.getElementById("video")
          videotag.muted = false
          history.push("/scenarios");
        }else{
          completedAllScenarios(attempted)
        }
      }else{
        randomStart()  
        setopenQuiz(true)
      }
    }

  function visability(selectedData,value){
    selectedData.element.style.opacity=value
  }
    function getTargetPosition(){
        let sc  = document.getElementById("gameScore").getBoundingClientRect();
        const y =sc.bottom-40
        const x = sc.left+40
        setCount({x,y})
    }
      
    const clickHotspot=()=>{
      const targetMesh = scene.getChildByName("hint1")
      console.log("HINNNNT",targetMesh)
      handleClickOpen(1,targetMesh , undefined,undefined)
    }
   

    const handleClickOpen = (id,selectedObject,e,dataHotspots) => {
      console.log("ID",id)
      console.log("selectedObject",selectedObject)
      console.log("e",e)
      console.log("dataHotspots",dataHotspots)
        setActiveID(id);
        // let selectedData = [...dataHotspots]
        visability(selectedObject,1)
          if(selectedObject.name== ("hint"+id)){
            playhotspotsfx() // doesn't work!!!
            visability(selectedObject,0)  
            selectedObject.visible=false
            setwillshowHints(true)
            const hotspotParent = scene.getChildByName("hotspots")
            const h2 = hotspotParent.getChildByName(id)
            console.log("H@@",h2)
            if(h2.element.style.opacity=="0"){
              visability(h2,1)  
              // selectedData[id].visible=true
              // data.hotspots = selectedData
              // setData(data)
              // const {clientX , clientY} = e
              const clientX = window.innerWidth/2
              const clientY = window.innerHeight/2
              setinitScorePosition({x:clientX-20,y:clientY-20})
              getTargetPosition({clientX , clientY})

              animationTimeoutId= setTimeout(()=>{
                pausevideo()
                setcolored(false)
                setTotalScore(prev=>prev+10)
                setGameScore(prev=>prev +10)
                setactiveHotspot(1)

                // const old = {...detailedScore}
                // old.risk[0] += 10 
                // setdetailedScore(old)
                setTimeout( ()=>setcolored(true),1000)
                setopenstopPopup(false)
              },2000)
            }
          }else{
            setopenDialog(true);
          }
      };

    const showHint=()=>{
      
      playshowhotspotsfx()
      // d.visible = d.visible ==undefined ? false:true
    const index = data.hotspots.findIndex(d=>d.visible===undefined)
    const hotspotParent = scene.getChildByName("hotspots")
    const h = hotspotParent.getChildByName(`hint${index}`)
    console.log("hotspotParent",hotspotParent)
    console.log("h2",h)

    if(h) {
      visability(h,willshowHints?1:0) 
    }
    setwillshowHints(!willshowHints)
  }
    const handleClose = () => {
 
        playVideo()
        setopenDialog(false);
    };
    const tickTime=()=>{
            settimeLeft(t => { 
              const ct = videotag.currentTime 
              const tt = videotag.duration
              if( CurrentExam.as !==undefined){
                if(CurrentExam.as[autostopIndex]){
                  if(!autostopFlag[autostopIndex] && (ct >=CurrentExam.as[autostopIndex])){
                    autostopFlag[autostopIndex] =true
                    handleToggleAutoPause(true)
                    autostopIndex+=1
                  }}
                }
                  if(( CurrentExam.game !=='risk' &&(ct>=tt -1  && ct<=tt ))){
                if(correctionVideo!==false && PlayedcorrectionVideo==false){
                  startCorrectionVideo()
                }else{                 
                  // AllHotSpotsDisovered(CurrentExam.ac)
                }
              }
              if(!PlayedcorrectionVideo){
              if(CurrentExam.startTime==null){              
                if((Timestamp &&(ct>=tt -2 ))){
                  Timestamp = false
                  if(CurrentExam.game =='quiz'){
                    PlayQuiz()
                  }else if (CurrentExam.game =='find'){
                    PlayFRE()
                  }
                }
              } else{
                  if((t>=300 - CurrentExam.startTime -1  && t<=300 -CurrentExam.startTime )){
                    if(CurrentExam.game =='quiz'){
                      PlayQuiz()
                    }else if (CurrentExam.game =='find'){
                      PlayFRE()
                    }
                  }
                }

            }
            if(t==0){
              // AllHotSpotsDisovered()
              settimeLeft(300)
            }
              // }
              return t-1
    })
        
        setUserTime(old=>{
          return old+1
        })
      }
      
    const Interval = ()=>{
       videotag = document.getElementById("video")

         intervalID =   setInterval(()=>{
            setcurrentTime(Math.ceil(videotag.currentTime))
            tickTime()
        },1000)

    }

  const pausevideo = ()=>{
    videotag.pause()
  }
  const playVideo = ()=>{
    videotag = document.getElementById("video")
    videotag.play()

  }

  const CloseQuize = ()=>{
    playVideo()
    setopenQuiz(false)
  }
   const PlayQuiz = ()=>{
    pausevideo()
    setopenQuiz(true)
   }
   const PlayFRE =()=>{
    pausevideo()
    setopenFRE(true)
   }
   const closeFRE =()=>{
    playVideo()
    setopenFRE(false)
   }

// const caclulateScore = ()=>{
//   const {risk, quiz, find} = detailedScore
//     let acumulatedScore = risk[0] + quiz[0] + find[0] 
//     let TotalPossibleScore = risk[1] + quiz[1] + find[1]
//     const sucessRatio = Math.round((acumulatedScore/TotalPossibleScore)*100)
//     const suceed = sucessRatio>80?true:false
//   return suceed
// }
   const completedAllScenarios =(attempted)=>{ 
     const keys = Object.keys(fakeata)
     const notcompleted = keys.filter(k=>{
         const notAttended = fakeata[k].filter(exams=> !attempted.includes(exams.ID))
         return notAttended.length?true:false
     })
  
    //  if(notcompleted.length==0){
    //   if(finsihedModule.length>=3){
    //     if(caclulateScore()){
    //       playscoresuceedsfx()
    //     }else{
    //       playscorefailedfx()
    //     }
    //     history.push("/scoreSucceed")
    //     }

    //     // setDoneModule() 
    //     // setselected([])
    //     //  history.push("/areas");
    //  }
 }

   const handleToggleAutoPause = (willOpen)=>{
    if(willOpen){
      setopenstopPopup(true)
      pausevideo()

    }else{
      setopenstopPopup(false)
      playVideo()
    }
   }
   return ( 
    <> 
    {openstopPopup?<Autostop playredTimersfxsfx={playredTimersfxsfx} stopsfx={stop} handlestopPopup={handleToggleAutoPause} openstopPopup={openstopPopup}/>:null}
    {!loading?<>
     
     {Played?<> 
      <TUT texts={texts} txtlang={txtlang}  playclicksfx={playclicksfx} shotHintsRef={shotHintsRef} dialogBtnRef={dialogBtnRef} seenTutorial={seenTutorial} setcloseRotateAnimation={setcloseRotateAnimation} showHint={showHint} setopenDialog={setopenDialog} AnimateNumberChange={AnimateNumberChange} setstepIndex={setstepIndex} stepIndex={stepIndex} step = {steps[stepIndex]} clickHotspot={clickHotspot}/>
      <FocusOverlay focusComponent={{ height:steps[stepIndex].dim[1], width:steps[stepIndex].dim[0],
               x:steps[stepIndex].location[0], y:steps[stepIndex].location[1]}}  />
     {/* {closeRotateAnimation?<InteractionAnimation setcloseRotateAnimation={()=>{}} />:null}  */}
     </>:null}
      <Box sx={{ opacity:stepIndex==2?1:0.5}}>

         <GameHeader isPT={isPT} texts={texts} txtlang={txtlang} willShowHints={willshowHints} activeHotspot={activeHotspot} totlaHotspots={totlaHotspots}  showHint={showHint} disableTotalTime={CurrentExam.game==='risk'} skipGame={AllHotSpotsDisovered} gameIndex={gameIndex} totalGames={ scenarioData.length} settimeLeft={settimeLeft} timeLeft={timeLeft}  colored={colored}  gameScore={gameScore} TotalScore={TotalScore} maximumScore={maximumScore}/>
      </Box>
       <ScoreAnimation count={count} init={initScorePosition}/>
      <Box sx={{ opacity:(stepIndex===4 || stepIndex===5)?1:0.5}}>
       <GameFooter  texts={texts} txtlang={txtlang} colored={colored} shotHintsRef={shotHintsRef} isTutorial={true} displayHS={data.game=='risk'} showHint={showHint} activeHotspot={activeHotspot} totlaHotspots={totlaHotspots} willshowHints={willshowHints}/>
      </Box>
    {(data.hotspots && data.hotspots[ActiveID])?<Dialog texts={texts} txtlang={txtlang}  playclicksfx={()=>{}} dialogBtnRef={dialogBtnRef} isTutorial={true} data={data.hotspots[ActiveID]} handleClickOpen={handleClickOpen} handleClose={handleClose} openDialog={openDialog} /> :null}   
      {(!isImg && (CurrentExam.game && videotag)&&!isNaN(videotag.duration))?<VideoController playclicksfx={playclicksfx}  openstopPopup={openstopPopup} disableSlider={CurrentExam.game!=='risk'} setcurrentTime={setcurrentTime} currentTime={currentTime} totalTime={videotag.duration} settimeLeft={settimeLeft} Played={Played}  setPlayed={setPlayed} /> :null} 
      {openQuiz?<Quiz texts={texts} txtlang={txtlang}  playclicksfx={playclicksfx} playredTimersfxsfx={playredTimersfxsfx} stopsfx={stop} handleSubmit={handleSubmit} SetIsActive={SetIsActive} IsActive={IsActive} restart={restart} openQuiz={true} data={CurrentExam} setopenQuiz={setopenQuiz}  />:null}
      {(CurrentExam.Question && openFRE)?<FRE texts={texts} txtlang={txtlang}  playclicksfx={playclicksfx}playredTimersfxsfx={playredTimersfxsfx} stopsfx={stop} handleSubmit={handleSubmit}  SetIsActive={SetIsActive} IsActive={IsActive} restart={restart} setopenFRE={setopenFRE} data={CurrentExam}  openQuiz={openFRE} />:null} 
      {!Played? <Ready texts={texts} txtlang={txtlang}  btnTut={true} playclicksfx={playclicksfx} setcloseRotateAnimation={setcloseRotateAnimation} Interval={Interval} setPlayed={setPlayed}/>:null}
     
   </>:<Splash texts={texts} txtlang={txtlang}  moduleName={CurrentExam.MODULE} title={"Tutorial"} Sectiontitle={texts.splshtut[txtlang]}  loadingProgress={loadingProgress}/> }   
        </> 
  );
} 